<div class="tooltip">
  <div class="columns-container">
    <ph-flex-tooltip-column-left>
      <ng-container *ngFor="let row of rows; trackBy: trackByRow">
        <ng-container *ngTemplateOutlet="getLeftColumnTemplate(row); context: { $implicit: row }"></ng-container>
      </ng-container>
    </ph-flex-tooltip-column-left>
    <ph-flex-tooltip-column-right>
      <ng-container *ngFor="let row of rows; trackBy: trackByRow">
        <ng-container *ngTemplateOutlet="getRightColumnTemplate(row); context: { $implicit: row }"></ng-container>
      </ng-container>
    </ph-flex-tooltip-column-right>
  </div>
</div>
