import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AnnouncementsPageComponent } from './announcements-page/announcements-page.component';
import { CanDeactivateIdconsAnnouncementsPage } from './can-deactivate-idcons-announcements-page-guard.service';

const routes: Routes = [
  {
    path: '',
    component: AnnouncementsPageComponent,
    canDeactivate: [CanDeactivateIdconsAnnouncementsPage]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class IdconsAnnouncementsRoutingModule {}
