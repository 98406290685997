import { AppInjector, FrontendPaginationHelper, OnDestroyProvider, PaginatedDataSource, R3Activation } from 'flex-app-shared';
import { get } from 'lodash-es';
import { combineLatest, ReplaySubject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { IncidentReserveAvailabilityFacade } from '../../../store/incident-reserve-availability/incident-reserve-availability.facade';

export class IncidentReserveActivationsScreenDataSource extends PaginatedDataSource<R3Activation> {
  loadDataSubject = new ReplaySubject(1);

  paginationHelper = new FrontendPaginationHelper<R3Activation>((data, sortHeaderId) => {
    if (sortHeaderId === 'date' || sortHeaderId === 'timeSlot') {
      return get(data, 'period').startDateTime;
    }
    if (sortHeaderId === 'target') {
      return get(data, sortHeaderId)?.value;
    }
    return get(data, sortHeaderId);
  });
  facade = AppInjector.get(IncidentReserveAvailabilityFacade);

  constructor(onDestroyProvider: OnDestroyProvider) {
    super();

    onDestroyProvider.onDestroy$.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.ngOnDestroy());

    combineLatest([this.facade.activations$, this.loadDataSubject])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([newResult, loadDataInfo]) => {
        const { pageIndex, pageSize, searchTerm, sort } = loadDataInfo as any;

        this.paginationHelper.updateData(newResult);
        this.dataSubject.next(this.paginationHelper.handleLoadData(pageIndex, pageSize, searchTerm, sort));
        this.updatePaginator(pageIndex, pageSize, this.paginationHelper.filteredData.length);
      });
  }

  loadData(pageIndex: number | undefined, pageSize: number | undefined, searchTerm: string | undefined, sort: string | undefined): void {
    this.loadDataSubject.next({
      pageIndex,
      pageSize,
      searchTerm,
      sort
    });
  }
}
