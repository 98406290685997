<div class="ph-g-container-page container-page">
  <div class="flex-row">
    <div class="col-11">
      <router-outlet></router-outlet>
    </div>
    <div class="col-1">
      <div class="sticky-sidebar">
        <div class="flex-row app-countdown">
          <app-countdown class="col-1"></app-countdown>
        </div>
        <div class="flex-row">
          <div class="selected-deals">
            <mat-card>
              <mat-card-header>
                <mat-card-title>
                  <mat-icon svgIcon="ic-old:invoice-orders"></mat-icon>
                  <ng-container i18n="@@intraday-headerSelectedDeals">Selected deals</ng-container>
                </mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <ng-container *ngIf="bidSummary$ | async; let bidSummary">
                  <h4 class="bid" i18n="@@intraday-sell">Sell</h4>
                  <ng-container *ngTemplateOutlet="summary; context: { summary: bidSummary }"></ng-container>
                </ng-container>
                <hr />
                <ng-container *ngIf="askSummary$ | async; let askSummary">
                  <h4 class="ask" i18n="@@intraday-buy">Buy</h4>
                  <ng-container *ngTemplateOutlet="summary; context: { summary: askSummary }"></ng-container>
                </ng-container>
                <hr />
              </mat-card-content>
              <mat-card-actions>
                <span
                  matTooltip="Validation error. Please check the volumes."
                  i18n-matTooltip="@@intraday-deals-invalid"
                  [matTooltipDisabled]="!(confirmDealsDisabledBasedOnDealAmount$ | async)"
                >
                  <button mat-flat-button color="primary" (click)="confirmDeals()" [disabled]="confirmDealsDisabled$ | async">
                    <ph-flex-vertical-center>
                      <mat-icon svgIcon="ic-old:invoice-orders"></mat-icon>
                      <span i18n="@@intraday-confirmDeals">Confirm deals</span>
                    </ph-flex-vertical-center>
                  </button>
                </span>
              </mat-card-actions>
            </mat-card>
          </div>
        </div>
        <div class="flex-row">
          <app-intraday-deal-status></app-intraday-deal-status>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #confirmDialog>
  <div class="full-width-dialog">
    <mat-dialog-content>
      <p i18n="@@intraday-dialogConfirmTransaction" class="summary-box">You are about to irrevocably confirm this transaction.</p>
      <p
        i18n="@@intraday-dialogConfirmTransactionWithOpenOrders"
        *ngIf="(hasOpenOrdersForDraftDeals$ | async) && !(saveError$ | async)"
        class="summary-box warning"
      >
        One or more open orders will be cancelled.
      </p>
      <p *ngIf="saveError$ | async; let saveError" class="summary-box alert">
        <em>{{ saveError }}</em>
        <br />
        <ng-container i18n="@@intraday-textsStepSummaryAlertActionFallback">Try again or contact support.</ng-container>
      </p>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-stroked-button mat-dialog-close color="primary" i18n="@@intraday-buttonCancel">Cancel</button>
      <ph-flex-save-button
        (clickChanged)="submit()"
        [error]="saveError$ | async"
        [pending]="savePending$ | async"
        [shouldGetInitialFocus]="true"
        [minDisabledTimeAfterClick]="500"
        i18n="@@intraday-buttonOk"
      >
        OK
      </ph-flex-save-button>
    </mat-dialog-actions>
  </div>
</ng-template>

<ng-template #summary let-summary="summary">
  <p i18n="@@intraday-summaryDraftDeal">
    {{ summary.numberOfDeals | number: '0.0-0' }} { summary.numberOfDeals, plural, =0 { deals } =1 {deal} other {deals}} selected with a
    total volume of
    <em>
      <ph-flex-number [value]="summary.volume"></ph-flex-number>
      kWh</em
    >.<br />
    With an average price of
    <em>
      <ph-flex-number [value]="summary.averagePrice" [digitsInfo]="'0.2-2'"></ph-flex-number>
      €/MWh</em
    >.
  </p>
</ng-template>
