<div class="logo-container" [ngSwitch]="isExpanded">
  <ng-container *ngSwitchCase="true">
    <ng-content select="[slot=logo]"></ng-content>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <ng-content select="[slot=logo-small]"></ng-content>
  </ng-container>
</div>
<div class="menu-items">
  <ng-content></ng-content>
</div>
<div class="expand-collapse">
  <div class="toggle" (click)="toggleExpand()">
    <div class="handle" [ngSwitch]="isExpanded">
      <mat-icon svgIcon="ic-new:chevrons-left" *ngSwitchCase="true"></mat-icon>
      <mat-icon svgIcon="ic-new:chevrons-right" *ngSwitchDefault></mat-icon>
    </div>
  </div>
</div>
