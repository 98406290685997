<th mat-header-cell *matHeaderCellDef colspan="100">
  <div class="context-container">
    <ng-container *ngIf="allRowsSelected$ | async; else pageSelected">
      <span i18n="@@tableMultiSelect-allSelectedText"
        >All <span class="selected-count">{{ selectedRowCount$ | async }}</span> items are selected.</span
      >
      <a (click)="clearSelection()" i18n="@@tableMultiSelect-undoSelectionAction">Undo selection</a>
    </ng-container>
  </div>
</th>

<ng-template #pageSelected>
  <span i18n="@@tableMultiSelect-pageSelectedText"
    >All <span class="selected-count">{{ selectedRowCount$ | async }}</span> items on this page are selected.</span
  >
  <a (click)="selectAll()" i18n="@@tableMultiSelect-selectAllAction">Select all {{ totalRowCount$ | async }} items</a>
</ng-template>
