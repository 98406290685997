import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { IntradayHistoryState } from './intraday-history-state.service';

@Injectable({
  providedIn: 'root'
})
export class IntraDayHistoryFacade {
  constructor(private store: Store) {}

  selectedGridPoint$ = this.store.select(IntradayHistoryState.selectedGridPoint);
  selectedFilters$ = this.store.select(IntradayHistoryState.selectedFilters);

  showAllDeals$ = this.store.select(IntradayHistoryState.showAllDeals);
}
