import { AfterViewInit, Component, Directive, Host, Inject, Injectable, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { FormGroupProvider } from '../form-group-provider';
import { UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import {
  AppInjector,
  AUTOCOMPLETE_DATA_SOURCE,
  AUTOCOMPLETE_DATA_SOURCE_FILTER,
  AutocompleteComponent,
  Customer,
  CustomerDatasource,
  CustomerService,
  DataSourceFilter,
  GridPoint,
  GridPointDatasource,
  GridPointService,
  IntradayControl,
  MixinBase,
  OnDestroyMixin,
  PaginatedDataSource,
  ControlFilterProvider,
  DestroyableMixin,
  FlexNgXsFormSync,
  FrontendPaginationHelper
} from 'flex-app-shared';
import { MatStep, MatStepLabel } from '@angular/material/stepper';
import { IntradayFacade } from '../../../../state/intraday-facade.service';
import { combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { Router } from '@angular/router';
import { map, takeUntil } from 'rxjs/operators';
import { get, without } from 'lodash-es';
import { SelectionModel, CollectionViewer } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Injectable()
export class GopacsCustomerDatasource extends CustomerDatasource {
  dataSource$ = this.facade.idconsCustomers$ as Observable<Customer[]>;

  constructor(customerService: CustomerService, private facade: IntradayFacade) {
    super(customerService);
  }

  displayEntityWith(value: Customer): string {
    return `${value.legalName}`;
  }
}

@Directive({
  selector: '[appGopacsCustomerDataSource]',
  providers: [
    {
      provide: AUTOCOMPLETE_DATA_SOURCE,
      useClass: GopacsCustomerDatasource,
      deps: [CustomerService, IntradayFacade]
    }
  ]
})
export class GopacsCustomerDatasourceDirective {}

@Injectable()
export class GopacsGridpointDatasource extends GridPointDatasource {
  dataSource$ = this.facade.idconsGridPoints$ as Observable<GridPoint[]>;

  constructor(gridPointService: GridPointService, @Optional() router: Router, private facade: IntradayFacade) {
    super(gridPointService, router);
  }
}

@Injectable()
export class DataSourceFilterByCustomerId implements DataSourceFilter<any> {
  customerIdSubject = new ReplaySubject(1);

  getFilter(values: any[]): Observable<boolean[]> {
    return this.customerIdSubject.asObservable().pipe(
      map((customerId) => {
        if (customerId) {
          return values.map((value) => get(value, 'customerId') === customerId);
        } else {
          return values.map(() => true);
        }
      })
    );
  }
}

@Directive({
  selector: '[appGopacsGridPointDataSource]',
  providers: [
    {
      provide: AUTOCOMPLETE_DATA_SOURCE,
      useClass: GopacsGridpointDatasource,
      deps: [GridPointService, Router, IntradayFacade]
    }
  ]
})
export class GopacsGridPointDatasourceDirective implements OnInit {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('phFlexGridPointDataSource') config: { clearSelectionWhenNoCustomerSelected: boolean } = {
    clearSelectionWhenNoCustomerSelected: false
  };

  constructor(@Inject(AUTOCOMPLETE_DATA_SOURCE) private gridPointDatasource: GridPointDatasource) {}

  ngOnInit(): void {
    this.gridPointDatasource.clearSelectionWhenNoCustomerSelected = this.config.clearSelectionWhenNoCustomerSelected;
  }
}

@Component({
  selector: 'app-step-selection',
  templateUrl: './step-selection.component.html',
  styleUrls: ['./step-selection.component.scss']
})
export class StepSelectionComponent extends OnDestroyMixin(MixinBase) implements FormGroupProvider, OnInit, AfterViewInit {
  dataSource = new IdconsControlDataSource();

  @FlexNgXsFormSync('intraday.order.selectedControlIds', { subPath: 'controlIds', initialSyncOnlyTruthyFormValues: false })
  formGroup = this.fb.group({
    gridPointId: [''],
    customerId: ['']
  });

  displayedColumns = ['select', 'gridPointDescription', 'controlDescription'];

  @ViewChild(MatStepLabel) stepLabel: MatStepLabel;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(@Host() private matStep: MatStep, private fb: UntypedFormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.dataSource.customerIdFilterProvider.register(this.formGroup.get('customerId'));
    this.dataSource.gridPointIdFilterProvider.register(this.formGroup.get('gridPointId'));

    this.formGroup.addControl('controlIds', this.dataSource.controlIdSelectionModelAdapter.control);

    this.matStep.stepControl = this.formGroup;

    this.sort.active = 'controlDescription';
    this.sort.direction = 'desc';
  }

  ngAfterViewInit(): void {
    this.matStep.stepLabel = this.stepLabel;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  showAllSelected(): void {
    this.formGroup.reset();

    if (this.sort.active === 'select' && this.sort.direction === 'desc') {
      return;
    }

    this.sort.sort({ id: 'select', start: 'desc', disableClear: false });
  }
}

/**
 * Set up an adapter for a selectionModel to behave like a formControl with Required validation
 */
export class SelectionModelControlAdapter<T> extends DestroyableMixin(OnDestroyMixin(MixinBase)) {
  selectionModel: SelectionModel<T>;
  control = new UntypedFormControl([], { validators: [Validators.required] });

  registerSelectionModel(selectionModel: SelectionModel<T>): void {
    this.selectionModel = selectionModel;

    this.selectionModel.changed.pipe(takeUntil(this.onDestroy$)).subscribe(({ source }) => {
      this.control.setValue(source.selected);
    });
  }
}

export class IdconsControlDataSource extends PaginatedDataSource<IntradayControl> {
  intraDayFacade = AppInjector.get(IntradayFacade);

  paginationHelper = new FrontendPaginationHelper<IntradayControl>((data, sortHeaderId) => {
    if (sortHeaderId === 'select') {
      return this.controlIdSelectionModel.isSelected(data.controlId);
    }
    return get(data, sortHeaderId);
  });

  originalDataSubject = new ReplaySubject<IntradayControl[]>(1);
  loadDataSubject = new ReplaySubject(1);

  controlIdSelectionModel: SelectionModel<string> = new SelectionModel(true);
  controlIdSelectionModelAdapter = new SelectionModelControlAdapter(this);

  gridPointIdFilterProvider = new ControlFilterProvider(this);
  customerIdFilterProvider = new ControlFilterProvider(this);

  allSelected$ = this.controlIdSelectionModel.changed.pipe(map((change) => this.controlIdSelectionModel.selected));

  notVisibleSelected$ = this.data$.pipe(
    map((data) => without(this.controlIdSelectionModel.selected, ...data.map((item) => item.controlId)))
  );

  constructor() {
    super();
    this.refreshDataAlwaysObservables.push(this.gridPointIdFilterProvider.onChanges$, this.customerIdFilterProvider.onChanges$);
    this.controlIdSelectionModelAdapter.registerSelectionModel(this.controlIdSelectionModel);
  }

  connect(collectionViewer: CollectionViewer): Observable<IntradayControl[]> {
    this.intraDayFacade.idconsControls$.pipe(takeUntil(this.onDestroy$)).subscribe((result) => {
      this.originalDataSubject.next(result);
    });

    combineLatest([this.originalDataSubject, this.loadDataSubject])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([originalData, loadDataInfo]) => {
        const { pageIndex, pageSize, searchTerm, sort } = loadDataInfo as any;

        let newResult = originalData;
        if (this.gridPointIdFilterProvider.item?.value) {
          newResult = newResult.filter((resultItem) => resultItem.gridPointId === this.gridPointIdFilterProvider.item.value);
        }
        if (this.customerIdFilterProvider.item?.value) {
          newResult = newResult.filter((resultItem) => resultItem.customerId === this.customerIdFilterProvider.item.value);
        }

        this.paginationHelper.updateData(newResult);

        this.dataSubject.next(this.paginationHelper.handleLoadData(pageIndex, pageSize, searchTerm, sort));
        this.updatePaginator(pageIndex, pageSize, this.paginationHelper.length);
      });

    return super.connect(collectionViewer);
  }

  loadData(pageIndex: number | undefined, pageSize: number | undefined, searchTerm: string | undefined, sort: string | undefined): void {
    this.loadDataSubject.next({
      pageIndex,
      pageSize,
      searchTerm,
      sort
    });
  }
}
