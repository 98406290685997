import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenService } from 'flex-app-shared';
import { Observable } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';

export class UserPreferences {
  userName: string;
  name: string | null;
  intraDayOrderMatchedEmailEnabled: boolean;
  intraDayOrdersRequestedEmailEnabled: boolean;
  intraDayOrdersRequestedSmsEnabled: boolean;
}

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class UserPreferenceService {
  uri = '/api/v1/user-preferences';

  constructor(private http: HttpClient, private tokenService: TokenService) {}

  getByUserName(): Observable<UserPreferences> {
    return this.tokenService.subject$.pipe(
      first(),
      switchMap((userName) => this.http.get<UserPreferences>(`${this.uri}/${userName}`))
    );
  }

  update(userPreferences: UserPreferences): Observable<UserPreferences> {
    return this.tokenService.subject$.pipe(
      first(),
      switchMap((userName) => this.http.put<UserPreferences>(`${this.uri}/${userName}`, { ...userPreferences }, httpOptions))
    );
  }
}
