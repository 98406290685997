import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { IncidentReserveActivationsScreenComponent } from './incident-reserve-activations-screen/incident-reserve-activations-screen.component';
import { IncidentReserveAvailabilityScreenComponent } from './incident-reserve-availability-screen/incident-reserve-availability-screen.component';
import { DefaultCustomerGuardService, DummyComponent } from 'flex-app-shared';
import { IncidentReserveAvailabilityToolbarContainerComponent } from './incident-reserve-availability-toolbar-container/incident-reserve-availability-toolbar-container.component';
import { ActivateState, DeactivateState } from '../../store/incident-reserve-availability/route-active';
import { IncidentReserveActivationsDetailScreenComponent } from './incident-reserve-activations-detail-screen/incident-reserve-activations-detail-screen.component';
import { DummyContainerComponent } from '../../../../flex-app-shared/src/lib/core/dummy/dummy-container.component';

const routes: Routes = [
  {
    path: '',
    component: IncidentReserveAvailabilityToolbarContainerComponent,
    canActivate: [ActivateState],
    canDeactivate: [DeactivateState],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'availability'
      },
      {
        path: 'availability',
        component: IncidentReserveAvailabilityScreenComponent,
        canActivate: [DefaultCustomerGuardService],
        children: [
          {
            path: ':customerId',
            component: DummyComponent,
            children: [
              {
                path: ':dateRange',
                component: DummyComponent,
                children: [
                  {
                    path: ':serviceAgreementId',
                    component: DummyComponent
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: 'activations',
        component: DummyContainerComponent,
        canActivate: [DefaultCustomerGuardService],
        children: [
          {
            path: ':customerId',
            component: IncidentReserveActivationsScreenComponent,
            children: [
              {
                path: ':dateRange',
                component: DummyComponent,
                children: [
                  {
                    path: ':serviceAgreementId',
                    component: DummyComponent
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: 'activation/:activationId/:serviceAgreementId',
        data: {
          showBackButtonOnly: true
        },
        component: IncidentReserveActivationsDetailScreenComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class IncidentReserveRoutingModule {}
