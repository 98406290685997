/**
 * When a service agreement is active filter that to pick a default service agreement
 * When no service agreements are active check if there is one in the future.
 * When no service agreement is active or in the future use the most recent in the past.
 */
import { R3ServiceAgreement } from 'flex-app-shared';
import { head, orderBy } from 'lodash-es';
import { addDays, isAfter, isSameDay, isWithinInterval } from 'date-fns';

export function getDefaultServiceAgreement(serviceAgreements: R3ServiceAgreement[]): R3ServiceAgreement {
  const serviceAgreementsActive = serviceAgreements.filter(isActive);
  const serviceAgreementsInFuture = serviceAgreements.filter(isInFuture);
  const serviceAgreementsInPast = serviceAgreements.filter(isInPast);

  /**
   *  - first checks for earliest startDate
   *  - when startDate is equal check for earliest endDate
   *  - when startDate and endDate are equal check for systemReference
   *
   *  Note: systemReference is unique, and it is the last resort.
   */
  return head(
    [
      head(orderBy(serviceAgreementsActive, ['period.startDate', 'period.endDate', 'systemReference'], ['asc', 'asc', 'desc'])),
      head(orderBy(serviceAgreementsInFuture, ['period.startDate', 'period.endDate', 'systemReference'], ['asc', 'asc', 'desc'])),
      head(orderBy(serviceAgreementsInPast, ['period.startDate', 'period.endDate', 'systemReference'], ['desc', 'desc', 'desc']))
    ].filter((a) => !!a)
  );
}

function isActive(serviceAgreement: R3ServiceAgreement): boolean {
  const tomorrow = addDays(new Date(), 1);
  const start = new Date(serviceAgreement.period.startDate);
  const end = new Date(serviceAgreement.period.endDate);

  return isSameDay(start, tomorrow) || isSameDay(end, tomorrow) || isWithinInterval(tomorrow, { start, end });
}

function isInFuture(serviceAgreement: R3ServiceAgreement): boolean {
  const tomorrow = addDays(new Date(), 1);
  const start = new Date(serviceAgreement.period.startDate);
  const end = new Date(serviceAgreement.period.endDate);

  return isAfter(start, tomorrow) && isAfter(end, tomorrow);
}

function isInPast(serviceAgreement: R3ServiceAgreement): boolean {
  const tomorrow = addDays(new Date(), 1);
  const start = new Date(serviceAgreement.period.startDate);
  const end = new Date(serviceAgreement.period.endDate);

  return isAfter(tomorrow, start) && isAfter(tomorrow, end);
}
