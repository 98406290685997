import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoIconComponent } from './info-icon/info-icon.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FlexCoreModule } from '../../core/core.module';

@NgModule({
  declarations: [InfoIconComponent],
  imports: [CommonModule, MatIconModule, MatTooltipModule, FlexCoreModule],
  exports: [InfoIconComponent]
})
export class FlexInfoIconModule {}
