import { Injectable } from '@angular/core';
import { Action, Selector, SelectorOptions, State, StateContext, Store } from '@ngxs/store';
import { isAfter, parseISO, subHours } from 'date-fns';
import {
  AsyncMessageReceivedEvent,
  AsyncMessagingService,
  AuthorityService,
  FlexStorageEvent,
  getRetryConfig,
  IDCONS_ANNOUNCEMENT_IDS_READ_STORAGE_KEY,
  MessageType,
  StorageFactory,
  StorageHelper,
  VIEW_INTRA_DAY_IDCONS_ORDER
} from 'flex-app-shared';
import { groupBy, max, min, orderBy } from 'lodash-es';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { filter, tap } from 'rxjs/operators';
import {
  IdconsAnnouncement,
  IdconsAnnouncementStateEnum,
  IdconsAnnouncementType
} from '../../app/shared/idcons/announcement/idcons-announcement';
import { IdconsAnnouncementService } from '../../app/shared/idcons/announcement/idcons-announcement.service';
import {
  IdconsActiveAnnouncementsMarkAsReadCommand,
  IdconsAnnouncementsLoadedEvent,
  IdconsAnnouncementsMarkAllAsReadCommand,
  IdconsAnnouncementSseMessageReceived,
  LoadIdconsAnnouncementsCommand
} from './idcons-announcements.actions';
import { IdconsAnnouncementsPerDay } from './idcons-announcements.facade';
import { recomputeAnnouncementStateBasedOnTime } from '../../app/idcons-announcements/announcements/announcement/announcement.utils';

export class LastReadIdconsAnnouncementStorageHelper<T> extends StorageHelper<T> {
  constructor() {
    super(StorageFactory.localStorageFactoryWithFallback(), IDCONS_ANNOUNCEMENT_IDS_READ_STORAGE_KEY);
  }
}

export const idconsAnnouncementStorageHelper = new LastReadIdconsAnnouncementStorageHelper<string[]>();

export class IdconsAnnouncementStateModel {
  model: IdconsAnnouncement[] = [];
  busyFetching: boolean = false;
  lastReadAnnouncementIds = idconsAnnouncementStorageHelper.fetch();
}

@State({
  name: 'idconsAnnouncements',
  defaults: new IdconsAnnouncementStateModel()
})
@Injectable({
  providedIn: 'root'
})
@SelectorOptions({
  injectContainerState: false,
  suppressErrors: false
})
export class IdconsAnnouncementState {
  constructor(
    private idconsAnnouncementService: IdconsAnnouncementService,
    private store: Store,
    authorityService: AuthorityService,
    asyncMessagingService: AsyncMessagingService
  ) {
    authorityService
      .hasAuthorities(VIEW_INTRA_DAY_IDCONS_ORDER)
      .pipe(filter((hasViewIdcons) => !!hasViewIdcons))
      .subscribe(() => {
        setTimeout(() => store.dispatch(new LoadIdconsAnnouncementsCommand()));

        const loadAnnouncements$ = idconsAnnouncementService.loadAnnouncements().pipe(
          tap((result) => {
            store.dispatch(new IdconsAnnouncementsLoadedEvent(result));
          })
        );
        asyncMessagingService
          .onMessageOfTypeWithInit(
            loadAnnouncements$,
            MessageType.IdconsAnnouncementClosedSseEvent,
            MessageType.IdconsAnnouncementLogicallyDeletedSseEvent,
            MessageType.IdconsAnnouncementCreatedSseEvent,
            MessageType.IdconsAnnouncementUpdatedSseEvent
          )
          .subscribe((sseMessage) => {
            store.dispatch(new IdconsAnnouncementSseMessageReceived(sseMessage));
          });
      });
  }

  @Selector([IdconsAnnouncementState])
  public static lastReadAnnouncementIds(state: IdconsAnnouncementStateModel): string[] {
    return state.lastReadAnnouncementIds;
  }

  @Selector([IdconsAnnouncementState])
  public static isBusyFetching(state: IdconsAnnouncementStateModel): boolean {
    return state.busyFetching;
  }

  @Selector([IdconsAnnouncementState])
  public static announcements(state: IdconsAnnouncementStateModel): IdconsAnnouncement[] {
    return state.model;
  }

  @Selector([IdconsAnnouncementState.announcements])
  public static anouncementsSortedByDate(announcements: IdconsAnnouncement[]): IdconsAnnouncement[] {
    return orderBy(
      announcements,
      [({ problemPeriod, createdDateTime }) => (problemPeriod ? problemPeriod.startDateTime : createdDateTime), 'problemPeriod.toDateTime'],
      ['desc', 'desc']
    );
  }

  @Selector([IdconsAnnouncementState.anouncementsSortedByDate])
  public static activeAnnouncementsSortedByDate(anouncementsSortedByDate: IdconsAnnouncement[]): IdconsAnnouncement[] {
    return anouncementsSortedByDate.filter((announcement) => {
      const announcementState = recomputeAnnouncementStateBasedOnTime(announcement);
      return (
        (announcementState !== IdconsAnnouncementStateEnum.ANNOUNCEMENT_CLOSED &&
          announcementState !== IdconsAnnouncementStateEnum.ANNOUNCEMENT_DELETED &&
          announcement.type !== IdconsAnnouncementType.FREE_TEXT) ||
        (announcement.type === IdconsAnnouncementType.FREE_TEXT &&
          isAfter(parseISO(announcement.createdDateTime), subHours(new Date(), 36)))
      );
    });
  }

  @Selector([IdconsAnnouncementState.anouncementsSortedByDate])
  public static announcementsGroupedByDay(sortedAnnouncements: IdconsAnnouncement[]): IdconsAnnouncementsPerDay {
    return groupBy(sortedAnnouncements, (announcement) => announcement.day);
  }

  @Selector([IdconsAnnouncementState.announcements])
  public static getEnrichedAnnouncementsGroupedByDayAndSortedByDate(sortedAnnouncements: IdconsAnnouncement[]): IdconsAnnouncement[] {
    return sortedAnnouncements.map((announcement) => {
      const problemProfileMinMW = announcement.problemProfile ? min(announcement.problemProfile) : 0;
      const problemProfileMaxMW = announcement.problemProfile ? max(announcement.problemProfile) : 0;
      return { ...announcement, problemProfileMinMW, problemProfileMaxMW };
    });
  }

  @Selector([
    IdconsAnnouncementState.announcements,
    IdconsAnnouncementState.activeAnnouncementsSortedByDate,
    IdconsAnnouncementState.lastReadAnnouncementIds
  ])
  public static unreadActiveAnnouncements(
    announcements: IdconsAnnouncement[],
    activeAnnouncements: IdconsAnnouncement[],
    lastReadAnnouncementIds: string[]
  ): IdconsAnnouncement[] {
    return activeAnnouncements.filter(({ id }) => !lastReadAnnouncementIds?.includes(id));
  }

  @Selector([IdconsAnnouncementState.activeAnnouncementsSortedByDate, IdconsAnnouncementState.lastReadAnnouncementIds])
  public static unreadActiveAnnouncementCount(activeAnnouncements: IdconsAnnouncement[], lastReadAnnouncementIds: string): number {
    return activeAnnouncements.filter(({ id }) => !lastReadAnnouncementIds?.includes(id)).length;
  }

  @Action(LoadIdconsAnnouncementsCommand, { cancelUncompleted: true })
  loadAnnouncements({ patchState, dispatch }: StateContext<IdconsAnnouncementStateModel>): Observable<IdconsAnnouncement[]> {
    patchState({ busyFetching: true, model: [] });

    return this.idconsAnnouncementService.loadAnnouncements().pipe(
      retry(getRetryConfig({ excludedStatusCodes: [] })),
      tap((announcements) => {
        dispatch(new IdconsAnnouncementsLoadedEvent(announcements));
      })
    );
  }

  @Action(IdconsAnnouncementsLoadedEvent)
  handleIdconsAnnouncementsLoadedEvent(
    { patchState }: StateContext<IdconsAnnouncementStateModel>,
    event: IdconsAnnouncementsLoadedEvent
  ): void {
    patchState({ busyFetching: false, model: event.announcements });
  }

  @Action(IdconsActiveAnnouncementsMarkAsReadCommand)
  handleIdconsActiveAnnouncementsMarkAsReadCommand(
    { getState, patchState }: StateContext<IdconsAnnouncementStateModel>,
    { activeAnnouncements }: IdconsActiveAnnouncementsMarkAsReadCommand
  ): void {
    // Only store if not stored already
    const idsToStore = activeAnnouncements.filter((id) => !getState().lastReadAnnouncementIds?.includes(id));

    const oldState = getState().lastReadAnnouncementIds || [];

    if (idsToStore.length) {
      patchState({
        lastReadAnnouncementIds: [...oldState, ...idsToStore]
      });

      idconsAnnouncementStorageHelper.store(getState().lastReadAnnouncementIds);
    }
  }

  @Action(IdconsAnnouncementsMarkAllAsReadCommand)
  handleIconsAnnouncementsMarkAllAsReadCommand({ getState, patchState }: StateContext<IdconsAnnouncementStateModel>): void {
    // The way it works does not allow to just clear 'lastReadAnnouncementIds' and use an empty array.
    // That would mean all messages are unread.

    const allUnreadAnnouncementsIds = this.store.selectSnapshot(IdconsAnnouncementState.unreadActiveAnnouncements).map(({ id }) => id);

    const oldState = getState().lastReadAnnouncementIds || [];

    patchState({
      lastReadAnnouncementIds: [...oldState, ...allUnreadAnnouncementsIds]
    });

    idconsAnnouncementStorageHelper.store(getState().lastReadAnnouncementIds);
  }

  @Action(FlexStorageEvent)
  handleFlexStorageEvent({ patchState }: StateContext<IdconsAnnouncementStateModel>, event: FlexStorageEvent): void {
    if (idconsAnnouncementStorageHelper.isMatchingEvent(event)) {
      patchState({
        lastReadAnnouncementIds: idconsAnnouncementStorageHelper.fetch()
      });
    }
  }

  @Action(IdconsAnnouncementSseMessageReceived)
  handleSseMessageReceivedEvent(
    { patchState, getState }: StateContext<IdconsAnnouncementStateModel>,
    event: AsyncMessageReceivedEvent
  ): void {
    switch (event.message.type) {
      case MessageType.IdconsAnnouncementClosedSseEvent:
      case MessageType.IdconsAnnouncementLogicallyDeletedSseEvent:
        // Close/Delete
        const eventId = JSON.parse(event.message.payload)?.id;
        patchState({
          model: getState().model.map((announcement) => {
            if (announcement.id !== eventId) {
              return announcement;
            }
            return {
              ...announcement,
              announcementState:
                event.message.type === MessageType.IdconsAnnouncementClosedSseEvent
                  ? IdconsAnnouncementStateEnum.ANNOUNCEMENT_CLOSED
                  : IdconsAnnouncementStateEnum.ANNOUNCEMENT_DELETED
            };
          })
        });
        break;
      case MessageType.IdconsAnnouncementCreatedSseEvent:
        // Create
        const newAnnouncement: IdconsAnnouncement = JSON.parse(event.message.payload);
        patchState({
          model: [newAnnouncement, ...getState().model]
        });
        break;
      case MessageType.IdconsAnnouncementUpdatedSseEvent:
        // Update
        const update: IdconsAnnouncement = JSON.parse(event.message.payload);
        patchState({
          model: getState().model.map((announcement) => {
            if (announcement.id !== update.id) {
              return announcement;
            }
            return {
              ...announcement,
              ...update
            };
          })
        });
    }
  }
}
