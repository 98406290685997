import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableSelectionModelDirective } from './table-selection-model.directive';
import { TableSelectCheckboxCellComponent } from './table-select-checkbox-cell.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';
import { TableSelectionActionsCellComponent } from './table-selection-actions-cell/table-selection-actions-cell.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { FlexSaveButtonModule } from '../save-button/save-button.module';
import { FlexCoreModule } from '../../core/core.module';
import { TableSelectionContextCellComponent } from './table-selection-context-cell/table-selection-context-cell.component';
import { TableSelectionContextRowDirective } from './table-selection-context-row/table-selection-context-row.directive';
import { HideRowWithNoColumnsDirective } from './hide-row-with-no-columns.directive';

@NgModule({
  declarations: [
    TableSelectionModelDirective,
    TableSelectCheckboxCellComponent,
    TableSelectionActionsCellComponent,
    TableSelectionContextCellComponent,
    TableSelectionContextRowDirective,
    HideRowWithNoColumnsDirective
  ],
  imports: [
    CommonModule,
    MatCheckboxModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    FlexSaveButtonModule,
    FlexCoreModule
  ],
  exports: [
    TableSelectionModelDirective,
    TableSelectCheckboxCellComponent,
    TableSelectionActionsCellComponent,
    TableSelectionContextCellComponent,
    TableSelectionContextRowDirective,
    HideRowWithNoColumnsDirective
  ]
})
export class FlexTableMultiSelectModule {}
