import { MatDialogRef } from '@angular/material/dialog';
import {
  DealableControlIdsPerDutchDay,
  Direction,
  IntradayControl,
  IntradayDealStatus,
  IntraDayDealStatusUpdate,
  IntraDayJson,
  IntraDayPosition,
  IntraDayPricesReceivedData,
  MandatoryTradeDirection,
  PaginatedResponse,
  PostPendingIntradayDeal,
  PriceHistoryJson,
  RecentDeal,
  TimeSlot
} from 'flex-app-shared';
import { IntradaySlotStatus } from './intraday-state.service';

export class UpdateControlDealsCommand {
  public static type: string = '[IntraDay] Set deals for slot';

  constructor(
    public startDateTime: string,
    public toDateTime: string,
    public direction: Direction,
    public deals: ControlDeal[],
    public priceId: string
  ) {}
}

export class UpdateLatestIntraDayInformationCommand {
  public static type: string = '[IntraDay] Update available slots';

  constructor(
    public force: boolean = true // Force update even if initialized
  ) {}
}

export class UpdateLatestIntraDayDealsCommand {
  public static type: string = '[IntraDay] Update available deals';

  constructor(public pageIndex?: number, public pageSize?: number, public sort?: string, public filter?: any) {}
}

export class UpdateTodaysIntraDayDealsCommand {
  public static type: string = `[IntraDay] Update today's available deals`;

  constructor(
    public pageIndex?: number,
    public pageSize?: number,
    public searchTerm?: string,
    public sort?: string,
    public searchCategory?: string,
    public customerId?: string,
    public dealStatus?: IntradayDealStatus,
    public direction?: Direction
  ) {}
}

export class ClearIntradayErrorStateCommand {
  public static type: string = '[IntraDay] Clear any intraday deal error state';
}

export class ClearIntraDayDraftDealsCommand {
  public static type: string = '[IntraDay] Clear any intraday draft deals';

  constructor(public dialogRef?: MatDialogRef<any>) {}
}

export class ConfirmIntradayDealsCommand {
  public static type: string = '[IntraDay] Confirm current intraday deals';

  constructor(public dialogRef: MatDialogRef<any>) {}
}

export class IntraDayDealsReceivedEvent {
  public static type: string = '[IntraDay] Deals received';

  constructor(public deals: PaginatedResponse<PostPendingIntradayDeal>, public showOrderTypeColumn: boolean) {}
}

export class IntraDayDealsReceivedForCurrentDayEvent {
  public static type: string = '[IntraDay] Deals received for current day';

  constructor(public deals: PaginatedResponse<PostPendingIntradayDeal>) {}
}

export class IntraDayDealStatusesReceivedEvent {
  public static type: string = '[IntraDay] Deal statuses received';

  constructor(public dealStatuses: IntraDayDealStatusUpdate[]) {}
}

export class RecentDealsClearedEvent {
  public static type: string = '[Intraday] Deal statuses cleared';
}

export class EditIntradayTimeSlot {
  public static type: string = '[IntraDay] Edit intra day deal time slot';

  constructor(public direction: Direction, public startDateTime: string, public toDateTime: string) {}
}

export class MapIntraDayDealsToNewPriceWindowCommand {
  public static type: string = '[IntraDay] Update existing draft deals to new prices and price windows';
}

export class IntraDaySlotStatusChanged {
  public static type: string = '[IntraDay] IntraDaySlotStatus changed';

  constructor(public status: IntradaySlotStatus) {}
}

export class IntraDayJsonReceivedEvent {
  public static type: string = '[IntraDay] IntraDayJson received';

  constructor(public result: IntraDayJson) {}
}

export class IntradayPricesReceivedEvent {
  public static type: string = '[IntraDay] Intra Day prices received';

  constructor(public result: IntraDayPricesReceivedData) {}
}

export class IdconsDealingEnabledUpdatedEvent {
  public static type: string = '[IntraDay] Idcons dealing enabled flag updated';

  constructor(public result: boolean) {}
}

export class RemLimitOrderDealingEnabledUpdatedEvent {
  public static type: string = '[IntraDay] Rem Limit Order Dealing enabled flag updated';

  constructor(public result: boolean) {}
}

export class MandatoryTradingDirectionsUpdatedEvent {
  public static type: string = '[IntraDay] Mandatory trading directions updated';

  constructor(public result: MandatoryTradeDirection[]) {}
}

export class IntraDayPositionsUpdatedEvent {
  public static type: string = '[IntraDay] Intraday positions updated';

  constructor(public result: IntraDayPosition[]) {}
}

export class IntradayControlsUpdatedEvent {
  public static type: string = '[IntraDay] Controls updated';

  constructor(public controls: IntradayControl[]) {}
}

export class IntraDayDealableControlsUpdatedEvent {
  public static type: string = '[IntraDay] Dealable controls updated';

  constructor(public dealableControlIdsPerDutchCalendarDay: DealableControlIdsPerDutchDay[]) {}
}

export class IntraDayAddOrUpdateRecentDealCommand {
  static type: string = '[IntraDay] Add or update RecentDeal with provided delta';

  constructor(public newOrUpdatedRecentDeal: RecentDeal) {}
}

export class ControlDeal {
  controlId: string;
  volume: number;
}

export class IntradayUpdatePriceHistoryCommand {
  static type: string = '[IntraDay] Update price history for timeslot';

  constructor(public timeSlot: TimeSlot) {}
}

export class IntraDayUpdatePriceHistoryUpdatedEvent {
  static type: string = '[IntraDay] Price history for timeslot updated';

  constructor(public data: PriceHistoryJson) {}
}

export class IntradayUpdatePriceHistoryDeactivateCommand {
  static type: string = '[IntraDay] Price history dialog closed';
}

export class SlotsPageActivatedEvent {
  static type: string = '[IntraDay] Slots page activated';
}

export class SlotsPageDeactivatedEvent {
  static type: string = '[IntraDay] Slots page deactivated';
}

export class IntradayActivate {
  static type: string = '[IntraDay] State activated';
}

export class IntradayDeactivate {
  static type: string = '[IntraDay] State deactivated';
}
