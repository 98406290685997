import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { FlexTooltipArrowComponent, TooltipDirective } from './tooltip.directive';
import { PortalModule } from '@angular/cdk/portal';
import { ControlErrorOverlayDirective } from './control-error-overlay.directive';
import { FlexTooltipContainerComponent } from './flex-tooltip-container.component';

@NgModule({
  declarations: [TooltipDirective, FlexTooltipArrowComponent, FlexTooltipContainerComponent, ControlErrorOverlayDirective],
  imports: [CommonModule, OverlayModule, PortalModule],
  exports: [TooltipDirective, ControlErrorOverlayDirective]
})
export class FlexPopOverContextModule {}
