<div class="row">
  <span class="ellipse" *ngIf="!isIconOnly"></span>
  <span class="label-container">
    <span class="label">
      <ng-container>{{ label }}</ng-container>
    </span>
  </span>
  <span class="short-label" *ngIf="isIconOnly">{{ shortLabel }}</span>
</div>
<span class="highlight" *ngIf="isSelected"></span>
