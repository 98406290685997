import { Component, InjectionToken, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { PeriodUnit } from '../default-period';

@Component({
  selector: 'ph-flex-date-range-fixed-period-button-group',
  templateUrl: './date-range-fixed-period-button-group.component.html',
  styleUrls: ['./date-range-fixed-period-button-group.component.scss']
})
export class DateRangeFixedPeriodButtonGroupComponent {
  @Input() availablePeriodUnits: PeriodUnit[] = [PeriodUnit.DAY, PeriodUnit.MONTH, PeriodUnit.YEAR];

  selectedPeriodControl: UntypedFormControl;
  PeriodUnit = PeriodUnit;
}
