import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InlineIconButtonComponent } from './inline-icon-button/inline-icon-button.component';

@NgModule({
  declarations: [InlineIconButtonComponent],
  imports: [CommonModule],
  exports: [InlineIconButtonComponent]
})
export class FlexButtonsModule {}
