import { Component } from '@angular/core';

@Component({
  selector: 'ph-flex-dummy-container',
  template: '<router-outlet></router-outlet>',
  styleUrls: ['dummy-container.component.scss']
})
export class DummyContainerComponent {
  // Dummy component with router outlet to support nesting
}
