import { NavigationExtras, Router } from '@angular/router';
import { flatMap, fromPairs, intersection, isEqual, orderBy, pickBy, replace, sortBy, toPairs, uniq, zip } from 'lodash-es';
import { findNotNullParameterValue } from '../../core/common/find-parameter-value';
import { CUSTOMER_ID_SESSION_STORAGE_KEY } from '../../core/storage-store/browser-storage';

/**
 * Router parameters with [key] will always be synced to the corresponding session storage key when they are set.
 * Likewise, values in stession storage defined here will be used to construct urls when available
 */
export const parameterToStorageMapping = {
  customerId: CUSTOMER_ID_SESSION_STORAGE_KEY
};

/**
 * Navigate to the route matching the given parameters. Will retain any existing parameters that are currently in the route.
 * It will also sync any updates from partialData to sessionStorage, if configured in parameterToStorageMapping.
 * What it will NOT do is read the initial value from sessionStorage, since it cannot detect that partialData is an initial (null) value and will clear the sessionStorage.
 * Currently DefaultCustomerGuardService, or some other method, should still be used for initialization.
 * @param router  Router instance
 * @param possibleRoutes Array of routes, e.g. ['incident-reserve-availability/:customerId', 'incident-reserve-availability/:customerId/:serviceAgreementId']
 * @param partialData Data to update the route with. Should have keys matching the parameters in the routes
 * @param navigationExtras Extra data for router.navigate call
 */
export function handleStateToRouteSync(
  router: Router,
  possibleRoutes: string[],
  partialData: any = {},
  navigationExtras?: NavigationExtras
): any {
  possibleRoutes = possibleRoutes?.filter((route) => !!route) ?? [];

  const parametersForRoutes = possibleRoutes.map((route) =>
    route
      .split('/')
      .filter((splitRoute) => splitRoute && splitRoute[0] === ':')
      .map((splitRoute) => splitRoute.substr(1))
  );

  const uniqueParameters = uniq(flatMap(parametersForRoutes));
  const currentRouteParameters = fromPairs(
    uniqueParameters
      .map((parameterName) => [parameterName, findNotNullParameterValue(router.routerState.snapshot, parameterName)])
      .filter(([key, value]) => value !== null)
  );
  const parametersInPartialData = Object.keys(partialData);

  const dataFromStorage = fromPairs(
    toPairs(parameterToStorageMapping)
      .map(([key, value]) => {
        return [key, sessionStorage.getItem(value)];
      })
      .filter(([key, value]) => value)
  );

  const newState: any = fromPairs(
    toPairs({
      ...currentRouteParameters,
      ...dataFromStorage,
      ...partialData
    }).filter(([key, value]) => !!value)
  );

  const newStateKeys = Object.keys(newState);
  const preferredRoutes = zip(possibleRoutes, parametersForRoutes)
    // Ensure all parameters for the route are present in newStateKeys
    .filter(([possibleRoute, parametersForRoute]) =>
      isEqual(sortBy(intersection(parametersForRoute, newStateKeys)), sortBy(parametersForRoute))
    );
  // Sort by number of matched parameters (desc) first, then shortest routes first
  const sortedPreferredRoutes = orderBy(preferredRoutes, [(v) => v[1].length, (v) => v[0].length], ['desc', 'asc']);

  if (sortedPreferredRoutes.length === 0) {
    // Cannot navigate if there are no matched routes
    return;
  }

  const targetRoute = sortedPreferredRoutes[0];

  const matchedStorageKeys = pickBy(parameterToStorageMapping, (value, key) => parametersInPartialData.includes(key));
  toPairs(matchedStorageKeys).forEach(([key, value]) => {
    if (newState[key]) {
      sessionStorage.setItem(value, newState[key]);
    } else {
      sessionStorage.removeItem(value);
    }
  });

  const newRoute = replace(targetRoute[0], /:[^/]+/g, (match) => {
    const param = match.substr(1);
    return newState[param];
  });

  console.warn('new route: ', newRoute);

  if (![newRoute, '/' + newRoute].includes(router.getCurrentNavigation()?.finalUrl?.toString())) {
    // Do not navigate if already navigating
    router.navigate([newRoute], navigationExtras);
  }
}
