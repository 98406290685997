import { NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { IncidentReserveRoutingModule } from './incident-reserve-routing.module';
import { AvailabilityItemComponent } from './availability-item/availability-item.component';
import { IncidentReserveAvailabilityScreenComponent } from './incident-reserve-availability-screen/incident-reserve-availability-screen.component';
import { NewAvailabilityDialogComponent } from './new-availability-dialog/new-availability-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  FlexAutocompleteModule,
  customLocaleNl,
  FlexApiPrivateModule,
  FlexCoreModule,
  FlexNumberInputModule,
  FlexSaveButtonModule,
  FlexLoadingContextModule,
  FlexFiltersModule,
  FlexDateRangeFixedPeriodModule
} from 'flex-app-shared';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { AvailabilityItemCalendarPeriodPipe } from './availability-item/availability-item-calendar-period.pipe';
import { AdjustAvailabilityComponent } from './adjust-availability/adjust-availability.component';
import { MatDividerModule } from '@angular/material/divider';
import { CustomDatePickerHeaderComponent } from './custom-date-picker-header/custom-date-picker-header.component';
import { FiltersIncidentReserveAvailabilityComponent } from './filters-incident-reserve-availability/filters-incident-reserve-availability.component';
import { IncidentReserveActivationsScreenComponent } from './incident-reserve-activations-screen/incident-reserve-activations-screen.component';
import { IncidentReserveAvailabilityToolbarContainerComponent } from './incident-reserve-availability-toolbar-container/incident-reserve-availability-toolbar-container.component';
import { FlexToolbarTabsModule } from '../../../../flex-app-shared/src/lib/material/toolbar-tabs/flex-toolbar-tabs.module';
import { ServiceAgreementDisplayLabelComponent } from './service-agreement-display-label/service-agreement-display-label.component';
import { IncidentReserveActivationsDetailScreenComponent } from './incident-reserve-activations-detail-screen/incident-reserve-activations-detail-screen.component';
import { IncidentReserveActivationsDetailScreenChartComponent } from './incident-reserve-activations-detail-screen/incident-reserve-activations-detail-screen-chart/incident-reserve-activations-detail-screen-chart.component';

@NgModule({
  declarations: [
    AvailabilityItemComponent,
    IncidentReserveAvailabilityScreenComponent,
    NewAvailabilityDialogComponent,
    AvailabilityItemCalendarPeriodPipe,
    AdjustAvailabilityComponent,
    CustomDatePickerHeaderComponent,
    FiltersIncidentReserveAvailabilityComponent,
    IncidentReserveAvailabilityToolbarContainerComponent,
    IncidentReserveActivationsScreenComponent,
    ServiceAgreementDisplayLabelComponent,
    IncidentReserveActivationsDetailScreenComponent,
    IncidentReserveActivationsDetailScreenChartComponent
  ],
  imports: [
    CommonModule,
    IncidentReserveRoutingModule,
    MatCardModule,
    MatTooltipModule,
    MatIconModule,
    MatDialogModule,
    MatRadioModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    FlexNumberInputModule,
    FlexCoreModule,
    FlexSaveButtonModule,
    MatButtonModule,
    MatSelectModule,
    FlexAutocompleteModule,
    FlexApiPrivateModule,
    FormsModule,
    NgxsFormPluginModule,
    FlexLoadingContextModule,
    MatButtonToggleModule,
    MatDividerModule,
    FlexFiltersModule,
    FlexDateRangeFixedPeriodModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    FlexToolbarTabsModule
  ]
})
export class IncidentReserveModule {}

registerLocaleData(customLocaleNl, 'nl');
