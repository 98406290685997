import { PortalOverlayManager } from './portal-overlay-manager';
import { CdkPortal, ComponentPortal } from '@angular/cdk/portal';
import { Injector } from '@angular/core';
import { AppInjector } from '../../core/common/app-injector';
import { COMPONENT_PORTAL_OVERLAY_MANAGER } from './component-portal-overlay-manager';

/**
 * Embed a portal in the a provided component
 */
export class EmbeddedComponentOverlayManager extends PortalOverlayManager {
  public embeddedPortal: CdkPortal;
  public component: any;

  registerEmbeddedComponent(component: any, embeddedPortal: CdkPortal): void {
    this.component = component;
    this.embeddedPortal = embeddedPortal;
  }

  open(): void {
    const injector = Injector.create({
      parent: AppInjector.get(Injector),
      providers: [
        {
          provide: COMPONENT_PORTAL_OVERLAY_MANAGER,
          useValue: this
        }
      ]
    });
    this.portal = new ComponentPortal(this.component, null, injector);
    super.open();
  }
}
