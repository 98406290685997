import { Injectable } from '@angular/core';
import { getDate, getDaysInMonth, getMonth, getYear } from 'date-fns';
import { PeriodUnit } from './default-period';
import { normalizeToDate } from '../../core/date-time/utils';

/**
 * Stores year/month/date state and enriches dates when switching from a larger period to a smaller one
 */
@Injectable() // Provided by Date range fixed period component
export class DateRangeFixedSelectedDateHelperService {
  private currentDay: number;
  private currentMonth: number;
  private currentYear: number;

  private currentView: PeriodUnit;

  /**
   * Get the new date
   */
  getDate(): Date {
    return this.propsToDate();
  }

  /**
   * Set the component based on the given date and the current periodUnit
   *
   * TODO Split into separate date and periodUnit updates, and update them in the source observable chain with tap in the value accessor
   */
  update(date: Date, periodUnit: PeriodUnit): void {
    this.updatePeriodUnit(periodUnit);
    this.updateDate(date);
  }

  updatePeriodUnit(periodUnit: PeriodUnit): void {
    this.currentView = periodUnit;
  }

  updateDate(date: Date): void {
    this.dateToProps(normalizeToDate(date));
  }

  private dateToProps(date: Date): void {
    if (this.currentView === PeriodUnit.DAY) {
      this.currentDay = getDate(date);
    }
    if (this.currentView <= PeriodUnit.MONTH) {
      this.currentMonth = getMonth(date);
    }
    if (this.currentView <= PeriodUnit.YEAR) {
      this.currentYear = getYear(date);
    }
  }

  private propsToDate(): Date {
    const now = new Date();
    const currentYear = this.currentYear ?? getYear(now);
    const currentMonth = this.currentMonth ?? getMonth(now);
    const currentDay = this.currentDay ?? getDate(now);

    switch (this.currentView) {
      case PeriodUnit.YEAR:
        return new Date(currentYear, 0); // Start of year
      case PeriodUnit.MONTH:
        return new Date(currentYear, currentMonth); // Start of month
      default:
        const monthDate = new Date(currentYear, currentMonth);
        // Check if the currentDay is selectable, if not, use the last day of the month
        return new Date(currentYear, currentMonth, Math.min(currentDay, getDaysInMonth(monthDate)));
    }
  }
}
