import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { IntradayState } from '../../state/intraday-state.service';
import { Store } from '@ngxs/store';
import { Direction, IntradayDealStatus } from 'flex-app-shared';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IntradayDealStatusOverviewComponent } from '../intraday-deal-status-overview/intraday-deal-status-overview.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-intraday-deal-status',
  templateUrl: './intraday-deal-status.component.html',
  styleUrls: ['./intraday-deal-status.component.scss']
})
export class IntradayDealStatusComponent {
  IntraDayDealStatus = IntradayDealStatus;
  Direction = Direction;
  askSummary$ = this.store.select(IntradayState.intraDayDealStatusSummaryForDirection(Direction.CONSUMPTION));
  bidSummary$ = this.store.select(IntradayState.intraDayDealStatusSummaryForDirection(Direction.PRODUCTION));
  dialogRef: MatDialogRef<IntradayDealStatusOverviewComponent>;
  dealsFilter = {
    dealStatus: null,
    direction: null
  };
  @ViewChild('dealStatusDialog', { static: true })
  private readonly dealStatusDialog: TemplateRef<any>;

  constructor(
    private store: Store,
    private matDialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  onShowDealsDialog(intraDayDealStatus: IntradayDealStatus = null, dealDirection: Direction = null): void {
    this.dealsFilter = {
      dealStatus: intraDayDealStatus,
      direction: dealDirection
    };
    this.dialogRef = this.matDialog.open(this.dealStatusDialog, { maxHeight: '100vh' });
  }
}
