import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDrawer, MatDrawerMode } from '@angular/material/sidenav';
import {
  AuthService,
  AVAILBLE_MENU_ITEMS,
  MANAGE_R3_AVAILABILITY,
  MixinBase,
  OnDestroyMixin,
  theme,
  VIEW_ADMIN,
  VIEW_DISPATCH_SCHEDULE,
  VIEW_FLEX_OVERVIEW,
  VIEW_INTRA_DAY
} from 'flex-app-shared';
import { animationFrameScheduler, BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map, subscribeOn, switchMap, takeUntil } from 'rxjs/operators';
import { TopBarService } from '../../../../../flex-app-shared/src/lib/material/top-bar/top-bar.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent extends OnDestroyMixin(MixinBase) implements OnInit, AfterViewInit {
  @ViewChild('menuTemplate') menuTemplate: TemplateRef<any>;
  @ViewChild(MatDrawer) drawer: MatDrawer;
  isExpandable: boolean = true;
  isExpanded: boolean = true;
  mobileMenuOpened: boolean = false;
  isOpenedSubject = new BehaviorSubject(false);
  modeSubject = new ReplaySubject<MatDrawerMode>(1);
  hasBackdrop$ = this.modeSubject.pipe(map((mode) => mode === 'over'));
  userName$: Observable<string> = this.authService.userName$;
  private menuCollapseMediaQuery = `(min-width: ${theme.layout.width.m}px)`;
  menuCollapseBreakpoint$ = this.breakPointObserver.observe(this.menuCollapseMediaQuery);
  private authorities: { [key: string]: string[] } = {
    '/incident-reserve-availability': [MANAGE_R3_AVAILABILITY],
    '/dispatching-schedule': [VIEW_DISPATCH_SCHEDULE],
    '/intraday': [VIEW_INTRA_DAY],
    '/flex-overview': [VIEW_FLEX_OVERVIEW],
    '/messages': [VIEW_ADMIN]
  };

  constructor(
    @Inject(AVAILBLE_MENU_ITEMS) public menuItems: string[],
    public authService: AuthService,
    private breakPointObserver: BreakpointObserver,
    private topBarService: TopBarService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.menuCollapseBreakpoint$
      .pipe(
        switchMap(() => this.drawer.openedChange),
        takeUntil(this.onDestroy$)
      )
      .subscribe((openedChange) => {
        if (!this.breakPointObserver.isMatched(this.menuCollapseMediaQuery)) {
          this.mobileMenuOpened = openedChange;
        }
      });
  }

  ngOnInit(): void {
    this.menuCollapseBreakpoint$.pipe(takeUntil(this.onDestroy$)).subscribe((result) => {
      if (result.matches) {
        // Desktop state
        this.isOpenedSubject.next(true);
        this.modeSubject.next('side');
        this.isExpandable = true;
      } else {
        // Mobile state
        this.isOpenedSubject.next(this.mobileMenuOpened);
        this.modeSubject.next('over');
        this.isExpandable = false;
        this.isExpanded = true;
      }
    });

    this.topBarService.openMobileSidebar$.pipe(takeUntil(this.onDestroy$)).subscribe((openMobile) => {
      this.isOpenedSubject.next(true);
    });

    this.isOpenedSubject.pipe(subscribeOn(animationFrameScheduler)).subscribe((isOpened) => {
      if (isOpened) {
        this.drawer.open();
      } else {
        this.drawer.close();
      }
      this.cdr.detectChanges();
    });
  }

  authoritiesForMenuItem(menuItem: string): string[] {
    return this.authorities[menuItem] || [];
  }

  logout(): void {
    this.authService.logout();
  }
}
