import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ImbalanceReserveAvailabilityActivate, ImbalanceReserveAvailabilityDeactivate } from './incident-reserve-availability.actions';

@Injectable({ providedIn: 'root' })
export class ActivateState {
  constructor(private store: Store) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.store.dispatch(new ImbalanceReserveAvailabilityActivate());
    return true;
  }
}

@Injectable({ providedIn: 'root' })
export class DeactivateState {
  constructor(private store: Store) {}

  canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.store.dispatch(new ImbalanceReserveAvailabilityDeactivate());
    return true;
  }
}
