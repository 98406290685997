<ng-container [ngSwitch]="menuRoute">
  <ng-container *ngSwitchCase="'/dispatching-schedule'">
    <a routerLink="{{ menuRoute }}">
      <ph-flex-side-menu-item
        icon="ic-new:plan1"
        i18n-label="@@menuItem.dispatchingSchedule"
        label="Dispatching schedule"
      ></ph-flex-side-menu-item>
    </a>
  </ng-container>
  <ng-container *ngSwitchCase="'/intraday'">
    <a routerLink="{{ menuRoute }}">
      <ph-flex-side-menu-item icon="ic-new:wait1" i18n-label="@@menuItem.intraday" label="Intraday"></ph-flex-side-menu-item>
    </a>
  </ng-container>
  <ng-container *ngSwitchCase="'/incident-reserve-availability'">
    <a routerLink="{{ menuRoute }}">
      <ph-flex-side-menu-item
        icon="ic-new:thunder"
        i18n-label="@@menuItem.incidentReserveAvailability"
        label="Incident reserve"
      ></ph-flex-side-menu-item>
    </a>
  </ng-container>
  <ng-container *ngSwitchCase="'/flex-overview'">
    <a routerLink="{{ menuRoute }}">
      <ph-flex-side-menu-item icon="ic-new:report" i18n-label="@@menuItem.flexOverview" label="Flex overview"></ph-flex-side-menu-item>
    </a>
  </ng-container>
</ng-container>
