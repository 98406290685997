import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Moment } from 'moment';
import { MatDatepicker } from '@angular/material/datepicker';
import {
  AdjustAvailability,
  AdjustmentDirection,
  AvailabilityType,
  CalendarPeriod,
  CustomValidators,
  FnErrorMatcher,
  MixinBase,
  OnDestroyMixin
} from 'flex-app-shared';
import { takeUntil } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IncidentReserveAvailabilityFacade } from '../../../store/incident-reserve-availability/incident-reserve-availability.facade';
import {
  IncidentReserveAvailabilityDialogView,
  IncidentReserveAvailabilityDialogViewForDirection
} from '../../../store/incident-reserve-availability/incident-reserve-availability.state';
import { CustomDatePickerHeaderComponent } from '../custom-date-picker-header/custom-date-picker-header.component';
import { BehaviorSubject } from 'rxjs';

export class NewAvailabilityDialogData {
  period: CalendarPeriod;
  enableDownwards: boolean;
  enableUpwards: boolean;
}

export enum AvailabilityDirection {
  DOWNWARDS = 'DOWNWARDS',
  UPWARDS = 'UPWARDS'
}

@Component({
  selector: 'app-new-availability-dialog',
  templateUrl: './new-availability-dialog.component.html',
  styleUrls: ['./new-availability-dialog.component.scss'],
  providers: []
})
export class NewAvailabilityDialogComponent extends OnDestroyMixin(MixinBase) implements OnInit {
  CustomDatePickerHeaderComponent = CustomDatePickerHeaderComponent;

  showAllErrors = false;
  fnErrorMatcher = new FnErrorMatcher(() => this.showAllErrors);
  oldValueUpwards = 0;
  oldValueDownwards = 0;

  oldTypeUpwards = AvailabilityType.ASSURED;
  oldTypeDownwards = AvailabilityType.ASSURED;

  AvailabilityType = AvailabilityType;
  AvailabilityDirection = AvailabilityDirection;

  data$ = this.facade.dialogData(this.dialogData.period);
  data: IncidentReserveAvailabilityDialogView = null;

  capacitiesUpwardsType$ = new BehaviorSubject<AvailabilityType>(null);
  capacitiesDownwardsType$ = new BehaviorSubject<AvailabilityType>(null);

  isBusy$ = this.facade.isBusyOrSaving$;

  maxPeriod: CalendarPeriod;

  dialogForm: UntypedFormGroup = this.fb.group({
    range: this.fb.group({
      startDate: ['', [Validators.required]],
      endDate: ['', [Validators.required]]
    }),
    capacities: this.fb.group({
      upwards: this.fb.group({
        toggle: [this.dialogData.enableUpwards],
        type: null,
        value: [
          '',
          [
            CustomValidators.conditionalRequired(() => this.isUpwardsCapacityEnabled() && this.isUpwardsAssured()),
            CustomValidators.conditionalMinExclusive(() => this.isUpwardsCapacityEnabled() && this.isUpwardsAssured(), 0),
            CustomValidators.maxFn(() => this.isUpwardsCapacityEnabled() && this.isUpwardsAssured() && this.getUpwardsAvailableCapacity())
          ]
        ]
      }),
      downwards: this.fb.group({
        toggle: [this.dialogData.enableDownwards],
        type: null,
        value: [
          '',
          [
            CustomValidators.conditionalRequired(() => this.isDownwardsCapacityEnabled() && this.isDownwardsAssured()),
            CustomValidators.conditionalMinExclusive(() => this.isDownwardsCapacityEnabled() && this.isDownwardsAssured(), 0),
            CustomValidators.maxFn(
              () => this.isDownwardsCapacityEnabled() && this.isDownwardsAssured() && this.getDownwardsAvailableCapacity()
            )
          ]
        ]
      })
    })
  });

  private isUpwardsAssured(): boolean {
    return this.dialogForm?.get('capacities.upwards.type').value === AvailabilityType.ASSURED;
  }

  private isDownwardsAssured(): boolean {
    return this.dialogForm?.get('capacities.downwards.type').value === AvailabilityType.ASSURED;
  }

  constructor(
    public fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<NewAvailabilityDialogData>,
    @Inject(MAT_DIALOG_DATA) public dialogData: NewAvailabilityDialogData,
    public facade: IncidentReserveAvailabilityFacade
  ) {
    super();
  }

  isSaveButtonDisabled(): boolean {
    return !this.dialogForm.valid;
  }

  isUpwardsCapacityEnabled(): boolean {
    return (
      this.dialogForm &&
      this.dialogForm.get('capacities.upwards.toggle').value &&
      this.dialogForm.get('capacities.upwards.type').value === AvailabilityType.ASSURED
    );
  }

  isDownwardsCapacityEnabled(): boolean {
    return (
      this.dialogForm &&
      this.dialogForm.get('capacities.downwards.toggle').value &&
      this.dialogForm.get('capacities.downwards.type').value === AvailabilityType.ASSURED
    );
  }

  isNotBothDisabled(): boolean {
    return (
      this.dialogForm &&
      (this.dialogForm.get('capacities.upwards.toggle').value || this.dialogForm.get('capacities.downwards.toggle').value)
    );
  }

  /**
   * If there is no current assured capaccity, and the service agremeent has a value of 0, it should not be allowed
   */
  isAssuredAllowed(direction: AdjustmentDirection): boolean {
    const dataForDirection = direction === AdjustmentDirection.DOWNWARDS ? this.data.downward : this.data.upward;

    // If existing data is higher than 0, return true
    if (dataForDirection.defaultCapacityMW !== null && dataForDirection.defaultCapacityMW > 0) {
      return true;
    }

    // If max allowed is higher than 0, return true
    if (dataForDirection.maxCapacityMW !== null && dataForDirection.maxCapacityMW > 0) {
      return true;
    }

    // Just return true if type is already assured
    if (dataForDirection.defaultType === AvailabilityType.ASSURED) {
      return true;
    }

    return false;
  }

  ngOnInit(): void {
    let isInitialized = false;

    this.data$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
      this.data = data;

      // initialize
      this.capacitiesDownwardsType$.next(this.data?.downward?.defaultType);
      this.capacitiesUpwardsType$.next(this.data?.upward?.defaultType);

      if (!isInitialized && data) {
        this.initializeFormData();
        isInitialized = true;
      }
    });

    this.registerFormValueChangeListeners();
  }

  getUpwardsAvailableCapacity(): number {
    return this.data?.upward.maxCapacityMW;
  }

  getDownwardsAvailableCapacity(): number {
    return this.data?.downward.maxCapacityMW;
  }

  dateMonthSelected(path: string, normalizedMonth: Moment, datepicker: MatDatepicker<Moment>): void {
    this.dialogForm.get(path).setValue(normalizedMonth);
    this.dialogForm.get(path).markAsDirty();
    this.dialogForm.get(path).markAsTouched();
    datepicker.close();
  }

  handleSave(): void {
    const periodData = this.dialogForm.get('range').value;

    let upwardsValue = this.dialogForm.get('capacities.upwards.value').value;
    let downwardsValue = this.dialogForm.get('capacities.downwards.value').value;

    let upwardsType = this.dialogForm.get('capacities.upwards.type').value;
    let downwardsType = this.dialogForm.get('capacities.downwards.type').value;

    // Set values to null if toggle is false. The store will replace these with the last availability values for this period
    if (!this.dialogForm.get('capacities.downwards.toggle').value) {
      downwardsValue = null;
      downwardsType = null;
    }

    if (!this.dialogForm.get('capacities.upwards.toggle').value) {
      upwardsValue = null;
      upwardsType = null;
    }

    this.facade
      .updateAvailability(
        CalendarPeriod.toCalendarPeriod(periodData.startDate, periodData.endDate),
        AdjustAvailability.fromTypeAndCapacity(upwardsType, upwardsValue),
        AdjustAvailability.fromTypeAndCapacity(downwardsType, downwardsValue)
      )
      .subscribe((result) => {
        if (result.success) {
          this.dialogRef?.close();
        }
      });
  }

  /**
   * To be called after initializing the form with dialog data
   */
  private registerFormValueChangeListeners(): void {
    this.dialogForm
      .get('capacities.upwards.value')
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe((value) => {
        if (this.dialogForm.get('capacities.upwards.toggle').value) {
          this.oldValueUpwards = value;
        }
      });
    this.dialogForm
      .get('capacities.downwards.value')
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe((value) => {
        if (this.dialogForm.get('capacities.downwards.toggle').value) {
          this.oldValueDownwards = value;
        }
      });

    this.dialogForm
      .get('capacities.upwards.type')
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe((value) => {
        if (this.dialogForm.get('capacities.upwards.toggle').value) {
          // Ensure conditional min validation is updated
          this.dialogForm.get('capacities.upwards.value').updateValueAndValidity();

          this.capacitiesUpwardsType$.next(value);

          this.oldTypeUpwards = value;
        }
      });
    this.dialogForm
      .get('capacities.downwards.type')
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe((value) => {
        if (this.dialogForm.get('capacities.downwards.toggle').value) {
          // Ensure conditional min validation is updated
          this.dialogForm.get('capacities.downwards.value').updateValueAndValidity();

          this.oldTypeDownwards = value;
        }
      });

    // Set value to 0 when toggling off
    this.dialogForm
      .get('capacities.upwards.toggle')
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe((value) => {
        if (value) {
          this.dialogForm.get('capacities.upwards.value').setValue(this.oldValueUpwards);
          this.dialogForm.get('capacities.upwards.type').setValue(this.oldTypeUpwards);
        } else {
          this.dialogForm.get('capacities.upwards.value').setValue(this.data.upward.defaultCapacityMW);
          this.dialogForm.get('capacities.upwards.type').setValue(this.data.upward.defaultType);
        }
      });

    // Whenever the toggles change, we should reset the value controls
    this.dialogForm
      .get('capacities.downwards.toggle')
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe((value) => {
        if (value) {
          this.dialogForm.get('capacities.downwards.value').setValue(this.oldValueDownwards);
          this.dialogForm.get('capacities.downwards.type').setValue(this.oldTypeDownwards);
        } else {
          this.dialogForm.get('capacities.downwards.value').setValue(this.data.downward.defaultCapacityMW);
          this.dialogForm.get('capacities.downwards.type').setValue(this.data.downward.defaultType);
        }
      });
  }

  private setFormDataForDirection(formGroup: AbstractControl, data: IncidentReserveAvailabilityDialogViewForDirection): void {
    formGroup.patchValue({
      value: data.defaultCapacityMW,
      toggle: formGroup.get('toggle').value,
      type: data.defaultType
    });
  }

  /**
   * User dialog data to initialize form
   */
  private initializeFormData(): void {
    // set oldValues to their current value
    this.oldValueDownwards = this.data.downward.defaultCapacityMW;
    this.oldValueUpwards = this.data.upward.defaultCapacityMW;

    this.oldTypeDownwards = this.data.downward.defaultType;
    this.oldTypeUpwards = this.data.upward.defaultType;

    this.setFormDataForDirection(this.dialogForm.get('capacities.upwards'), this.data.upward);
    this.setFormDataForDirection(this.dialogForm.get('capacities.downwards'), this.data.downward);

    this.dialogForm.patchValue({
      range: this.data.initialPeriod
    });
  }
}
