import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { IdconsAnnouncementState } from './idcons-announcements.state';
import { IdconsActiveAnnouncementsMarkAsReadCommand, IdconsAnnouncementsMarkAllAsReadCommand } from './idcons-announcements.actions';
import { map } from 'rxjs/operators';
import { IdconsAnnouncement } from '../../app/shared/idcons/announcement/idcons-announcement';

export type IdconsAnnouncementsPerDay = { [day: string]: IdconsAnnouncement[] };

@Injectable({
  providedIn: 'root'
})
export class IdconsAnnouncementsFacade {
  announcementsSortedByDateGroupedByDay$ = this.store.select(IdconsAnnouncementState.announcementsGroupedByDay);

  activeAnnouncements$ = this.store.select(IdconsAnnouncementState.activeAnnouncementsSortedByDate);

  unreadActiveAnnouncementCount$ = this.store.select(IdconsAnnouncementState.unreadActiveAnnouncementCount);

  noUnreadActiveAnnouncements$ = this.unreadActiveAnnouncementCount$.pipe(
    map((unreadActiveAnnouncementCount) => unreadActiveAnnouncementCount === 0)
  );

  unreadAnnouncements$ = this.store.select(IdconsAnnouncementState.unreadActiveAnnouncements);

  isBusyFetching$ = this.store.select(IdconsAnnouncementState.isBusyFetching);

  constructor(private store: Store) {}

  markAllAnnouncementsAsRead(): void {
    this.store.dispatch(new IdconsAnnouncementsMarkAllAsReadCommand());
  }

  markAnnouncementIdsAsRead(announcements: IdconsAnnouncement[]): void {
    const announcementIds = announcements.map((announcement) => announcement.id);
    this.store.dispatch(new IdconsActiveAnnouncementsMarkAsReadCommand(announcementIds));
  }
}
