import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { FlexScrollStrategyService } from '../common/scroll-strategy';
import { OnDestroyMixin } from '../common/on-destroy.mixin';
import { MixinBase } from '../common/constructor-type.mixin';

@Component({
  selector: 'ph-flex-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent extends OnDestroyMixin(MixinBase) implements OnInit {
  constructor(private scrollService: FlexScrollStrategyService, private elementRef: ElementRef, private renderer: Renderer2) {
    super();
  }

  ngOnInit(): void {
    this.scrollService.topOffset$.pipe(takeUntil(this.onDestroy$)).subscribe((topOffset) => {
      if (topOffset) {
        this.renderer.setStyle(this.elementRef.nativeElement, 'margin-bottom', `${-topOffset}px`);
      } else {
        this.renderer.removeStyle(this.elementRef.nativeElement, 'margin-bottom');
      }
    });
  }
}
