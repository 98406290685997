<ng-container *ngIf="dateControl" [ngSwitch]="dateRangeSelectedPeriod$ | async">
  <ph-flex-date-range-fixed-period-day
    *ngSwitchCase="PeriodUnit.DAY"
    [label]="label"
    [control]="dateControl"
    [min]="min"
    [max]="max"
  ></ph-flex-date-range-fixed-period-day>
  <ph-flex-date-range-fixed-period-month
    *ngSwitchCase="PeriodUnit.MONTH"
    [label]="label"
    [control]="dateControl"
    [min]="min"
    [max]="max"
  ></ph-flex-date-range-fixed-period-month>
  <ph-flex-date-range-fixed-period-year
    *ngSwitchCase="PeriodUnit.YEAR"
    [label]="label"
    [control]="dateControl"
    [min]="min"
    [max]="max"
  ></ph-flex-date-range-fixed-period-year>
</ng-container>
