import { AfterViewInit, Component, Inject, Input, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { isSameDay } from 'date-fns';
import {
  AdjustmentDirection,
  AvailabilityType,
  CalendarPeriod,
  MixinBase,
  normalizeToDate,
  OnDestroyMixin,
  R3Activation,
  TimeSlot
} from 'flex-app-shared';
import { Observable } from 'rxjs';
import { IncidentReserveAvailabilityFacade } from '../../../store/incident-reserve-availability/incident-reserve-availability.facade';
import { IncidentReserveActivationsScreenDataSource } from './incident-reserve-activations-screen-datasource';
import { Route, Router } from '@angular/router';

@Component({
  selector: 'app-incident-reserve-activations-screen',
  templateUrl: './incident-reserve-activations-screen.component.html',
  styleUrls: ['./incident-reserve-activations-screen.component.scss']
})
export class IncidentReserveActivationsScreenComponent extends OnDestroyMixin(MixinBase) implements AfterViewInit {
  public readonly direction = AdjustmentDirection;
  public readonly availabilityType = AvailabilityType;

  @Input()
  public readonly defaultPageSize: number = 10;
  @ViewChild(MatPaginator, { static: false })
  private readonly paginator: MatPaginator;
  @ViewChild(MatSort, { static: false })
  private readonly sort: MatSort;

  isBusy$: Observable<boolean> = this.facade.isBusy$;

  dataSource = new IncidentReserveActivationsScreenDataSource(this);
  columns: ReadonlyArray<string> = ['direction', 'date', 'timeSlot', 'availabilityType', 'target', 'detail'];
  cardTitleDate$: Observable<string> = this.facade.cardTitleDate$;

  constructor(public facade: IncidentReserveAvailabilityFacade, @Inject(LOCALE_ID) public locale: string, private router: Router) {
    super();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  isSameDay(period: TimeSlot): boolean {
    const startDate = new Date(TimeSlot.startDateTimeValueOfCache.getOrDefault(period));
    const toDate = new Date(TimeSlot.toDateTimeValueOfCache.getOrDefault(period));

    return isSameDay(startDate, toDate);
  }

  navigateToDetailPage(activation: R3Activation): void {
    this.router.navigate([
      'incident-reserve-availability',
      'activation',
      activation.operationId,
      this.facade.getSelectedServiceAgreementId()
    ]);
  }

  canNavigateToDetailPage(activation: R3Activation): boolean {
    return !activation.cancelled && activation.measurementsAvailable;
  }
}
