import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TableSelectionModel } from './table-selection-model';
import { OnDestroyMixin } from '../../core/common/on-destroy.mixin';
import { MixinBase } from '../../core/common/constructor-type.mixin';

@Directive({
  selector: '[phFlexTableSelectionModel]',
  providers: [
    {
      provide: TableSelectionModel,
      useClass: TableSelectionModel
    }
  ]
})
export class TableSelectionModelDirective extends OnDestroyMixin(MixinBase) implements OnChanges {
  @Input('phFlexTableSelectionModel')
  trackBy: any;

  constructor(private tableSelectionModel: TableSelectionModel<any>) {
    super();
    this.onDestroy$.subscribe(() => tableSelectionModel.ngOnDestroy());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.trackBy && this.trackBy) {
      this.tableSelectionModel.trackBy = this.trackBy;
    }
  }
}
