import { Component, OnInit, ViewChild } from '@angular/core';
import { CdkColumnDef } from '@angular/cdk/table';
import { MatColumnDef, MatHeaderCellDef } from '@angular/material/table';
import { selectContextColum } from '../table-selection-header-columns';
import { TableSelectionModel } from '../table-selection-model';
import { Observable } from 'rxjs';

@Component({
  selector: 'ph-flex-table-selection-context-cell',
  templateUrl: './table-selection-context-cell.component.html',
  styleUrls: ['./table-selection-context-cell.component.scss'],
  providers: [
    { provide: CdkColumnDef, useExisting: TableSelectionContextCellComponent },
    { provide: 'MAT_SORT_HEADER_COLUMN_DEF', useExisting: TableSelectionContextCellComponent }
  ]
})
export class TableSelectionContextCellComponent extends MatColumnDef implements OnInit {
  @ViewChild(MatHeaderCellDef)
  headerCell;

  allRowsSelected$ = this.selectionModel.allRowsSelected$;

  get selectedRowCount$(): Observable<number> {
    return this.selectionModel.getSelectedRowCount$();
  }

  totalRowCount$ = this.selectionModel.totalRowCount$;

  constructor(private selectionModel: TableSelectionModel<any>) {
    super();
  }

  ngOnInit(): void {
    this.name = selectContextColum;
  }

  selectAll(): void {
    this.selectionModel.selectAll();
  }

  clearSelection(): void {
    this.selectionModel.clear();
  }
}
