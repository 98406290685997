/**
 * Copy pasted from angular material to facilitate overriding/duplicating focus behavior
 */

/** Selector for finding table cells. */
export const CELL_SELECTOR = '.cdk-cell, .mat-cell, td';
/** Selector for finding editable table cells. */
export const EDITABLE_CELL_SELECTOR = '.cdk-popover-edit-cell, .mat-popover-edit-cell';
/** Selector for finding the table element. */
export const TABLE_SELECTOR = 'table, cdk-table, mat-table';

/** IE 11 compatible matches implementation. */
export function matches(element: Element, selector: string): boolean {
  return element.matches ? element.matches(selector) : (element as any).msMatchesSelector(selector);
}

/** IE 11 compatible closest implementation that is able to start from non-Element Nodes. */
export function closest(element: EventTarget | Element | null | undefined, selector: string): Element | null {
  if (!(element instanceof Node)) {
    return null;
  }

  let curr: Node | null = element;
  while (curr != null && !(curr instanceof Element)) {
    curr = curr.parentNode;
  }

  // @ts-ignore
  return curr && ((hasNativeClosest ? curr.closest(selector) : polyfillClosest(curr, selector)) as Element | null);
}

/** Polyfill for browsers without Element.closest. */
function polyfillClosest(element: Element, selector: string): Element | null {
  let curr: Node | null = element;
  while (curr != null && !(curr instanceof Element && matches(curr, selector))) {
    curr = curr.parentNode;
  }

  return (curr || null) as Element | null;
}

const hasNativeClosest = !!Element.prototype.closest;
