import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-order-created-snackbar',
  templateUrl: './order-created-snackbar.component.html',
  styleUrls: ['./order-created-snackbar.component.scss']
})
export class OrderCreatedSnackbarComponent {
  constructor() {}
}
