import * as d3 from 'd3';
import { BaseType, ScaleTime, select, Selection } from 'd3';
import { animationFrameScheduler, BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { AxisPosition, DynamicDataHelper, SubjectProvider } from '../../common';
import { ScaleBand, ScaleContinuousNumeric } from 'd3-scale';
import { D3GraphAxisSubModule } from '../d3-graph-axis-submodule';
import { EnterElement } from 'd3-selection';
import { OnDestroyProvider } from '../../../../core/common/on-destroy.mixin';
import { subscribeOn } from 'rxjs/operators';
import { expectObservableValue } from '../../../../core/common/expect-observable-value';

type SupportedXScale = ScaleTime<number, number>;
type SupportedYScale = ScaleContinuousNumeric<number, number> | ScaleTime<number, number> | ScaleBand<any>;
type AxisLabelDataPointCoordinate = { x: string; y: string; transform: string; label: string };
type AxisDatum = { offset: { top: number; left: number } };

export class AxisConfig {
  position: AxisPosition = 'left';

  /**
   * Spacing between the positioning of the left label and the left scale start
   */
  marginLeft: number = 85;

  /**
   * Spacing between the positioning of the right label and the left scale end
   */
  marginRight: number = 85;
}

export class D3GraphLineAxis extends D3GraphAxisSubModule<AxisConfig, SupportedXScale, SupportedYScale> {
  private numberOfTicks: number;
  private axisHelper: D3GraphAxisHelper;
  private labelHelper: D3GraphAxisLabelHelper;

  private ticksProvider = new SubjectProvider<number[]>(this);

  constructor(
    onDestroyProvider: OnDestroyProvider,
    protected className: string = 'y-axis',
    protected label: string = ''
  ) {
    super(onDestroyProvider);

    this.configProvider = new SubjectProvider<AxisConfig>(this, new BehaviorSubject(new AxisConfig()));
  }

  setTicks$(ticks$: Observable<number[]>): void {
    this.ticksProvider.follow(ticks$);
  }

  protected internalAttach(node: Selection<EnterElement, any, BaseType, any>): Subscription {
    this.axisHelper = new D3GraphAxisHelper(node);
    this.labelHelper = new D3GraphAxisLabelHelper(node);

    expectObservableValue(this.yScaleProvider.value$, 'No yScaleprovider in D3GraphLineAxis');
    expectObservableValue(this.xScaleProvider.value$, 'No xScaleProvider in D3GraphLineAxis');
    expectObservableValue(this.configProvider.value$, 'No configProvider in D3GraphLineAxis');
    expectObservableValue(this.ticksProvider.value$, 'No ticksProvider in D3GraphLineAxis');

    return combineLatest([this.yScaleProvider.value$, this.xScaleProvider.value$, this.configProvider.value$, this.ticksProvider.value$])
      .pipe(subscribeOn(animationFrameScheduler))
      .subscribe(([yScale, xScale, config, ticks]) => {
        const axisRight = config.position === 'right';
        node.attr('class', `${this.className} ${this.className}-${config.position}`);

        const yAxis = axisRight ? d3.axisRight(yScale).tickSize(0).tickPadding(15) : d3.axisLeft(yScale).tickSize(0).tickPadding(15);
        const top = axisRight ? xScale.range()[1] : xScale.range()[0];

        yAxis.tickValues(ticks);

        this.axisHelper.axis = yAxis;
        this.axisHelper.setData([
          {
            offset: { top, left: 0 }
          }
        ]);

        if (this.label) {
          this.labelHelper.setData([axisRight ? this.getRightData(config) : this.getLeftData(config)]);
        } else {
          this.labelHelper.setData([]);
        }
      });
  }

  private getLeftData(config: AxisConfig): AxisLabelDataPointCoordinate {
    const yScale = this.yScaleProvider.value;
    const xScale = this.xScaleProvider.value;
    const yScaleMiddle = yScale.range()[1] / 2;

    return {
      transform: 'rotate(-90)',
      x: (-yScaleMiddle).toString(),
      y: (xScale.range()[0] - 100).toString(),
      label: this.label
    };
  }

  private getRightData(config: AxisConfig): AxisLabelDataPointCoordinate {
    const yScale = this.yScaleProvider.value;
    const xScale = this.xScaleProvider.value;
    const yScaleMiddle = yScale.range()[1] / 2;

    return {
      transform: 'rotate(90)',
      x: yScaleMiddle.toString(),
      y: (-(xScale.range()[1] + 85)).toString(),
      label: this.label
    };
  }
}

export class D3GraphAxisHelper extends DynamicDataHelper<AxisDatum> {
  axis: any;
  protected class = 'axis';
  protected nodeName = 'g';

  /* eslint-disable no-invalid-this */
  protected setDynamic(selectAllWithData: Selection<BaseType, AxisDatum, BaseType, any>): void {
    const result = selectAllWithData.attr('transform', (datum) => `translate(${datum.offset.top}, ${datum.offset.left})`).call(this.axis);

    result.each(function (): void {
      select(this).select('.domain').remove();
    }); // Remove vertical line drawn by d3 axis

    result.join(
      (enter) => enter,
      (update) => update,
      (exit) => exit.remove()
    );
  }

  protected setStatic(selectAllWithData: Selection<BaseType, AxisDatum, BaseType, any>): void {}
}

export class D3GraphAxisLabelHelper extends DynamicDataHelper<AxisLabelDataPointCoordinate> {
  protected nodeName = 'text';
  protected class = 'y-axis-label-text';

  protected setDynamic(selectAllWithData: Selection<BaseType, AxisLabelDataPointCoordinate, BaseType, any>): void {
    selectAllWithData
      .attr('transform', (datum) => datum.transform)
      .attr('x', (datum) => datum.x)
      .attr('y', (datum) => datum.y)
      .attr('dy', '1em')
      .style('text-anchor', 'middle')
      .text((datum) => datum.label);
  }

  protected setStatic(selectAllWithData: Selection<BaseType, AxisLabelDataPointCoordinate, BaseType, any>): void {}
}
