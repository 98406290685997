import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule, Store } from '@ngxs/store';
import { fromEvent } from 'rxjs';
import { FlexStorageEvent } from './storage-store.actions';

@NgModule({
  declarations: [],
  imports: [CommonModule, NgxsModule]
})
export class FlexSseStorageStoreModule {
  constructor(store: Store) {
    fromEvent(window, 'storage').subscribe((event: StorageEvent) => {
      store.dispatch(new FlexStorageEvent(event.key, event.newValue, event.storageArea));
    });
  }
}
