import { first, isNil, isUndefined, last, max, maxBy, unzipWith, zipWith } from 'lodash-es';

export const defaultAllowedSteps = [
  1, 5, 10, 20, 25, 50, 100, 200, 250, 500, 1000, 2000, 2500, 5000, 10_000, 20_000, 25_000, 50_000, 100_000
];

function diff(a: number, b: number): number {
  if (a > b) {
    return a - b;
  } else {
    return b - a;
  }
}

/**
 * Produce a list of intermediate objects consisting of the minimum stepSize and ticks for each domain given that conforms to the numberOfTicks requirement using one of the provided allowedSteps.
 * Aligns the given domains on 0 using alignTicks.
 */
export function determineStepSizes(
  domainMinimums: number[],
  domainMaximums: number[],
  numberOfTicks: number,
  allowedSteps: number[]
): { stepSize: number; ticks: number[] }[] {
  if ([...domainMinimums, ...domainMaximums].some((min) => isNaN(min) || isNil(min))) {
    // Safe fallback return value
    return domainMinimums.map(() => ({
      stepSize: 1,
      ticks: [0]
    }));
  }

  // Some safety against infinite loops
  const maxIterations = domainMinimums.length * allowedSteps.length;
  let currentIteration = 0;

  const generators = zipWith(domainMinimums, domainMaximums, (minimum, maximum) => ticksGenerator(minimum, maximum, allowedSteps));

  while (currentIteration < maxIterations) {
    currentIteration++;
    // Determine if there are too many ticks and the step size needs to be increased
    let nextGeneratorToIncrement = generators.find((generator) => generator.next().value.ticks.length > numberOfTicks);

    if (!nextGeneratorToIncrement) {
      // All ticks fit, but we still need to align on 0
      const zeroAlignedDomains = alignTicks(
        generators.map((generator) => generator.next().value.ticks),
        generators.map((generator) => generator.next().value.stepSize)
      );

      if (zeroAlignedDomains.some((domain) => domain.length > numberOfTicks)) {
        // Increment the longest array firs
        nextGeneratorToIncrement = maxBy(generators, (generator) => generator.next().value.ticks.length);
      }
    }

    if (nextGeneratorToIncrement) {
      nextGeneratorToIncrement.next(true);
    } else {
      const result = zipWith(
        generators,
        alignTicks(
          generators.map((generator) => generator.next().value.ticks),
          generators.map((generator) => generator.next().value.stepSize)
        ),
        (generator, ticks) => ({
          stepSize: generator.next().value.stepSize,
          ticks
        })
      );

      // Cleanup
      generators.forEach((generator) => generator.return(null));
      return result;
    }
  }

  if (currentIteration === maxIterations) {
    console.error('Max iterations reached in determineStepSizes.');
  }
}

/**
 * Returns the ticks generated by getTicksForStepSizeWithoutPadding for the current allowedStep. Get next step by calling .next(true)
 * Calling .next() will give the last ticks
 */
function* ticksGenerator(
  domainMinimum: number,
  domainMaximum: number,
  allowedSteps: number[]
): Generator<{ ticks: number[]; stepSize: number }, { ticks: number[]; stepSize: number }, boolean> {
  let allowedStepIndex = 0;
  let currentTicks = getTicksForStepSizeWithoutPadding(domainMinimum, domainMaximum, allowedSteps[allowedStepIndex]);

  while (true) {
    const yieldResult = yield { ticks: currentTicks, stepSize: allowedSteps[allowedStepIndex] };
    if (yieldResult) {
      if (allowedStepIndex < allowedSteps.length) {
        allowedStepIndex++;
      }

      currentTicks = getTicksForStepSizeWithoutPadding(domainMinimum, domainMaximum, allowedSteps[allowedStepIndex]);
    }
  }
}

export function getTicksForStepSizeWithoutPadding(domainMin: number, domainMax: number, stepSize: number): number[] {
  const ticks = [domainMin - getDeltaToLowerTick(domainMin, stepSize)];

  while (last(ticks) < domainMax) {
    ticks.push(last(ticks) + stepSize);
  }

  return ticks;
}

/**
 * Main function.
 * Returns a list of ticks for the given domains that is 0 aligned, uses the step size specified in allowedSteps and has exactly numberOfTicks ticks.
 * determineStepSizes is used to get the base ticks, and padTicks to expand the ticks to the specified number of ticks.
 * domainSizingFactor indicates the ratio of the given data to the produced scale.
 * A ratio below 0 means there is empty space around the data, a ratio above 1 means some data will fall outside the drawn area.
 */
export function getAllTicksZeroAligned(
  domains: number[][],
  numberOfTicks: number = 6,
  allowedSteps: number[] = defaultAllowedSteps,
  domainSizingFactor: number = 1
): number[][] {
  const domainMinimums = domains.map((domain) => (domain?.length > 0 ? Math.min(...domain) / domainSizingFactor : 0));
  const domainMaximums = domains.map((domain) => (domain?.length > 0 ? Math.max(...domain) / domainSizingFactor : 0));
  const determineStepSizesResult = determineStepSizes(domainMinimums, domainMaximums, numberOfTicks, allowedSteps);

  const paddingRatios = zipWith(domainMinimums, domainMaximums, determineStepSizesResult, (domainMin, domainMax, result) => {
    const foundStep = result.stepSize;
    return [getDeltaToLowerTick(domainMin, foundStep) / foundStep, getDeltaToHigherTick(domainMax, foundStep) / foundStep];
  });

  const maxLowerTickDelta = maxBy(paddingRatios, (ratios) => ratios[0] || 0)[0];
  const maxUpperTickDelta = maxBy(paddingRatios, (ratios) => ratios[1] || 0)[1];

  const preferLowerTick = maxLowerTickDelta < maxUpperTickDelta;

  return determineStepSizesResult.map(({ stepSize, ticks }) => padTicks(ticks, preferLowerTick, stepSize, numberOfTicks));
}

/**
 * Add steps to the given ticks list to ensure that the 0 step is aligned with the other domains
 */
export function alignTicks(domains: number[][], stepSizes: number[]): number[][] {
  const domainMinimums = domains.map((domain) => Math.min(...domain, 0));
  const domainMaximums = domains.map((domain) => Math.max(...domain, 0));

  const segmentsBelowZero = zipWith(
    domainMinimums,
    domainMaximums,
    stepSizes,
    (domainMinimum, domainMaximum, stepSize) => Math.abs(Math.min(0, domainMinimum) - Math.min(0, domainMaximum)) / stepSize
  );
  const segmentsAboveZero = zipWith(
    domainMinimums,
    domainMaximums,
    stepSizes,
    (domainMinimum, domainMaximum, stepSize) => Math.abs(Math.max(0, domainMaximum) - Math.max(0, domainMinimum)) / stepSize
  );

  const maxSegmentsBelow = max(segmentsBelowZero);
  const maxSegmentsAbove = max(segmentsAboveZero);

  return unzipWith<any, any>([domains, segmentsBelowZero, segmentsAboveZero, stepSizes], (domain, belowZero, aboveZero, stepSize) => {
    const newDomain = [...domain];

    if (domain.length === 0) {
      return domain;
    }

    let domainMin = Math.min(...domain) || 0;
    let domainMax = Math.max(...domain) || 0;

    // Expand to 0
    while (domainMin > 0) {
      domainMin = domainMin - stepSize;
      newDomain.unshift(domainMin);
    }

    while (domainMax < 0) {
      domainMax = domainMax + stepSize;
      newDomain.push(domainMax);
    }

    // Add additional segments
    while (maxSegmentsBelow - belowZero > 0) {
      domainMin = domainMin - stepSize;
      newDomain.unshift(domainMin);
      belowZero++;
    }

    while (maxSegmentsAbove - aboveZero > 0) {
      domainMax = domainMax + stepSize;
      newDomain.push(domainMax);
      aboveZero++;
    }

    return newDomain;
  });
}

/**
 * Expand an array of ticks to match the step size and number of ticks.
 * Determine preferBottomPadding based on (for example) the closest relative domain in the set to the start/end of the series. Will be used to place odd padding.
 */
export function padTicks(ticks: number[], preferBottomPadding: boolean, stepSize: number, numberOfTicks: number): number[] {
  if (ticks.length === numberOfTicks) {
    return ticks;
  }
  const isInverted = first(ticks) > last(ticks);
  ticks = [...ticks];
  if (isInverted) {
    ticks.reverse();
  }

  let ticksToAdd = numberOfTicks - ticks.length;

  if (ticksToAdd % 2 === 1) {
    // Determine if the odd tick should be added at the top or bottom
    if (preferBottomPadding) {
      ticks.unshift(first(ticks) - stepSize);
    } else {
      ticks.push(last(ticks) + stepSize);
    }

    ticksToAdd = ticksToAdd - 1;
  }

  while (ticksToAdd > 0) {
    ticksToAdd = ticksToAdd - 2;
    ticks.unshift(first(ticks) - stepSize);
    ticks.push(last(ticks) + stepSize);
  }

  if (isInverted) {
    ticks.reverse();
  }
  return ticks;
}

export function getDeltaToLowerTick(value: number, stepSize: number): number {
  const factor = value / stepSize;
  const flooredFactor = Math.floor(factor);
  return diff(flooredFactor * stepSize, value);
}

export function getDeltaToHigherTick(value: number, stepSize: number): number {
  const factor = value / stepSize;
  const flooredFactor = Math.ceil(factor);
  return diff(flooredFactor * stepSize, value);
}

/**
 * Make finding the baseline tick explicit
 */
export function getBaselineTick(ticks: number[]): number {
  const fixedBaseline = 0;
  const baseLineTick = ticks.find((tick) => tick === fixedBaseline);
  if (isUndefined(baseLineTick)) {
    console.warn('There is no 0 baseline on the ticks.');
  }
  return baseLineTick;
}
