<ng-container *ngIf="matches(IntradayOrderStatus.REQUESTED)" i18n="@@intraday-idconsOrderStatusPending">Pending</ng-container>
<ng-container *ngIf="matches(IntradayOrderStatus.CREATED)" i18n="@@intraday-idconsOrderStatusOpen">Open</ng-container>
<ng-container *ngIf="matches(IntradayOrderStatus.UPDATED)" i18n="@@intraday-idconsOrderStatusUpdated">Updated</ng-container>
<ng-container *ngIf="matches(IntradayOrderStatus.CANCELLATION_REQUESTED)" i18n="@@intraday-idconsOrderStatusCancellationRequested"
  >Cancelling</ng-container
>
<ng-container *ngIf="matches(IntradayOrderStatus.IN_PROGRESS)" i18n="@@intraday-idconsOrderStatusInProgress">In progress</ng-container>
<ng-container *ngIf="matches(IntradayOrderStatus.EXPIRED)" i18n="@@intraday-idconsOrderStatusExpired">Expired</ng-container>
<ng-container *ngIf="matches(IntradayOrderStatus.FAILED)" i18n="@@intraday-idconsOrderStatusFailed">Failed</ng-container>
<ng-container *ngIf="matches(IntradayOrderStatus.COMPLETED)" i18n="@@intraday-idconsOrderStatusCompleted">Completed</ng-container>
<ng-container *ngIf="matches(IntradayOrderStatus.CANCELLED)" i18n="@@intraday-idconsOrderStatusCancelled">Cancelled</ng-container>
