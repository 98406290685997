import {
  AdjustAvailability,
  CalendarPeriod,
  OperationMeasurements,
  R3Activation,
  R3Availability,
  R3AvailabilityFee,
  R3ServiceAgreement,
  R3TsoAgreement
} from 'flex-app-shared';

export enum IncidentReserveAvailabilityView {
  YEAR,
  MONTH
}

export class ImbalanceReserveAvailabilityLoadedEvent {
  public static readonly type = '[Imbalance reserve availability] Loaded data';

  constructor(
    public customerId: string,
    public availabilities: R3Availability[],
    public serviceAgreements: R3ServiceAgreement[],
    public earliestEditableDay: string,
    public earliestEditableDayEditableUntil: Date,
    public earliestAuctionDay: string,
    public r3TsoAgreements: R3TsoAgreement[]
  ) {}
}

export class ImbalanceReserveAvailabilityViewChangedEvent {
  public static readonly type = '[Imbalance reserve availability] Period view changed';

  constructor(public view: IncidentReserveAvailabilityView) {}
}

export class IncidentReserveAvailabilityChangeAvailabilityCommand {
  public static readonly type = '[Imbalance reserve availability] Save availability for period';

  constructor(public period: CalendarPeriod, public upwards: AdjustAvailability, public downwards: AdjustAvailability) {}
}

export class ImbalanceReserveAvailabilityRefreshCommand {
  public static readonly type = '[Imbalance reserve availability] Refresh availability';

  constructor(public customerId: string) {}
}

export class IncidentReserveAvailabilitySelectMonthCommand {
  public static readonly type = '[Imbalance reserve availability] Select month command';

  constructor(public period: CalendarPeriod) {}
}

export class ImbalanceReserveAvailabilityRefreshFeesCommand {
  public static readonly type = '[Imbalance reserve availability] Refresh fees command';

  constructor(public serviceAgreementId: string) {}
}

export class ImbalanceReserveAvailabilityFeesUpdatedEvent {
  public static readonly type = '[Imbalance reserve availability] Fees updated event';

  constructor(
    public serviceAgreementId: string,
    public downwardsAvailabilityFees: R3AvailabilityFee[],
    public upwardsAvailabilityFees: R3AvailabilityFee[]
  ) {}
}

export class ImbalanceReserveAvailabilityRefreshActivationsCommand {
  public static readonly type = '[Imbalance reserve availability] Refresh activations command';

  constructor(public serviceAgreementId: string) {}
}

export class ImbalanceReserveAvailabilityRefreshActivationCommand {
  public static readonly type = '[Imbalance reserve availability] Refresh activation for service agreement command';

  constructor(public serviceAgreementId: string, public activationId: string) {}
}

export class ImbalanceReserveAvailabilityActivationMeasurementsUpdatedEvent {
  public static readonly type = '[Imbalance reserve availability] Activation measurements updated';

  constructor(public serviceAgreementId: string, public activationId: string, public data: OperationMeasurements) {}
}

export class ImbalanceReserveAvailabilityActivationsUpdatedEvent {
  public static readonly type = '[Imbalance reserve availability] Activations updated event';

  constructor(public serviceAgreementId: string, public activations: R3Activation[]) {}
}

export class ImbalanceReserveAvailabilityActivate {
  public static readonly type = '[Imbalance reserve availability] Route activated';
}

export class ImbalanceReserveAvailabilityDeactivate {
  public static readonly type = '[Imbalance reserve availability] Route deactivated';
}
