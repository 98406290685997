<mat-card>
  <mat-card-header>
    <mat-card-title>
      <mat-icon svgIcon="ic-old:history"></mat-icon>
      <ng-container i18n="@@intraday-headerTodaysDeals">Recent deals</ng-container>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <ng-container *ngIf="bidSummary$ | async; let bidSummary">
      <h4 class="bid" i18n="@@intraday-headerTodaysDealsSell">Sell</h4>
      <ng-container *ngTemplateOutlet="dealStatusSummary; context: { summary: bidSummary, direction: Direction.PRODUCTION }"></ng-container>
    </ng-container>
    <hr />
    <ng-container *ngIf="askSummary$ | async; let askSummary">
      <h4 class="ask" i18n="@@intraday-headerTodaysDealsBuy">Buy</h4>
      <ng-container
        *ngTemplateOutlet="dealStatusSummary; context: { summary: askSummary, direction: Direction.CONSUMPTION }"
      ></ng-container>
      <hr />
    </ng-container>
  </mat-card-content>
  <mat-card-footer>
    <mat-card-actions>
      <button mat-stroked-button color="primary" (click)="onShowDealsDialog()">
        <ph-flex-vertical-center
          ><mat-icon svgIcon="ic-old:file-text-o"></mat-icon
          ><span i18n="@@intraday-buttonTodaysDeals">Details</span></ph-flex-vertical-center
        >
      </button>
    </mat-card-actions>
  </mat-card-footer>
</mat-card>

<ng-template let-dialogRef="dialogRef" #dealStatusDialog>
  <app-intraday-deal-status-overview [dealStatus]="dealsFilter.dealStatus" [direction]="dealsFilter.direction">
  </app-intraday-deal-status-overview>
</ng-template>

<ng-template #dealStatusSummary let-summary="summary" let-direction="direction">
  <p i18n="@@intraday-summaryConfirmedDeals">
    <ph-flex-number [value]="summary.numberOfDeals"></ph-flex-number> confirmed {summary.numberOfDeals, plural, =0 {deals} =1 {deal} other
    {deals}} with a total volume of <em><ph-flex-number [value]="summary.volume"></ph-flex-number> kWh</em>.<br />
    With an average price of <em><ph-flex-number [value]="summary.averagePrice" [digitsInfo]="'0.2-2'"></ph-flex-number> €/MWh</em>.
  </p>
  <div class="status-breakdown">
    <span class="pending" (click)="onShowDealsDialog(IntraDayDealStatus.PENDING, direction)" i18n="@@intraday-summaryConfirmedDealsPending"
      ><ph-flex-number [value]="summary.pending"></ph-flex-number> pending</span
    >
    <mat-spinner [diameter]="16" *ngIf="summary.pending > 0"></mat-spinner>
    <span class="separator"></span>
    <span
      class="confirmed"
      (click)="onShowDealsDialog(IntraDayDealStatus.CONFIRMED, direction)"
      i18n="@@intraday-summaryConfirmedDealsConfirmed"
      ><ph-flex-number [value]="summary.confirmed"></ph-flex-number> confirmed</span
    >
    <span class="separator"></span>
    <span class="failed" (click)="onShowDealsDialog(IntraDayDealStatus.FAILED, direction)" i18n="@@intraday-summaryConfirmedDealsFailed"
      ><ph-flex-number [value]="summary.failed"></ph-flex-number> failed</span
    >
  </div>
</ng-template>
