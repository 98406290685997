<ng-container [formGroup]="filterFormGroup">
  <div class="customer-filter-bar ph-flex-mat-form-field-group-inline">
    <mat-form-field *ngIf="hasMultipleCustomers$ | async" class="ph-flex-mat-form-field-medium">
      <ph-flex-autocomplete
        appGopacsCustomerDataSource
        formControlName="customerId"
        [shouldOnlyEmitIds]="true"
        [shouldEmitNull]="true"
        [phFlexDataSourceFilterByCustomerId]="customerIdsWithCapacity"
        #selectedCustomer
      ></ph-flex-autocomplete>
      <mat-label i18n="@@intraday-tableHeaderCustomer">Company name</mat-label>
    </mat-form-field>
    <mat-form-field class="ph-flex-mat-form-field-wide">
      <ph-flex-autocomplete
        appGopacsGridPointDataSource
        [phFlexDataSourceFilterByCustomerId]="filterFormGroup.get('customerId').valid && filterFormGroup.get('customerId').value"
        [phFlexDataSourceFilterByGridPointId]="gridPointIdsWithCapacity"
        phFlexDataSourceFilterClearValueIfNotSelectable
        phFlexDataSourceFilterRemoveSelectedValue
        formControlName="gridPointId"
        [shouldOnlyEmitIds]="true"
        [shouldEmitNull]="true"
        [disabled]="filterFormGroup.get('gridPointId').disabled"
        #selectedGridPoint
      ></ph-flex-autocomplete>
      <mat-label i18n="@@intraday-tableHeaderGridPoint">Gridpoint</mat-label>
    </mat-form-field>
    <ph-flex-table-filter [autofocus]="true" label="Search" i18n-label="@@intraday-labelSearchTerm"></ph-flex-table-filter>
  </div>
</ng-container>
<mat-slide-toggle #hideEmptyControlsToggle [checked]="true" i18n="@@intraday-labelHideControls">
  Hide components without available volume
</mat-slide-toggle>
<ph-flex-table-container>
  <table
    mat-table
    [dataSource]="dataSource"
    [trackBy]="trackBy"
    editable
    [class.buy]="editSlotContext.direction === OrderDirection.BUY"
    [class.sell]="editSlotContext.direction === OrderDirection.SELL"
    [class.no-padding]="tableHasNoPadding$ | async"
  >
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let node">
        <div class="description">
          <div class="node-padding expand-collapse" [ngSwitch]="isExpandable(0, node)">
            <ng-container *ngSwitchCase="false">
              <button mat-icon-button disabled></button>
            </ng-container>
            <ng-container *ngSwitchCase="true">
              <button ph-flex-inline-icon-button [style.visibility]="treeControl.isExpandable(node.id) ? 'visible' : 'hidden'">
                <mat-icon [svgIcon]="treeControl.isExpanded(node.id) ? 'ic-old:minus-square-o' : 'ic-old:plus-square-o'"></mat-icon>
              </button>
            </ng-container>
          </div>
          <div class="control-icon" *ngIf="node.level === FlattenedIntraDayLevel.CONTROL" [ngSwitch]="node.controlDirection">
            <mat-icon *ngSwitchCase="IntraDayControlDirection.PRODUCTION" class="production" svgIcon="ic-old:arrow_upward"></mat-icon>
            <mat-icon *ngSwitchCase="IntraDayControlDirection.CONSUMPTION" class="consumption" svgIcon="ic-old:arrow_downward"></mat-icon>
          </div>
          <div [ngSwitch]="node.level" class="description-text">
            <ng-container *ngSwitchCase="FlattenedIntraDayLevel.CUSTOMER"
              >{{ node.customerLegalName }}
              <em>
                <ng-container *ngIf="!treeControl.isExpanded(node.id)">
                  <ng-container *ngTemplateOutlet="slotControls; context: { node: node }"></ng-container>
                </ng-container>
              </em>
            </ng-container>
            <ng-container *ngSwitchCase="FlattenedIntraDayLevel.GRID_POINT"
              >{{ node.gridPointDescription }} <em>| EAN: {{ node.gridPointEan }}</em>
              <ng-container *ngIf="!treeControl.isExpanded(node.id)">
                <ng-container *ngTemplateOutlet="slotControls; context: { node: node }"></ng-container>
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="FlattenedIntraDayLevel.CONTROL">
              {{ node.controlDescription }}
            </ng-container>
          </div>
        </div>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="availableCapacityPercentage">
      <th mat-header-cell *matHeaderCellDef>
        <ng-container i18n="@@intraday-tableHeaderPosition">
          <div>Position</div>
          [%]
        </ng-container>
      </th>
      <td
        mat-cell
        *matCellDef="let node"
        class="number"
        [ngSwitch]="node.level"
        matTooltip="nameplate power: {{ node.namePlatePower }} kW, position: {{
          node.totalMinPosition === node.totalMaxPosition ? node.totalMaxPosition : node.totalMinPosition + ' - ' + node.totalMaxPosition
        }} kW"
        i18n-matTooltip="@@intraday-control-position"
        [matTooltipDisabled]="node.level !== FlattenedIntraDayLevel.CONTROL"
      >
        <ng-container *ngSwitchCase="FlattenedIntraDayLevel.CONTROL">
          <ng-container *ngIf="node.positionMinPercentageOfNamePlatePower !== node.positionMaxPercentageOfNamePlatePower"
            >{{ node.positionMinPercentageOfNamePlatePower | percent }} -
            {{ node.positionMaxPercentageOfNamePlatePower | percent }}</ng-container
          >
          <ng-container *ngIf="node.positionMinPercentageOfNamePlatePower === node.positionMaxPercentageOfNamePlatePower">{{
            node.positionMinPercentageOfNamePlatePower | percent
          }}</ng-container>
        </ng-container>
        <ng-container *ngSwitchDefault></ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="dealPrice">
      <th mat-header-cell *matHeaderCellDef>
        <ng-container i18n="@@intraday-tableHeaderDealAmount">
          <div>Deal amount</div>
          [€]
        </ng-container>
      </th>
      <td mat-cell *matCellDef="let node" class="number" [ngSwitch]="node.level">
        <ng-container *ngSwitchCase="FlattenedIntraDayLevel.CONTROL"
          >{{ getDealPrice(editSlotContext, formGroup.get(node.controlId)?.value) | number : '0.2-2' }}
        </ng-container>
        <ng-container *ngSwitchCase="FlattenedIntraDayLevel.GRID_POINT"
          >{{ getDealPrice(editSlotContext, dealTotalPerGridPoint[node.gridPointId]) | number : '0.2-2' }}
        </ng-container>
        <ng-container *ngSwitchCase="FlattenedIntraDayLevel.CUSTOMER"
          >{{ getDealPrice(editSlotContext, dealTotalPerCustomer[node.customerId]) | number : '0.2-2' }}
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="max">
      <th mat-header-cell *matHeaderCellDef>
        <ng-container i18n="@@intraday-tableHeaderMax"> Max</ng-container>
      </th>
      <td mat-cell class="number" *matCellDef="let node">
        {{ node.maxAvailableCapacity }}
      </td>
      <td mat-footer-cell></td>
    </ng-container>
    <ng-container matColumnDef="position">
      <th mat-header-cell *matHeaderCellDef>
        <ng-container i18n="@@intraday-tableHeaderPosition">
          <div>Position</div>
          [kW]
        </ng-container>
      </th>
      <td class="number" mat-cell *matCellDef="let node">
        {{ node.totalPosition | number : '0.0-0' }}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="availableCapacity">
      <th mat-header-cell *matHeaderCellDef>
        <ng-container i18n="@@intraday-tableHeaderAvailableCapacity">
          <div>Dealable</div>
          [kW]
        </ng-container>
      </th>
      <td class="number" mat-cell *matCellDef="let node">
        <div>
          <mat-basic-chip *ngIf="getAvailableOffset(node); let availableOffset" (click)="applyAvailableOffset(node, $event)">
            <ng-container *ngIf="availableOffset > 0">+</ng-container>
            {{ availableOffset | number : '0.0-0' }}
          </mat-basic-chip>
        </div>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="deal">
      <th mat-header-cell *matHeaderCellDef>
        <ng-container i18n="@@intraday-tableHeaderDeal">
          <div>
            <ng-container *ngIf="editSlotContext.direction === OrderDirection.BUY">Buy</ng-container>
            <ng-container *ngIf="editSlotContext.direction === OrderDirection.SELL">Sell</ng-container>
          </div>
          [kW]
        </ng-container>
      </th>
      <td
        mat-cell
        *matCellDef="let node"
        class="number edit"
        [matPopoverEdit]="dealEdit"
        [matPopoverEditDisabled]="node.level !== 2"
        [appSlotFocus]="node"
        matPopoverEditTabOut
        [class.invalid]="isNodeInvalid(node) || undefined"
        [class.warning]="isNodeWarning(node) || undefined"
      >
        <button matEditOpen [class.disabled]="node.level !== FlattenedIntraDayLevel.CONTROL" (click)="rowClick(node, $event)">
          {{ getDealTotal(node) | number : '0.0-0' }}
        </button>

        <ng-template #dealEdit>
          <form
            matEditLens
            matEditLensClickOutBehavior="close"
            [class.warning]="isNodeWarning(node) || undefined"
            [formGroup]="formGroup"
            class="intra-day-edit-lens-form"
            [class.buy]="editSlotContext.direction === OrderDirection.BUY"
            [class.sell]="editSlotContext.direction === OrderDirection.SELL"
          >
            <mat-form-field
              [phFlexControlErrorOverlay]="overlayTemplate"
              [phFlexControlErrorOverlayControl]="formGroup.get(node.controlId)"
              [phFlexControlErrorOverlayForceShow]="isNodeWarning(node)"
              [phFlexControlErrorOverlayClasses]="isNodeWarning(node) ? ['warning'] : []"
            >
              <mat-hint i18n="@@intraday-hintAvailableCapacity">Dealable: {{ node.availableCapacity }} kW</mat-hint>
              <ph-flex-number-input [formControlName]="node.controlId"></ph-flex-number-input>
            </mat-form-field>
          </form>
        </ng-template>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let node; columns: displayedColumns"
      [class.level-0]="node.level === FlattenedIntraDayLevel.CUSTOMER"
      [class.level-1]="node.level === FlattenedIntraDayLevel.GRID_POINT"
      [class.level-2]="node.level === FlattenedIntraDayLevel.CONTROL"
      [ngClass]="treeControl.isExpanded(node.id) ? 'expanded' : ''"
      (click)="rowClick(node, $event)"
    ></tr>
  </table>
</ph-flex-table-container>

<ng-template #slotControls let-node="node">
  <ng-container i18n="@@intraday-slotControls">
    | {{ node.totalChildren }}
    { node.totalChildren, plural, =1 { component } other { components }}
  </ng-container>
</ng-template>

<ng-template #overlayTemplate="cdkPortal" cdkPortal let-control="control" let-node="node">
  <ng-container *ngIf="control?.errors; let errors">
    <mat-error>
      <ph-flex-form-error-messages
        [control]="control"
        i18n-descriptiveName="@@intraday-orderAmount"
        descriptiveName="order amount"
        valuePostfix=" kW"
      ></ph-flex-form-error-messages>
    </mat-error>
  </ng-container>
  <ng-container *ngIf="!control?.errors">
    <ng-container i18n="@@intraday-warningPositionHigherThanNameplatePower"
      >Position will be higher than the specified nameplate power
    </ng-container>
    <ng-container
      *ngIf="
        (node.totalPosition + control.value) / node.namePlatePower > 1 && (node.totalPosition + control.value) / node.namePlatePower < 1.01
      "
    >
      ({{ node.totalPosition + control.value - node.namePlatePower | number : '0.0-1' }} kW)
    </ng-container>
    <ng-container *ngIf="control.value / node.availableCapacity >= 1.01">
      ({{ (node.totalPosition + control.value) / node.namePlatePower | percent : '0.0-0' }})
    </ng-container>
  </ng-container>
</ng-template>

<ng-template matStepLabel>
  <ng-container *ngIf="shouldShowLabelWithoutValue()">Volume</ng-container>
  <ng-container *ngIf="!shouldShowLabelWithoutValue()"> {{ getTotalOfAllDeals() | number : '1.2' }} kWh</ng-container>
</ng-template>
