<mat-button-toggle-group *ngIf="selectedPeriodControl" [formControl]="selectedPeriodControl" phFlexInputTheme>
  <ng-container *ngFor="let periodUnit of availablePeriodUnits">
    <mat-button-toggle *ngIf="periodUnit === PeriodUnit.DAY" [value]="PeriodUnit.DAY" i18n="@@flexOverview-labelViewDay"
      >Day</mat-button-toggle
    >
    <mat-button-toggle *ngIf="periodUnit === PeriodUnit.MONTH" [value]="PeriodUnit.MONTH" i18n="@@flexOverview-labelViewMonth"
      >Month</mat-button-toggle
    >
    <mat-button-toggle *ngIf="periodUnit === PeriodUnit.YEAR" [value]="PeriodUnit.YEAR" i18n="@@flexOverview-labelViewYear"
      >Year</mat-button-toggle
    >
  </ng-container>
</mat-button-toggle-group>
