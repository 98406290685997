import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import {
  AuthorityService,
  Capacity,
  Direction,
  FlexDialogService,
  IntraDayPriceDelta,
  IntraDaySlot,
  IntraDaySlotWithFlags,
  MANAGE_INTRA_DAY_IDCONS_ORDER,
  MANAGE_INTRA_DAY_REM_ORDER,
  MixinBase,
  OnDestroyMixin,
  OnDestroyProvider,
  TimeSlot,
  VIEW_INTRA_DAY_IDCONS_ORDER,
  VIEW_INTRA_DAY_REM_ORDER
} from 'flex-app-shared';
import { memoize } from 'lodash-es';
import { Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { CreateOrderDialogComponent } from '../../order/create-order-dialog/create-order-dialog.component';
import { IntradayFacade } from '../../state/intraday-facade.service';
import { IntradaySlotStatus, IntradayState } from '../../state/intraday-state.service';
import { EditIntradayTimeSlot, UpdateLatestIntraDayInformationCommand } from '../../state/intraday.actions';
import {
  IntradayPriceHistoryDialogComponent,
  IntradayPriceHistoryDialogData
} from '../intraday-price-history-dialog/intraday-price-history-dialog.component';
import { FlexScrollStrategyService } from '../../../../../../flex-app-shared/src/lib/core/common/scroll-strategy';

@Component({
  selector: 'app-intraday-slot-overview',
  templateUrl: './intraday-slot-overview.component.html',
  styleUrls: ['./intraday-slot-overview.component.scss']
})
export class IntradaySlotOverviewComponent extends OnDestroyMixin(MixinBase) {
  public readonly isOverview: boolean = true;
  VIEW_INTRA_DAY_IDCONS_ORDER = VIEW_INTRA_DAY_IDCONS_ORDER;
  VIEW_INTRA_DAY_REM_ORDER = VIEW_INTRA_DAY_REM_ORDER;
  MANAGE_INTRA_DAY_IDCONS_ORDER = MANAGE_INTRA_DAY_IDCONS_ORDER;
  MANAGE_INTRA_DAY_REM_ORDER = MANAGE_INTRA_DAY_REM_ORDER;

  Direction = Direction;
  IntraDayPriceDelta = IntraDayPriceDelta;
  slots$: Observable<IntraDaySlotWithFlags[]> = this.store.select(IntradayState.slotsWithFlags);
  isExpired$: Observable<boolean> = this.store
    .select(IntradayState.slotStatus)
    .pipe(map((status) => status === IntradaySlotStatus.EXPIRED || status === IntradaySlotStatus.STALE));

  isStale$: Observable<boolean> = this.store.select(IntradayState.slotStatus).pipe(map((status) => status === IntradaySlotStatus.STALE));

  latestDraftDealTotals = new LatestAdapter(this, this.store.select(IntradayState.draftDealTotals));
  isOrderCreationEnabled$ = this.facade.isOrderCreationEnabled$;

  askDealVolumeCachedDynamicSelector = CachedDynamicSelector.getStringArgumentCachedFactory(
    (startDateTime: string, toDateTime: string): Observable<number> => {
      return this.store.select(IntradayState.dealVolumeForDirection(Direction.CONSUMPTION, startDateTime, toDateTime));
    },
    this.onDestroy$
  );

  bidDealVolumeCachedDynamicSelector = CachedDynamicSelector.getStringArgumentCachedFactory(
    (startDateTime: string, toDateTime: string): Observable<number> => {
      return this.store.select(IntradayState.dealVolumeForDirection(Direction.PRODUCTION, startDateTime, toDateTime));
    },
    this.onDestroy$
  );

  constructor(
    private store: Store,
    private dialog: MatDialog,
    private facade: IntradayFacade,
    private authorityService: AuthorityService,
    private scrollService: FlexScrollStrategyService
  ) {
    super();
  }

  trackByPriceId(index: number, slot: IntraDaySlot): string {
    return slot.askPriceId;
  }

  editVolume(direction: Direction, startDateTime: string, toDateTime: string): void {
    this.store.dispatch(new EditIntradayTimeSlot(direction, startDateTime, toDateTime));
  }

  isEditEnabledForSlot(slot: IntraDaySlot, direction: Direction): boolean {
    let hasCapacity: boolean;

    const currentVolume = this.latestDraftDealTotals.latest?.find(
      (total) => TimeSlot.isEqual(total.period, slot.period) && (!direction || total.direction === direction)
    )?.volume;

    switch (direction) {
      case Direction.CONSUMPTION:
        hasCapacity = Capacity.isAboveZero(slot?.availableAskCapacity) || !!currentVolume;
        break;
      case Direction.PRODUCTION:
        hasCapacity = Capacity.isAboveZero(slot?.availableBidCapacity) || !!currentVolume;
        break;
      default:
        hasCapacity =
          Capacity.isAboveZero(slot?.availableAskCapacity) || Capacity.isAboveZero(slot?.availableBidCapacity) || !!currentVolume;
        break;
    }

    const slotStatus = this.store.selectSnapshot(IntradayState.slotStatus);
    return hasCapacity && slotStatus !== IntradaySlotStatus.STALE;
  }

  showGopacsDialog(): any {
    // FA-9327: Send command to refresh all IntraDayInformation, especially the list of dealable controls, so that
    // the time slots for today and tomorrow are correctly filled, even when the client has missed an sse event.
    this.store.dispatch(new UpdateLatestIntraDayInformationCommand());
    this.dialog.open(CreateOrderDialogComponent, {
      maxWidth: 'min(98vw, max(80vw, 800px))' // 800px matches the (tuned) min-width of the order dialog content, 80vw is the default dialog size, 98vw leaves some minimal space around the dialog.
    });
  }

  showPriceHistoryDialog(slot: IntraDaySlot, direction: Direction): any {
    const data: IntradayPriceHistoryDialogData = {
      timeslot: slot.period,
      direction
    };

    this.dialog.open(IntradayPriceHistoryDialogComponent, {
      data,
      width: FlexDialogService.getModalWidth('s'),
      minHeight: FlexDialogService.getModalMinHeight('s')
    });
  }
}

/**
 * Use to cache dynamic selectors used in HTML.
 * Will update based on emitted values from state and always return the same observable.
 */
class CachedDynamicSelector<T> {
  private valueSubject = new ReplaySubject<T>(1);
  value$: Observable<T> = this.valueSubject.asObservable();
  private onDestroySubject = new Subject<void>();

  constructor(sourceObservable: Observable<T>, onDestroy$: Observable<any>) {
    this.onDestroySubject.subscribe(() => this.valueSubject.complete());

    sourceObservable.pipe(takeUntil(this.onDestroySubject)).subscribe({
      next: (result) => this.valueSubject.next(result),
      error: (err) => this.valueSubject.error(err),
      complete: () => this.valueSubject.complete()
    });

    if (onDestroy$) {
      onDestroy$.subscribe(() => this.destroy());
    }
  }

  static getStringArgumentCachedFactory<T>(fn: (...args: any) => any, onDestroy$?: Observable<any>): (...args: any) => Observable<T> {
    return memoize(
      (...args) => {
        const result = fn(...args);
        return result;
      },
      (...args) => args.map((a) => `${a}`).join('-')
    );
  }

  destroy(): void {
    this.onDestroySubject.next();
    this.onDestroySubject.complete();
  }
}

export class LatestAdapter<T> {
  latest: T | null;
  error: any;
  complete: boolean | null;

  private subscription: Subscription;

  constructor(private onDestroyProvider: OnDestroyProvider, private observable$: Observable<T>) {
    this.reconnect();
  }

  reconnect(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    this.latest = null;
    this.error = null;
    this.complete = false;

    this.subscription = this.observable$.pipe(takeUntil(this.onDestroyProvider.onDestroy$)).subscribe({
      next: (result) => (this.latest = result),
      error: (err) => (this.error = err),
      complete: () => {
        this.complete = true;
        this.subscription = null;
      }
    });
  }
}
