import { combineLatest, Observable, of, switchMap } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { flatMap, isEqual, uniq } from 'lodash-es';
import { SubmoduleAttachHelper } from './submodule-attach.helper';
import { D3GraphDynamicScaleProvider } from '../scales/d3-graph-dynamic-scale-provider';
import { D3GraphScaleProvider } from '../scales/d3-graph-scale-provider';
import { D3GraphSeriesSubModule } from '../submodules/d3-graph-submodule';
import { OnDestroyProvider } from '../../../core/common/on-destroy.mixin';
import { MultiSeriesAttachHelper } from './multi-series-attach.helper';
import { expectObservableValue } from '../../../core/common/expect-observable-value';
import { getBaselineTick } from '../scales/ticks-helper';

export class SeriesAttachHelper<
  SupportedXScale = any,
  YScaleProvider extends D3GraphScaleProvider<any, any> = any,
  Config = any,
  Datum = any
> extends SubmoduleAttachHelper {
  dynamicYScaleProvider = new D3GraphDynamicScaleProvider<any, any>(this);
  usedYScaleProvider$ = this.dynamicYScaleProvider.scaleProvider$;
  usedYScales$ = this.usedYScaleProvider$.pipe(map((usedYScaleProvider) => usedYScaleProvider?.scale$));
  usedYScaleProviders$ = this.usedYScaleProvider$.pipe(map((usedYScaleProvider) => (usedYScaleProvider ? [usedYScaleProvider] : [])));
  protected subModule: D3GraphSeriesSubModule<SupportedXScale, YScaleProvider, Config, Datum>;
  /**
   * Can be used to show for example an axis based on *if* data is shown on the screen
   */
  canUseData$: Observable<boolean> = combineLatest([this.isDisabled$, this.subModule.hasData$]).pipe(
    map(([isDisabled, hasData]) => !isDisabled && hasData)
  );

  constructor(
    onDestroyProvider: OnDestroyProvider,
    seriesSubModule: D3GraphSeriesSubModule<SupportedXScale, YScaleProvider, Config, Datum>
  ) {
    super(onDestroyProvider, seriesSubModule);

    this.dynamicYScaleProvider.follow(
      combineLatest([this.subModule.usedYScaleProvider$, this.isDisabled$, this.isShownProvider.value$]).pipe(
        map(([scaleProvider, isDisabled, isShown]) => (!isDisabled && isShown ? scaleProvider : null)),
        distinctUntilChanged(isEqual)
      )
    );
  }

  static shownYScaleProviders$<SupportedXScale, YScaleProvider extends D3GraphScaleProvider<any, any>, Config, Datum>(
    seriesAttachHelpers: (
      | SeriesAttachHelper<SupportedXScale, YScaleProvider, Config, Datum>
      | MultiSeriesAttachHelper<any, any, any, any>
    )[]
  ): Observable<D3GraphScaleProvider<any, any>[]> {
    const scaleProviders$ = seriesAttachHelpers.map((attachHelper) => {
      expectObservableValue(attachHelper.canUseData$, 'Expected canUseData$ to return value in shownYScaleProviders$', attachHelper);
      return attachHelper.canUseData$.pipe(
        switchMap((canUse) => {
          if (!canUse) {
            return of([]);
          }

          return attachHelper.usedYScaleProviders$;
        })
      );
    });

    return combineLatest(scaleProviders$).pipe(map((value) => uniq(flatMap(value)).filter((a) => a)));
  }

  /**
   * Get the baseline positions for the provided series attach helpers
   */
  static baselineYPositions$<SupportedXScale, YScaleProvider extends D3GraphScaleProvider<any, any>, Config, Datum>(
    seriesAttachHelpers: (
      | SeriesAttachHelper<SupportedXScale, YScaleProvider, Config, Datum>
      | MultiSeriesAttachHelper<any, any, any, any>
    )[]
  ): Observable<number[]> {
    return SeriesAttachHelper.shownYScaleProviders$(seriesAttachHelpers).pipe(
      switchMap((shownYScaleProviders) => {
        return shownYScaleProviders.length === 0
          ? of([])
          : combineLatest(
              shownYScaleProviders.map((yScaleProvider) => {
                return combineLatest([yScaleProvider.scale$, yScaleProvider.ticks$]).pipe(
                  map(([scale, ticks]) => scale(getBaselineTick(ticks)))
                );
              })
            );
      }),
      map((baselinePosition) => uniq(baselinePosition))
    );
  }
}
