import { BreakpointObserver } from '@angular/cdk/layout';
import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { AuthorityService, Capacity, Direction, theme } from 'flex-app-shared';
import { toPairs } from 'lodash-es';
import { map } from 'rxjs';
import { IntradayCreateOrdersJson } from '../../../shared/intraday-order/intraday-order.service';
import { IntradayFacade } from '../../state/intraday-facade.service';
import { OrderDirection } from '../../state/order/intraday-order.state';
import { StepDirectionComponent } from './steps/step-direction/step-direction.component';
import { StepPriceComponent } from './steps/step-price/step-price.component';
import { StepTimeComponent } from './steps/step-time/step-time.component';
import { StepVolumeComponent } from './steps/step-volume/step-volume.component';

@Component({
  selector: 'app-create-order-dialog',
  templateUrl: './create-order-dialog.component.html',
  styleUrls: ['./create-order-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateOrderDialogComponent implements AfterViewInit, OnDestroy {
  private horizontalStepperMediaQuery = `(min-width: ${theme.layout.width.m}px)`;

  OrderDirection = OrderDirection;

  isPending$ = this.intradayFacade.idconsSavePending$;

  pricePerMWh$ = this.intradayFacade.idconsPrice$;

  @ViewChild(StepDirectionComponent) stepDirectionComponent: StepDirectionComponent;
  @ViewChild(StepTimeComponent) stepTimeComponent: StepTimeComponent;
  @ViewChild(StepVolumeComponent) stepVolumeComponent: StepVolumeComponent;
  @ViewChild(StepPriceComponent) stepPriceComponent: StepPriceComponent;

  @ViewChild(MatStepper) stepper: MatStepper;
  @ViewChild('orderAddedSnackbar') orderAddedSnackbarTemplate: TemplateRef<any>;

  stepperFormGroup = this.fb.group({});

  stepperOrientation$ = this.breakpointObserver
    .observe(this.horizontalStepperMediaQuery)
    .pipe(map((matchResult) => (matchResult.matches ? 'horizontal' : 'vertical')));

  constructor(
    private fb: UntypedFormBuilder,
    private authorityService: AuthorityService,
    public intradayFacade: IntradayFacade,
    private dialogRef: MatDialogRef<CreateOrderDialogComponent>,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnDestroy(): void {
    this.intradayFacade.resetOrderForm();
  }

  ngAfterViewInit(): void {
    this.stepperFormGroup.addControl('direction', this.stepDirectionComponent.formGroup);
    this.stepperFormGroup.addControl('time', this.stepTimeComponent.formGroup);
    this.stepperFormGroup.addControl('volume', this.stepVolumeComponent.formGroup);
    this.stepperFormGroup.addControl('pricePerMWh', this.stepPriceComponent.formGroup);
  }

  isCurrentStepValid(): boolean {
    return this.stepper?.selected.stepControl.valid;
  }

  hasPreviousStep(): boolean {
    return this.stepper?.selectedIndex !== 0;
  }

  hasNextStep(): boolean {
    if (!this.stepper) {
      return false;
    }
    return this.stepper.selectedIndex < this.stepper.steps.length - 1;
  }

  isSubmit(): boolean {
    if (!this.stepper) {
      return false;
    }
    return this.stepper.selectedIndex === this.stepper.steps.length - 1;
  }

  submit(): void {
    this.intradayFacade.createIntradayOrder(this.dialogRef);
  }
}
