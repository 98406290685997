<span [ngSwitch]="getOrderType()" phFlexLocaleClass>
  <ng-container *ngSwitchCase="OrderType.Buy"><ng-container *ngTemplateOutlet="buy"></ng-container></ng-container>
  <ng-container *ngSwitchCase="OrderType.BuyIntradayPlus"><ng-container *ngTemplateOutlet="buyPlus"></ng-container></ng-container>
  <ng-container *ngSwitchCase="OrderType.Sell"><ng-container *ngTemplateOutlet="sell"></ng-container></ng-container>
  <ng-container *ngSwitchCase="OrderType.SellIntradayPlus"><ng-container *ngTemplateOutlet="sellPlus"></ng-container></ng-container>
</span>

<ng-template #buy><span class="chip status-box-buy" i18n="@@intraday-labelDealTypeBuy">Buy</span></ng-template>
<ng-template #buyPlus
  ><span class="chip status-box-buy"
    ><ng-container i18n="@@intraday-labelDealTypeBuy">Buy</ng-container><span class="chip-plus">+</span></span
  ></ng-template
>
<ng-template #sell><span class="chip status-box-sell" i18n="@@intraday-labelDealTypeSell">Sell</span></ng-template>
<ng-template #sellPlus
  ><span class="chip status-box-sell"
    ><ng-container i18n="@@intraday-labelDealTypeSell">Sell</ng-container><span class="chip-plus">+</span></span
  ></ng-template
>
