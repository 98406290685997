import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexSideMenuComponent } from './side-menu/side-menu.component';
import { MatIconModule } from '@angular/material/icon';
import { FlexSideMenuItemComponent } from './side-menu-item/side-menu-item.component';
import { FlexSideMenuItemChildComponent } from './side-menu-item-child/side-menu-item-child.component';

@NgModule({
  declarations: [FlexSideMenuComponent, FlexSideMenuItemComponent, FlexSideMenuItemChildComponent],
  imports: [CommonModule, MatIconModule],
  exports: [FlexSideMenuComponent, FlexSideMenuItemComponent, FlexSideMenuItemChildComponent]
})
export class SideMenuModule {}
