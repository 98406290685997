import { Component, HostBinding, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ph-flex-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class FlexSideMenuComponent {
  /**
   * Is full width when true, icon only when false.
   * Property is also used by side-menu-item and side-menu-item-child
   */
  @HostBinding('class.expanded')
  @Input()
  isExpanded = true;

  @Output()
  isExpandedChange = new EventEmitter<boolean>();

  /**
   * Configurable expandable property. Is used to display the expanded to icon only toggle
   */
  @HostBinding('class.expandable')
  @Input()
  isExpandable = true;

  toggleExpand(): void {
    this.isExpanded = !this.isExpanded;
    this.isExpandedChange.emit(this.isExpanded);
  }
}
