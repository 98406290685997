import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { addDays, addHours, differenceInMilliseconds, differenceInMinutes, parseISO, startOfDay, startOfHour, subMinutes } from 'date-fns';
import { Direction, IntradayControl, IntraDayControlDeals, IntraDayPositionHelper, TimeSlot } from 'flex-app-shared';
import { NumberRange, RangeMap } from 'range-ts';
import { combineLatest, merge, Observable, of, timer } from 'rxjs';
import { distinctUntilChanged, filter, map, mapTo, startWith, switchMap } from 'rxjs/operators';
import { IntradayCreateOrdersJson, IntradayOrder, IntradayOrderStatusHelper } from '../../shared/intraday-order/intraday-order.service';
import { IntradaySlotStatus, IntradayState } from './intraday-state.service';
import {
  ClearIntradayErrorStateCommand,
  ConfirmIntradayDealsCommand,
  IntradayUpdatePriceHistoryCommand,
  IntradayUpdatePriceHistoryDeactivateCommand
} from './intraday.actions';
import {
  CancelIntradayOrderCommand,
  CancelIntradayOrdersCommand,
  CreateIntradayOrderCommand,
  LoadIntradayOrdersCommand,
  ResetIntradayOrderFormCommand,
  ResetIntradayOrderVolumesCommand
} from './order/intraday-order.actions';
import { IntradayOrderState } from './order/intraday-order.state';

@Injectable({
  providedIn: 'root'
})
export class IntradayFacade {
  CONFIRM_BUTTON_DISABLED_MILLIS = 2000;

  controls$ = this.store.select(IntradayState.availableControls);
  positions$ = combineLatest([this.store.select(IntradayState.positions), this.store.select(IntradayState.editSlotContextPeriod)]).pipe(
    map(([positions, editSlotContextPeriod]) => {
      const filteredPositions = positions.filter((position) => TimeSlot.overlaps(position.period, editSlotContextPeriod));

      const helper = new IntraDayPositionHelper(editSlotContextPeriod);
      filteredPositions.forEach((position) => helper.add(position));
      return helper.getPositions();
    })
  );
  editSlotContext$ = this.store.select(IntradayState.editSlotContext);
  askSummary$ = this.store.select(IntradayState.summaryForDirection(Direction.CONSUMPTION));
  bidSummary$ = this.store.select(IntradayState.summaryForDirection(Direction.PRODUCTION));
  committedDeals$ = this.store.select(IntradayState.committedDeals);
  confirmDealsDisabledBasedOnSlotStatus$ = this.store
    .select(IntradayState.slotStatus)
    .pipe(map((slotStatus) => slotStatus === IntradaySlotStatus.EXPIRED || slotStatus === IntradaySlotStatus.STALE));

  confirmDealsDisabledBasedOnDealAmount$ = this.store.select(IntradayState.dealsInvalidBasedOnAvailableCapacityOrMinVolume);
  confirmDealsDisabledBasedOnPosition$ = this.store.select(IntradayState.dealsInvalidBasedOnControlValidations);
  confirmDealsDisabledBasedOnDraftDealsLength$ = this.store.select(IntradayState.hasDraftDeals).pipe(map((a) => !a));
  confirmDealsDisabledOneSecondBasedOnNewSlot$ = this.store.select(IntradayState.currentPriceWindowId).pipe(
    distinctUntilChanged(),
    switchMap(() => merge(of(true), timer(this.CONFIRM_BUTTON_DISABLED_MILLIS).pipe(mapTo(false))))
  );
  intradayOrderPositions$ = combineLatest([
    this.store.select(IntradayState.positions),
    this.store.select(IntradayOrderState.selectedTimeSlot)
  ]).pipe(
    map(([positions, timeSlot]) => {
      if (!timeSlot) {
        return [];
      }

      const filteredPositions = positions.filter((position) =>
        TimeSlot.overlaps(position.period, { startDateTime: timeSlot.startDateTime, toDateTime: timeSlot.toDateTime })
      );

      const helper = new IntraDayPositionHelper(timeSlot);
      filteredPositions.forEach((position) => helper.add(position));
      return helper.getPositions();
    })
  );

  mandatoryTradingDirections$ = this.store.select(IntradayState.mandatoryTradingDirections);

  intradaySaveError$ = this.store.select(IntradayState.saveError);
  intradaySavePending$ = this.store.select(IntradayState.savePending);
  idconsDealingEnabled$ = this.store.select(IntradayState.idconsDealingEnabled);
  idconsDealingDisabled$ = this.store.select(IntradayState.idconsDealingDisabled);
  hasRemLimitOrderDealingAuthority$ = this.store.select(IntradayOrderState.hasRemLimitOrderDealingAuthority);
  hasIntradayIdconsDealingAuthority$ = this.store.select(IntradayOrderState.hasIntradayIdconsDealingAuthority);
  isIntradayPlusToggleDisabled$ = combineLatest([this.idconsDealingDisabled$, this.hasIntradayIdconsDealingAuthority$]).pipe(
    map(([idconsDealingDisabled, hasAuthority]) => {
      return idconsDealingDisabled || !hasAuthority;
    })
  );

  remLimitOrderDealingEnabled$ = this.store.select(IntradayState.remLimitOrderDealingEnabled);
  isOrderCreationEnabled$ = this.store.select(IntradayState.isOrderCreationEnabled);

  idconsSaveError$ = this.store.select(IntradayOrderState.saveError);
  idconsSavePending$ = this.store.select(IntradayOrderState.savePending);

  idconsCancelError$ = this.store.select(IntradayOrderState.cancelError);
  idconsCancelPending$ = this.store.select(IntradayOrderState.cancelPending);

  idconsOpenOrders$ = this.store.select(IntradayOrderState.openOrders);

  idconsMarket$ = this.store.select(IntradayOrderState.selectedMarket);
  idconsDirection$ = this.store.select(IntradayOrderState.selectedDirection);
  idconsTimeSlot$ = this.store.select(IntradayOrderState.selectedTimeSlot);
  idconsPrice$ = this.store.select(IntradayOrderState.selectedPrice);

  idconsControls$ = this.store.select(IntradayOrderState.controls);
  idconsCustomers$ = this.store.select(IntradayOrderState.customers);
  idconsGridPoints$ = this.store.select(IntradayOrderState.gridPoints);
  idconsSplitCount$ = this.store.select(IntradayOrderState.splitCount);

  /**
   * Return a NumberRange that covers the dealable period for idcons orders
   */
  idconsDealableNumberRange$ = this.store.select(IntradayState.dealableControlIdsPerDutchCalendarDay).pipe(
    switchMap((dealableControlIdsPerDutchCalendarDay) => {
      // RangeSet would be better, but we don't have it implemented yet
      const dealableRangeMap = new RangeMap();
      const dealableDays = dealableControlIdsPerDutchCalendarDay
        .filter((value) => value.controlIds?.length > 0)
        .map((value) => parseISO(value.dutchDay));

      if (dealableDays.length === 0) {
        // No dealable days
        return of(null);
      }

      dealableDays.forEach((dealableDay) =>
        dealableRangeMap.putCoalescing(
          NumberRange.closedOpen(startOfDay(dealableDay).valueOf(), startOfDay(addDays(dealableDay, 1)).valueOf()),
          true
        )
      );

      const rolloverMinutes = 15; // Rollover 15 minutes before the next hour
      const msInHour = 3_600_000;

      let currentDate: Date;
      let startOfNextValidHour: Date;
      let nextHourRolloverStartDate: Date;
      updateDates();

      // Fire timer when the next value should be emitted based on rolloverMinutes
      return timer(differenceInMilliseconds(nextHourRolloverStartDate, currentDate), msInHour).pipe(
        startWith([null]),
        map(() => {
          updateDates();

          return getNumberRangeResult();
        })
      );

      function updateDates(): void {
        currentDate = new Date();
        startOfNextValidHour = startOfHour(addHours(currentDate, 1));

        if (differenceInMinutes(startOfNextValidHour, currentDate) <= rolloverMinutes) {
          // If the current time is within the rollover period of the next hour, add an additional hour to the current Date
          startOfNextValidHour = startOfHour(addHours(currentDate, 2));
        }

        nextHourRolloverStartDate = subMinutes(startOfNextValidHour, rolloverMinutes);
      }

      function getNumberRangeResult(): NumberRange {
        return dealableRangeMap.subRangeMap(NumberRange.atLeast(startOfNextValidHour.valueOf())).span();
      }
    })
  );

  hasOpenOrdersForDraftDeals$ = this.store.select(IntradayState.hasOpenOrdersForDraftDeals);
  priceHistory$ = this.store.select(IntradayState.currentPriceHistoryData);
  priceHistoryBusy$ = this.store.select(IntradayState.priceHistoryBusy);

  constructor(private store: Store) {}

  getIntradayControls(): IntradayControl[] {
    return this.store.selectSnapshot(IntradayOrderState.controls);
  }

  getOpenIntradayOrders(): IntradayOrder[] {
    return this.store.selectSnapshot(IntradayOrderState.openOrders) || [];
  }

  getIntradayOrderTimeSlot(): TimeSlot {
    return this.store.selectSnapshot(IntradayOrderState.selectedTimeSlot);
  }

  clearIntradayErrorState(): void {
    this.store.dispatch(new ClearIntradayErrorStateCommand());
  }

  createIntradayDeals(dialogRef: MatDialogRef<any>): any {
    this.store.dispatch(new ConfirmIntradayDealsCommand(dialogRef));
  }

  getIntraDayControlDeals$(direction: Direction, startDateTime: string, toDateTime: string): Observable<IntraDayControlDeals> {
    return this.store.select(IntradayState.intraDayControlDealsForDirection(direction, startDateTime, toDateTime));
  }

  getDealableControlIdsPerDay$(editSlotDate: Date): Observable<string[]> {
    return this.store.select(IntradayState.dealableControlIds(editSlotDate));
  }

  getShowOrderTypeColumn(): boolean {
    return this.store.selectSnapshot(IntradayState.showOrderTypeColumn);
  }

  createIntradayOrder(dialogRef: MatDialogRef<any>): any {
    this.store.dispatch(new CreateIntradayOrderCommand(dialogRef));
  }

  cancelIntradayOrder(idconsOrderId: string, dialogRef: MatDialogRef<any>): any {
    this.store.dispatch(new CancelIntradayOrderCommand(idconsOrderId, dialogRef));
  }

  cancelIntradayOrders(ids: string[], cancelAll: boolean): Observable<any> {
    return this.store.dispatch(new CancelIntradayOrdersCommand(ids, cancelAll)).pipe(
      switchMap(() => this.store.select(IntradayOrderState.cancelMultiplePending)),
      filter((pending) => !pending) // Only emit when pending becomes false
    );
  }

  loadIntradayOrders(pageIndex?: number, pageSize?: number, sort?: string): void {
    this.store.dispatch(new LoadIntradayOrdersCommand(pageIndex, pageSize, sort));
  }

  isOrderIdCancelable$(orderId: string): Observable<boolean> {
    return this.store.select(IntradayOrderState.openOrders).pipe(
      map((openOrders) => {
        const foundOrder = openOrders?.find((openOrder) => openOrder.id === orderId);

        return foundOrder && IntradayOrderStatusHelper.isCancelable(foundOrder.status);
      })
    );
  }

  getIntradayOrderPeriods(): TimeSlot[] {
    return this.store.selectSnapshot(IntradayOrderState.iconsOrderPeriods);
  }

  deactivateIntradayPriceHistory(): void {
    this.store.dispatch(new IntradayUpdatePriceHistoryDeactivateCommand());
  }

  activateIntradayPriceHistory(timeSlot: TimeSlot): void {
    this.store.dispatch(new IntradayUpdatePriceHistoryCommand(timeSlot));
  }

  resetOrderForm(): void {
    this.store.dispatch(new ResetIntradayOrderFormCommand());
  }

  resetOrderVolumes(): void {
    this.store.dispatch(new ResetIntradayOrderVolumesCommand());
  }
}
