import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'ph-flex-info-icon',
  templateUrl: './info-icon.component.html',
  styleUrls: ['./info-icon.component.scss']
})
export class InfoIconComponent {
  @ViewChild(MatTooltip)
  tooltip: MatTooltip;

  @Input() message: string;
  @Input() disabled: boolean;

  isDisabled(): boolean {
    return !!(!this.message || this.disabled);
  }

  handleClick(): void {
    this.tooltip.toggle();
  }
}
