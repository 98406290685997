import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { ObservableInputsMixin } from '../../../core/observable-inputs/observable-inputs';
import { OnDestroyMixin } from '../../../core/common/on-destroy.mixin';
import { MixinBase } from '../../../core/common/constructor-type.mixin';

@Directive({
  selector: '[phFlexDateRangeFixedPeriodDialog]'
})
export class DateRangeFixedPeriodDialogDirective extends ObservableInputsMixin(OnDestroyMixin(MixinBase)) implements AfterViewInit {
  @Input('phFlexDateRangeFixedPeriodDialog')
  inputProperty?: any;
  inputProperty$ = this.oi.observe(() => this.inputProperty);

  constructor(private renderer: Renderer2, private hostElement: ElementRef) {
    super();
  }

  ngAfterViewInit(): void {
    this.inputProperty$.subscribe((inputValue) => {
      if (inputValue || inputValue === '') {
        this.renderer.addClass(this.hostElement.nativeElement, 'ph-flex-date-range-fixed-period-dialog');
      } else {
        this.renderer.removeClass(this.hostElement.nativeElement, 'ph-flex-date-range-fixed-period-dialog');
      }
    });
  }
}
