<div class="ph-g-container-page">
  <ph-flex-loading-context [isLoading]="facade.isBusyFetchingActivationMeasurements$ | async">
    <mat-card class="ph-g-card-body-main ph-g-card-body-main-responsive ph-g-card-body-main-l">
      <mat-card-title class="card-title-date">
        <ng-container i18n="@@incidentReserveActivationsDetail-chartCardTitle">Delivered power</ng-container>
        -
        <ng-container *ngIf="problemPeriod$ | async; let problemPeriod">
          {{ problemPeriod.startDateTime | date: 'mediumDate' }}, {{ problemPeriod.startDateTime | date: 'shortTime' }} -
          {{ problemPeriod.toDateTime | date: 'shortTime' }}
        </ng-container>
      </mat-card-title>
      <mat-card-content>
        <app-incident-reserve-activations-detail-screen-chart
          *ngIf="!(facade.isBusyFetchingActivationMeasurements$ | async)"
        ></app-incident-reserve-activations-detail-screen-chart>
      </mat-card-content>
    </mat-card>
  </ph-flex-loading-context>
</div>
