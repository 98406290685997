<ng-container *ngIf="shouldShowPrices()">
  <mat-icon
    [class.negative]="isNegative"
    [class.positive]="isPositive"
    [class.equal]="isEqual"
    matTooltip="Price delta: {{ priceDeltaAmount | currency: 'EUR' }}"
    [matTooltipDisabled]="priceDelta === IntraDayPriceDelta.EQUAL || !priceDeltaAmount"
    [svgIcon]="getIcon()"
  >
  </mat-icon>
</ng-container>
