<h3 mat-dialog-title i18n="@@intraday-headerTodaysDealStatus">Status of recent deals: {{ today | date : 'dd-MM-yyyy' }}</h3>
<mat-dialog-content class="overflow-auto">
  <mat-toolbar class="filter-bar">
    <mat-form-field>
      <mat-label i18n="@@intraday-labelSearchCategory">Search category</mat-label>
      <mat-select #categoryFilter value="customerLegalName">
        <mat-option [value]="'customerLegalName'" i18n="@@intraday-labelSearchCategoryCustomerLegalName">Company name</mat-option>
        <mat-option [value]="'controlDescription'" i18n="@@intraday-labelSearchCategoryControlDescription">Component</mat-option>
        <mat-option [value]="'gridPointEan'" i18n="@@intraday-labelSearchCategoryGridPointEan">EAN</mat-option>
        <mat-option [value]="'gridPointDescription'" i18n="@@intraday-labelSearchCategoryGridPointDescription">Grid point</mat-option>
        <mat-option [value]="'dealReference'" i18n="@@intraday-labelSearchCategoryDealReference">Reference</mat-option>
      </mat-select>
    </mat-form-field>
    <ph-flex-table-filter label="Search" i18n-label="@@intraday-labelSearchTerm"></ph-flex-table-filter>
    <mat-form-field>
      <mat-label i18n="@@intraday-labelDealStatus">Status</mat-label>
      <mat-select #dealStatusFilter>
        <mat-option [value]="FILTER_ALL" i18n="@@intraday-labelDealStatusAll">All</mat-option>
        <mat-option [value]="IntraDayDealStatus.CONFIRMED" i18n="@@intraday-labelDealStatusConfirmed">Confirmed</mat-option>
        <mat-option [value]="IntraDayDealStatus.PENDING" i18n="@@intraday-labelDealStatusPending">Pending</mat-option>
        <mat-option [value]="IntraDayDealStatus.FAILED" i18n="@@intraday-labelDealStatusFailed">Failed</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label i18n="@@intraday-labelPriceType">Deal type</mat-label>
      <mat-select #directionFilter>
        <mat-option [value]="FILTER_ALL" i18n="@@intraday-labelDealTypeAll">All</mat-option>
        <mat-option [value]="Direction.PRODUCTION" i18n="@@intraday-labelDealTypeSell">Sell</mat-option>
        <mat-option [value]="Direction.CONSUMPTION" i18n="@@intraday-labelDealTypeBuy">Buy</mat-option>
      </mat-select>
    </mat-form-field>
  </mat-toolbar>
  <ph-flex-table-container>
    <table mat-table [dataSource]="dataSource" matSort matSortActive="created" matSortDirection="desc">
      <ng-container matColumnDef="dealStatus">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <ng-container i18n="@@intraday-tableHeaderStatus"> Status</ng-container>
        </th>
        <td mat-cell *matCellDef="let deal">
          <app-intraday-deal-display-status [deal]="deal"></app-intraday-deal-display-status>
        </td>
      </ng-container>
      <ng-container matColumnDef="dealDirection">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <ng-container i18n="@@intraday-tableHeaderType"> Type</ng-container>
        </th>
        <td mat-cell *matCellDef="let deal">
          <app-intraday-deal-direction [deal]="deal"></app-intraday-deal-direction>
        </td>
      </ng-container>
      <ng-container matColumnDef="created">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <ng-container i18n="@@intraday-tableHeaderCreated"> Created</ng-container>
        </th>
        <td mat-cell *matCellDef="let deal">{{ deal.created | date : 'HH:mm:ss' }}</td>
      </ng-container>
      <ng-container matColumnDef="customerLegalName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <ng-container i18n="@@intraday-tableHeaderCustomer"> Company name</ng-container>
        </th>
        <td mat-cell *matCellDef="let deal">{{ deal.customerLegalName }}</td>
      </ng-container>
      <ng-container matColumnDef="gridPointDescription">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <ng-container i18n="@@intraday-tableHeaderGridPoint"> Grid point</ng-container>
        </th>
        <td mat-cell *matCellDef="let deal">{{ deal.gridPointDescription }}</td>
      </ng-container>
      <ng-container matColumnDef="controlDescription">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <ng-container i18n="@@intraday-tableHeaderControl"> Component</ng-container>
        </th>
        <td mat-cell *matCellDef="let deal">{{ deal.controlDescription }}</td>
      </ng-container>
      <ng-container matColumnDef="dealPeriodDay">
        <th mat-header-cell *matHeaderCellDef>
          <ng-container i18n="@@intraday-tableHeaderPeriodDay"> Day</ng-container>
        </th>
        <td mat-cell *matCellDef="let deal">
          {{ deal.dealPeriod.startDateTime | date : 'shortDate' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="dealPeriod">
        <th mat-header-cell *matHeaderCellDef>
          <ng-container i18n="@@intraday-tableHeaderPeriod"> Period</ng-container>
        </th>
        <td mat-cell *matCellDef="let deal">
          {{ deal.dealPeriod.startDateTime | date : 'shortTime' }}
          - {{ deal.dealPeriod.toDateTime | date : 'shortTime' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="dealPrice">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <ng-container i18n="@@intraday-tableHeaderPrice"> Price</ng-container>
          [€/MWh]
        </th>
        <td mat-cell *matCellDef="let deal">{{ deal.dealPrice | currency : 'EUR' }}</td>
      </ng-container>
      <ng-container matColumnDef="dealCapacity">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <ng-container i18n="@@intraday-tableHeaderPower"> Power</ng-container>
          [kW]
        </th>
        <td mat-cell *matCellDef="let deal">{{ deal.dealCapacity | capacityNumber : 'kW' }}</td>
      </ng-container>
      <ng-container matColumnDef="dealAmount">
        <th mat-header-cell *matHeaderCellDef>
          <ng-container i18n="@@intraday-tableHeaderAmount"> Amount</ng-container>
          [€]
        </th>
        <td mat-cell *matCellDef="let deal">{{ deal.dealAmount.amount | currency : 'EUR' }}</td>
      </ng-container>
      <ng-container matColumnDef="dealReference">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <ng-container i18n="@@intraday-tableHeaderDealReference"> Reference</ng-container>
        </th>
        <td mat-cell *matCellDef="let deal">{{ deal.dealReference }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr mat-row *matRowDef="let row; columns: columns"></tr>
    </table>
  </ph-flex-table-container>

  <mat-paginator [showFirstLastButtons]="true"></mat-paginator>
</mat-dialog-content>
