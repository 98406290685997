import { Directive, Input, TemplateRef } from '@angular/core';
import { CalendarPeriod } from '../calendar-period';

@Directive({
  selector: '[phFlexCalendarPeriodListItem]'
})
export class CalendarPeriodListItemDirective {
  @Input() phFlexCalendarPeriodListItemWhen: (period: CalendarPeriod) => boolean;

  constructor(public template: TemplateRef<any>) {}
}
