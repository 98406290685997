<ph-flex-toolbar class="sticky">
  <ng-container class="tabs">
    <ph-flex-toolbar-tabs *ngIf="shouldShowTabs$ | async" [tabs]="tabs"></ph-flex-toolbar-tabs>
    <ng-container *ngIf="shouldShowBackButton$ | async">
      <div class="back-button-container">
        <button
          mat-flat-button
          (click)="back()"
          class="ph-flex-mat-flat-button-on-dark"
          i18n="@@incidentReserveAvailabilityToolbar-backButton"
        >
          Back
        </button>
      </div>
    </ng-container>
  </ng-container>

  <ng-container class="filters" *ngIf="shouldShowFilters$ | async">
    <ph-flex-filters
      [filterForm]="filterForm"
      [formComponent]="IntradayHistoryFiltersFormComponent"
      [dialogOptionsTemplate]="optionsTemplate"
      (selectedFiltersChanged)="filtersChanged($event)"
      [defaultValueOverrides]="{ customerId: '' }"
      breakPoint="xl"
    >
      <ng-container ngProjectAs="[ph-flex-filters-dialog-footer]">
        <mat-slide-toggle [formControl]="showAllDealsControl" i18n="@@intraday-labelShowAll">
          Also show non-confirmed deals
        </mat-slide-toggle>
      </ng-container>
    </ph-flex-filters>
  </ng-container>
</ph-flex-toolbar>

<router-outlet></router-outlet>

<ng-template #optionsTemplate let-option>
  <ng-container [ngSwitch]="option.key">
    <ng-container *ngSwitchCase="'customerId'" i18n="@@intraday-labelCustomer">Customer</ng-container>
    <ng-container *ngSwitchCase="'gridPointId'" i18n="@@intraday-labelGridPoint">Grid Point</ng-container>
    <ng-container *ngSwitchCase="'controlId'" i18n="@@intraday-labelControl">Component</ng-container>
    <ng-container *ngSwitchCase="'dealReference'" i18n="@@intraday-labelDealReference">Deal Reference</ng-container>
    <ng-container *ngSwitchCase="'dealPeriod'" i18n="@@intraday-labelDealPeriod">Deal Period</ng-container>
    <ng-container *ngSwitchCase="'createdPeriod'" i18n="@@intraday-labelCreatedPeriod">Created Period</ng-container>
    <ng-container *ngSwitchDefault>{{ option.key }}</ng-container>
  </ng-container>
</ng-template>
