import { Component, Input, OnInit } from '@angular/core';
import { Direction, PostPendingIntradayDeal } from 'flex-app-shared';
import { OrderDirection } from '../../state/order/intraday-order.state';

export enum OrderType {
  Buy = 'BUY',
  BuyIntradayPlus = 'BUY_PLUS',
  Sell = 'SELL',
  SellIntradayPlus = 'SELL_PLUS'
}

export function getOrderType(direction: Direction | OrderDirection, intradayPlus: boolean): OrderType {
  if (direction === Direction.CONSUMPTION || direction === OrderDirection.BUY) {
    if (intradayPlus) {
      return OrderType.BuyIntradayPlus;
    } else {
      return OrderType.Buy;
    }
  } else {
    if (intradayPlus) {
      return OrderType.SellIntradayPlus;
    } else {
      return OrderType.Sell;
    }
  }
}

@Component({
  selector: 'app-intraday-deal-direction',
  templateUrl: './intraday-deal-direction.component.html',
  styleUrls: ['./intraday-deal-direction.component.scss']
})
export class IntradayDealDirectionComponent {
  Direction = Direction;
  OrderDirection = OrderDirection;
  OrderType = OrderType;

  @Input()
  deal: PostPendingIntradayDeal | null;

  @Input()
  direction: Direction | OrderDirection;

  @Input()
  intradayPlus: boolean;

  constructor() {}
  getOrderType(): OrderType {
    return getOrderType(this.getDirection(), this.intradayPlus);
  }

  private getDirection(): Direction | OrderDirection {
    if (this.deal) {
      return this.deal.dealDirection;
    }
    return this.direction;
  }
}
