import { Directive, Inject, Injectable, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AUTOCOMPLETE_DATA_SOURCE } from '../../material/autocomplete/autocomplete.component';
import { LazyLoadDataSource } from '../../material/autocomplete/lazy-load-data-source';
import { toLower } from 'lodash-es';
import { Control } from './control';
import { ControlService } from './control.service';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { switchMap, auditTime } from 'rxjs/operators';

@Injectable()
export class ControlDatasource extends LazyLoadDataSource<Control> {
  private customerIdSubject = new BehaviorSubject<string>(null);
  private gridPointIdSubject = new BehaviorSubject<string>(null);

  constructor(private controlService: ControlService) {
    super();

    this.dataSource$ = combineLatest([this.customerIdSubject, this.gridPointIdSubject]).pipe(
      auditTime(10), // Small debounce
      switchMap(([customerId, gridPointId]) => {
        if (customerId || gridPointId) {
          return this.controlService.getFiltered({ customerId, gridPointId }) as Observable<Control[]>;
        } else {
          return this.controlService.getAll();
        }
      })
    );
  }

  filter(entities: ReadonlyArray<Control>, filter: string | null): ReadonlyArray<Control> {
    return entities.filter((entity) => !filter || toLower(entity.description).includes(toLower(filter)));
  }

  identifyWith(value: Control): string {
    return value.id;
  }

  displayEntityWith(value: Control): string {
    return `${value.description}`;
  }

  updateCustomerId(customerId: string): void {
    this.customerIdSubject.next(customerId);
  }

  updateGridPointId(gridPointId: string): void {
    this.gridPointIdSubject.next(gridPointId);
  }
}

@Directive({
  selector: '[phFlexControlDataSource]',
  providers: [
    {
      provide: AUTOCOMPLETE_DATA_SOURCE,
      useClass: ControlDatasource
    }
  ]
})
export class ControlDatasourceDirective implements OnChanges {
  @Input() customerIdFilter: string;
  @Input() gridPointIdFilter: string;

  constructor(@Inject(AUTOCOMPLETE_DATA_SOURCE) private controlDataSource: ControlDatasource) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.customerIdFilter) {
      this.controlDataSource.updateCustomerId(this.customerIdFilter);
    }

    if (changes.gridPointIdFilter) {
      this.controlDataSource.updateGridPointId(this.gridPointIdFilter);
    }
  }
}
