import { AfterViewInit, Component, Host, Input, ViewChild } from '@angular/core';
import { MatStep, MatStepLabel, MatStepper } from '@angular/material/stepper';
import { Parser } from 'expr-eval';
import { differenceInHours, parseISO } from 'date-fns';
import { IntradayFacade } from '../../../../state/intraday-facade.service';

@Component({
  selector: 'app-step-summary',
  templateUrl: './step-summary.component.html',
  styleUrls: ['./step-summary.component.scss']
})
export class StepSummaryComponent implements AfterViewInit {
  @ViewChild(MatStepLabel) stepLabel: MatStepLabel;

  @Input() pricePerMWh: number;
  @Input() dealsPerControlId: {
    [key: string]: number;
  };
  /**
   * Multiplier of number of deals due to splitting the order
   */
  @Input() splitCount: number = 1;

  saveError$ = this.intraDayFacade.idconsSaveError$;

  priceCalculator = Parser.parse('MWhPrice / 1000 * dealVolume * hours');

  constructor(@Host() private matStep: MatStep, public stepper: MatStepper, public intraDayFacade: IntradayFacade) {}

  getNumberOfDeals(): number {
    if (!this.dealsPerControlId) {
      return 0;
    }

    return Object.values(this.dealsPerControlId).filter((a) => !!a).length * this.splitCount;
  }

  getTotalPrice(): number {
    const timeSlot = this.intraDayFacade.getIntradayOrderTimeSlot();

    if (!this.dealsPerControlId || !this.pricePerMWh || !timeSlot) {
      return 0;
    }
    const hours = differenceInHours(parseISO(timeSlot.toDateTime), parseISO(timeSlot.startDateTime));

    return Object.values(this.dealsPerControlId).reduce(
      (c, a) =>
        c +
        this.priceCalculator.evaluate({
          MWhPrice: this.pricePerMWh,
          dealVolume: a,
          hours
        }),
      0
    );
  }

  ngAfterViewInit(): void {
    this.matStep.stepLabel = this.stepLabel;
  }
}
