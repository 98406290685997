<p i18n="@@intraday-filterDescription">Selecteer hier maximaal {{ maxSelectedFilters }} filter{{ maxSelectedFilters | plural }}.</p>

<ul>
  <li *ngFor="let option of options">
    <mat-checkbox (change)="changeSelection(option.key, $event)" [(ngModel)]="option.isChecked" [disabled]="option.disabled" disableRipple>
      <ng-template #defaultTemplate let-option>{{ option.key }}</ng-template>
      <ng-container
        [ngTemplateOutletContext]="{ $implicit: option }"
        [ngTemplateOutlet]="optionsTemplate || defaultTemplate"
      ></ng-container>
    </mat-checkbox>
  </li>
</ul>
