<ph-flex-toolbar class="sticky">
  <ng-container class="tabs">
    <ph-flex-toolbar-tabs *ngIf="shouldShowTabs$ | async" [tabs]="tabs"></ph-flex-toolbar-tabs>
    <ng-container *ngIf="(shouldShowTabs$ | async) === false">
      <div class="back-button-container">
        <button
          mat-flat-button
          (click)="back()"
          class="ph-flex-mat-flat-button-on-dark"
          i18n="@@incidentReserveAvailabilityToolbar-backButton"
        >
          Back
        </button>
      </div>
    </ng-container>
  </ng-container>

  <div class="filters">
    <ph-flex-filters
      *ngIf="shouldShowTabs$ | async"
      [filterForm]="filterForm"
      [formComponent]="FiltersIncidentReserveAvailabilityComponent"
    ></ph-flex-filters>
  </div>
</ph-flex-toolbar>

<router-outlet></router-outlet>
