import { ScaleBand } from 'd3';

/**
 * .invert but for scaleBands
 */
export function scaleBandInvert<T>(scale: ScaleBand<T>, value: number): T | null {
  const scaleRange = scale.range();
  const scaleStart = Math.min(...scaleRange);

  const seriesIndex = Math.floor((value - scaleStart - scale.paddingOuter() * scale.step()) / scale.step());
  const pointerOffset = value - scaleStart - scale.paddingOuter() * scale.step() - seriesIndex * scale.step();
  const isInBounds = pointerOffset < scale.bandwidth() && pointerOffset > 0;
  return isInBounds ? scale.domain()[seriesIndex] : null;
}
