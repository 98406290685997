import { PaginatedResponse, PostPendingIntradayDeal } from 'flex-app-shared';

export class IntraDayHistoryActivate {
  public static readonly type = '[Intra Day history] Route activated';
}

export class IntraDayHistoryDeactivate {
  public static readonly type = '[Intra Day history] Route deactivated';
}

export class UpdateIntraDayDealsHistoryCommand {
  public static type: string = '[Intra Day history] Update deals';

  constructor() {}
}

export class UpdateIntraDayHistoryGridPointCommand {
  public static type: string = '[Intra Day history] Update grid point';
  constructor(public gridPointId?: string) {}
}

export class IntraDayHistoryFormFiltersChangedEvent {
  public static type: string = '[Intra Day history] Update deals with current state parameters';
}

export class IntraDayHistoryReceivedEvent {
  public static type: string = '[Intra Day history] Deals updated';

  constructor(public deals: PaginatedResponse<PostPendingIntradayDeal>, public unpagedContainsMandatoryDeals: boolean) {}
}

export class IntraDayHistoryDownloadDealsCommand {
  public static type: string = '[Intra Day history] Download deals';
}

export class IntraDayDealsHistoryPaginationUpdatedEvent {
  public static type: string = '[Intra Day history] Pagination updated';
  constructor(public pageIndex?: number, public pageSize?: number, public sort?: string) {}
}

export class IntraDayDealsHistorySelectedFiltersChangedEvent {
  public static type: string = '[Intra Day history] Selected filters changed';
  constructor(public selectedFilters: string[]) {}
}
