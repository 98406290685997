import { ViewportRuler } from '@angular/cdk/scrolling';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FlexScrollStrategyService {
  private topOffsetSubject = new BehaviorSubject<number | undefined>(undefined);
  topOffset$ = this.topOffsetSubject.asObservable();

  readonly flexScrollStrategy: FlexScrollStrategy;

  setTopOffset(offset: number): void {
    this.topOffsetSubject.next(offset);
  }

  constructor(viewportRuler: ViewportRuler, @Inject(DOCUMENT) document: any) {
    this.flexScrollStrategy = new FlexScrollStrategy(viewportRuler, document, this);
  }
}

export class FlexScrollStrategy {
  private globalClass = 'ph-flex-global-scrollblock';
  private cdkGlobalClass = 'cdk-global-scrollblock';
  private isEnabled = false;
  private previousScrollPosition: { top: number; left: number };
  constructor(private viewportRuler: ViewportRuler, private document: any, private service: FlexScrollStrategyService) {}
  attach(): void {}
  enable(): void {
    if (this.canBeEnabled()) {
      this.previousScrollPosition = this.viewportRuler.getViewportScrollPosition();

      this.service.setTopOffset(this.previousScrollPosition.top);
      // You could/should also set left offset here
      // But in our case I think we want to handle left/right scroll in cards, and not on the HTML level.
      // So we don't need to handle it here.

      this.document.documentElement.classList.add(this.globalClass, this.cdkGlobalClass);
      this.isEnabled = true;
    }
  }

  disable(): void {
    if (this.isEnabled) {
      this.service.setTopOffset(undefined);

      this.document.documentElement.classList.remove(this.globalClass, this.cdkGlobalClass);

      window.scroll(this.previousScrollPosition.left, this.previousScrollPosition.top);

      this.isEnabled = false;
    }
  }

  private canBeEnabled(): boolean {
    // Since the scroll strategies can't be singletons, we have to use a global CSS class
    // (`ph-flex-global-scrollblock`) to make sure that we don't try to disable global
    // scrolling multiple times.
    const html = this.document.documentElement;

    if (html.classList.contains(this.globalClass) || this.isEnabled) {
      return false;
    }

    const body = this.document.body;
    const viewport = this.viewportRuler.getViewportSize();
    return body.scrollHeight > viewport.height; // Add scrollWidth check if we ever want to support horizontal scroll with this.
  }
}
