<ph-flex-svg phFlexResizeObserver (afterViewInit)="registerSvgRoot($event)"></ph-flex-svg>

<div class="legend">
  <div class="legend-item" *ngIf="dashedLineDayAheadPrice.hasData$ | async">
    <div>
      <ph-flex-svg (afterViewInit)="dashedLineDayAheadPrice.attachLabel($event)"></ph-flex-svg>
      <span i18n="@@intraday-dayAheadPriceLabel">Day-ahead price</span>
    </div>
  </div>
  <div class="legend-item" *ngIf="lineIntradayPrice.hasData$ | async">
    <div>
      <ph-flex-svg (afterViewInit)="lineIntradayPrice.attachLabel($event)"></ph-flex-svg>
      <span i18n="@@intraday-intradayPriceLabelBuy" *ngIf="direction === Direction.CONSUMPTION">Intraday buy</span>
      <span i18n="@@intraday-intradayPriceLabelSell" *ngIf="direction === Direction.PRODUCTION">Intraday sell</span>
    </div>
  </div>
</div>

<ng-template let-datum$ #tooltipTemplate>
  <ph-flex-tooltip phFlexResizeObserver *ngIf="datum$ | async; let datum" [rows]="tooltipRows" width="100%">
    <ng-container phFlexTooltipRowDef="dayAheadPrice">
      <div class="description-container" *phFlexTooltipColumnLeftDef>
        <ph-flex-text-truncate> <ng-container i18n="@@intraday-dayAheadPriceLabel">Day-ahead price</ng-container>: </ph-flex-text-truncate>
      </div>
      <div class="value-container" *phFlexTooltipColumnRightDef>
        <ph-flex-text-truncate>
          <span class="value">
            {{ getDayAheadPrice$() | async | number: '0.2-2' | default }}
          </span>
          <span class="unit"> €/MWh </span>
        </ph-flex-text-truncate>
      </div>
    </ng-container>
    <ng-container phFlexTooltipRowDef="intradayPrice">
      <div class="description-container" *phFlexTooltipColumnLeftDef>
        <ph-flex-text-truncate>
          <ng-container i18n="@@intraday-intradayPriceLabelBuy" *ngIf="direction === Direction.CONSUMPTION">Intraday buy</ng-container>
          <ng-container i18n="@@intraday-intradayPriceLabelSell" *ngIf="direction === Direction.PRODUCTION">Intraday sell</ng-container>:
        </ph-flex-text-truncate>
      </div>
      <div class="value-container" *phFlexTooltipColumnRightDef>
        <ph-flex-text-truncate>
          <span class="value">
            {{ getIntradayPriceForDate$(xScaleProvider.scale.invert(datum.x)) | async | number: '0.2-2' | default }}
          </span>
          <span class="unit"> €/MWh </span>
        </ph-flex-text-truncate>
      </div>
    </ng-container>
  </ph-flex-tooltip>
</ng-template>
