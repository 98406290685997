import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UpdateFormValue } from '@ngxs/form-plugin';
import { Action, Selector, SelectorOptions, State, StateContext, Store } from '@ngxs/store';
import {
  AsyncMessagingService,
  AuthorityService,
  Capacity,
  Customer,
  Direction,
  GridPoint,
  IntradayControl,
  IntradayOrderType,
  MANAGE_INTRA_DAY_IDCONS_ORDER,
  MANAGE_INTRA_DAY_REM_ORDER,
  NgXsFormModel,
  TimeSlot,
  VIEW_INTRA_DAY_IDCONS_ORDER
} from 'flex-app-shared';
import { isEmpty, mapValues, toPairs, uniqBy } from 'lodash-es';
import { tap } from 'rxjs/operators';
import { IntradayCreateOrdersJson, IntradayOrder, IntradayOrderService } from '../../../shared/intraday-order/intraday-order.service';
import { IntradayActivate, IntradayControlsUpdatedEvent, IntradayDeactivate } from '../intraday.actions';
import {
  CancelIntradayOrderCommand,
  CancelIntradayOrdersCommand,
  CreateIntradayOrderCommand,
  IntradayOrderHasIntradayIdconsDealingAuthorityEvent,
  IntradayOrderHasRemLimitOrderDealingAuthorityEvent,
  IntradayOrdersOrdersLoadedEvent,
  ResetIntradayOrderFormCommand,
  ResetIntradayOrderVolumesCommand
} from './intraday-order.actions';
import { OrderCreatedSnackbarComponent } from './order-created-snackbar/order-created-snackbar.component';

export enum OrderDirection {
  BUY = 'BUY',
  SELL = 'SELL'
}

export interface IntradayOrderEditSlotContext {
  direction: OrderDirection;
  startDateTime: string;
  toDateTime: string;
  pricePerMW: number;
  volume: number;
  priceId: string;
}

export class IntradayOrderDialogStateModel {
  selectedMarket = NgXsFormModel.defaults<{
    market: IntradayOrderType;
  }>({
    market: null
  });
  selectedTimeSlot = NgXsFormModel.defaults({
    selectedTimeSlot: null,
    selectedDayOffset: 0,
    allowMatchingIndividualHours: true
  });

  pricePerMWh = NgXsFormModel.defaults({
    pricePerMWh: null
  });

  orderDirection = NgXsFormModel.defaults<{
    orderDirection: OrderDirection;
    allowedForIdcons: boolean;
  }>({
    orderDirection: null,
    allowedForIdcons: true
  });

  selectedVolume = NgXsFormModel.defaults<{
    [key: string]: number;
  }>({});
}

export class IntradayOrderStateModel extends IntradayOrderDialogStateModel {
  hasRemLimitOrderDealingAuthority = false;
  hasIntradayIdconsDealingAuthority = false;

  openOrders: IntradayOrder[];

  saveError: string;
  savePending: boolean;

  cancelError: string;
  cancelPending: boolean;

  loadPending: boolean;

  initialized: boolean;

  controls: IntradayControl[] = [];

  selectedControlIds = NgXsFormModel.defaults([]);

  cancelMultipleError: string;
  cancelMultiplePending: boolean;

  isActive: boolean;
}

@State({
  name: 'order',
  defaults: new IntradayOrderStateModel()
})
@Injectable({
  providedIn: 'root'
})
@SelectorOptions({
  injectContainerState: false,
  suppressErrors: false
})
export class IntradayOrderState {
  constructor(
    private store: Store,
    private intradayOrderService: IntradayOrderService,
    private snackbar: MatSnackBar,
    private asyncMessagingService: AsyncMessagingService,
    private authorityService: AuthorityService
  ) {
    authorityService
      .hasAuthorities(MANAGE_INTRA_DAY_REM_ORDER)
      .subscribe((hasAuthority) => this.store.dispatch(new IntradayOrderHasRemLimitOrderDealingAuthorityEvent(hasAuthority)));

    authorityService
      .hasAuthorities(MANAGE_INTRA_DAY_IDCONS_ORDER)
      .subscribe((hasAuthority) => this.store.dispatch(new IntradayOrderHasIntradayIdconsDealingAuthorityEvent(hasAuthority)));

    // Optional openOrders$ to facilitate tests that don't use open orders, but do use the intraday order state
    this.intradayOrderService.openOrders$?.subscribe((result) => {
      this.store.dispatch(new IntradayOrdersOrdersLoadedEvent(result));
    });
  }

  @Selector([IntradayOrderState])
  static hasRemLimitOrderDealingAuthority(state: IntradayOrderStateModel): boolean {
    return state.hasRemLimitOrderDealingAuthority;
  }

  @Selector([IntradayOrderState])
  static hasIntradayIdconsDealingAuthority(state: IntradayOrderStateModel): boolean {
    return state.hasIntradayIdconsDealingAuthority;
  }

  @Selector([IntradayOrderState])
  static savePending(state: IntradayOrderStateModel): boolean {
    return state.savePending;
  }

  @Selector([IntradayOrderState])
  static saveError(state: IntradayOrderStateModel): string {
    return state.saveError;
  }

  @Selector([IntradayOrderState])
  static cancelPending(state: IntradayOrderStateModel): boolean {
    return state.cancelPending;
  }

  @Selector([IntradayOrderState])
  static cancelError(state: IntradayOrderStateModel): string {
    return state.cancelError;
  }

  @Selector([IntradayOrderState])
  static cancelMultiplePending(state: IntradayOrderStateModel): boolean {
    return state.cancelMultiplePending;
  }

  @Selector([IntradayOrderState])
  static cancelMultipleError(state: IntradayOrderStateModel): string {
    return state.cancelMultipleError;
  }

  @Selector([IntradayOrderState])
  static openOrders(state: IntradayOrderStateModel): IntradayOrder[] | null {
    return state.openOrders || null;
  }

  @Selector([IntradayOrderState])
  static selectedTimeSlot(state: IntradayOrderStateModel): TimeSlot {
    return state.selectedTimeSlot.model?.selectedTimeSlot;
  }

  @Selector([IntradayOrderState])
  static selectedPrice(state: IntradayOrderStateModel): number {
    return state.pricePerMWh.model.pricePerMWh;
  }

  @Selector([IntradayOrderState])
  static selectedDirection(state: IntradayOrderStateModel): OrderDirection {
    return state.orderDirection.model.orderDirection;
  }

  @Selector([IntradayOrderState])
  static selectedAllowedForIdcons(state: IntradayOrderStateModel): boolean {
    return state.orderDirection.model.allowedForIdcons;
  }

  @Selector([IntradayOrderState])
  static selectedMarket(state: IntradayOrderStateModel): IntradayOrderType {
    return state.selectedMarket.model.market;
  }

  @Selector([IntradayOrderState])
  static controls(state: IntradayOrderStateModel): IntradayControl[] {
    return state.controls;
  }

  @Selector([IntradayOrderState])
  static selectedVolume(state: IntradayOrderStateModel): { [key: string]: number } {
    return state.selectedVolume.model || {};
  }

  @Selector([IntradayOrderState])
  static allowMatchingIndividualHours(state: IntradayOrderStateModel): boolean {
    return state.selectedTimeSlot.model?.allowMatchingIndividualHours;
  }

  @Selector([IntradayOrderState.selectedTimeSlot, IntradayOrderState.allowMatchingIndividualHours])
  static iconsOrderPeriods(selectedTimeSlot: TimeSlot, allowMatchingIndividualHours: boolean): TimeSlot[] {
    if (!allowMatchingIndividualHours) {
      return [selectedTimeSlot];
    }
    return TimeSlot.asHourPeriods(selectedTimeSlot);
  }

  @Selector([IntradayOrderState.iconsOrderPeriods])
  static splitCount(orderPeriods: TimeSlot[]): number {
    return orderPeriods?.length || 0;
  }

  @Selector([IntradayOrderState.selectedVolume, IntradayOrderState.controls])
  static gridPointEansWithSelectedVolume(selectedVolume: { [key: string]: number }, controls: IntradayControl[]): string[] {
    return toPairs(selectedVolume)
      .filter(([controlId, volumeInkWh]) => !!volumeInkWh)
      .map(([controlId]) => controlId)
      .map((controlId) => controls.find((control) => control.controlId === controlId))
      .map((control) => control?.gridPointEan);
  }

  @Selector([IntradayOrderState.controls])
  static customers(controls: IntradayControl[]): Partial<Customer>[] {
    if (!controls || isEmpty(controls)) {
      return [];
    }

    const customersFromControls: Partial<Customer>[] = controls.map((control) => ({
      legalName: control.customerLegalName,
      id: control.customerId,
      customerId: control.customerId
    }));

    return uniqBy(customersFromControls, 'id');
  }

  @Selector([IntradayOrderState.controls])
  static gridPoints(controls: IntradayControl[]): Partial<GridPoint>[] {
    if (!controls || isEmpty(controls)) {
      return [];
    }

    const gridPointsFromControls: Partial<GridPoint>[] = controls.map((control) => ({
      id: control.gridPointId,
      gridPointId: control.gridPointId,
      description: control.gridPointDescription,
      ean: control.gridPointEan,
      customerId: control.customerId
    }));

    return uniqBy(gridPointsFromControls, 'id');
  }

  @Action(IntradayActivate)
  activateIntradayState({ patchState, getState, dispatch }: StateContext<IntradayOrderStateModel>): any {
    if (!getState().isActive && this.authorityService.hasAuthorities(VIEW_INTRA_DAY_IDCONS_ORDER)) {
      patchState({
        isActive: true
      });

      this.intradayOrderService.openOrdersWebsocket.activate();
    }
  }

  @Action(IntradayDeactivate)
  deactivateIntradayState({ setState, getState }: StateContext<IntradayOrderStateModel>): any {
    if (getState().isActive) {
      setState({
        ...new IntradayOrderStateModel(),
        // Preserve controls since it is updated when the intraday state updates
        controls: getState().controls,

        // Preserve both below, since always updated regardless of isActive state
        hasRemLimitOrderDealingAuthority: getState().hasRemLimitOrderDealingAuthority,
        hasIntradayIdconsDealingAuthority: getState().hasIntradayIdconsDealingAuthority
      });
      this.intradayOrderService.openOrdersWebsocket.deactivate();
    }
  }

  @Action(IntradayOrderHasRemLimitOrderDealingAuthorityEvent)
  handleIntradayOrderHasRemLimitOrderDealingAuthorityEvent(
    { patchState }: StateContext<IntradayOrderStateModel>,
    { hasRemLimitOrderDealingAuthority }: IntradayOrderHasRemLimitOrderDealingAuthorityEvent
  ): any {
    patchState({ hasRemLimitOrderDealingAuthority });
  }

  @Action(IntradayOrderHasIntradayIdconsDealingAuthorityEvent)
  handleIntradayOrderHasIntradayIdconsAuthorityEvent(
    { patchState }: StateContext<IntradayOrderStateModel>,
    { hasIntradayIdconsDealingAuthority }: IntradayOrderHasIntradayIdconsDealingAuthorityEvent
  ): any {
    patchState({ hasIntradayIdconsDealingAuthority });
  }

  @Action(ResetIntradayOrderFormCommand)
  handleResetIntradayOrderFormCommand({ patchState }: StateContext<IntradayOrderStateModel>): any {
    patchState({
      ...new IntradayOrderDialogStateModel()
    });
  }

  @Action(ResetIntradayOrderVolumesCommand)
  handleResetIntradayOrderVolumesCommand({ patchState, getState }: StateContext<IntradayOrderStateModel>): any {
    patchState({
      selectedVolume: NgXsFormModel.patchModel(
        getState().selectedVolume,
        mapValues(getState().selectedVolume.model, () => 0)
      )
    });
  }

  @Action(IntradayOrdersOrdersLoadedEvent)
  handleIntradayOrdersLoadedEvent({ patchState }: StateContext<IntradayOrderStateModel>, { orders }: IntradayOrdersOrdersLoadedEvent): any {
    patchState({
      openOrders: orders,
      initialized: true
    });
  }

  @Action(IntradayControlsUpdatedEvent)
  handleIntradayControlsUpdatedEvent(
    { patchState }: StateContext<IntradayOrderStateModel>,
    { controls }: IntradayControlsUpdatedEvent
  ): any {
    patchState({
      controls
    });
  }

  @Action(CreateIntradayOrderCommand)
  handleCreateIntradayOrderCommand(
    { patchState, getState }: StateContext<IntradayOrderStateModel>,
    { dialogRef }: CreateIntradayOrderCommand
  ): any {
    const payload = this.getSaveData();
    patchState({
      savePending: true,
      saveError: null
    });

    this.intradayOrderService.saveOrder(payload).subscribe({
      error: (err) => {
        patchState({
          saveError: err?.message,
          savePending: false
        });
      },
      next: () => {
        dialogRef?.close();
        this.snackbar.openFromComponent(OrderCreatedSnackbarComponent, {
          duration: 3000
        });
        patchState({
          savePending: false
        });
      }
    });
  }

  private getSaveData(): IntradayCreateOrdersJson {
    const periods = this.store.selectSnapshot(IntradayOrderState.iconsOrderPeriods);
    const direction = this.store.selectSnapshot(IntradayOrderState.selectedDirection);
    const allowedForIdcons = this.store.selectSnapshot(IntradayOrderState.selectedAllowedForIdcons);
    const price = this.store.selectSnapshot(IntradayOrderState.selectedPrice);
    const volumes = this.store.selectSnapshot(IntradayOrderState.selectedVolume);

    const orders = periods.map((period) => {
      const orderItems = toPairs(volumes)
        .filter(([controlId, volumeInkWh]) => !!volumeInkWh)
        .map(([controlId, volumeInkWh]) => {
          return {
            controlId,
            capacity: Capacity.kW(volumeInkWh as number)
          };
        });

      return {
        direction: direction === OrderDirection.SELL ? Direction.PRODUCTION : Direction.CONSUMPTION,
        allowedForIdcons,
        period,
        price,
        volumes: orderItems
      };
    });

    return { orders };
  }

  @Action(CancelIntradayOrderCommand)
  handleCancelIntradayOrderCommand(
    { patchState }: StateContext<IntradayOrderStateModel>,
    { orderId, dialogRef }: CancelIntradayOrderCommand
  ): any {
    patchState({
      cancelPending: true,
      cancelError: null
    });

    return this.intradayOrderService.cancelOrder(orderId).pipe(
      tap({
        error: (err) => {
          patchState({
            cancelError: err?.message
          });
        },
        complete: () => {
          setTimeout(() => {
            dialogRef?.close(true);
            patchState({
              cancelPending: false
            });
          }, 1000);
        }
      })
    );
  }

  @Action(CancelIntradayOrdersCommand)
  handleCancelIntradayOrdersCommand(
    { patchState }: StateContext<IntradayOrderStateModel>,
    { orderIds, cancelAll }: CancelIntradayOrdersCommand
  ): any {
    patchState({
      cancelMultipleError: null,
      cancelMultiplePending: true
    });

    const request$ = cancelAll ? this.intradayOrderService.cancelAllOrders() : this.intradayOrderService.cancelOrders(orderIds);

    return request$.pipe(
      tap({
        error: (err) => {
          patchState({
            cancelMultipleError: err,
            cancelMultiplePending: false
          });
        },
        next: () => {
          patchState({
            cancelMultiplePending: false
          });
        }
      })
    );
  }

  @Action(UpdateFormValue)
  handleUpdateFormValue({ patchState, getState }: StateContext<IntradayOrderStateModel>, { payload }: UpdateFormValue): any {
    if (!getState().isActive) {
      return;
    }
    if (payload.path.startsWith('intraday.order') && getState().saveError) {
      // Form value updated for idcons
      patchState({
        saveError: null
      });
    }
  }
}
