import { Injectable, LOCALE_ID } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

@Injectable()
export class FlexMatPaginatorIntlNl extends MatPaginatorIntl {
  firstPageLabel = 'Eerste pagina';
  itemsPerPageLabel = 'Items per pagina:';
  lastPageLabel = 'Laatste pagina';
  nextPageLabel = 'Volgende pagina';
  previousPageLabel = 'Vorige pagina';

  private superGetRangeLabel = super.getRangeLabel;

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    // Copied from superclass (cannot call parent property value)
    if (length === 0 || pageSize === 0) {
      return `0 van ${length}`;
    }

    // eslint-disable-next-line no-param-reassign
    length = Math.max(length, 0);

    const startIndex = page * pageSize;

    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

    return `${startIndex + 1} – ${endIndex} van ${length}`;
  };
}

export function matPaginatorIntlFactory(locale: string): MatPaginatorIntl {
  switch (locale) {
    case 'nl':
      return new FlexMatPaginatorIntlNl();
    default:
      return new MatPaginatorIntl();
  }
}

export const matPaginatorIntlProvider = {
  provide: MatPaginatorIntl,
  useFactory: matPaginatorIntlFactory,
  deps: [LOCALE_ID]
};
