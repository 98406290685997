import { Injectable } from '@angular/core';
import { UpdateFormValue } from '@ngxs/form-plugin';
import { RouterNavigation } from '@ngxs/router-plugin';
import { Action, Selector, SelectorOptions, State, StateContext, Store } from '@ngxs/store';
import { startOfDay } from 'date-fns';
import { getRetryConfig, GridPoint, GridPointService, normalizeToDate } from 'flex-app-shared';
import { Observable } from 'rxjs';
import { retry, tap } from 'rxjs/operators';
import {
  DayAheadData,
  DayAheadPrice,
  DealVolume,
  DispatchScheduleService,
  HeatBufferData,
  ImbalancePrices,
  OnOffControl,
  OnOffControlWithGridPoint,
  OnOffData
} from '../../app/shared/dispatch-schedule/dispatch-schedule.service';
import { DispatchingScheduleToolbarFacade } from '../dispatching-schedule-toolbar-state/dispatching-schedule-toolbar.facade';
import { DispatchScheduleActivate, DispatchScheduleDeactivate, DispatchScheduleUpdateDataCommand } from './dispatch-schedule.actions';

export class DispatchScheduleStateModel {
  active: boolean = false;
  isInitialized: boolean = false;

  lastDataDate: Date;

  onOffData: OnOffData;
  pulseStartDateTime: DayAheadData['pulseStartDateTime'];
  pulses: DayAheadData['pulses'];
  imbalancePrices: ImbalancePrices;
  dayAheadPrices: ReadonlyArray<DayAheadPrice>;
  intraDayDealVolumes: ReadonlyArray<DealVolume>;
  dayAheadDealVolumes: ReadonlyArray<DealVolume>;
  aggregatedDealVolumes: DealVolume[];
  tennetDispatchPriceStartDateTime: DayAheadData['tennetDispatchPriceStartDateTime'];
  tennetDispatchPriceDownwards: DayAheadData['tennetDispatchPriceDownwards'];
  tennetDispatchPriceUpwards: DayAheadData['tennetDispatchPriceUpwards'];
  forecastPriceConsumption: DayAheadData['forecastPriceConsumption'];
  forecastPriceConsumptionExtrapolationData: DayAheadData['forecastPriceConsumptionExtrapolationData'];
  forecastPriceProduction: DayAheadData['forecastPriceProduction'];
  forecastPriceProductionExtrapolationData: DayAheadData['forecastPriceProductionExtrapolationData'];
  forecastPriceStartDateTime: Date;
  heatBufferData: HeatBufferData;
  gridPoints: GridPoint[];

  onOffDataBusy: boolean = false;
  dayAheadDataBusy: boolean = false;
}

@State({
  name: 'dispatchSchedule',
  defaults: new DispatchScheduleStateModel()
})
@SelectorOptions({
  injectContainerState: false,
  suppressErrors: false
})
@Injectable({
  providedIn: 'root'
})
export class DispatchScheduleState {
  static refreshIntervalMs: number = 1000 * 60;

  constructor(
    private dispatchScheduleService: DispatchScheduleService,
    private gridPointService: GridPointService,
    private toolbarFacade: DispatchingScheduleToolbarFacade,
    private store: Store
  ) {
    setInterval(() => {
      if (this.toolbarFacade.isTodaySelected()) {
        if (!this.store.selectSnapshot(DispatchScheduleState.active)) {
          return;
        }
        this.store.dispatch(new DispatchScheduleUpdateDataCommand());
      }
    }, DispatchScheduleState.refreshIntervalMs);
  }

  @Selector([DispatchScheduleState])
  static active(state: DispatchScheduleStateModel): boolean {
    return state.active;
  }

  @Selector([DispatchScheduleState])
  static lastDataDate(model: DispatchScheduleStateModel): Date {
    return model.lastDataDate;
  }

  @Selector([DispatchScheduleState])
  static onOffData(model: DispatchScheduleStateModel): OnOffData {
    return model.onOffData;
  }

  @Selector([DispatchScheduleState.onOffData])
  static onOffControls(onOffData: OnOffData): OnOffControl[] {
    // Cast readonly to non-readonly
    return onOffData?.controls as OnOffControl[];
  }

  @Selector()
  static gridPoints(model: DispatchScheduleStateModel): GridPoint[] {
    return model.gridPoints;
  }

  @Selector([DispatchScheduleState.onOffControls, DispatchScheduleState.gridPoints])
  static onOffControlsWithGridPoint(onOffControls: OnOffControl[], gridPoints: GridPoint[]): OnOffControlWithGridPoint[] {
    return onOffControls?.map((onOffControl) => {
      if (!onOffControl.gridPointIds?.length) {
        return {
          ...onOffControl,
          gridPointsDescription: null
        };
      }

      const foundGridPoints = gridPoints?.filter((gridPoint) => onOffControl.gridPointIds.includes(gridPoint.id));
      const gridPointsDescription = foundGridPoints?.map((gridPoint) => gridPoint.description).join(', ');

      return {
        ...onOffControl,
        gridPointsDescription
      };
    });
  }

  @Selector([DispatchScheduleState])
  static pulseStartDateTime(model: DispatchScheduleStateModel): DayAheadData['pulseStartDateTime'] {
    return model.pulseStartDateTime;
  }

  @Selector([DispatchScheduleState])
  static pulses(model: DispatchScheduleStateModel): DayAheadData['pulses'] {
    return model.pulses;
  }

  @Selector([DispatchScheduleState])
  static imbalancePrices(model: DispatchScheduleStateModel): ImbalancePrices {
    return model.imbalancePrices;
  }

  @Selector([DispatchScheduleState])
  static dayAheadPrices(model: DispatchScheduleStateModel): ReadonlyArray<DayAheadPrice> {
    return model.dayAheadPrices;
  }

  @Selector([DispatchScheduleState])
  static forecastPriceStartDateTime(model: DispatchScheduleStateModel): Date {
    return model.forecastPriceStartDateTime;
  }

  @Selector([DispatchScheduleState])
  static forecastPriceConsumption(model: DispatchScheduleStateModel): DayAheadData['forecastPriceConsumption'] {
    return model.forecastPriceConsumption;
  }

  @Selector([DispatchScheduleState])
  static forecastPriceConsumptionExtrapolationData(
    model: DispatchScheduleStateModel
  ): DayAheadData['forecastPriceConsumptionExtrapolationData'] {
    return model.forecastPriceConsumptionExtrapolationData;
  }

  @Selector([DispatchScheduleState])
  static forecastPriceProduction(model: DispatchScheduleStateModel): DayAheadData['forecastPriceProduction'] {
    return model.forecastPriceProduction;
  }

  @Selector([DispatchScheduleState])
  static forecastPriceProductionExtrapolationData(
    model: DispatchScheduleStateModel
  ): DayAheadData['forecastPriceProductionExtrapolationData'] {
    return model.forecastPriceProductionExtrapolationData;
  }

  @Selector([DispatchScheduleState])
  static intraDayDealVolumes(model: DispatchScheduleStateModel): ReadonlyArray<DealVolume> {
    return model.intraDayDealVolumes;
  }

  @Selector([DispatchScheduleState])
  static dayAheadDealVolumes(model: DispatchScheduleStateModel): ReadonlyArray<DealVolume> {
    return model.dayAheadDealVolumes;
  }

  @Selector([DispatchScheduleState])
  static aggregatedDealVolumes(model: DispatchScheduleStateModel): DealVolume[] {
    return model.aggregatedDealVolumes;
  }

  @Selector([DispatchScheduleState])
  static tennetDispatchPriceStartDateTime(model: DispatchScheduleStateModel): DayAheadData['tennetDispatchPriceStartDateTime'] {
    return model.tennetDispatchPriceStartDateTime;
  }

  @Selector([DispatchScheduleState])
  static tennetDispatchPriceConsumption(model: DispatchScheduleStateModel): ImbalancePrices['downwardsPrices'] {
    return model.tennetDispatchPriceDownwards;
  }

  @Selector([DispatchScheduleState])
  static tennetDispatchPriceProduction(model: DispatchScheduleStateModel): ImbalancePrices['upwardsPrices'] {
    return model.tennetDispatchPriceUpwards;
  }

  @Selector([DispatchScheduleState])
  static heatBufferData(model: DispatchScheduleStateModel): HeatBufferData {
    return model.heatBufferData;
  }

  @Selector([DispatchScheduleState])
  static onOffDataBusy(model: DispatchScheduleStateModel): boolean {
    return model.onOffDataBusy;
  }

  @Selector([DispatchScheduleState])
  static dayAheadDataBusy(model: DispatchScheduleStateModel): boolean {
    return model.dayAheadDataBusy;
  }

  @Action(DispatchScheduleActivate)
  activate({ patchState }: StateContext<DispatchScheduleStateModel>): void {
    patchState({
      active: true
    });
  }

  @Action(DispatchScheduleDeactivate)
  deactivate({ patchState }: StateContext<DispatchScheduleStateModel>): void {
    patchState({
      active: false
    });
  }

  @Action(RouterNavigation)
  handleRouterNavigation({ getState, dispatch }: StateContext<DispatchScheduleStateModel>): void {
    if (!getState().active) {
      return;
    }
    dispatch(new DispatchScheduleUpdateDataCommand());
  }

  @Action(UpdateFormValue)
  handleUpdateFormValue({ getState, dispatch }: StateContext<DispatchScheduleStateModel>): any {
    if (!getState().active) {
      return;
    }
    dispatch(new DispatchScheduleUpdateDataCommand());
  }

  @Action(DispatchScheduleUpdateDataCommand, { cancelUncompleted: true })
  getNewDayAheadData({ patchState }: StateContext<DispatchScheduleStateModel>): Observable<any> {
    const { customerId, gridPointId, selectedDate } = this.toolbarFacade.getFilters();
    const normalizedSelectedDate = startOfDay(normalizeToDate(selectedDate));

    if (!customerId || !normalizedSelectedDate) {
      return;
    }

    patchState({
      dayAheadDataBusy: true
    });

    return this.dispatchScheduleService.getNewDayAheadData(customerId, normalizedSelectedDate, gridPointId).pipe(
      tap((result) => {
        patchState({
          pulseStartDateTime: result.pulseStartDateTime,
          pulses: result.pulses,
          imbalancePrices: result.imbalancePrices,
          dayAheadPrices: result.dayAheadPrices.prices,
          aggregatedDealVolumes: result.aggregatedDealVolumes,
          intraDayDealVolumes: result.intraDayDealVolumes,
          dayAheadDealVolumes: result.dayAheadDealVolumes,
          tennetDispatchPriceStartDateTime: result.tennetDispatchPriceStartDateTime,
          tennetDispatchPriceDownwards: result.tennetDispatchPriceDownwards,
          tennetDispatchPriceUpwards: result.tennetDispatchPriceUpwards,
          forecastPriceStartDateTime: result.forecastPriceStartDateTime,
          forecastPriceConsumption: result.forecastPriceConsumption,
          forecastPriceConsumptionExtrapolationData: result.forecastPriceConsumptionExtrapolationData,
          forecastPriceProduction: result.forecastPriceProduction,
          forecastPriceProductionExtrapolationData: result.forecastPriceProductionExtrapolationData,
          heatBufferData: result.heatBufferData,
          dayAheadDataBusy: false,
          lastDataDate: new Date()
        });
      })
    );
  }

  @Action(DispatchScheduleUpdateDataCommand, { cancelUncompleted: true })
  getOnOffData({ patchState }: StateContext<DispatchScheduleStateModel>): Observable<any> {
    const { customerId, gridPointId, selectedDate } = this.toolbarFacade.getFilters();

    if (!customerId || !selectedDate) {
      return;
    }

    patchState({
      onOffDataBusy: true
    });

    return this.dispatchScheduleService.getOnOffData(customerId, startOfDay(normalizeToDate(selectedDate)), gridPointId).pipe(
      retry(getRetryConfig()),
      tap((result) => {
        patchState({
          onOffData: result,
          onOffDataBusy: false
        });
      })
    );
  }

  @Action(DispatchScheduleUpdateDataCommand, { cancelUncompleted: true })
  getGridPoints({ patchState }: StateContext<DispatchScheduleStateModel>): Observable<any> {
    const { customerId } = this.toolbarFacade.getFilters();

    return (customerId ? this.gridPointService.getByCustomerId(customerId) : this.gridPointService.getAll()).pipe(
      retry(getRetryConfig()),
      tap((result) => {
        patchState({
          gridPoints: result
        });
      })
    );
  }
}
