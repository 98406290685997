<div class="items-left">
  <div class="mobile-menu-toggle">
    <button mat-icon-button disableRipple (click)="toggleMobileMenu()">
      <mat-icon svgIcon="ic-new:menu" class="icon"></mat-icon>
    </button>
  </div>
  <div class="page-title">
    <ng-content select="app-page-title"></ng-content>
  </div>
</div>

<div class="items-right">
  <ng-content select="ph-flex-motd-button"></ng-content>
  <ng-content select="ph-flex-dialog"></ng-content>
  <ph-flex-user-menu-trigger [matMenuTriggerFor]="userMenu" [username]="username"></ph-flex-user-menu-trigger>
  <ng-content select="mat-menu"></ng-content>
</div>
