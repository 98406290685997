<div class="ph-g-container-page">
  <ph-flex-loading-context [isLoading]="isBusy$ | async">
    <mat-card class="ph-g-card-body-main ph-g-card-body-main-responsive ph-g-card-body-main-l">
      <mat-card-title class="card-title-date" *ngIf="(facade.selectableServiceAgreements$ | async).length > 0"
        >{{ this.cardTitleDate$ | async }}
        <ph-service-agreement-display-label></ph-service-agreement-display-label>
        <div class="editable-countdown-container" *ngIf="earliestEditableDay$ | async; let earliestEditableDay">
          <span
            class="editable-countdown"
            (click)="openEditDialog(earliestEditableDay)"
            matTooltip="Availability for {{ earliestEditableDay | date : 'shortDate' }} can be changed until {{
              mockNextDeadline$ | async | date : 'short'
            }}."
            i18n-matTooltip="@@incidentReserveAvailability-availabilityDeadlineTooltip"
            ><ng-container i18n="@@incidentReserveAvailability-availabilityDeadlinePrefix">Availability deadline:</ng-container>
            {{ deadlineTimer$ | async }}</span
          >
        </div>
      </mat-card-title>
      <ng-container *ngIf="facade.hasSelectedCustomer$ | async">
        <mat-card-content *ngIf="(facade.selectableServiceAgreements$ | async).length > 0">
          <div class="calendar-container">
            <div *ngIf="(facade.currentView$ | async) === incidentReserveAvailabilityView.MONTH" class="grid-columns-7 days-of-week">
              <div *ngFor="let weekDay of weekDays" class="col-1">
                {{ weekDay | date : 'EEEE' }}
              </div>
            </div>
            <ph-flex-calendar-period-list
              [class.grid-columns-4]="(facade.currentView$ | async) === incidentReserveAvailabilityView.YEAR"
              [class.grid-columns-7]="(facade.currentView$ | async) === incidentReserveAvailabilityView.MONTH"
              [dataSource]="periodDatasource"
              class="availabilities"
            >
              <app-availability-item *phFlexCalendarPeriodListItem="let period" [period]="period"></app-availability-item>
            </ph-flex-calendar-period-list>
          </div>
        </mat-card-content>
        <mat-card-content
          *ngIf="!(isBusy$ | async) && (facade.selectableServiceAgreements$ | async)?.length === 0"
          class="ph-g-card-body-main no-contracts"
        >
          <em i18n="@@incidentReserveAvailability-noServiceAgreements">No contracts.</em>
        </mat-card-content>
      </ng-container>
    </mat-card>
  </ph-flex-loading-context>
</div>
