import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { MixinBase, OnDestroyMixin } from 'flex-app-shared';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { IntradayFacade } from '../state/intraday-facade.service';
import { IntradayState } from '../state/intraday-state.service';

@Component({
  selector: 'app-intraday',
  templateUrl: './intraday.component.html',
  styleUrls: ['./intraday.component.scss']
})
export class IntraDayComponent extends OnDestroyMixin(MixinBase) {
  @ViewChild('confirmDialog') confirmDialog;

  askSummary$ = this.facade.askSummary$;
  bidSummary$ = this.facade.bidSummary$;
  committedDeals$ = this.facade.committedDeals$;
  confirmDealsDisabledBasedOnDealAmount$ = this.facade.confirmDealsDisabledBasedOnDealAmount$;

  hasOpenOrdersForDraftDeals$ = this.facade.hasOpenOrdersForDraftDeals$;

  confirmDealsDisabled$ = combineLatest([
    this.facade.confirmDealsDisabledBasedOnDealAmount$,
    this.facade.confirmDealsDisabledBasedOnSlotStatus$,
    this.facade.confirmDealsDisabledBasedOnPosition$,
    this.facade.confirmDealsDisabledBasedOnDraftDealsLength$,
    this.facade.confirmDealsDisabledOneSecondBasedOnNewSlot$
  ]).pipe(map((results) => results.reduce((c, a) => c || a)));

  committedDealsColumns = ['slot', 'direction', 'controlId', 'capacity', 'price', 'status'];

  dealsInvalidBasedOnAvailableCapacityOrMinVolume$ = this.store.select(IntradayState.dealsInvalidBasedOnAvailableCapacityOrMinVolume);

  saveError$ = this.facade.intradaySaveError$;
  savePending$ = this.facade.intradaySavePending$;

  dialogRef: MatDialogRef<any>;

  constructor(
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private facade: IntradayFacade,
    private dialog: MatDialog
  ) {
    super();
  }

  confirmDeals(): void {
    this.facade.clearIntradayErrorState();
    this.dialogRef = this.dialog.open(this.confirmDialog);
  }

  submit(): void {
    this.facade.createIntradayDeals(this.dialogRef);
  }
}
