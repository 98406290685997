<ng-container *ngIf="tabsExpandedBreakpointMatches$ | async; else select">
  <ph-flex-toolbar-tab *ngFor="let tab of tabs" [active]="isTabActive(tab)" (click)="handleClick(tab)">{{ tab.label }}</ph-flex-toolbar-tab>
</ng-container>

<ng-template #select>
  <ph-flex-toolbar-tab-select>
    <mat-select
      [value]="activeTabRoutePath$ | async"
      panelClass="toolbar-tab-select"
      placeholder="Select tab"
      i18n-placeholder="@@toolbarTabs-selectPlaceholder"
      (valueChange)="selectUpdated($event)"
    >
      <mat-option *ngFor="let tab of tabs" [value]="tab.path">{{ tab.label }}</mat-option>
    </mat-select>
  </ph-flex-toolbar-tab-select>
</ng-template>
