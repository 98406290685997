import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { COMPONENT_PORTAL_OVERLAY_MANAGER } from './component-portal-overlay-manager';
import { EmbeddedComponentOverlayManager } from './embedded-component-overlay-manager';

@Component({
  selector: 'ph-flex-tooltip-container',
  styleUrls: ['tooltip-container.scss'],
  encapsulation: ViewEncapsulation.None,
  template: ` <ng-template [cdkPortalOutlet]="overlayManager.embeddedPortal"></ng-template> `
})
export class FlexTooltipContainerComponent {
  constructor(@Inject(COMPONENT_PORTAL_OVERLAY_MANAGER) public overlayManager: EmbeddedComponentOverlayManager) {}
}
