<mat-toolbar class="filter-bar">
  <mat-form-field>
    <mat-label i18n="@@intraday-labelSearchCategory">Search category</mat-label>
    <mat-select #categoryFilter value="customerLegalName">
      <mat-option [value]="'customerLegalName'" i18n="@@intraday-labelSearchCategoryCustomerLegalName">Company name</mat-option>
      <mat-option [value]="'controlDescription'" i18n="@@intraday-labelSearchCategoryControlDescription">Component</mat-option>
      <mat-option [value]="'gridPointDescription'" i18n="@@intraday-labelSearchCategoryGridPointDescription">Grid point</mat-option>
    </mat-select>
  </mat-form-field>
  <ph-flex-table-filter label="Search" i18n-label="@@intraday-labelSearchTerm"></ph-flex-table-filter>
  <mat-form-field>
    <mat-label i18n="@@intraday-labelOrderType">Order type</mat-label>
    <mat-select #orderTypeFilter [value]="FILTER_ALL">
      <mat-option [value]="FILTER_ALL" i18n="@@intraday-labelDealTypeAll">All</mat-option>
      <mat-option [value]="OrderType.Sell"><ng-container i18n="@@intraday-labelDealTypeSell">Sell</ng-container></mat-option>
      <mat-option [value]="OrderType.SellIntradayPlus"><ng-container i18n="@@intraday-labelDealTypeSell">Sell</ng-container>+</mat-option>
      <mat-option [value]="OrderType.Buy"><ng-container i18n="@@intraday-labelDealTypeBuy">Buy</ng-container></mat-option>
      <mat-option [value]="OrderType.BuyIntradayPlus"><ng-container i18n="@@intraday-labelDealTypeBuy">Buy</ng-container>+</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label i18n="@@intraday-labelOrderStatus">Order status</mat-label>
    <mat-select #orderStatusFilter [value]="FILTER_ALL">
      <mat-option [value]="FILTER_ALL" i18n="@@intraday-labelDealTypeAll">All</mat-option>
      <mat-option [value]="OrderStatus.CREATED" i18n="@@intraday-idconsOrderStatusOpen">Open</mat-option>
      <mat-option [value]="OrderStatus.REQUESTED" i18n="@@intraday-idconsOrderStatusPending">Pending</mat-option>
      <mat-option [value]="OrderStatus.CANCELLED" i18n="@@intraday-idconsOrderStatusCancelled">Cancelled</mat-option>
      <mat-option [value]="OrderStatus.CANCELLATION_REQUESTED" i18n="@@intraday-idconsOrderStatusCancellationRequested"
        >Cancelling</mat-option
      >
      <mat-option [value]="OrderStatus.EXPIRED" i18n="@@intraday-idconsOrderStatusExpired">Expired</mat-option>
      <mat-option [value]="OrderStatus.FAILED" i18n="@@intraday-idconsOrderStatusFailed">Failed</mat-option>
      <mat-option [value]="OrderStatus.IN_PROGRESS" i18n="@@intraday-idconsOrderStatusInProgress">In progress</mat-option>
      <mat-option [value]="OrderStatus.COMPLETED" i18n="@@intraday-idconsOrderStatusCompleted">Completed</mat-option>
      <mat-option [value]="OrderStatus.UPDATED" i18n="@@intraday-idconsOrderStatusUpdated">Updated</mat-option>
    </mat-select>
  </mat-form-field>
</mat-toolbar>

<table
  mat-table
  [dataSource]="dataSource"
  matSort
  matSortActive="created"
  matSortDirection="desc"
  [trackBy]="trackBy"
  [phFlexTableSelectionModel]="trackBy"
>
  <ph-flex-table-select-checkbox-cell></ph-flex-table-select-checkbox-cell>
  <ph-flex-table-selection-actions-cell
    [actions]="[TableSelectionAction.DELETE]"
    [actionObservables]="actionObservables"
  ></ph-flex-table-selection-actions-cell>
  <ph-flex-table-selection-context-cell></ph-flex-table-selection-context-cell>
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <ng-container i18n="@@intraday-tableHeaderStatus"> Status</ng-container>
    </th>
    <td mat-cell *matCellDef="let order">
      <app-intraday-order-status [value]="order.status"></app-intraday-order-status>
    </td>
  </ng-container>
  <ng-container matColumnDef="direction">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <ng-container i18n="@@intraday-tableHeaderType"> Type</ng-container>
    </th>
    <td mat-cell *matCellDef="let order">
      <app-intraday-deal-direction [direction]="order.direction" [intradayPlus]="order.allowedForIdcons"></app-intraday-deal-direction>
    </td>
  </ng-container>
  <ng-container matColumnDef="created">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <ng-container i18n="@@intraday-tableHeaderCreated"> Created</ng-container>
    </th>
    <td mat-cell *matCellDef="let order">{{ order.created | date: 'short' }}</td>
  </ng-container>
  <ng-container matColumnDef="customerLegalName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <ng-container i18n="@@intraday-tableHeaderCustomer"> Customer</ng-container>
    </th>
    <td mat-cell *matCellDef="let order">{{ order.customerLegalName }}</td>
  </ng-container>
  <ng-container matColumnDef="gridPointDescription">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <ng-container i18n="@@intraday-tableHeaderGridPoint"> Grid point</ng-container>
    </th>
    <td mat-cell *matCellDef="let order">{{ order.gridPointDescription }}</td>
  </ng-container>
  <ng-container matColumnDef="controlDescription">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <ng-container i18n="@@intraday-tableHeaderControl"> Component</ng-container>
    </th>
    <td mat-cell *matCellDef="let order">{{ order.controlDescription }}</td>
  </ng-container>
  <ng-container matColumnDef="dealPeriodDay">
    <th mat-header-cell *matHeaderCellDef>
      <ng-container i18n="@@intraday-tableHeaderPeriodDay"> Day</ng-container>
    </th>
    <td mat-cell *matCellDef="let order">
      {{ order.period.startDateTime | date: 'shortDate' }}
    </td>
  </ng-container>
  <ng-container matColumnDef="dealPeriod">
    <th mat-header-cell *matHeaderCellDef>
      <ng-container i18n="@@intraday-tableHeaderPeriod"> Period</ng-container>
    </th>
    <td mat-cell *matCellDef="let order">
      {{ order.period.startDateTime | date: 'shortTime' }}
      - {{ order.period.toDateTime | date: 'shortTime' }}
    </td>
  </ng-container>
  <ng-container matColumnDef="price">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <ng-container i18n="@@intraday-tableHeaderPrice"> Price</ng-container>
      [€/MWh]
    </th>
    <td mat-cell *matCellDef="let order">{{ order.price.money.amount | currency: 'EUR' }}</td>
  </ng-container>
  <ng-container matColumnDef="remainingCapacity">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <ng-container i18n="@@intraday-tableHeaderPower"> Power</ng-container>
      [kW]
    </th>
    <td mat-cell *matCellDef="let order">{{ order.remainingCapacity | capacity: 'kW' }}</td>
  </ng-container>
  <ng-container matColumnDef="amount">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <ng-container i18n="@@intraday-tableHeaderAmount"> Amount</ng-container>
      [€]
    </th>
    <td mat-cell *matCellDef="let order">{{ order.amount.amount | currency: 'EUR' }}</td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="headerColumns$ | async"></tr>
  <tr mat-row *phFlexTableSelectionContextRow phFlexHideRowWithNoColumns></tr>
  <tr mat-row *matRowDef="let row; columns: columns"></tr>
</table>
<mat-paginator [showFirstLastButtons]="true"></mat-paginator>
