<div class="main-item-container">
  <div class="main-item" (click)="toggleExpand()">
    <div class="row">
      <mat-icon [svgIcon]="icon"></mat-icon>
      <span class="menu-label">
        <span
          ><span>{{ label }}</span></span
        >
      </span>
      <span class="expand-collapse" *ngIf="hasChildren()" [ngSwitch]="isIconOnly">
        <ng-container *ngSwitchCase="true" [ngSwitch]="isExpanded">
          <mat-icon svgIcon="ic-new:triangle-up" *ngSwitchCase="true"></mat-icon>
          <mat-icon svgIcon="ic-new:triangle-down" *ngSwitchDefault></mat-icon>
        </ng-container>

        <ng-container *ngSwitchDefault [ngSwitch]="isExpanded">
          <mat-icon svgIcon="ic-new:chevron-up" *ngSwitchCase="true"></mat-icon>
          <mat-icon svgIcon="ic-new:chevron-down" *ngSwitchDefault></mat-icon>
        </ng-container>
      </span>
    </div>
  </div>
</div>
<div class="child-row-container" [@childContainerExpand]="isExpanded ? 'expanded' : 'collapsed'">
  <ng-content></ng-content>
</div>
<span class="highlight" *ngIf="isSelected"></span>
