<ph-flex-top-bar [username]="username">
  <app-page-title [page]="currentPageService.matchedPage$ | async" type="toolbar"></app-page-title>
  <ph-flex-motd-button></ph-flex-motd-button>
  <ph-flex-dialog
    (closeAction)="handleAnnouncementDialogAction($event)"
    [dialogContent]="messagesDialog"
    [dialogContentFullWidth]="true"
    panelClass="idcons-announcements"
    dialogActionLabel="Messages overview"
    dialogCancelLabel="Close"
    dialogModalSize="l"
    dialogTitle="Current messages"
    i18n-dialogActionLabel="@@idconsAnnouncement-dialogActionLabel"
    i18n-dialogTitle="@@idconsAnnouncement-dialogTitle"
    i18n-dialogCancelLabel="@@idconsAnnouncement-dialogLabelClose"
  >
    <ph-flex-announcements-trigger
      [hideNewAnnouncementsBadge]="facade.noUnreadActiveAnnouncements$ | async"
      [newAnnouncements]="facade.unreadActiveAnnouncementCount$ | async"
      [phFlexAuthShow]="VIEW_IDCONS_INTRA_DAY_ORDER"
      class="item"
      phFlexModalDialogTrigger
    ></ph-flex-announcements-trigger>
  </ph-flex-dialog>
  <mat-menu xPosition="before">
    <button mat-menu-item routerLink="/api-keys" [phFlexAuthShow]="[MANAGE_API_KEY]">
      <span><span i18n="@@menuItem.apiKeys">API keys</span></span>
    </button>
    <button mat-menu-item [matMenuTriggerFor]="language">
      <span>
        <span *ngIf="locale === 'nl'; then nederlands; else english"></span>
      </span>
    </button>
    <button (click)="openUserPreferencesDialog()" [phFlexAuthShow]="[MANAGE_USER_PREFERENCES]" i18n="@@notifications" mat-menu-item>
      Notifications
    </button>
    <button (click)="changePassword()" i18n="@@changePassword" mat-menu-item>Change password</button>
    <button mat-menu-item class="ph-flex-logout" (click)="logout()">
      <span><span i18n="@@logout">Logout</span></span>
    </button>
  </mat-menu>
</ph-flex-top-bar>

<mat-menu #language="matMenu" class="ph-flex-mat-menu-override">
  <button mat-menu-item (click)="switchLocale('nl')">
    <span><span *ngTemplateOutlet="nederlands"></span></span>
  </button>
  <button mat-menu-item (click)="switchLocale('en')">
    <span><span *ngTemplateOutlet="english"></span></span>
  </button>
</mat-menu>

<ng-template #messagesDialog>
  <app-idcons-announcements
    [announcements]="activeAnnouncements$ | async"
    [unreadAnnouncementsIds]="unreadAnnouncementsIds$ | async"
  ></app-idcons-announcements>
</ng-template>

<ng-template #nederlands>Nederlands</ng-template>
<ng-template #english>English</ng-template>
