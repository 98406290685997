import { Capacity, TimeSlot } from 'flex-app-shared';

export enum IdconsAnnouncementType {
  TRANSPORTPROBLEM = 'TRANSPORTPROBLEM',
  CONGESTIONMANAGEMENT = 'CONGESTIONMANAGEMENT',
  FREE_TEXT = 'FREE_TEXT'
}

export enum IdconsAnnouncementStateEnum {
  ANNOUNCEMENT_OPEN = 'ANNOUNCEMENT_OPEN',
  ANNOUNCEMENT_CLOSED = 'ANNOUNCEMENT_CLOSED',
  ANNOUNCEMENT_DELETED = 'ANNOUNCEMENT_DELETED'
}

/**
 * States if it Is Mandatory to do bids for GOPACS based on the
 * Dutch Grid Code regime. Voluntary does NOT mean you don’t need
 * to follow your GOPACS trade. It only referring to the Dutch Grid Code.
 */
export enum IdconsComplianceType {
  MANDATORY = 'MANDATORY',
  VOLUNTARY = 'VOLUNTARY'
}

class IdconsAnnouncementPeriod {
  startDateTime: string;
  toDateTime: string;
  numberOfQuartersInTimeSpan?: number;
}

export class IdconsAnnouncement {
  id: string;
  message?: string;
  includeZipCodes?: boolean;
  zipCodeGranularity?: number;
  createdDateTime: string;
  lastUpdatedDateTime: string;
  problemAreaDescription?: string;
  requestAreaDescriptionBuyOrders?: string;
  requestAreaDescriptionSellOrders?: string;
  problemProfile?: IdconsProblemProfile[];
  problemProfileMin?: Capacity;
  problemProfileMax?: Capacity;
  announcementState?: IdconsAnnouncementStateEnum;
  problemPeriod?: IdconsAnnouncementPeriod;
  organisationName: string;
  complianceType?: IdconsComplianceType;
  day?: string;
  type: IdconsAnnouncementType;
  bidValidityPeriod?: IdconsAnnouncementPeriod;
}

class IdconsProblemProfile {
  period: TimeSlot;
  capacity: Capacity;
}
