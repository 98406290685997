import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'ph-flex-announcements-trigger',
  templateUrl: './announcements-trigger.component.html',
  styleUrls: ['./announcements-trigger.component.scss']
})
export class AnnouncementsTriggerComponent implements OnChanges {
  numberOfAnnouncements;

  @Input()
  newAnnouncements: number;

  @Input()
  hideNewAnnouncementsBadge: boolean;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.numberOfAnnouncements = changes?.newAnnouncements.currentValue > 9 ? '9+' : changes.newAnnouncements.currentValue;
  }
}
