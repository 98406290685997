import { ChangeDetectorRef, Component, HostBinding, Input, OnChanges, SimpleChanges, TemplateRef } from '@angular/core';

function assert(condition: any, msg?: string): asserts condition {
  console.assert(condition, msg);
}
class RowDatum {
  leftColumnTemplate: TemplateRef<any>;
  rightColumnTemplate: TemplateRef<any>;
}

@Component({
  selector: 'ph-flex-tooltip',
  templateUrl: 'tooltip.component.html',
  styleUrls: ['tooltip.component.scss']
})
export class TooltipComponent {
  private static FALLBACK_NAME = 'ph-fallback';

  @Input()
  @HostBinding('style.width.px')
  width: number = 360;

  private rowMap = new Map<string, RowDatum>();

  /**
   * Order of rows to display
   */
  @Input() rows: string[] = [];

  trackByRow(index: number, row: string): string {
    return row;
  }

  registerRow(name: string, leftColumnTemplate: TemplateRef<any>, rightColumnTemplate: TemplateRef<any>): void {
    assert(leftColumnTemplate && rightColumnTemplate, 'Left and right column template must be provided.');
    assert(name, 'Row name is required.');
    assert(name !== TooltipComponent.FALLBACK_NAME, `Name ${name} is reserved and cannot be used with registerRow.`);

    this.rowMap.set(name, { leftColumnTemplate, rightColumnTemplate });
  }

  registerFallbackRow(leftColumnTemplate: TemplateRef<any>, rightColumnTemplate: TemplateRef<any>): void {
    assert(leftColumnTemplate && rightColumnTemplate, 'Left and right column template must be provided.');

    this.rowMap.set(TooltipComponent.FALLBACK_NAME, { leftColumnTemplate, rightColumnTemplate });
  }

  getLeftColumnTemplate(rowName: string): TemplateRef<any> {
    return this.getRowDatum(rowName)?.leftColumnTemplate;
  }

  getRightColumnTemplate(rowName: string): TemplateRef<any> {
    return this.getRowDatum(rowName)?.rightColumnTemplate;
  }

  private getRowDatum(rowName: string): RowDatum {
    if (!this.rowMap.has(rowName)) {
      if (!this.rowMap.has(TooltipComponent.FALLBACK_NAME)) {
        throw new Error(`No row with name ${rowName} and no fallback row was defined.`);
      }
      return this.rowMap.get(TooltipComponent.FALLBACK_NAME);
    }
    return this.rowMap.get(rowName);
  }
}
