import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FlexNgXsFormSync, MixinBase, OnDestroyMixin, ToolbarTab } from 'flex-app-shared';
import { map } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';
import { FiltersIncidentReserveAvailabilityComponent } from '../filters-incident-reserve-availability/filters-incident-reserve-availability.component';

@Component({
  selector: 'app-incident-reserve-availability-toolbar-container',
  templateUrl: './incident-reserve-availability-toolbar-container.component.html',
  styleUrls: ['./incident-reserve-availability-toolbar-container.component.scss']
})
export class IncidentReserveAvailabilityToolbarContainerComponent extends OnDestroyMixin(MixinBase) {
  FiltersIncidentReserveAvailabilityComponent = FiltersIncidentReserveAvailabilityComponent;

  tabs: ToolbarTab[] = [
    {
      path: [
        // Note, it is important that the full URL, including all router params, are available here, so we do not lose data when we switch between tabs.
        '/incident-reserve-availability/availability',
        '/incident-reserve-availability/availability/:customerId',
        '/incident-reserve-availability/availability/:customerId/:dateRange',
        '/incident-reserve-availability/availability/:customerId/:dateRange/:serviceAgreementId'
      ],
      label: $localize`:@@incidentReserveAvailability-tabAvailability:Availability`
    },
    {
      path: [
        '/incident-reserve-availability/activations',
        '/incident-reserve-availability/activations/:customerId',
        '/incident-reserve-availability/activations/:customerId/:dateRange',
        '/incident-reserve-availability/activations/:customerId/:dateRange/:serviceAgreementId',
        '/incident-reserve-availability/activation'
      ],
      label: $localize`:@@incidentReserveAvailability-tabActivations:Activations`
    }
  ];

  @FlexNgXsFormSync('imbalanceReserveAvailability.filters', { initialSyncOnlyTruthyFormValues: true, useRawValue: true })
  filterForm = this.fb.group({
    customerId: null,
    serviceAgreementId: null,
    dateRange: null
  });

  constructor(private fb: UntypedFormBuilder, private router: Router, private activatedRoute: ActivatedRoute) {
    super();
  }

  shouldShowTabs$ = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    startWith(null), // Trigger check without router event
    map((event) => this.activatedRoute?.snapshot.firstChild),
    map((route) => !route.data?.showBackButtonOnly)
  );

  back(): void {
    window.history.back();
  }
}
