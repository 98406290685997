import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DummyComponent, FlexCoreModule, LandingPageService } from 'flex-app-shared';
import {
  ActivateDispatchingScheduleToolbarState,
  DeactivateDispatchingScheduleToolbarState
} from '../store/dispatching-schedule-toolbar-state/route-active';

const routes: Routes = [
  { path: '', component: DummyComponent, pathMatch: 'full', canActivate: [LandingPageService] },
  {
    path: 'callback',
    component: DummyComponent,
    pathMatch: 'full',
    canActivate: [LandingPageService]
  },
  {
    path: 'prototype',
    loadChildren: () => import('./prototype/prototype.module').then((mod) => mod.PrototypeModule)
  },
  {
    path: 'incident-reserve-availability',
    loadChildren: () => import('./incident-reserve/incident-reserve.module').then((mod) => mod.IncidentReserveModule)
  },
  {
    path: 'dispatching-schedule',
    canActivate: [ActivateDispatchingScheduleToolbarState],
    canDeactivate: [DeactivateDispatchingScheduleToolbarState],
    loadChildren: () => import('./dispatching-schedule/dispatching-schedule.module').then((mod) => mod.DispatchingScheduleModule)
  },
  {
    path: 'api-keys',
    loadChildren: () => import('./api-keys/api-keys.module').then((mod) => mod.ApiKeysModule)
  },
  {
    path: 'intraday',
    loadChildren: () => import('./intraday/intraday.module').then((mod) => mod.IntraDayModule)
  },
  {
    path: 'flex-overview',
    loadChildren: () => import('./flex-overview/flex-overview.module').then((mod) => mod.FlexOverviewModule)
  },
  {
    path: 'messages',
    loadChildren: () => import('./idcons-announcements/idcons-announcements.module').then((mod) => mod.IdconsAnnouncementsModule)
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }), FlexCoreModule],
  exports: [RouterModule]
})
export class AppRoutingModule {}
