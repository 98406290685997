<ph-flex-svg phFlexResizeObserver (afterViewInit)="registerSvgRoot($event)"></ph-flex-svg>

<div class="legend">
  <div class="legend-item" *ngIf="measurementLine.hasData$ | async">
    <div>
      <ph-flex-svg (afterViewInit)="measurementLine.attachLabel($event)"></ph-flex-svg>
      <span i18n="@@incidentReserveActivationsDetail-measurementsLabel">Delivered</span>
    </div>
  </div>
  <div class="legend-item" *ngIf="baselineLine.hasData$ | async">
    <div>
      <ph-flex-svg (afterViewInit)="baselineLine.attachLabel($event)"></ph-flex-svg>
      <span i18n="@@incidentReserveActivationsDetail-baselineLabel">Baseline</span>
    </div>
  </div>
  <div class="legend-item" *ngIf="targetLine.hasData$ | async">
    <div>
      <ph-flex-svg (afterViewInit)="targetLine.attachLabel($event)"></ph-flex-svg>
      <span i18n="@@incidentReserveActivationsDetail-targetLabel">Target</span>
    </div>
  </div>
</div>

<ng-template let-datum$ #tooltipTemplate>
  <ph-flex-tooltip phFlexResizeObserver *ngIf="datum$ | async; let datum" [rows]="tooltipRows" width="100%">
    <ng-container phFlexTooltipRowDef="measurementSeries">
      <div class="description-container" *phFlexTooltipColumnLeftDef>
        <ph-flex-text-truncate
          ><ng-container i18n="@@incidentReserveActivationsDetail-measurementsLabel">Measured</ng-container>:</ph-flex-text-truncate
        >
      </div>
      <div class="value-container" *phFlexTooltipColumnRightDef>
        <ph-flex-text-truncate>
          <span class="value">
            {{ getMeasurementForDate(xScaleProvider.scale.invert(datum.x)) | async | number | default }}
          </span>
          <span class="unit"> MW </span>
        </ph-flex-text-truncate>
      </div>
    </ng-container>
    <ng-container phFlexTooltipRowDef="baselineSeries">
      <div class="description-container" *phFlexTooltipColumnLeftDef>
        <ph-flex-text-truncate
          ><ng-container i18n="@@incidentReserveActivationsDetail-baselineLabel">Baseline</ng-container>:</ph-flex-text-truncate
        >
      </div>
      <div class="value-container" *phFlexTooltipColumnRightDef>
        <ph-flex-text-truncate>
          <span class="value">
            {{ getBaselineValueForDate(xScaleProvider.scale.invert(datum.x)) | async | number | default }}
          </span>
          <span class="unit"> MW </span>
        </ph-flex-text-truncate>
      </div>
    </ng-container>
    <ng-container phFlexTooltipRowDef="deliveryTargetSeries">
      <div class="description-container" *phFlexTooltipColumnLeftDef>
        <ph-flex-text-truncate
          ><ng-container i18n="@@incidentReserveActivationsDetail-targetLabel">Target</ng-container>:</ph-flex-text-truncate
        >
      </div>
      <div class="value-container" *phFlexTooltipColumnRightDef>
        <ph-flex-text-truncate>
          <span class="value">
            {{ getDeliveryTargetValueForDate(xScaleProvider.scale.invert(datum.x)) | async | number | default }}
          </span>
          <span class="unit"> MW </span>
        </ph-flex-text-truncate>
      </div>
    </ng-container>
  </ph-flex-tooltip>
</ng-template>

<mat-slide-toggle (change)="onYAxisToggleChange($event)" i18n="@@flexOverview-markConsumptionAsPositive"
  >Mark consumption as positive
</mat-slide-toggle>
