import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexTopBarComponent } from './top-bar.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { FlexUserMenuTriggerComponent } from './user-menu-trigger/user-menu-trigger.component';
import { MatMenuModule } from '@angular/material/menu';
import { AnnouncementsTriggerComponent } from './announcements-trigger/announcements-trigger.component';

@NgModule({
  declarations: [FlexTopBarComponent, FlexUserMenuTriggerComponent, AnnouncementsTriggerComponent],
  imports: [CommonModule, MatButtonModule, MatIconModule, MatBadgeModule, MatMenuModule],
  exports: [FlexTopBarComponent, AnnouncementsTriggerComponent]
})
export class TopBarModule {}
