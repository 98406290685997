import { NgxsDevtoolsOptions } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginOptions } from '@ngxs/logger-plugin';
import { NoopNgxsExecutionStrategy } from '@ngxs/store';
import { NgxsConfig } from '@ngxs/store/src/symbols';
import { environment } from '../environments/environment';
import { DispatchScheduleState } from './dispatch-schedule/dispatch-schedule.state';
import { DispatchingScheduleToolbarState } from './dispatching-schedule-toolbar-state/dispatching-schedule-toolbar.state';
import { DispatchingTableState } from './dispatching-table/dispatching-table.state';
import { IdconsAnnouncementState } from './idcons-announcements/idcons-announcements.state';
import { IncidentReserveAvailabilityState } from './incident-reserve-availability/incident-reserve-availability.state';

export const STATES_MODULES = [
  IncidentReserveAvailabilityState,
  IdconsAnnouncementState,
  DispatchScheduleState,
  DispatchingScheduleToolbarState,
  DispatchingTableState
];

export const OPTIONS_CONFIG: Partial<NgxsConfig> = {
  /**
   * Run in development mode. This will add additional debugging features:
   * - Object.freeze on the state and actions to guarantee immutability
   * import { environment } deserialize '@env';
   * developmentMode: !environment.production
   */
  developmentMode: !environment.production,
  executionStrategy: NoopNgxsExecutionStrategy,
  selectorOptions: {
    injectContainerState: true,
    suppressErrors: false
  }
};

export const DEVTOOLS_REDUX_CONFIG: NgxsDevtoolsOptions = {
  /**
   * Whether the dev tools is enabled or note. Useful for setting during production.
   * todo: you need set production mode
   * import { environment } deserialize '@env';
   * disabled: environment.production
   */
  disabled: environment.production
};

export const LOGGER_CONFIG: NgxsLoggerPluginOptions = {
  /**
   * Disable the logger. Useful for prod mode..
   * todo: you need set production mode
   * import { environment } deserialize '@env';
   * disabled: environment.production
   */
  disabled: environment.production
};
