import { CollectionViewer } from '@angular/cdk/collections';
import { parseISO } from 'date-fns';
import {
  AppInjector,
  asBatch,
  Capacity,
  ControlFilterProvider,
  IntradayControl,
  IntradayControlDirection,
  IntradayPositionWithMinMax,
  MandatoryTradeDirection,
  MatSlideToggleFilterProvider,
  PaginatedDataSource,
  TimeSlot,
  TreeControlFilterProvider
} from 'flex-app-shared';
import Fuse from 'fuse.js';
import { flatMap, flatMapDeep, get, groupBy, isEqual, mapValues, orderBy } from 'lodash-es';
import { combineLatest, merge, Observable, Subscription } from 'rxjs';
import { auditTime, distinctUntilChanged, filter, map, shareReplay, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import {
  FlattenedIntradayControlNode,
  FlattenedIntradayLevel,
  FlattenedIntradayNode,
  IntradayGraphNode
} from '../../../../intraday/intraday-slot/intraday-deal-controls-data-source';
import { IntradayOrderEditSlotContext } from '../../../../state/order/intraday-order.state';
import { IntradayFacade } from '../../../../state/intraday-facade.service';
import { calculateAvailableControlPower, getPosition } from '../../../../state/intraday-state.service';
import FuseResult = Fuse.FuseResult;

export class StepVolumeDataSource extends PaginatedDataSource<FlattenedIntradayNode> {
  gridPointIdFilterProvider = new ControlFilterProvider(this);
  customerIdFilterProvider = new ControlFilterProvider(this);

  treeControlFilterProvider = new TreeControlFilterProvider(this);
  hideEmptyControlsFilterProvider = new MatSlideToggleFilterProvider(this);

  clearedSelectionDueToSingleCustomer: boolean = false;

  editSlotContext$: Observable<IntradayOrderEditSlotContext>;

  dealableControlIdsForSelectedDay$: Observable<string[]>;

  controls$: Observable<IntradayControl[]> = AppInjector.get(IntradayFacade).idconsControls$;

  mandatoryTradingDirections$ = AppInjector.get(IntradayFacade).mandatoryTradingDirections$;

  positions$ = AppInjector.get(IntradayFacade).intradayOrderPositions$;

  lastDataSubscription: Subscription;
  builtTree$: Observable<FlattenedIntradayNode[]>;
  dataAfterFilter: FlattenedIntradayNode[];
  fuse: Fuse<FlattenedIntradayNode, any>;
  data$ = this.dataSubject.asObservable().pipe(
    tap((value) => (this.data = value)),
    asBatch({
      batchSize: 10,
      batchDelayMs: 20,
      equalFn: (a, b) => a?.id === b?.id
    }),
    shareReplay(1)
  );

  constructor() {
    super();
    this.refreshDataObservables.push(
      this.gridPointIdFilterProvider.onChanges$,
      this.customerIdFilterProvider.onChanges$,
      this.treeControlFilterProvider.onChanges$,
      this.hideEmptyControlsFilterProvider.onChanges$
    );

    merge(
      this.gridPointIdFilterProvider.onChanges$,
      this.customerIdFilterProvider.onChanges$,
      this.hideEmptyControlsFilterProvider.onChanges$,
      this.tableFilterProvider.onChanges$
    )
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        // Clear selection when certain filters change
        this.treeControlFilterProvider.item.expansionModel.clear();
        this.clearedSelectionDueToSingleCustomer = false;
      });

    this.editSlotContext$ = combineLatest([
      AppInjector.get(IntradayFacade).idconsDirection$,
      AppInjector.get(IntradayFacade).idconsPrice$,
      AppInjector.get(IntradayFacade).idconsTimeSlot$
    ]).pipe(
      distinctUntilChanged(isEqual),
      map(([direction, price, timeSlot]) => {
        return {
          volume: 0,
          toDateTime: timeSlot?.toDateTime,
          startDateTime: timeSlot?.startDateTime,
          pricePerMW: price,
          direction,
          priceId: 'n/a'
        } as IntradayOrderEditSlotContext;
      })
    );

    this.dealableControlIdsForSelectedDay$ = this.editSlotContext$.pipe(
      filter((editSlotContext) => !!editSlotContext),
      switchMap((editSlotContext) => {
        const editSlotContextDate = parseISO(editSlotContext.startDateTime);
        return AppInjector.get(IntradayFacade).getDealableControlIdsPerDay$(editSlotContextDate);
      })
    );
  }

  getNodeLevel(id: string): number {
    return this.dataAfterFilter.find((item) => item.id === id)?.level;
  }

  connect(collectionViewer: CollectionViewer): Observable<FlattenedIntradayNode[]> {
    this.builtTree$ = combineLatest([
      this.controls$,
      this.dealableControlIdsForSelectedDay$,
      this.positions$,
      this.editSlotContext$,
      this.mandatoryTradingDirections$,
      this.hideEmptyControlsFilterProvider.onChanges$.pipe(startWith(null))
    ]).pipe(
      auditTime(10), // Prevent multiple calls within a small amount of time triggering buildTree
      map(([result, dealableControlIds, positions, slotContext, mandatoryTradingDirections]) => {
        const tree = this.buildTree(result, dealableControlIds, positions, slotContext, mandatoryTradingDirections);

        this.fuse = new Fuse(tree, {
          threshold: 0.3,
          keys: [
            {
              name: 'customerLegalName',
              weight: 1
            },
            {
              name: 'gridPointDescription',
              weight: 2
            },
            {
              name: 'controlDescription',
              weight: 3
            },
            {
              name: 'gridPointEan',
              weight: 5
            }
          ]
        });

        return tree;
      }),
      shareReplay(1)
    );

    this.treeControlFilterProvider.registerDataNodesObservable(this.builtTree$.pipe(map((nodes) => nodes.map((node) => node.id))));

    return super.connect(collectionViewer);
  }

  getParentNode(node: FlattenedIntradayNode, providedData?: FlattenedIntradayNode[]): FlattenedIntradayNode {
    const data = providedData || this.data || [];
    const nodeIndex = data.indexOf(node);
    for (let i = nodeIndex - 1; i >= 0; i--) {
      if (data[i].level === node.level - 1) {
        return data[i];
      }
    }
  }

  getChildNodes(node: FlattenedIntradayNode, providedData?: FlattenedIntradayNode[]): FlattenedIntradayNode[] {
    const data = providedData || this.data || [];
    const nodeIndex = data.indexOf(node);
    const results = [];
    for (let i = nodeIndex + 1; i < data.length && data[i].level > node.level; i++) {
      results.push(data[i]);
    }
    return results;
  }

  loadData(pageIndex?: number, pageSize?: number, searchTerm?: string, sort?: string): void {
    if (this.lastDataSubscription) {
      this.lastDataSubscription.unsubscribe();
      this.lastDataSubscription = null;
    }

    this.lastDataSubscription = this.builtTree$.subscribe((tree) => {
      let filteredTree = tree;

      if (searchTerm) {
        const result = this.fuse.search(searchTerm);
        filteredTree = this.restoreTree(result, tree);
      }

      // Update with the filtered tree before removing items based on expansion state;
      this.dataAfterFilter = filteredTree;

      // Filter on customerId
      const customerId = this.customerIdFilterProvider?.item?.value;
      if (customerId) {
        filteredTree = filteredTree.filter((node) => node.customerId === customerId);

        // Additionally, filter on gridPointId
        const gridPointId = this.gridPointIdFilterProvider?.item?.value;
        if (gridPointId) {
          filteredTree = filteredTree.filter(
            (node) => get(node, 'gridPointId') === gridPointId || node.level === FlattenedIntradayLevel.CUSTOMER
          );
        }
      }

      // Remove customer node if there is only one customer
      const customerNodes = filteredTree.filter((node) => node.level === FlattenedIntradayLevel.CUSTOMER);

      if (customerNodes.length === 1) {
        if (!this.clearedSelectionDueToSingleCustomer) {
          this.clearedSelectionDueToSingleCustomer = true;
          this.treeControlFilterProvider.item?.expandAll();
        }
        filteredTree = filteredTree.filter((node) => node.level !== FlattenedIntradayLevel.CUSTOMER);
      } else {
        this.clearedSelectionDueToSingleCustomer = false;
      }

      filteredTree = filteredTree.filter((node) => {
        const parentNode = this.getParentNode(node, filteredTree);
        return !parentNode || this.treeControlFilterProvider.item?.isExpanded(parentNode.id);
      });

      this.dataSubject.next(filteredTree);
    });
  }

  buildTree(
    controls: IntradayControl[],
    dealableControlIds: string[],
    positions: IntradayPositionWithMinMax[],
    slotContext: IntradayOrderEditSlotContext,
    mandatoryTradingDirections: MandatoryTradeDirection[]
  ): FlattenedIntradayNode[] {
    const sortedControls = orderBy(
      controls,
      [
        (control) => control.customerLegalName.toLocaleLowerCase(),
        (control) => control.gridPointDescription.toLocaleLowerCase(),
        (control) => control.controlDirection,
        (control) => control.controlDescription.toLocaleLowerCase()
      ],
      ['asc', 'asc', 'desc', 'asc']
    );

    const nestedControls = mapValues(
      groupBy(sortedControls, (a) => a.customerId),
      (controlsForCustomer) => groupBy(controlsForCustomer, (a) => a.gridPointId)
    );

    const positionsWithGridPointId = positions
      .map((position) => ({
        ...position,
        gridPointId: controls.find((control) => control.controlId === position.controlId)?.gridPointId
      }))
      // Filter out all positions that do not have a gridpointId, this can happen if the controls are filtered based on e.g. a pre qualified flag
      .filter((position) => !!position.gridPointId);

    const nestedPositions = mapValues(
      groupBy(positionsWithGridPointId, (a) => a.customerId),
      (positionsForCustomer) => groupBy(positionsForCustomer, (a) => a.gridPointId)
    );
    const flattenedNodes: FlattenedIntradayNode[] = [];

    const controlsPerCustomer = new Map();

    Object.keys(nestedControls).map((customerId) => {
      const controlsForCustomer = nestedControls[customerId];
      const positionsForCustomer = nestedPositions[customerId] || {};
      return Object.keys(controlsForCustomer).map((gridPointId, i) => {
        const controlsForGridPoint = controlsForCustomer[gridPointId];
        const positionsForGridPoint = get(positionsForCustomer, gridPointId) || [];

        const filteredControlsForGridPoint = controlsForGridPoint
          .map((control) => {
            const foundPosition = positionsForGridPoint.find((position) => position.controlId === control.controlId);

            const positionMin = Capacity.asKW(foundPosition?.positionMin) || 0;
            const positionMax = Capacity.asKW(foundPosition?.positionMax) || 0;

            const mandatoryTradeDirection = mandatoryTradingDirections.find(
              (currentMandatoryTradeDirection) =>
                currentMandatoryTradeDirection.gridPointId === control.gridPointId &&
                TimeSlot.overlaps(
                  { startDateTime: slotContext.startDateTime, toDateTime: slotContext.toDateTime },
                  currentMandatoryTradeDirection.period
                )
            )?.direction;

            const maxAvailableCapacity = calculateAvailableControlPower(
              Capacity.asKW(control.namePlatePower),
              positionMin,
              positionMax,
              control.controlDirection,
              slotContext.direction,
              true,
              mandatoryTradeDirection
            );

            let availableCapacity = 0;
            // Calculate only when dealable
            if (dealableControlIds.includes(control.controlId)) {
              availableCapacity = calculateAvailableControlPower(
                Capacity.asKW(control.namePlatePower),
                positionMin,
                positionMax,
                control.controlDirection,
                slotContext.direction,
                false,
                mandatoryTradeDirection
              );

              // Round down to nearest 100kW
              availableCapacity = Math.round(availableCapacity - (availableCapacity % 100));
            }

            return {
              id: control.controlId,
              level: FlattenedIntradayLevel.CONTROL,
              controlId: control.controlId,
              gridPointId: control.gridPointId,
              customerId: control.customerId,
              controlDirection: control.controlDirection,
              controlDescription: control.controlDescription,
              totalCapacity: getNamePlatePower(control),
              totalMinPosition: positionMin,
              totalMaxPosition: positionMax,
              totalPosition: getPosition(positionMin, positionMax, control.controlDirection, slotContext.direction),
              availableCapacity,
              totalAvailableCapacity: availableCapacity,
              maxAvailableCapacity,
              namePlatePower: Capacity.asKW(control.namePlatePower),
              positionMinPercentageOfNamePlatePower: positionMin / Capacity.asKW(control.namePlatePower),
              positionMaxPercentageOfNamePlatePower: positionMax / Capacity.asKW(control.namePlatePower)
            } as FlattenedIntradayControlNode;
          })
          .filter((flattenedControl) => flattenedControl.availableCapacity !== 0 || !this.hideEmptyControlsFilterProvider.item?.checked);

        const totalAvailableCapacity = Object.values(filteredControlsForGridPoint).reduce((c, a) => c + a.availableCapacity, 0);

        if (i === 0) {
          controlsPerCustomer.set(customerId, filteredControlsForGridPoint.length);
          const totalCustomerCapacity = Object.values(controlsForCustomer).reduce(
            (c, a) => c + a.reduce((c2, a2) => c2 + getNamePlatePower(a2), 0),
            0
          );
          const totalCustomerPositionMax = Object.values(positionsForCustomer).reduce(
            (c, a) => c + a.reduce((c2, a2) => c2 + Capacity.asKW(a2.positionMax), 0),
            0
          );
          const totalCustomerPositionMin = Object.values(positionsForCustomer).reduce(
            (c, a) => c + a.reduce((c2, a2) => c2 + Capacity.asKW(a2.positionMin), 0),
            0
          );
          const totalCustomerPosition = Object.values(positionsForCustomer).reduce(
            (c, a) =>
              c +
              a.reduce((c2, a2) => {
                const control = controls.find((currentControl) => currentControl.controlId === a2.controlId);

                return (
                  c2 +
                  getPosition(Capacity.asKW(a2.positionMin), Capacity.asKW(a2.positionMax), control.controlDirection, slotContext.direction)
                );
              }, 0),
            0
          );
          // Add customer node
          flattenedNodes.push({
            id: customerId,
            level: FlattenedIntradayLevel.CUSTOMER,
            customerId,
            customerLegalName: controlsForGridPoint[0].customerLegalName,
            totalChildren: Object.values(controlsForCustomer).reduce((c, a) => c + a.length, 0),
            totalCapacity: totalCustomerCapacity,
            totalMaxPosition: totalCustomerPositionMax,
            totalMinPosition: totalCustomerPositionMin,
            totalPosition: totalCustomerPosition,
            totalAvailableCapacity,
            controlIds: flatMap(Object.values(controlsForCustomer), (currentControls) =>
              currentControls.map((control) => control.controlId)
            )
          });
        } else {
          controlsPerCustomer.set(customerId, controlsPerCustomer.get(customerId) + filteredControlsForGridPoint.length);

          const customerNode = flattenedNodes.find((node) => node.id === customerId);
          customerNode.totalAvailableCapacity = customerNode.totalAvailableCapacity + totalAvailableCapacity;
        }

        const totalGridPointCapacity = controlsForGridPoint.reduce((c, a) => c + getNamePlatePower(a), 0);
        const totalGridPointPositionMax = positionsForGridPoint.reduce((c, a) => c + Capacity.asKW(a.positionMax), 0);
        const totalGridPointPositionMin = positionsForGridPoint.reduce((c, a) => c + Capacity.asKW(a.positionMin), 0);
        const totalGridPointPosition = positionsForGridPoint.reduce((c, a) => {
          const control = controls.find((currentControl) => currentControl.controlId === a.controlId);
          return (
            c + getPosition(Capacity.asKW(a.positionMin), Capacity.asKW(a.positionMax), control.controlDirection, slotContext.direction)
          );
        }, 0);
        const totalGridPointAvailableCapacity = filteredControlsForGridPoint.reduce((c, a) => c + a.availableCapacity, 0);
        if (filteredControlsForGridPoint.length > 0) {
          flattenedNodes.push(
            {
              id: gridPointId,
              customerId,
              level: FlattenedIntradayLevel.GRID_POINT,
              gridPointDescription: controlsForGridPoint[0].gridPointDescription,
              gridPointEan: controlsForGridPoint[0].gridPointEan,
              gridPointId,
              totalChildren: filteredControlsForGridPoint.length,
              totalCapacity: totalGridPointCapacity,
              totalMinPosition: totalGridPointPositionMin,
              totalMaxPosition: totalGridPointPositionMax,
              totalPosition: totalGridPointPosition,
              totalAvailableCapacity: totalGridPointAvailableCapacity,
              controlIds: controlsForGridPoint.map((currentControl) => currentControl.controlId)
            },
            // Add nodes for controls
            ...filteredControlsForGridPoint
          );
        }
      });
    });

    return flattenedNodes
      .map((node) => {
        if (node.level === FlattenedIntradayLevel.CUSTOMER) {
          node.totalChildren = controlsPerCustomer.get(node.customerId);
          return node;
        } else {
          return node;
        }
      })
      .filter((node) => node.level !== FlattenedIntradayLevel.CUSTOMER || node.totalChildren > 0);
  }

  /**
   * Ensure that the full tree is available if any children are selected.
   * Preserve ordering of search result.
   */
  private restoreTree(result: FuseResult<FlattenedIntradayNode>[], fullTree: FlattenedIntradayNode[]): FlattenedIntradayNode[] {
    const resultItems = result.map((currentResult) => currentResult.item);

    const fullGraph = this.toGraph(fullTree);
    const filteredGraphByCustomer = fullGraph.map((node) => this.filterNodeChildren(node, resultItems)).filter((node) => !!node);

    return this.fromGraph(filteredGraphByCustomer);
  }

  /**
   * Filter nodes based on the allowedNodes list
   * If the node is present in the list, all children are included without filtering.
   *
   * If the node is not present, the children are filtered.
   * If one or more children are not filtered, the node is retained.
   * Otherwise it is removed
   */
  private filterNodeChildren(node: IntradayGraphNode, allowedNodes: FlattenedIntradayNode[]): IntradayGraphNode | null {
    if (allowedNodes.includes(node.node)) {
      // Node is in result, include all children without filtering
      return node;
    }

    const filteredChildren = node.children.filter((child) => this.filterNodeChildren(child, allowedNodes));

    if (filteredChildren.length === 0) {
      // Lowest node or no children
      return allowedNodes.includes(node.node) ? node : null;
    }

    return {
      ...node,
      children: filteredChildren
    };
  }

  /**
   * Flatmap a graph created by this.toGraph() to a list of FlattenedIntraDayNode
   */
  fromGraph(items: IntradayGraphNode[]): FlattenedIntradayNode[] {
    return flatMapDeep(items, (item) => [item.node, ...this.fromGraph(item.children)]);
  }

  /**
   * Create a graph with customer -> gridPoint -> control
   */
  toGraph(items: FlattenedIntradayNode[]): IntradayGraphNode[] {
    const result = this.toGraphRecursive({} as any, items);
    return result.children;
  }

  private toGraphRecursive(node: FlattenedIntradayNode, children: FlattenedIntradayNode[]): IntradayGraphNode {
    const currentChildren: IntradayGraphNode[] = [];

    let currentChildNode: FlattenedIntradayNode;
    let currentChildChildren: FlattenedIntradayNode[] = [];

    children.forEach((item) => {
      if (!currentChildNode) {
        currentChildNode = item;
      } else {
        if (item.level > currentChildNode.level) {
          currentChildChildren.push(item);
        } else {
          // Found higher level node
          currentChildren.push(this.toGraphRecursive(currentChildNode, currentChildChildren));
          currentChildNode = item;
          currentChildChildren = [];
        }
      }
    });

    if (currentChildNode) {
      currentChildren.push(this.toGraphRecursive(currentChildNode, currentChildChildren));
    }

    return {
      node,
      children: currentChildren
    };
  }
}

export function getNamePlatePower(control: IntradayControl): number {
  if (control.controlDirection === IntradayControlDirection.CONSUMPTION) {
    return Capacity.asKW(control.namePlatePower);
  } else {
    return -Capacity.asKW(control.namePlatePower);
  }
}
