import { Directive, HostBinding, Inject, Optional } from '@angular/core';
import { MatHeaderRowDef, MatRowDef } from '@angular/material/table';
import { isEmpty } from 'lodash-es';
import { CDK_TABLE } from '@angular/cdk/table';
import { OnDestroyMixin } from '../../core/common/on-destroy.mixin';
import { MixinBase } from '../../core/common/constructor-type.mixin';
import { takeUntil } from 'rxjs/operators';
import { TableSelectionModel } from './table-selection-model';

@Directive({
  selector: '[phFlexHideRowWithNoColumns]'
})
export class HideRowWithNoColumnsDirective extends OnDestroyMixin(MixinBase) {
  @HostBinding('style.display')
  display: string;

  constructor(
    @Optional() private headerRowDef: MatHeaderRowDef,
    @Optional() private rowDef: MatRowDef<any>,
    private selectionModel: TableSelectionModel<any>,
    @Inject(CDK_TABLE) public _table?: any
  ) {
    super();

    this.selectionModel.hasSelection$.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      // Support both headerRowDef and rowDef as components
      this.display = isEmpty(this.headerRowDef?.columns || this.rowDef?.columns) ? 'none' : undefined;
    });
  }
}
