import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SetFormDisabled, SetFormEnabled, UpdateFormValue } from '@ngxs/form-plugin';
import { RouterNavigation } from '@ngxs/router-plugin';
import { Action, Selector, SelectorOptions, State, StateContext } from '@ngxs/store';
import { isSameDay, parseISO } from 'date-fns';
import format from 'date-fns/format';
import {
  CUSTOMER_ID_SESSION_STORAGE_KEY,
  CUSTOMER_STORAGE_TOKEN,
  DISPATCH_SCHEDULE_GRID_POINT_ID_SESSION_STORAGE_KEY,
  DISPATCH_SCHEDULE_SELECTED_DATE_SESSION_STORAGE_KEY,
  findParameterValue,
  handleStateToRouteSync,
  NgXsFormModel,
  normalizeToDate
} from 'flex-app-shared';
import { DispatchingScheduleToolbarActivate, DispatchingScheduleToolbarDeactivate } from './dispatching-schedule-toolbar.actions';

export class DispatchScheduleToolbarStateModel {
  active: boolean = false;
  isInitialized: boolean = false;

  filters = NgXsFormModel.defaults({
    selectedDate: null,
    gridPointId: null,
    customerId: null
  });

  lastDataDate: Date;
  busy: boolean = false;
}

@State({
  name: 'dispatchScheduleToolbar',
  defaults: new DispatchScheduleToolbarStateModel()
})
@SelectorOptions({
  injectContainerState: false,
  suppressErrors: false
})
@Injectable({
  providedIn: 'root'
})
export class DispatchingScheduleToolbarState {
  static basePath = '/dispatching-schedule';

  constructor(@Inject(CUSTOMER_STORAGE_TOKEN) private storage: Storage, private router: Router) {}

  @Selector([DispatchingScheduleToolbarState])
  static selectedDate(model: DispatchScheduleToolbarStateModel): Date {
    return model.filters?.model?.selectedDate;
  }

  @Selector([DispatchingScheduleToolbarState])
  static selectedCustomerId(model: DispatchScheduleToolbarStateModel): string {
    return model.filters?.model?.customerId;
  }

  @Selector([DispatchingScheduleToolbarState])
  static selectedGridPointId(model: DispatchScheduleToolbarStateModel): string {
    return model.filters?.model?.gridPointId;
  }

  @Selector([DispatchingScheduleToolbarState])
  static busy(state: DispatchScheduleToolbarStateModel): boolean {
    return state.busy;
  }

  @Selector([DispatchingScheduleToolbarState])
  static active(state: DispatchScheduleToolbarStateModel): boolean {
    return state.active;
  }

  @Selector([DispatchingScheduleToolbarState])
  static isTodaySelected(model: DispatchScheduleToolbarStateModel): boolean {
    return model.filters?.model?.selectedDate && isSameDay(normalizeToDate(model.filters.model.selectedDate), new Date());
  }

  @Selector([DispatchingScheduleToolbarState])
  static lastDataDate(model: DispatchScheduleToolbarStateModel): Date {
    return model.lastDataDate;
  }

  @Selector([DispatchingScheduleToolbarState])
  static filters(model: DispatchScheduleToolbarStateModel): NgXsFormModel<any> {
    return model?.filters;
  }

  @Action(DispatchingScheduleToolbarActivate)
  activate({ patchState }: StateContext<DispatchScheduleToolbarStateModel>): void {
    patchState({
      active: true
    });
  }

  @Action(DispatchingScheduleToolbarDeactivate)
  deactivate({ patchState }: StateContext<DispatchScheduleToolbarStateModel>): void {
    patchState({
      active: false
    });
  }

  @Action(RouterNavigation)
  handleRouterNavigation(
    { patchState, getState, dispatch }: StateContext<DispatchScheduleToolbarStateModel>,
    { routerState }: RouterNavigation
  ): void {
    if (!getState().active) {
      // Reset
      patchState({
        filters: NgXsFormModel.defaults({
          selectedDate: null,
          gridPointId: null,
          customerId: null
        })
      });
      this.storage.removeItem(DISPATCH_SCHEDULE_SELECTED_DATE_SESSION_STORAGE_KEY);
      return;
    }
    const selectedDate =
      findParameterValue(routerState, 'selectedDate') ?? this.storage.getItem(DISPATCH_SCHEDULE_SELECTED_DATE_SESSION_STORAGE_KEY);
    const customerId = findParameterValue(routerState, 'customerId') ?? this.storage.getItem(CUSTOMER_ID_SESSION_STORAGE_KEY);
    const gridPointId = findParameterValue(routerState, 'gridPointId');

    patchState({
      filters: {
        ...getState().filters,
        model: {
          customerId,
          gridPointId,
          selectedDate: (selectedDate && parseISO(selectedDate)) || new Date()
        }
      }
    });

    this.syncStorage(selectedDate, customerId, gridPointId);
  }

  @Action(UpdateFormValue)
  syncRouteAndSetForm({ getState, dispatch }: StateContext<DispatchScheduleToolbarStateModel>, event: UpdateFormValue): any {
    if (!getState().active) {
      return;
    }
    const { customerId, gridPointId, selectedDate } = getState().filters.model;

    if (!event.payload.path.includes('dispatchScheduleToolbar.filters')) {
      return;
    }

    const pageSubPath =
      this.router.url
        .substr(DispatchingScheduleToolbarState.basePath.length + 1)
        .split('/')
        .filter((a) => !!a)[0] ?? 'schedule';

    if (pageSubPath !== 'schedule' && pageSubPath !== 'positions') {
      return;
    }

    handleStateToRouteSync(
      this.router,
      [
        `${DispatchingScheduleToolbarState.basePath}/${pageSubPath}`,
        `${DispatchingScheduleToolbarState.basePath}/${pageSubPath}/:customerId`,
        `${DispatchingScheduleToolbarState.basePath}/${pageSubPath}/:customerId/:selectedDate`,
        `${DispatchingScheduleToolbarState.basePath}/${pageSubPath}/:customerId/:selectedDate/:gridPointId`
      ],
      {
        customerId,
        gridPointId,
        selectedDate: format(normalizeToDate(selectedDate), 'yyyy-MM-dd')
      }
    );

    this.syncStorage(selectedDate, customerId, gridPointId);

    if (!customerId) {
      dispatch(new SetFormDisabled('dispatchSchedule.filters.gridPointId'));
      return;
    }
    dispatch(new SetFormEnabled('dispatchSchedule.filters.gridPointId'));
  }

  private syncStorage(selectedDate: string | null, customerId: string | null, gridPointId: string | null): void {
    this.storage.setItem(DISPATCH_SCHEDULE_SELECTED_DATE_SESSION_STORAGE_KEY, selectedDate || '');
    this.storage.setItem(CUSTOMER_ID_SESSION_STORAGE_KEY, customerId || '');
    this.storage.setItem(DISPATCH_SCHEDULE_GRID_POINT_ID_SESSION_STORAGE_KEY, gridPointId || '');
  }
}
