<mat-card *ngIf="editSlotContext$ | async; let editSlotContext">
  <mat-card-header>
    <mat-card-title i18n="@@intraday-headerSlotDealTime">
      <ng-container *ngIf="editSlotContext.direction === Direction.CONSUMPTION">Buy order for</ng-container>
      <ng-container *ngIf="editSlotContext.direction === Direction.PRODUCTION">Sell order for</ng-container>
      {{ editSlotContext.startDateTime | date : 'shortTime' }} -
      {{ editSlotContext.toDateTime | date : 'shortTime' }}
    </mat-card-title>
    <mat-card-subtitle>
      <span class="header-price-text" i18n="@@intraday-headerPrice">Price:</span>
      <app-intraday-slot-delta-icon [slot]="slot$ | async" [direction]="editSlotContext.direction"></app-intraday-slot-delta-icon>
      <span
        class="price"
        [class.bid]="editSlotContext.direction === Direction.PRODUCTION"
        [class.ask]="editSlotContext.direction === Direction.CONSUMPTION"
      >
        {{ editSlotContext.pricePerMW | number : '0.2-2' }} €/MWh</span
      >
    </mat-card-subtitle>
    <mat-card-actions>
      <mat-slide-toggle #hideEmptyControlsToggle [checked]="true" i18n="@@intraday-labelHideControls">
        Hide components without available volume
      </mat-slide-toggle>
    </mat-card-actions>
  </mat-card-header>
  <mat-card-content>
    <div>
      <ph-flex-table-filter [autofocus]="true"></ph-flex-table-filter>
    </div>
    <table
      mat-table
      [dataSource]="dataSource"
      [trackBy]="trackBy"
      editable
      [class.buy]="editSlotContext.direction === Direction.CONSUMPTION"
      [class.sell]="editSlotContext.direction === Direction.PRODUCTION"
      [class.no-padding]="tableHasNoPadding$ | async"
    >
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let node">
          <div class="description">
            <div class="node-padding expand-collapse" [ngSwitch]="isExpandable(0, node)">
              <ng-container *ngSwitchCase="false">
                <button mat-icon-button disabled></button>
              </ng-container>
              <ng-container *ngSwitchCase="true">
                <button ph-flex-inline-icon-button [style.visibility]="treeControl.isExpandable(node) ? 'visible' : 'hidden'">
                  <mat-icon [svgIcon]="treeControl.isExpanded(node) ? 'ic-old:minus-square-o' : 'ic-old:plus-square-o'"></mat-icon>
                </button>
              </ng-container>
            </div>
            <div class="control-icon" *ngIf="node.level === FlattenedIntraDayLevel.CONTROL" [ngSwitch]="node.controlDirection">
              <mat-icon *ngSwitchCase="IntraDayControlDirection.PRODUCTION" class="production" svgIcon="ic-old:arrow_upward"></mat-icon>
              <mat-icon *ngSwitchCase="IntraDayControlDirection.CONSUMPTION" class="consumption" svgIcon="ic-old:arrow_downward"></mat-icon>
            </div>
            <div [ngSwitch]="node.level" class="description-text">
              <ng-container *ngSwitchCase="FlattenedIntraDayLevel.CUSTOMER"
                >{{ node.customerLegalName }}
                <em>
                  <ng-container *ngIf="!treeControl.isExpanded(node)">
                    <ng-container *ngTemplateOutlet="slotControls; context: { node: node }"></ng-container>
                  </ng-container>
                </em>
              </ng-container>
              <ng-container *ngSwitchCase="FlattenedIntraDayLevel.GRID_POINT"
                >{{ node.gridPointDescription }} <em>| EAN: {{ node.gridPointEan }}</em>
                <ng-container *ngIf="!treeControl.isExpanded(node)">
                  <ng-container *ngTemplateOutlet="slotControls; context: { node: node }"></ng-container>
                </ng-container>
              </ng-container>
              <ng-container *ngSwitchCase="FlattenedIntraDayLevel.CONTROL">
                {{ node.controlDescription }}
              </ng-container>
            </div>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="availableCapacityPercentage">
        <th mat-header-cell *matHeaderCellDef>
          <ng-container i18n="@@intraday-tableHeaderPosition">
            <div>Position</div>
            [%]
          </ng-container>
        </th>
        <td
          mat-cell
          *matCellDef="let node"
          class="number"
          [ngSwitch]="node.level"
          matTooltip="nameplate power: {{ node.namePlatePower }} kW, position: {{
            node.totalMinPosition === node.totalMaxPosition ? node.totalMaxPosition : node.totalMinPosition + ' - ' + node.totalMaxPosition
          }} kW"
          i18n-matTooltip="@@intraday-control-position"
          [matTooltipDisabled]="node.level !== FlattenedIntraDayLevel.CONTROL"
        >
          <ng-container *ngSwitchCase="FlattenedIntraDayLevel.CONTROL">
            <ng-container *ngSwitchCase="FlattenedIntraDayLevel.CONTROL">
              <ng-container *ngIf="node.positionMinPercentageOfNamePlatePower !== node.positionMaxPercentageOfNamePlatePower"
                >{{ node.positionMinPercentageOfNamePlatePower | percent }} -
                {{ node.positionMaxPercentageOfNamePlatePower | percent }}</ng-container
              >
              <ng-container *ngIf="node.positionMinPercentageOfNamePlatePower === node.positionMaxPercentageOfNamePlatePower">{{
                node.positionMinPercentageOfNamePlatePower | percent
              }}</ng-container>
            </ng-container>
          </ng-container>
          <ng-container *ngSwitchDefault></ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="dealPrice">
        <th mat-header-cell *matHeaderCellDef>
          <ng-container i18n="@@intraday-tableHeaderDealAmount">
            <div>Deal amount</div>
            [€]
          </ng-container>
        </th>
        <td mat-cell *matCellDef="let node" class="number" [ngSwitch]="node.level">
          <ng-container *ngSwitchCase="FlattenedIntraDayLevel.CONTROL"
            >{{ getDealPrice(editSlotContext, controlFormGroup.get(node.controlId)?.value) | number : '0.2-2' }}
          </ng-container>
          <ng-container *ngSwitchCase="FlattenedIntraDayLevel.GRID_POINT"
            >{{ getDealPrice(editSlotContext, dealTotalPerGridPoint[node.gridPointId]) | number : '0.2-2' }}
          </ng-container>
          <ng-container *ngSwitchCase="FlattenedIntraDayLevel.CUSTOMER"
            >{{ getDealPrice(editSlotContext, dealTotalPerCustomer[node.customerId]) | number : '0.2-2' }}
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="max">
        <th mat-header-cell *matHeaderCellDef>
          <ng-container i18n="@@intraday-tableHeaderMax"> Max </ng-container>
        </th>
        <td mat-cell class="number" *matCellDef="let node">
          {{ node.maxAvailableCapacity }}
        </td>
      </ng-container>
      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef>
          <ng-container i18n="@@intraday-tableHeaderPosition">
            <div>Position</div>
            [kW]
          </ng-container>
        </th>
        <td class="number" mat-cell *matCellDef="let node">
          {{ node.totalPosition }}
        </td>
      </ng-container>
      <ng-container matColumnDef="availableCapacity">
        <th mat-header-cell *matHeaderCellDef>
          <ng-container i18n="@@intraday-tableHeaderAvailableCapacity">
            <div>Dealable</div>
            [kW]
          </ng-container>
        </th>
        <td class="number" mat-cell *matCellDef="let node">
          <div>
            <mat-basic-chip *ngIf="getAvailableOffset(node); let availableOffset" (click)="applyAvailableOffset(node, $event)">
              <ng-container *ngIf="availableOffset > 0">+</ng-container>
              {{ availableOffset | number : '0.0-0' }}
            </mat-basic-chip>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="deal">
        <th mat-header-cell *matHeaderCellDef>
          <ng-container i18n="@@intraday-tableHeaderDeal">
            <div>
              <ng-container *ngIf="editSlotContext.direction === Direction.CONSUMPTION">Buy</ng-container>
              <ng-container *ngIf="editSlotContext.direction === Direction.PRODUCTION">Sell</ng-container>
            </div>
            [kW]
          </ng-container>
        </th>
        <td
          mat-cell
          *matCellDef="let node"
          class="number edit"
          [matPopoverEdit]="dealEdit"
          [matPopoverEditDisabled]="node.level !== 2"
          [appSlotFocus]="node"
          matPopoverEditTabOut
          [class.invalid]="isNodeInvalid(node) || undefined"
          [class.warning]="isNodeWarning(node) || undefined"
        >
          <button matEditOpen [class.disabled]="node.level !== FlattenedIntraDayLevel.CONTROL" (click)="rowClick(node, $event)">
            {{ getDealTotal(node) | number : '0.0-0' }}
          </button>

          <ng-template #dealEdit>
            <form
              matEditLens
              matEditLensClickOutBehavior="close"
              [class.warning]="isNodeWarning(node) || undefined"
              [formGroup]="controlFormGroup"
              class="intra-day-edit-lens-form"
              [class.buy]="editSlotContext.direction === Direction.CONSUMPTION"
              [class.sell]="editSlotContext.direction === Direction.PRODUCTION"
            >
              <mat-form-field
                [phFlexControlErrorOverlay]="overlayTemplate"
                [phFlexControlErrorOverlayControl]="controlFormGroup.get(node.controlId)"
                [phFlexControlErrorOverlayForceShow]="isNodeWarning(node)"
                [phFlexControlErrorOverlayClasses]="isNodeWarning(node) ? ['warning'] : []"
                [context]="{ node: node }"
              >
                <mat-hint i18n="@@intraday-hintAvailableCapacity">Dealable: {{ node.availableCapacity }} kW</mat-hint>
                <ph-flex-number-input [formControlName]="node.controlId"></ph-flex-number-input>
              </mat-form-field>
            </form>
          </ng-template>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let node; columns: displayedColumns"
        [class.level-0]="node.level === FlattenedIntraDayLevel.CUSTOMER"
        [class.level-1]="node.level === FlattenedIntraDayLevel.GRID_POINT"
        [class.level-2]="node.level === FlattenedIntraDayLevel.CONTROL"
        [ngClass]="treeControl.isExpanded(node) ? 'expanded' : ''"
        (click)="rowClick(node, $event)"
      ></tr>
    </table>
  </mat-card-content>
  <mat-card-actions>
    <button mat-flat-button color="warn" (click)="clear()">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:erase"></mat-icon>
        <span i18n="@@intraday-buttonClear">Clear</span>
      </ph-flex-vertical-center>
    </button>
  </mat-card-actions>
</mat-card>

<ng-template #overlayTemplate="cdkPortal" cdkPortal let-control="control" let-node="node">
  <ng-container *ngIf="node">
    <ng-container *ngIf="control?.errors; let errors">
      <mat-error *ngIf="errors.min; let error" i18n="@@intraday-errorDealAmountMin"
      >Deal amount cannot be lower than {{ error.min }}</mat-error
      >
      <mat-error *ngIf="errors.invalidNumberOfDecimals" i18n="@@intraday-errorDealAmountInvalidNumberOfDecimals"
      >Deal amount should be a whole number
      </mat-error>
      <mat-error *ngIf="errors.max" i18n="@@intraday-errorDealAmountMax"
      >Deal amount cannot be larger than the available power ({{ node.availableCapacity }} kW)
      </mat-error>
      <mat-error *ngIf="errors.invalidNumber" i18n="@@intraday-errorDealAmountInvalidNumber"> Not a valid number </mat-error>
    </ng-container>
    <ng-container *ngIf="!control?.errors">
      <ng-container i18n="@@intraday-warningPositionHigherThanNameplatePower"
      >Position will be higher than the specified nameplate power
      </ng-container>
      <ng-container
        *ngIf="
        (node.totalPosition + control.value) / node.namePlatePower > 1 && (node.totalPosition + control.value) / node.namePlatePower < 1.01
      "
      >
        ({{ node.totalPosition + control.value - node.namePlatePower | number : '0.0-1' }} kW)
      </ng-container>
      <ng-container *ngIf="control.value / node.availableCapacity >= 1.01">
        ({{ (node.totalPosition + control.value) / node.namePlatePower | percent : '0.0-0' }})
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #slotControls let-node="node">
  <ng-container i18n="@@intraday-slotControls">
    | {{ node.totalChildren }}
    { node.totalChildren, plural, =1 { component } other { components }}
  </ng-container>
</ng-template>
