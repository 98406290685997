import {
  addHours,
  addMinutes,
  addSeconds,
  differenceInHours,
  differenceInMilliseconds,
  differenceInMinutes,
  getSeconds,
  isAfter,
  isSameSecond,
  min,
  startOfMinute
} from 'date-fns';
import { BehaviorSubject, interval, merge, Observable, of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

const hourI18n = $localize`:@@incidentReserveAvailability-availabilityDeadlineHourUnit:h`;

function getNextTransition(target: Date, now: Date): Date | undefined {
  // Support targets that do not happen on a clock minute transition
  const secondsOffset = getSeconds(target);

  const startOfNextMinute = addSeconds(startOfMinute(addMinutes(now, 1)), secondsOffset);

  if (isSameSecond(startOfNextMinute, now)) {
    return min([addMinutes(startOfNextMinute, 1), target]);
  } else if (isAfter(target, now)) {
    return min([startOfNextMinute, target]);
  }
  // Emit again after 1 minute when the target has already passed
  return startOfNextMinute;
}

export function getCountdownTimer$(target: Date, nowProvider: () => Date = () => new Date()): Observable<string> {
  const intervalSubject = new BehaviorSubject<Date>(getNextTransition(target, nowProvider()));

  function getText(): string {
    const now = nowProvider();

    const hours = differenceInHours(target, now);
    const minutes = differenceInMinutes(target, addHours(now, hours));

    if (hours > 0) {
      return `${hours}${hourI18n} ${minutes}m`;
    } else if (minutes > 0) {
      return `${minutes}m`;
    } else if (isAfter(target, now)) {
      return `< 1m`;
    } else {
      return '0s';
    }
  }

  return merge(
    of(getText()),
    intervalSubject.pipe(
      switchMap(() => interval(differenceInMilliseconds(getNextTransition(target, nowProvider()), nowProvider())).pipe(first())),
      map(() => {
        const next = getNextTransition(target, nowProvider());
        if (next) {
          intervalSubject.next(next);
        }
        return getText();
      })
    )
  );
}
