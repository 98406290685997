<div class="ph-g-container-page">
  <mat-card
    class="ph-g-card-body-main ph-g-card-body-main-responsive ph-g-card-body-main-l ph-g-card-body-table ph-g-card-body-table-paginator ph-g-card-without-margins"
  >
    <mat-card-title>
      {{ this.cardTitleDate$ | async }}
      <ph-service-agreement-display-label></ph-service-agreement-display-label>
    </mat-card-title>
    <mat-card-content>
      <ph-flex-loading-context [isLoading]="isBusy$ | async" class="ph-g-card-loading-context">
        <table mat-table matSort matSortActive="date" matSortDirection="desc" [dataSource]="dataSource" class="ph-g-table">
          <ng-container matColumnDef="direction">
            <th *matHeaderCellDef mat-header-cell mat-sort-header i18n="@@incidentReserveActivations-direction">Direction</th>
            <td mat-cell *matCellDef="let activation">
              <div class="icon-td" [ngSwitch]="activation.direction">
                <ng-container *ngSwitchCase="direction.DOWNWARDS">
                  <mat-icon class="status-down" svgIcon="ic-old:arrow_downward"></mat-icon>
                  <ng-container i18n="@@incidentReserveActivations-downwards">Downwards</ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="direction.UPWARDS">
                  <mat-icon class="status-up" svgIcon="ic-old:arrow_upward"></mat-icon>
                  <ng-container i18n="@@incidentReserveActivations-upwards">Upwards</ng-container>
                </ng-container>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="date">
            <th *matHeaderCellDef mat-header-cell mat-sort-header i18n="@@incidentReserveActivations-date">Date</th>
            <td mat-cell *matCellDef="let activation">
              <div class="icon-td">
                {{ activation.period.startDateTime | date: 'shortDate' }}
                <ng-container *ngIf="!isSameDay(activation.period)"> - {{ activation.period.toDateTime | date: 'shortDate' }}</ng-container>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="timeSlot">
            <th *matHeaderCellDef mat-header-cell mat-sort-header i18n="@@incidentReserveActivations-period">Period</th>
            <td mat-cell *matCellDef="let activation">
              {{ activation.period.startDateTime | date: 'shortTime' }}
              - {{ activation.period.toDateTime | date: 'shortTime' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="availabilityType">
            <th *matHeaderCellDef mat-header-cell mat-sort-header i18n="@@incidentReserveActivations-delivery">Delivery</th>
            <td mat-cell *matCellDef="let activation">
              <ng-container [ngSwitch]="activation.cancelled">
                <ng-container *ngSwitchCase="false" [ngSwitch]="activation.availabilityType">
                  <span *ngSwitchCase="availabilityType.VOLUNTARY" i18n="@@incidentReserveActivations-voluntary">Voluntary</span>
                  <span *ngSwitchCase="availabilityType.ASSURED" i18n="@@incidentReserveActivations-assured">Assured</span>
                  <span *ngSwitchCase="availabilityType.OFF" i18n="@@incidentReserveActivations-off">Off</span>
                </ng-container>
                <ng-container *ngSwitchCase="true">
                  <span i18n="@@incidentReserveActivations-cancelled">Cancelled</span>
                </ng-container>
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="target">
            <th *matHeaderCellDef mat-header-cell mat-sort-header i18n="@@incidentReserveActivations-target">Target (MW)</th>
            <td mat-cell *matCellDef="let activation">
              <ng-container [ngSwitch]="activation.target !== null && !activation.cancelled">
                <ng-container *ngSwitchCase="true">
                  {{ activation.target | capacityNumber }}
                </ng-container>
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="detail">
            <th *matHeaderCellDef mat-header-cell></th>
            <td mat-cell *matCellDef="let activation">
              <ph-flex-vertical-center>
                <button mat-icon-button (click)="navigateToDetailPage(activation)" *ngIf="canNavigateToDetailPage(activation)">
                  <mat-icon svgIcon="ic-new:chevron-right"></mat-icon>
                </button>
              </ph-flex-vertical-center>
            </td>
          </ng-container>

          <tr *matNoDataRow class="ph-flex-no-data-row">
            <td colspan="5" *ngIf="!(isBusy$ | async)" i18n="@@incidentReserveActivations-noData">
              No activations for this period and service agreement.
            </td>
          </tr>
          <tr mat-header-row *matHeaderRowDef="columns"></tr>
          <tr mat-row *matRowDef="let row; columns: columns"></tr>
        </table>
        <mat-paginator [showFirstLastButtons]="true" [pageSize]="defaultPageSize" [pageSizeOptions]="[10, 20, 30, 50, 100]"></mat-paginator>
      </ph-flex-loading-context>
    </mat-card-content>
  </mat-card>
</div>
