import { Component, OnInit, ViewChild } from '@angular/core';
import { CdkColumnDef } from '@angular/cdk/table';
import { MatCellDef, MatColumnDef, MatHeaderCellDef } from '@angular/material/table';
import { TableSelectionModel } from './table-selection-model';
import { selectColumn } from './table-selection-header-columns';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'ph-flex-table-select-checkbox-cell',
  styles: [
    `
      .select-column {
        width: 32px;
        /*  TODO tune, checkbox is 16px + padding */
      }
    `
  ],
  template: `
    <th mat-header-cell *matHeaderCellDef class="select-column">
      <mat-checkbox
        (click)="handleHeaderCheckboxClick($event)"
        [checked]="tableSelectionModel.toggleAllChecked$ | async"
        [indeterminate]="tableSelectionModel.toggleAllIndeterminate$ | async"
      ></mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row; let i = index" class="select-column">
      <mat-checkbox
        (change)="handleCellCheckboxChange(row, i, $event)"
        [checked]="tableSelectionModel.isChecked$(row, i) | async"
      ></mat-checkbox>
    </td>
  `,
  providers: [
    { provide: CdkColumnDef, useExisting: TableSelectCheckboxCellComponent },
    { provide: 'MAT_SORT_HEADER_COLUMN_DEF', useExisting: TableSelectCheckboxCellComponent }
  ]
})
export class TableSelectCheckboxCellComponent extends MatColumnDef implements OnInit {
  @ViewChild(MatHeaderCellDef)
  headerCell;

  @ViewChild(MatCellDef)
  cell;

  ngOnInit(): void {
    this.name = selectColumn;
  }

  constructor(public tableSelectionModel: TableSelectionModel<any>) {
    super();
  }

  handleHeaderCheckboxClick(event: Event): void {
    // Click is used instead of change since the internal state of the checkbox since there seem to be issues when the internal checkbox state otherwise.
    event.preventDefault();
    event.stopPropagation();
    this.tableSelectionModel.togglePage();
  }

  handleCellCheckboxChange(row: any, index: number, $event: MatCheckboxChange): void {
    if ($event.checked !== this.tableSelectionModel.isChecked(row, index)) {
      this.tableSelectionModel.toggle(row, index);
    }
  }
}
