import { Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FILTER_FORM, MixinBase, OnDestroyMixin, PeriodUnit, R3ServiceAgreement } from 'flex-app-shared';
import { Observable } from 'rxjs';
import { IncidentReserveAvailabilityFacade } from '../../../store/incident-reserve-availability/incident-reserve-availability.facade';

@Component({
  selector: 'app-filters-incident-reserve-availability',
  templateUrl: './filters-incident-reserve-availability.component.html',
  styleUrls: ['./filters-incident-reserve-availability.component.scss']
})
export class FiltersIncidentReserveAvailabilityComponent extends OnDestroyMixin(MixinBase) {
  constructor(
    @Inject(FILTER_FORM) public filterForm: UntypedFormGroup,
    public facade: IncidentReserveAvailabilityFacade,
    @Optional() @Inject(MAT_DIALOG_DATA) private dialogData: any
  ) {
    super();
  }

  get inDialog(): boolean {
    return !!this.dialogData;
  }

  availablePeriodUnits = [PeriodUnit.YEAR, PeriodUnit.MONTH];
  PeriodUnit = PeriodUnit;
  selectableServiceAgreements$ = this.facade.selectableServiceAgreements$;

  min$: Observable<any> = this.facade.serviceAgreementsPeriodMin$;
  max$: Observable<any> = this.facade.serviceAgreementsPeriodMax$;
}
