<app-announcements [noMessages]="days.length === 0">
  <ng-container *ngFor="let day of days">
    <h2 class="day-title">{{ day | date: 'mediumDate' }}</h2>
    <ng-container *ngFor="let announcement of announcementsPerDay[day]">
      <app-announcement
        [announcement]="announcement"
        [class.first-child-announcement]="days.length >= 1"
        [unread]="isUnreadAnnouncement(announcement.id)"
        class="intraday"
      ></app-announcement>
    </ng-container>
  </ng-container>
</app-announcements>
