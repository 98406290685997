import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { IntradayState } from './intraday-state.service';
import { SlotsPageActivatedEvent, SlotsPageDeactivatedEvent } from './intraday.actions';

@Injectable({
  providedIn: 'root'
})
export class CanActivateSlotsPage {
  constructor(private store: Store, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // Reject activation if no slots are available.
    if (!this.store.selectSnapshot(IntradayState.editSlotContext)) {
      return this.router.navigate(['intraday']);
    }
    this.store.dispatch(new SlotsPageActivatedEvent());
    return true;
  }
}

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateSlotsPage {
  constructor(private store: Store) {}

  canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.store.dispatch(new SlotsPageDeactivatedEvent());

    return true;
  }
}
