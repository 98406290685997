import { AfterContentInit, Component, ContentChild, Input, OnInit } from '@angular/core';
import { TopBarService } from './top-bar.service';
import { MatMenu } from '@angular/material/menu';

@Component({
  selector: 'ph-flex-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class FlexTopBarComponent implements AfterContentInit {
  @Input()
  username: string;

  @ContentChild(MatMenu)
  userMenu: MatMenu;

  constructor(private topBarService: TopBarService) {}

  toggleMobileMenu(): any {
    this.topBarService.openMobileSidebar();
  }

  ngAfterContentInit(): void {
    this.userMenu.panelClass = 'ph-flex-mat-menu-override';
  }
}
