<ng-container [ngSwitch]="firstSegment()">
  <ng-container *ngSwitchCase="'imbalance-config'">
    <ng-container i18n="@@menuItem.imbalanceConfig">Imbalance</ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'incident-reserve-availability'">
    <ng-container i18n="@@menuItem.incidentReserveAvailability">Incident reserve</ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'schedule'">
    <span i18n="@@menuItem.dispatchingSchedule">Dispatching schedule</span>
  </ng-container>
  <ng-container *ngSwitchCase="'api-keys'">
    <span i18n="@@menuItem.apiKeys">API keys</span>
  </ng-container>
  <ng-container *ngSwitchCase="'intraday'">
    <span i18n="@@menuItem.intraday">Intraday</span>
  </ng-container>
  <ng-container *ngSwitchCase="'flex-overview'">
    <span i18n="@@menuItem.flexOverview">Flex overview</span>
  </ng-container>
  <ng-container *ngSwitchCase="'messages'">
    <span i18n="@@menuItem.messages">Messages overview</span>
  </ng-container>
  <ng-container *ngSwitchDefault [ngSwitch]="type">
    <ng-container *ngSwitchCase="'toolbar'">Flex application - Powerhouse</ng-container>
    <ng-container *ngSwitchCase="'sidebar'">{{ page }}</ng-container>
  </ng-container>
</ng-container>
