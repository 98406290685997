import { Injectable } from '@angular/core';
import { addMilliseconds, differenceInMilliseconds, endOfDay } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class DayChangeService {
  registerOnDayChange(cb: () => void, minimumMillisecondsBeforeEndOfDay: number = 1000): DayChangeHandler {
    return new DayChangeHandler(minimumMillisecondsBeforeEndOfDay, cb);
  }
}

export class DayChangeHandler {
  dayChangeHandle = null;

  constructor(private minimumMillisecondsBeforeEndOfDay: number, private cb: () => void) {}

  reset(): void {
    if (this.dayChangeHandle) {
      clearTimeout(this.dayChangeHandle);
    }

    const endOfCurrentOrNextDayWithMargin = this.calculateEndOfCurrentOrNextDayWithMargin(this.minimumMillisecondsBeforeEndOfDay);

    this.dayChangeHandle = setTimeout(() => {
      this.dayChangeHandle = null;
      this.cb();
      this.reset();
    }, endOfCurrentOrNextDayWithMargin);
  }

  private calculateEndOfDay(now: Date): number {
    const currentEndOfDay = endOfDay(now);
    return differenceInMilliseconds(currentEndOfDay, now);
  }

  private calculateEndOfCurrentOrNextDayWithMargin(minimumMillisecondsBeforeEndOfDay: number): number {
    const now = this.getNow();
    let remainingMillisecondsBeforeRecentDealsClear = this.calculateEndOfDay(now);

    if (remainingMillisecondsBeforeRecentDealsClear < minimumMillisecondsBeforeEndOfDay) {
      remainingMillisecondsBeforeRecentDealsClear = this.calculateEndOfDay(addMilliseconds(now, 1000)) + 1000;
    }
    return remainingMillisecondsBeforeRecentDealsClear;
  }

  private getNow(): Date {
    return new Date();
  }
}
