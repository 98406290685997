<mat-card-title i18n="@@incidentReserveAvailabilityDialog-title" mat-dialog-title>Update availability</mat-card-title>
<mat-dialog-content [formGroup]="dialogForm">
  <ph-flex-loading-context [isLoading]="isBusy$ | async">
    <div class="intro">
      <p i18n="@@incidentReserveAvailabilityDialog-intro">
        Enter your participation in the Powerhouse incident reserve pool for a specific period.
      </p>
    </div>
    <div class="range flex-grid cols-2" formGroupName="range">
      <div class="col-2">
        <mat-form-field class="ph-flex-mat-form-field-large">
          <mat-label i18n="@@incidentReserveAvailabilityDialog-periodSelectionLabel"> Period</mat-label>
          <mat-date-range-input [rangePicker]="periodDateRangePicker" [min]="data.maxPeriod.startDate" [max]="data.maxPeriod.endDate">
            <input matStartDate formControlName="startDate" />
            <input matEndDate formControlName="endDate" />
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="periodDateRangePicker"></mat-datepicker-toggle>
          <mat-date-range-picker #periodDateRangePicker [calendarHeaderComponent]="CustomDatePickerHeaderComponent"></mat-date-range-picker>
          <mat-error *ngIf="dialogForm.get('range.startDate').errors?.required"> Start date is required. </mat-error>
          <mat-error
            *ngIf="dialogForm.get('range.startDate').errors?.matDatepickerMin"
            i18n="@@incidentReserveAvailabilityDialog-startDateMatDatepickerMin"
          >
            Start date cannot be before the start of the current service agreement and it should still be modifiable.
          </mat-error>
          <mat-error *ngIf="dialogForm.get('range.endDate').errors?.required" i18n="@@incidentReserveAvailabilityDialog-endDateRequired">
            End date is required.
          </mat-error>
          <mat-error
            *ngIf="dialogForm.get('range.endDate').errors?.matEndDateInvalid"
            i18n="@@incidentReserveAvailabilityDialog-endDateMatEndDateInvalid"
          >
            End date must be after start date.
          </mat-error>
          <mat-error
            *ngIf="dialogForm.get('range.endDate').errors?.matDatepickerMax"
            i18n="@@incidentReserveAvailabilityDialog-endDateMatDatepickerMax"
          >
            End date cannot be later than the end of the current service agreement.
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="capacities flex-grid cols-2 half-gutter-space" formGroupName="capacities">
      <div class="upwards col-1" formGroupName="upwards">
        <ng-container
          *ngTemplateOutlet="
            formForDirection;
            context: { form: dialogForm.get('capacities.upwards'), direction: AvailabilityDirection.UPWARDS }
          "
        ></ng-container>
      </div>
      <div class="downwards col-1" formGroupName="downwards">
        <ng-container
          *ngTemplateOutlet="
            formForDirection;
            context: { form: dialogForm.get('capacities.downwards'), direction: AvailabilityDirection.DOWNWARDS }
          "
        ></ng-container>
      </div>
    </div>
  </ph-flex-loading-context>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-stroked-button class="primary" mat-dialog-close>
    <ph-flex-vertical-center>
      <mat-icon svgIcon="ic-old:close"></mat-icon>
      <span i18n="@@cancelButton">Cancel</span>
    </ph-flex-vertical-center>
  </button>
  <ph-flex-save-button
    (clickChanged)="handleSave()"
    [pending]="facade.isBusyUpdatingAvailability$ | async"
    [error]="facade.savingAvailabilityStatusError$ | async"
    [disabled]="dialogForm.invalid || !isNotBothDisabled()"
    ><ph-flex-vertical-center
      ><mat-icon svgIcon="ic-old:save"></mat-icon><span i18n="@@saveButton">Save</span></ph-flex-vertical-center
    ></ph-flex-save-button
  >
</mat-dialog-actions>

<ng-template #formForDirection let-form="form" let-direction="direction">
  <div [formGroup]="form">
    <mat-slide-toggle formControlName="toggle" [ngSwitch]="direction" class="direction-toggle">
      <ng-container *ngSwitchCase="AvailabilityDirection.DOWNWARDS" i18n="@@incidentReserveAvailabilityDialog-downwardsCapacityLabel">
        Downwards
      </ng-container>
      <ng-container *ngSwitchCase="AvailabilityDirection.UPWARDS" i18n="@@incidentReserveAvailabilityDialog-upwardsCapacityLabel">
        Upwards
      </ng-container>
    </mat-slide-toggle>
    <div class="availability-input-container">
      <div class="unchanged-overlay" [class.hidden]="form.get('toggle').value" i18n="@@incidentReserveAvailabilityDialog-unchanged">
        <div class="unchanged-chip">Unchanged</div>
      </div>
      <div class="box-header">
        <ng-container *ngTemplateOutlet="availabilityHeader; context: { direction: direction }"></ng-container>
      </div>
      <div class="box-content">
        <div [attr.disabled]="form.get('toggle').value ? null : ''">
          <div class="label" i18n="@@incidentReserveAvailabilityDialog-participationLabel">Participation</div>
          <mat-radio-group formControlName="type">
            <mat-radio-button
              [value]="AvailabilityType.ASSURED"
              class="assured"
              i18n="@@incidentReserveAvailabilityDialog-assuredLabel"
              [disabled]="!isAssuredAllowed(direction)"
              >Assured
            </mat-radio-button>
            <mat-radio-button
              [value]="AvailabilityType.VOLUNTARY"
              class="voluntary"
              i18n="@@incidentReserveAvailabilityDialog-voluntaryLabel"
              >Voluntary
            </mat-radio-button>
            <mat-radio-button [value]="AvailabilityType.OFF" class="off" i18n="@@incidentReserveAvailabilityDialog-offLabel"
              >Off
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <div [attr.disabled]="form.get('toggle').value ? null : ''" [class.hidden]="form.get('type').value !== AvailabilityType.ASSURED">
          <mat-form-field>
            <mat-label><span i18n="@@incidentReserveAvailabilityDialog-volumeLabel">Volume</span> (MW)</mat-label>
            <ph-flex-number-input formControlName="value" [errorStateMatcher]="fnErrorMatcher"></ph-flex-number-input>
            <mat-hint
              >Maximum:
              <em>{{ direction === AvailabilityDirection.DOWNWARDS ? getDownwardsAvailableCapacity() : getUpwardsAvailableCapacity() }}</em>
            </mat-hint>
            <mat-error *ngIf="form.get('value')?.errors?.invalidNumber" i18n="@@notAValidNumberError"> Not a valid number </mat-error>
            <mat-error *ngIf="form.get('value')?.errors?.required" [ngSwitch]="direction">
              <ng-container
                *ngSwitchCase="AvailabilityDirection.DOWNWARDS"
                i18n="@@incidentReserveAvailabilityDialog-downwardsVolumeRequiredError"
              >
                Downwards volume is required
              </ng-container>
              <ng-container
                *ngSwitchCase="AvailabilityDirection.UPWARDS"
                i18n="@@incidentReserveAvailabilityDialog-upwardsVolumeRequiredError"
              >
                Upwards volume is required
              </ng-container>
            </mat-error>
            <mat-error *ngIf="form.get('value')?.errors?.max" [ngSwitch]="direction">
              <ng-container
                *ngSwitchCase="AvailabilityDirection.DOWNWARDS"
                i18n="@@incidentReserveAvailabilityDialog-downwardsExceedsContractedVolumeError"
              >
                Downwards volume cannot exceed contracted capacity
              </ng-container>
              <ng-container
                *ngSwitchCase="AvailabilityDirection.UPWARDS"
                i18n="@@incidentReserveAvailabilityDialog-upwardsExceedsContractedVolumeError"
              >
                Upwards volume cannot exceed contracted capacity
              </ng-container>
            </mat-error>
            <mat-error *ngIf="form.get('value')?.errors?.minExclusive" [ngSwitch]="direction">
              <ng-container
                *ngSwitchCase="AvailabilityDirection.DOWNWARDS"
                i18n="@@incidentReserveAvailabilityDialog-downwardsVolumeNegativeError"
              >
                Downwards volume cannot be negative
              </ng-container>
              <ng-container
                *ngSwitchCase="AvailabilityDirection.UPWARDS"
                i18n="@@incidentReserveAvailabilityDialog-upwardsVolumeNegativeError"
              >
                Upwards volume cannot be negative
              </ng-container>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #availabilityHeader let-direction="direction">
  <ph-flex-vertical-center *ngIf="direction === AvailabilityDirection.UPWARDS">
    <mat-icon svgIcon="ic-new:arrow-up"></mat-icon>
    <span class="uppercase" i18n="@@incidentReserveAvailabilityDialog-upwardsCapacityLabel">Upwards</span>
    <span
      >&nbsp;-
      <ng-container *ngTemplateOutlet="capacityType; context: { capacityType: this.capacitiesUpwardsType$ | async }"></ng-container>
      <ng-container *ngIf="(this.capacitiesUpwardsType$ | async) === AvailabilityType.ASSURED">
        {{ dialogForm.get('capacities.upwards.value').value }} MW
      </ng-container></span
    >
  </ph-flex-vertical-center>
  <ph-flex-vertical-center *ngIf="direction === AvailabilityDirection.DOWNWARDS">
    <mat-icon svgIcon="ic-new:arrow-down"></mat-icon>
    <span class="uppercase" i18n="@@incidentReserveAvailabilityDialog-downwardsCapacityLabel">Downwards</span>
    <span
      >&nbsp;-
      <ng-container *ngTemplateOutlet="capacityType; context: { capacityType: this.capacitiesDownwardsType$ | async }"></ng-container>
      <ng-container *ngIf="(this.capacitiesUpwardsType$ | async) === AvailabilityType.ASSURED">
        {{ dialogForm.get('capacities.downwards.value').value }} MW
      </ng-container></span
    >
  </ph-flex-vertical-center>
</ng-template>

<ng-template #capacityType let-capacityType="capacityType">
  <ng-container [ngSwitch]="capacityType">
    <ng-container *ngSwitchCase="AvailabilityType.OFF" i18n="@@incidentReserveAvailabilityDialog-offLabel">Off </ng-container>
    <ng-container *ngSwitchCase="AvailabilityType.ASSURED" i18n="@@incidentReserveAvailabilityDialog-assuredLabel"> Assured </ng-container>
    <ng-container *ngSwitchCase="AvailabilityType.VOLUNTARY" i18n="@@incidentReserveAvailabilityDialog-voluntaryLabel">
      Voluntary
    </ng-container>
  </ng-container>
</ng-template>
