import { AfterViewInit, ChangeDetectionStrategy, Component, Host, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { FormGroupProvider } from '../form-group-provider';
import { CustomValidators, MixinBase, OnDestroyMixin, FlexNgXsFormSync } from 'flex-app-shared';
import { MatStep, MatStepLabel, MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-step-price',
  templateUrl: './step-price.component.html',
  styleUrls: ['./step-price.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepPriceComponent extends OnDestroyMixin(MixinBase) implements FormGroupProvider, OnInit, AfterViewInit {
  @FlexNgXsFormSync('intraday.order.pricePerMWh', { initialSyncOnlyTruthyFormValues: false })
  formGroup = this.fb.group({
    pricePerMWh: [null, [Validators.required, Validators.min(-9999.99), Validators.max(9999.99), CustomValidators.numberOfDecimals(0, 2)]]
  });

  @ViewChild(MatStepLabel) stepLabel: MatStepLabel;

  constructor(@Host() private matStep: MatStep, private fb: UntypedFormBuilder, public stepper: MatStepper) {
    super();
  }

  ngOnInit(): void {
    this.matStep.stepControl = this.formGroup;
  }

  ngAfterViewInit(): void {
    this.matStep.stepLabel = this.stepLabel;
  }

  isCurrentStepSelected(): boolean {
    return this.stepper.selected === this.matStep;
  }
}
