import { Directive, Inject, Injectable, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { get, isArray } from 'lodash-es';
import { AUTOCOMPLETE_DATA_SOURCE_FILTER, DataSourceFilter } from '../../material/autocomplete/autocomplete.component';

@Injectable()
export class DataSourceFilterById implements DataSourceFilter<any> {
  idSubject = new BehaviorSubject<string | string[]>(null);

  protected id$ = this.idSubject.asObservable();

  getFilter(values: any[]): Observable<boolean[]> {
    return this.id$.pipe(
      map((id) => {
        if (id) {
          const ids = isArray(id) ? id : [id];

          return values.map((value) =>
            ids.some((currentIdFilter) => {
              if (!(get(value, 'id') || get(value, 'ids'))) {
                return true;
              }
              return get(value, 'id') === currentIdFilter || get(value, 'ids')?.includes(currentIdFilter);
            })
          );
        } else {
          return values.map(() => true);
        }
      })
    );
  }
}

@Directive({
  selector: '[phFlexDataSourceFilterById]',
  providers: [
    {
      provide: AUTOCOMPLETE_DATA_SOURCE_FILTER,
      useFactory: (a) => a,
      multi: true,
      deps: [DataSourceFilterById]
    },
    {
      provide: DataSourceFilterById,
      useClass: DataSourceFilterById
    }
  ]
})
export class DataSourceFilterByIdDirective implements OnChanges, OnDestroy {
  @Input('phFlexDataSourceFilterById') id: string | string[];

  constructor(@Inject(DataSourceFilterById) private dataSourceFilter: DataSourceFilterById) {}

  ngOnDestroy(): void {
    this.dataSourceFilter.idSubject.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.id) {
      this.dataSourceFilter.idSubject.next(this.id);
    }
  }
}
