<div class="ph-flex-form-group-container" [formGroup]="formGroup">
  <div>
    <mat-button-toggle-group formControlName="orderDirection">
      <mat-button-toggle [value]="OrderDirection.SELL" class="sell" i18n="@@intraday-labelDealTypeSell">Sell</mat-button-toggle>
      <mat-button-toggle [value]="OrderDirection.BUY" class="buy" i18n="@@intraday-labelDealTypeBuy">Buy</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <ph-flex-vertical-center class="allowed-for-idcons-container">
    <mat-slide-toggle formControlName="allowedForIdcons" disableRipple i18n="@@intraday-labelAllowedForIdcons">
      Intraday+: Allow order matching for congestion management
    </mat-slide-toggle>
    <ph-flex-info-icon [message]="getAllowedForIdconsExplanationText()"></ph-flex-info-icon>
  </ph-flex-vertical-center>
</div>

<ng-template matStepLabel>
  <ng-container *ngIf="formGroup.invalid" i18n="idcons-stepLabelDirection">Direction</ng-container>
  <ng-container *ngIf="formGroup.valid">
    <app-intraday-deal-direction [direction]="formGroup.get('orderDirection').value"></app-intraday-deal-direction>
  </ng-container>
</ng-template>
