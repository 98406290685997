import { MediaMatcher } from '@angular/cdk/layout';
import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  forwardRef,
  HostBinding,
  Inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { isAfter, isBefore, parseISO, sub } from 'date-fns';
import { Capacity, MixinBase, OnDestroyMixin, SubjectProvider, theme, TimeSlot } from 'flex-app-shared';
import { merge, timer } from 'rxjs';
import { IdconsAnnouncementsFacade } from '../../../../store/idcons-announcements/idcons-announcements.facade';
import {
  IdconsAnnouncement,
  IdconsAnnouncementStateEnum,
  IdconsAnnouncementType
} from '../../../shared/idcons/announcement/idcons-announcement';
import {
  convertProblemPeriodToTimeWindowDate,
  getAnnouncementStateBidValidity,
  getAnnouncementStateProblemPeriod,
  ComponentAnnouncementState
} from './announcement.utils';

@Component({
  selector: 'app-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.scss']
})
export class AnnouncementComponent extends OnDestroyMixin(MixinBase) implements AfterContentInit, OnInit, OnChanges, AfterViewInit {
  constructor(private mediaMatcher: MediaMatcher, public facade: IdconsAnnouncementsFacade, private cdr: ChangeDetectorRef) {
    super();
  }

  private stateChangedProvider = new SubjectProvider<number>(this);

  @Input() announcement: IdconsAnnouncement;

  @HostBinding('class.slim')
  @Input()
  slim: boolean = false;

  @HostBinding('class.unread')
  @Input()
  unread: boolean = false;

  announcementHeight: number;
  announcementState: keyof typeof ComponentAnnouncementState = null;
  disableAnimation = true;
  TimeSlot = TimeSlot;
  Capacity = Capacity;
  ComponentAnnouncementState = ComponentAnnouncementState;
  IdConsAnnouncementType = IdconsAnnouncementType;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.announcement) {
      this.stateChangedProvider.follow(merge(...this.getStateTransitionDates(this.announcement).map((date) => timer(date))));
    }
  }

  ngOnInit(): void {
    this.updateAnnouncementState();

    this.stateChangedProvider.value$.subscribe(() => {
      this.updateAnnouncementState();
      this.cdr.detectChanges();
    });
  }

  ngAfterContentInit(): void {
    this.announcementHeight = this.slim
      ? theme.components.announcement['min-height'].slim
      : theme.components.announcement['min-height'].regular;
  }

  // Workaround for angular component issue https://github.com/angular/components/issues/13870
  ngAfterViewInit(): void {
    // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
    setTimeout(() => (this.disableAnimation = false));
  }

  markAnnouncementAsRead(announcement: IdconsAnnouncement): void {
    this.facade.markAnnouncementIdsAsRead([announcement]);
  }

  private updateAnnouncementState(): void {
    const bidValidityToDateTime = this.announcement?.bidValidityPeriod?.toDateTime;
    const problemPeriodToDateTime = this.announcement?.problemPeriod?.toDateTime;

    const stateBasedOnTime = bidValidityToDateTime
      ? getAnnouncementStateBidValidity(bidValidityToDateTime, problemPeriodToDateTime)
      : getAnnouncementStateProblemPeriod(problemPeriodToDateTime);

    this.announcementState =
      this.announcement?.announcementState === IdconsAnnouncementStateEnum.ANNOUNCEMENT_CLOSED
        ? IdconsAnnouncementStateEnum.ANNOUNCEMENT_CLOSED
        : stateBasedOnTime;
  }

  private getStateTransitionDates(announcement: IdconsAnnouncement): Date[] {
    const dates = [];

    if (announcement?.problemPeriod) {
      const problemPeriodToDateTime = announcement.problemPeriod.toDateTime;
      const problemPeriodToDateTimeWindowDate: Date = convertProblemPeriodToTimeWindowDate(problemPeriodToDateTime);
      dates.push(problemPeriodToDateTimeWindowDate);
    }

    if (announcement?.bidValidityPeriod) {
      const parsedBidValidityToDate: Date = parseISO(announcement.bidValidityPeriod.toDateTime);
      dates.push(parsedBidValidityToDate);
    }

    return dates.filter((date) => isAfter(date, new Date()));
  }
}
