import { WebsocketManagedEntity } from './websocket-managed-entity';
import { combineLatest, EMPTY, switchMap } from 'rxjs';
import { SubjectProvider } from '../../../d3-graph/d3-graph/common';
import { map, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { get, isArray, keyBy, orderBy, set, sortBy, values } from 'lodash-es';
import { OnDestroyProvider } from '../../common/on-destroy.mixin';

export class WebsocketManagedEntityWithUpdates<T extends { id: string }> extends WebsocketManagedEntity<T[]> {
  protected lastResultMapProvider = new SubjectProvider<{ [key: string]: T }>(this);

  protected sortFields: string[];
  protected sortOrders: ('asc' | 'desc')[];

  result$ = this.resultProvider.value$.pipe(
    map((results) => {
      if (this.sortFields && this.sortOrders) {
        return orderBy(results, this.sortFields, this.sortOrders);
      } else if (this.sortFields) {
        return sortBy(results, this.sortFields);
      }
      return results;
    })
  );

  constructor(
    onDestroyProvider: OnDestroyProvider,
    constructorDestination?: string,
    sortFields?: string | string[],
    sortOrders?: ('asc' | 'desc') | ('asc' | 'desc')[]
  ) {
    super(onDestroyProvider, constructorDestination);

    if (sortFields) {
      this.sortFields = isArray(sortFields) ? sortFields : [sortFields];
    }

    if (sortOrders) {
      this.sortOrders = isArray(sortOrders) ? sortOrders : [sortOrders];
    }
  }

  protected init(): void {
    this.resultProvider.follow(
      combineLatest([this.destinationProvider.value$, this.isActiveProvider.value$, this.websocketService$]).pipe(
        switchMap(([destination, isActive, websocketService]) =>
          isActive ? websocketService.subscribeToResourceInit<T[]>(destination) : EMPTY
        )
      )
    );

    // Update lastResultMapProvider when the results change
    this.lastResultMapProvider.follow(this.resultProvider.value$.pipe(map((result) => keyBy(result, 'id'))));

    combineLatest([this.destinationProvider.value$, this.isActiveProvider.value$, this.websocketService$])
      .pipe(
        switchMap(([destination, isActive, websocketService]) =>
          isActive ? websocketService.subscribeToResourceUpdates<T>(destination) : EMPTY
        ),
        takeUntil(this.onDestroy$)
      )
      .subscribe((updatedEntity) => {
        const lastResultMap = this.lastResultMapProvider.value;
        set(lastResultMap, updatedEntity.id, updatedEntity);

        this.resultProvider.next(values(lastResultMap));
      });
  }
}
