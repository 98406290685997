import { AfterViewInit, ChangeDetectionStrategy, Component, Host, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatStep, MatStepLabel, MatStepper } from '@angular/material/stepper';
import { add, isAfter } from 'date-fns';
import { endOfDay } from 'date-fns/fp';
import { MixinBase, OnDestroyMixin, TimeSlot, FlexNgXsFormSync } from 'flex-app-shared';
import { groupBy } from 'lodash-es';
import moment from 'moment';
import { NumberRange } from 'range-ts';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { IntradayFacade } from '../../../../state/intraday-facade.service';
import { FormGroupProvider } from '../form-group-provider';

@Component({
  selector: 'app-step-time',
  templateUrl: './step-time.component.html',
  styleUrls: ['./step-time.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepTimeComponent extends OnDestroyMixin(MixinBase) implements OnInit, FormGroupProvider, AfterViewInit {
  availableTimeSlotDays: {
    [key: string]: TimeSlot[];
  };
  @ViewChild(MatStepLabel) stepLabel: MatStepLabel;
  @FlexNgXsFormSync('intraday.order.selectedTimeSlot', { initialSyncOnlyTruthyFormValues: true })
  formGroup = this.fb.group({
    selectedTimeSlot: [null, Validators.required],
    selectedDayOffset: [null],
    allowMatchingIndividualHours: [null]
  });

  editableNumberRange$: Observable<NumberRange | null> = this.intraDayFacade.idconsDealableNumberRange$;

  targetDay = new Date();
  isTomorrowAvailable$: Observable<boolean> = this.editableNumberRange$.pipe(
    map((editableNumberRange) => editableNumberRange && isAfter(editableNumberRange.upperEndpoint.valueOf(), endOfDay(new Date())))
  );

  constructor(
    @Host() private matStep: MatStep,
    private fb: UntypedFormBuilder,
    private stepper: MatStepper,
    public intraDayFacade: IntradayFacade
  ) {
    super();
  }

  ngOnInit(): void {
    // Get timeslots for every hour in the next two days
    const totalTimeSlot = TimeSlot.fromDuration(moment().add(1, 'hour').startOf('hour'), moment.duration(2, 'days'));
    const timeSlots = TimeSlot.asTimeUnit(totalTimeSlot, 'hour');
    this.availableTimeSlotDays = groupBy(timeSlots, (value) => moment(value.startDateTime).format('YYYY-MM-DD'));

    this.matStep.stepControl = this.formGroup;

    this.formGroup
      .get('selectedDayOffset')
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe((newOffset) => {
        this.targetDay = add(new Date(), { days: newOffset });
      });
  }

  getTimeSlotDays(): string[] {
    return Object.keys(this.availableTimeSlotDays || {});
  }

  ngAfterViewInit(): void {
    this.matStep.stepLabel = this.stepLabel;
  }

  shouldShowLabelWithoutValue(): boolean {
    return this.isCurrentStepSelected() || this.formGroup.invalid;
  }

  isCurrentStepSelected(): boolean {
    return this.stepper.selected === this.matStep;
  }
}
