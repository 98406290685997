import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Capacity, Direction, Money, SINGLETON, TimeSlot, VolumePrice, WebsocketManagedEntityWithUpdates } from 'flex-app-shared';
import { Observable } from 'rxjs';

export enum IntradayOrderStatus {
  REQUESTED = 'REQUESTED',
  CREATED = 'CREATED',
  UPDATED = 'UPDATED',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  CANCELLATION_REQUESTED = 'CANCELLATION_REQUESTED',
  IN_PROGRESS = 'IN_PROGRESS',
  FAILED = 'FAILED',
  EXPIRED = 'EXPIRED' // Does not exist yet
}

export class IntradayOrderStatusHelper {
  static IdconsOrderStatusEndStates = [
    IntradayOrderStatus.COMPLETED,
    IntradayOrderStatus.CANCELLED,
    IntradayOrderStatus.FAILED,
    IntradayOrderStatus.EXPIRED
  ];

  static IntradayOrderStatusCancelableStates = [IntradayOrderStatus.CREATED, IntradayOrderStatus.UPDATED];

  static isEndState(currentStatus: IntradayOrderStatus): boolean {
    return IntradayOrderStatusHelper.IdconsOrderStatusEndStates.includes(currentStatus);
  }

  static isCancelable(currentStatus: IntradayOrderStatus): boolean {
    return IntradayOrderStatusHelper.IntradayOrderStatusCancelableStates.includes(currentStatus);
  }
}

export class IntradayOrder {
  id: string;
  direction: Direction;
  capacity: Capacity;
  remainingCapacity: Capacity;
  period: TimeSlot;
  status: IntradayOrderStatus;
  controlId: string;
  controlDescription: string;
  gridPointEan: string;
  gridPointDescription?: string;
  price: VolumePrice;
  amount: Money;
  customerId: string;
  customerLegalName?: string;
  createdByUserName: string;
  created: string;
  lastUpdated: string;
  reason: string;
  reference: string;
  allowedForIdcons: boolean;
}

export class IntradayCreateOrdersJson {
  orders: IntradayCreateOrderJson[];
}

export class IntradayCreateOrderJson {
  direction: Direction;
  period: TimeSlot;
  volumes: IntradayOrderItem[];
  price: number;
  allowedForIdcons: boolean;
}

export class IntradayOrderItem {
  controlId: string;
  capacity: Capacity;
}

@Injectable({
  providedIn: 'root'
})
export class IntradayOrderService {
  uri = '/api/v1/intraday/orders';
  destination = '/intraday/orders';

  openOrdersWebsocket = new WebsocketManagedEntityWithUpdates<IntradayOrder>(
    SINGLETON,
    '/intra-day/orders',
    ['created', 'dealPeriod'],
    ['desc', 'desc']
  );

  openOrders$ = this.openOrdersWebsocket.result$;

  constructor(private http: HttpClient) {}

  saveOrder(orders: IntradayCreateOrdersJson): Observable<any> {
    return this.http.post(`${this.uri}`, orders);
  }

  cancelOrder(id: string): Observable<any> {
    return this.http.put(`${this.uri}/${id}/cancel`, {});
  }

  cancelOrders(orderIds: string[]): Observable<any> {
    return this.http.put(`${this.uri}/cancel`, { orderIds });
  }

  cancelAllOrders(): Observable<any> {
    return this.http.put(`${this.uri}/cancel-all`, {});
  }
}
