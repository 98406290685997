import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss']
})
export class PageTitleComponent {
  @Input() type: 'toolbar' | 'sidebar' = 'toolbar';

  @Input() page: string;

  /**
   * Get the first segment (before /) of the page
   */
  firstSegment(): string {
    if (!this.page) {
      return null;
    }

    return this.page
      .split('/')
      .filter((a) => !!a)
      .at(0);
  }
}
