import { HttpResponse } from '@angular/common/http';
import { Store } from '@ngxs/store';
import { AppInjector, IntradayService, PaginatedDataSource, PostPendingIntradayDeal } from 'flex-app-shared';
import { Observable, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { IntradayHistoryState } from '../state/history/intraday-history-state.service';
import { IntraDayDealsHistoryPaginationUpdatedEvent } from '../state/history/intraday-history.actions';

export class IntraDayHistoryDataSource extends PaginatedDataSource<PostPendingIntradayDeal> {
  private lastSubscription: Subscription;
  private intraDayService: IntradayService;
  private showAllDeals = false;
  private store = AppInjector.get(Store);

  constructor(intraDayService: IntradayService) {
    super();
    this.intraDayService = intraDayService;
  }

  loadData(pageIndex?: number, pageSize?: number, searchTerm?: string, sort?: string): void {
    if (!this.lastSubscription) {
      this.init();
    }

    this.store.dispatch(new IntraDayDealsHistoryPaginationUpdatedEvent(pageIndex, pageSize, sort));
  }

  isEmpty(): boolean {
    return this.data?.length === 0;
  }

  downloadData(): Observable<HttpResponse<Blob>> {
    return this.intraDayService.downloadData(this.getSortString(), this.store.selectSnapshot(IntradayHistoryState.filters));
  }

  showAllDealsChanged(showAll: boolean): void {
    this.showAllDeals = showAll;
    this.internalAlwaysLoadData();
  }

  private init(): void {
    this.lastSubscription = this.store
      .select(IntradayHistoryState.deals)
      .pipe(
        filter((result) => !!result),
        takeUntil(this.onDestroy$)
      )
      .subscribe((deals) => {
        this.dataSubject.next(deals?.content);
        this.updatePaginatorFromPaginatedResponse(deals);
      });
  }
}
