import { Directive, ElementRef, EventEmitter, Host, OnDestroy, Output } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { OnDestroyMixin } from './on-destroy.mixin';
import { MixinBase } from './constructor-type.mixin';
import { isNil } from 'lodash-es';

@Directive({
  selector: '[phFlexResizeObserver]'
})
export class FlexResizeObserverDirective implements OnDestroy {
  private contentRectSubject = new ReplaySubject<DOMRectReadOnly>(1);
  contentRect$ = this.contentRectSubject.asObservable();

  private resizeObserver: ResizeObserver;

  constructor(el: ElementRef) {
    this.resizeObserver = new ResizeObserver((entries, observer) => {
      // Use requestAnimationFrame to prevent "ResizeObserver loop limit exceeded" errors
      requestAnimationFrame(() => {
        // We only support 1 element, the elementRef, for this directive
        const entry = entries[0];

        this.contentRectSubject.next(entry.contentRect as DOMRectReadOnly);
      });
    });

    this.resizeObserver.observe(el.nativeElement);
  }

  ngOnDestroy(): void {
    this.resizeObserver.disconnect();
    this.contentRectSubject.complete();
  }
}

/**
 * Utility directive to be used in conjunction with phFlexResizeObserver.
 * Can be used like this: <ph-flex-svg phFlexResizeObserver (phFlexUpdateWidth)="updateWidth($event)">
 *   Where updateWidth updates e.g. the xAxisWidth.
 */
@Directive({
  selector: '[phFlexWidthChanged]'
})
export class FlexWidthChangedDirective extends OnDestroyMixin(MixinBase) {
  @Output() phFlexWidthChanged = new EventEmitter<number>();

  constructor(@Host() flexResizeObserverDirective: FlexResizeObserverDirective) {
    super();

    flexResizeObserverDirective.contentRect$
      .pipe(
        map((contentRect) => contentRect.width),
        distinctUntilChanged(),
        takeUntil(this.onDestroy$)
      )
      .subscribe((width) => {
        this.phFlexWidthChanged.emit(width);
      });
  }
}

@Directive({
  selector: '[phFlexHeightChanged]'
})
export class FlexHeightChangedDirective extends OnDestroyMixin(MixinBase) {
  @Output() phFlexHeightChanged = new EventEmitter<number>();

  constructor(@Host() flexResizeObserverDirective: FlexResizeObserverDirective) {
    super();

    flexResizeObserverDirective.contentRect$
      .pipe(
        map((contentRect) => contentRect.height),
        distinctUntilChanged(),
        takeUntil(this.onDestroy$)
      )
      .subscribe((height) => {
        this.phFlexHeightChanged.emit(height);
      });
  }
}
