import { Component, Host, HostBinding, Inject, Input, OnChanges, OnInit, Optional, SimpleChanges } from '@angular/core';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { MixinBase } from '../../../core/common/constructor-type.mixin';
import { OnDestroyMixin } from '../../../core/common/on-destroy.mixin';
import { filter, takeUntil } from 'rxjs/operators';
import { capitalize, upperCase } from 'lodash-es';
import { FlexSideMenuComponent } from '../side-menu/side-menu.component';

@Component({
  selector: 'ph-flex-side-menu-item-child',
  templateUrl: './side-menu-item-child.component.html',
  styleUrls: ['./side-menu-item-child.component.scss']
})
export class FlexSideMenuItemChildComponent extends OnDestroyMixin(MixinBase) implements OnChanges {
  @Input()
  label: string;

  // Updated when label changes
  shortLabel: string;

  @HostBinding('class.selected')
  get isSelected(): boolean {
    return this.isActive;
  }

  @HostBinding('class.icon-only')
  get isIconOnly(): boolean {
    return !this.flexSideMenuComponent.isExpanded;
  }

  isActive = false;

  constructor(
    @Host() @Optional() private routerLink: RouterLink,
    private router: Router,
    @Inject(FlexSideMenuComponent) private flexSideMenuComponent: FlexSideMenuComponent
  ) {
    super();

    router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.onDestroy$)
      )
      .subscribe(() => {
        this.updateIsActive();
      });
  }

  private updateIsActive(): void {
    if (!this.routerLink) {
      return;
    }

    this.isActive = this.router.isActive(this.routerLink.urlTree, true);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.label) {
      this.shortLabel = getShortLabel(this.label);
    }
  }
}

export function getShortLabel(fullLabel: string): string {
  if (!fullLabel) {
    return '';
  }

  const splitLabel = fullLabel.split(' ');

  if (splitLabel.length === 1) {
    // Single word, capitalize and use first 2 characters
    return capitalize(fullLabel).substr(0, 2);
  }

  return upperCase(splitLabel[0][0] + splitLabel[1][0]);
}
