import { MatDialogRef } from '@angular/material/dialog';
import { IntradayCreateOrdersJson, IntradayOrder } from '../../../shared/intraday-order/intraday-order.service';

export class ResetIntradayOrderFormCommand {
  public static type: string = '[Intraday orders] Reset intraday order form';
}

export class ResetIntradayOrderVolumesCommand {
  public static type: string = '[Intraday orders] Reset intraday order volumes';
}

export class CreateIntradayOrderCommand {
  public static type: string = '[Intraday orders] Create new order';

  constructor(public dialogRef?: MatDialogRef<any>) {}
}

export class LoadIntradayOrdersCommand {
  public static type: string = '[Intraday orders] Load orders command';

  constructor(public pageIndex?: number, public pageSize?: number, public sort?: string) {}
}

export class IntradayOrdersOrdersLoadedEvent {
  public static type: string = '[Intraday orders] Orders loaded event';

  constructor(public orders: IntradayOrder[]) {}
}

export class CancelIntradayOrderCommand {
  public static type: string = '[Intraday orders] Cancel order';

  constructor(public orderId: string, public dialogRef?: MatDialogRef<any>) {}
}

export class CancelIntradayOrdersCommand {
  public static type: string = '[Intraday orders] Cancel multiple';

  constructor(public orderIds: string[], public cancelAll: boolean) {}
}

export class IntradayOrderHasRemLimitOrderDealingAuthorityEvent {
  public static type: string = '[Intraday orders] Updated intraday idcons rem limit order dealing authority';

  constructor(public hasRemLimitOrderDealingAuthority: boolean) {}
}

export class IntradayOrderHasIntradayIdconsDealingAuthorityEvent {
  public static type: string = '[Intraday orders] Updated intraday idcons dealing authority';

  constructor(public hasIntradayIdconsDealingAuthority: boolean) {}
}
