import FuseResult = Fuse.FuseResult;
import {
  AppInjector,
  asBatch,
  Capacity,
  Direction,
  IntradayControl,
  IntradayControlDirection,
  IntradayPositionWithMinMax,
  MandatoryTradeDirection,
  PaginatedDataSource,
  TimeSlot
} from 'flex-app-shared';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { IntradayFacade } from '../../state/intraday-facade.service';
import { calculateAvailableControlPower, EditSlotContext, getPosition } from '../../state/intraday-state.service';
import { TreeControl } from '@angular/cdk/tree';
import Fuse from 'fuse.js';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { CollectionViewer } from '@angular/cdk/collections';
import { filter, map, shareReplay, startWith, tap } from 'rxjs/operators';
import { flatMap, flatMapDeep, get, groupBy, has, mapValues, orderBy } from 'lodash-es';

export enum FlattenedIntradayLevel {
  CUSTOMER = 0,
  GRID_POINT = 1,
  CONTROL = 2
}

export type FlattenedIntradayNode = FlattenedIntradayControlNode | FlattenedIntradayCustomerNode | FlattenedIntradayGridPointNode;

export interface IntradayGraphNode {
  node: FlattenedIntradayNode;
  children: IntradayGraphNode[];
}

export abstract class BaseFlattenedIntraDayNode {
  level: number;
  totalCapacity: number;
  totalMinPosition: number;
  totalMaxPosition: number;
  totalPosition: number; // min or max, based on direction
  totalAvailableCapacity: number;
  id: string; // id to keep track of selected items
  customerId: string;

  static getControlIds(node: FlattenedIntradayNode): string[] {
    if (has(node, 'controlIds')) {
      return get(node, 'controlIds');
    }
    return [get(node, 'controlId')];
  }
}

export abstract class FlattenedIntradayCustomerNode extends BaseFlattenedIntraDayNode {
  level: FlattenedIntradayLevel.CUSTOMER;
  customerLegalName: string;
  totalChildren: number;
  controlIds: string[];
}

export abstract class FlattenedIntradayGridPointNode extends BaseFlattenedIntraDayNode {
  level: FlattenedIntradayLevel.GRID_POINT;
  gridPointId: string;
  gridPointDescription: string;
  gridPointEan: string;
  totalChildren: number;
  controlIds: string[];
}

export abstract class FlattenedIntradayControlNode extends BaseFlattenedIntraDayNode {
  level: FlattenedIntradayLevel.CONTROL;
  gridPointId: string;
  controlId: string;
  controlDirection: IntradayControlDirection;
  controlDescription: string;
  availableCapacity: number;
  maxAvailableCapacity: number;
  namePlatePower: number;
  positionMinPercentageOfNamePlatePower: number;
  positionMaxPercentageOfNamePlatePower: number;
}

export class IntradayDealControlsDataSource extends PaginatedDataSource<FlattenedIntradayNode> {
  controls$: Observable<IntradayControl[]> = AppInjector.get(IntradayFacade).controls$;
  editSlotContext$: Observable<EditSlotContext> = AppInjector.get(IntradayFacade).editSlotContext$;
  positions$: Observable<IntradayPositionWithMinMax[]> = AppInjector.get(IntradayFacade).positions$;
  mandatoryTradingDirections$ = AppInjector.get(IntradayFacade).mandatoryTradingDirections$;
  lastDataSubscription: Subscription;
  treeControl: TreeControl<FlattenedIntradayNode>;
  treeControlUpdateSubscription: Subscription;
  hideEmptyControlsToggleSubscription: Subscription;
  hideEmptyControls: boolean = true;
  builtTree$: Observable<FlattenedIntradayNode[]>;
  dataAfterFilter: FlattenedIntradayNode[];
  fuse: Fuse<FlattenedIntradayNode, any>;
  data$ = this.dataSubject.asObservable().pipe(
    tap((value) => (this.data = value)),
    asBatch({
      batchSize: 10,
      batchDelayMs: 20,
      equalFn: (a, b) => a?.id === b?.id
    }),
    shareReplay(1)
  );
  protected busyUpdatingSelection = false;
  private hideEmptyControlsToggle: MatSlideToggle;

  connect(collectionViewer: CollectionViewer): Observable<FlattenedIntradayNode[]> {
    this.builtTree$ = combineLatest([
      this.controls$,
      this.positions$,
      this.editSlotContext$,
      this.mandatoryTradingDirections$,
      this.treeControl.expansionModel.changed.pipe(
        startWith(null),
        filter(() => !this.busyUpdatingSelection)
      )
    ]).pipe(
      map(([result, positions, slotContext, mandatoryTradingDirections]) => {
        this.busyUpdatingSelection = true;
        const tree = this.buildTree(result, positions, slotContext, mandatoryTradingDirections);

        const currentlySelected = this.treeControl.expansionModel.selected;
        this.treeControl.expansionModel.deselect(...currentlySelected);
        this.treeControl.dataNodes = tree;
        this.treeControl.dataNodes
          .filter((node) => currentlySelected.some((selectedNode) => node.id === selectedNode.id))
          .forEach((node) => this.treeControl.expand(node));

        this.busyUpdatingSelection = false;

        this.fuse = new Fuse(tree, {
          threshold: 0.3,
          keys: [
            {
              name: 'customerLegalName',
              weight: 1
            },
            {
              name: 'gridPointDescription',
              weight: 2
            },
            {
              name: 'controlDescription',
              weight: 3
            },
            {
              name: 'gridPointEan',
              weight: 5
            }
          ]
        });

        return tree;
      })
    );

    return super.connect(collectionViewer);
  }

  registerToggleSubscription(toggle: MatSlideToggle): void {
    this.hideEmptyControlsToggle = toggle;
    if (this.hideEmptyControlsToggleSubscription) {
      this.hideEmptyControlsToggleSubscription.unsubscribe();
      this.hideEmptyControls = true;
    }

    this.hideEmptyControlsToggleSubscription = this.hideEmptyControlsToggle.toggleChange.subscribe(() => {
      this.hideEmptyControls = !this.hideEmptyControls;
      this.loadData();
    });
  }

  registerTreeControl(treeControl: TreeControl<FlattenedIntradayNode>): void {
    this.treeControl = treeControl;
    if (this.treeControlUpdateSubscription) {
      this.treeControlUpdateSubscription.unsubscribe();
      this.treeControlUpdateSubscription = null;
    }
  }

  getParentNode(node: FlattenedIntradayNode, providedData?: FlattenedIntradayNode[]): FlattenedIntradayNode {
    const data = providedData || this.data || [];
    const nodeIndex = data.indexOf(node);
    for (let i = nodeIndex - 1; i >= 0; i--) {
      if (data[i].level === node.level - 1) {
        return data[i];
      }
    }
  }

  getChildNodes(node: FlattenedIntradayNode, providedData?: FlattenedIntradayNode[]): FlattenedIntradayNode[] {
    const data = providedData || this.data || [];
    const nodeIndex = data.indexOf(node);
    const results = [];
    for (let i = nodeIndex + 1; i < data.length && data[i].level > node.level; i++) {
      results.push(data[i]);
    }
    return results;
  }

  loadData(pageIndex?: number, pageSize?: number, searchTerm?: string, sort?: string): void {
    if (this.lastDataSubscription) {
      this.lastDataSubscription.unsubscribe();
      this.lastDataSubscription = null;
    }
    // Only true on first buildTree$ result
    // Will either collapse all (default), or expand all (when only one customer is selected or when searching)
    let shouldResetSelection = true;

    this.lastDataSubscription = this.builtTree$.subscribe((tree) => {
      let filteredTree = tree;

      this.busyUpdatingSelection = true;
      if (shouldResetSelection) {
        this.treeControl.collapseAll();
      }

      if (searchTerm) {
        const result = this.fuse.search(searchTerm);

        if (shouldResetSelection) {
          this.treeControl.dataNodes
            .filter((node) => filteredTree.some((selectedNode) => node.id === selectedNode.id))
            .forEach((node) => this.treeControl.expand(node));
        }
        filteredTree = this.restoreTree(result, tree);
      }

      // Update with the filtered tree before removing items based on expansion state;
      this.dataAfterFilter = filteredTree;

      if (shouldResetSelection) {
        if (this.dataAfterFilter.filter((node) => node.level === FlattenedIntradayLevel.CUSTOMER).length === 1) {
          // The data set has no customers, so we expand all items
          const customerNode = this.dataAfterFilter.find((node) => node.level === FlattenedIntradayLevel.CUSTOMER);
          this.treeControl.expandDescendants(customerNode);
        }
      }

      // Remove customer node if there is only one customer
      if (filteredTree.filter((node) => node.level === FlattenedIntradayLevel.CUSTOMER).length === 1) {
        filteredTree = filteredTree.filter((node) => node.level !== FlattenedIntradayLevel.CUSTOMER);
      }

      filteredTree = filteredTree.filter((node) => {
        const parentNode = this.getParentNode(node, tree);
        return !parentNode || this.treeControl.isExpanded(parentNode);
      });

      this.busyUpdatingSelection = false;
      shouldResetSelection = false;

      this.dataSubject.next(filteredTree);
    });
  }

  buildTree(
    controls: IntradayControl[],
    positions: IntradayPositionWithMinMax[],
    slotContext: EditSlotContext,
    mandatoryTradingDirections: MandatoryTradeDirection[]
  ): FlattenedIntradayNode[] {
    const sortedControls = orderBy(
      controls,
      [
        (control) => control.customerLegalName.toLocaleLowerCase(),
        (control) => control.gridPointDescription.toLocaleLowerCase(),
        (control) => control.controlDirection,
        (control) => control.controlDescription.toLocaleLowerCase()
      ],
      ['asc', 'asc', 'desc', 'asc']
    );
    const nestedControls = mapValues(
      groupBy(sortedControls, (a) => a.customerId),
      (controlsForCustomer) => groupBy(controlsForCustomer, (a) => a.gridPointId)
    );

    const positionsWithGridPointId = positions.map((position) => ({
      ...position,
      gridPointId: controls.find((control) => control.controlId === position.controlId)?.gridPointId
    }));

    const nestedPositions = mapValues(
      groupBy(positionsWithGridPointId, (a) => a.customerId),
      (positionsForCustomer) => groupBy(positionsForCustomer, (a) => a.gridPointId)
    );

    const flattenedNodes: FlattenedIntradayNode[] = [];

    const controlsPerCustomer = new Map();

    Object.keys(nestedControls).map((customerId) => {
      const controlsForCustomer = nestedControls[customerId];
      const positionsForCustomer = nestedPositions[customerId] || {};
      return Object.keys(controlsForCustomer).map((gridPointId, i) => {
        const controlsForGridPoint = controlsForCustomer[gridPointId];
        const positionsForGridPoint = get(positionsForCustomer, gridPointId) || [];

        const filteredControlsForGridPoint = controlsForGridPoint
          .map((control) => {
            const foundPosition = positionsForGridPoint.find((position) => position.controlId === control.controlId);
            let positionMin = 0;
            let positionMax = 0;
            let mandatoryTradeDirection: Direction;
            let maxAvailableCapacity: number = 0;
            let availableCapacity: number = 0;

            // No found position means no day ahead data, which means that availableCapacity is always 0.
            if (foundPosition) {
              positionMin = Capacity.asKW(foundPosition.positionMin) || 0;
              positionMax = Capacity.asKW(foundPosition.positionMax) || 0;

              mandatoryTradeDirection = mandatoryTradingDirections.find(
                (currentMandatoryTradeDirection) =>
                  currentMandatoryTradeDirection.gridPointId === control.gridPointId &&
                  TimeSlot.overlaps(
                    { startDateTime: slotContext.startDateTime, toDateTime: slotContext.toDateTime },
                    currentMandatoryTradeDirection.period
                  )
              )?.direction;

              maxAvailableCapacity = calculateAvailableControlPower(
                Capacity.asKW(control.namePlatePower),
                positionMin,
                positionMax,
                control.controlDirection,
                slotContext.direction,
                true,
                mandatoryTradeDirection
              );
              availableCapacity = calculateAvailableControlPower(
                Capacity.asKW(control.namePlatePower),
                positionMin,
                positionMax,
                control.controlDirection,
                slotContext.direction,
                false,
                mandatoryTradeDirection
              );
            }

            return {
              id: control.controlId,
              level: FlattenedIntradayLevel.CONTROL,
              controlId: control.controlId,
              gridPointId: control.gridPointId,
              customerId: control.customerId,
              controlDirection: control.controlDirection,
              controlDescription: control.controlDescription,
              totalCapacity: getNamePlatePower(control),
              totalMinPosition: positionMin,
              totalMaxPosition: positionMax,
              totalPosition: getPosition(positionMin, positionMax, control.controlDirection, slotContext.direction),
              availableCapacity,
              totalAvailableCapacity: availableCapacity,
              maxAvailableCapacity,
              namePlatePower: Capacity.asKW(control.namePlatePower),
              positionMinPercentageOfNamePlatePower: positionMin / Capacity.asKW(control.namePlatePower),
              positionMaxPercentageOfNamePlatePower: positionMax / Capacity.asKW(control.namePlatePower)
            } as FlattenedIntradayControlNode;
          })
          .filter((flattenedControl) => flattenedControl.availableCapacity !== 0 || !this.hideEmptyControls);

        const totalAvailableCapacity = Object.values(filteredControlsForGridPoint).reduce((c, a) => c + a.availableCapacity, 0);

        if (i === 0) {
          controlsPerCustomer.set(customerId, filteredControlsForGridPoint.length);
          const totalCustomerCapacity = Object.values(controlsForCustomer).reduce(
            (c, a) => c + a.reduce((c2, a2) => c2 + getNamePlatePower(a2), 0),
            0
          );
          const totalCustomerPositionMax = Object.values(positionsForCustomer).reduce(
            (c, a) => c + a.reduce((c2, a2) => c2 + Capacity.asKW(a2.positionMax), 0),
            0
          );
          const totalCustomerPositionMin = Object.values(positionsForCustomer).reduce(
            (c, a) => c + a.reduce((c2, a2) => c2 + Capacity.asKW(a2.positionMin), 0),
            0
          );
          const totalCustomerPosition = Object.values(positionsForCustomer).reduce(
            (c, a) =>
              c +
              a.reduce((c2, a2) => {
                const control = controls.find((currentControl) => currentControl.controlId === a2.controlId);

                return (
                  c2 +
                  getPosition(Capacity.asKW(a2.positionMin), Capacity.asKW(a2.positionMax), control.controlDirection, slotContext.direction)
                );
              }, 0),
            0
          );

          // Add customer node
          flattenedNodes.push({
            id: customerId,
            level: FlattenedIntradayLevel.CUSTOMER,
            customerId,
            customerLegalName: controlsForGridPoint[0].customerLegalName,
            totalChildren: Object.values(controlsForCustomer).reduce((c, a) => c + a.length, 0),
            totalCapacity: totalCustomerCapacity,
            totalMaxPosition: totalCustomerPositionMax,
            totalMinPosition: totalCustomerPositionMin,
            totalPosition: totalCustomerPosition,
            totalAvailableCapacity,
            controlIds: flatMap(Object.values(controlsForCustomer), (currentControls) =>
              currentControls.map((control) => control.controlId)
            )
          });
        } else {
          controlsPerCustomer.set(customerId, controlsPerCustomer.get(customerId) + filteredControlsForGridPoint.length);

          const customerNode = flattenedNodes.find((node) => node.id === customerId);
          customerNode.totalAvailableCapacity = customerNode.totalAvailableCapacity + totalAvailableCapacity;
        }

        const totalGridPointCapacity = controlsForGridPoint.reduce((c, a) => c + getNamePlatePower(a), 0);
        const totalGridPointPositionMax = positionsForGridPoint.reduce((c, a) => c + Capacity.asKW(a.positionMax), 0);
        const totalGridPointPositionMin = positionsForGridPoint.reduce((c, a) => c + Capacity.asKW(a.positionMin), 0);
        const totalGridPointPosition = positionsForGridPoint.reduce((c, a) => {
          const control = controls.find((currentControl) => currentControl.controlId === a.controlId);
          return (
            c + getPosition(Capacity.asKW(a.positionMin), Capacity.asKW(a.positionMax), control.controlDirection, slotContext.direction)
          );
        }, 0);

        const totalGridPointAvailableCapacity = filteredControlsForGridPoint.reduce((c, a) => c + a.availableCapacity, 0);
        if (filteredControlsForGridPoint.length > 0) {
          flattenedNodes.push(
            {
              id: gridPointId,
              customerId,
              level: FlattenedIntradayLevel.GRID_POINT,
              gridPointDescription: controlsForGridPoint[0].gridPointDescription,
              gridPointEan: controlsForGridPoint[0].gridPointEan,
              gridPointId,
              totalChildren: filteredControlsForGridPoint.length,
              totalCapacity: totalGridPointCapacity,
              totalMinPosition: totalGridPointPositionMin,
              totalMaxPosition: totalGridPointPositionMax,
              totalPosition: totalGridPointPosition,
              totalAvailableCapacity: totalGridPointAvailableCapacity,
              controlIds: controlsForGridPoint.map((currentControl) => currentControl.controlId)
            },
            // Add nodes for controls
            ...filteredControlsForGridPoint
          );
        }
      });
    });

    return flattenedNodes
      .map((node) => {
        if (node.level === FlattenedIntradayLevel.CUSTOMER) {
          node.totalChildren = controlsPerCustomer.get(node.customerId);
          return node;
        } else {
          return node;
        }
      })
      .filter((node) => node.level !== FlattenedIntradayLevel.CUSTOMER || node.totalChildren > 0);
  }

  /**
   * Flatmap a graph created by this.toGraph() to a list of FlattenedIntraDayNode
   */
  fromGraph(items: IntradayGraphNode[]): FlattenedIntradayNode[] {
    return flatMapDeep(items, (item) => [item.node, ...this.fromGraph(item.children)]);
  }

  /**
   * Create a graph with customer -> gridPoint -> control
   */
  toGraph(items: FlattenedIntradayNode[]): IntradayGraphNode[] {
    const result = this.toGraphRecursive({} as any, items);
    return result.children;
  }

  /**
   * Ensure that the full tree is available if any children are selected.
   * Preserve ordering of search result.
   */
  private restoreTree(result: FuseResult<FlattenedIntradayNode>[], fullTree: FlattenedIntradayNode[]): FlattenedIntradayNode[] {
    const resultItems = result.map((currentResult) => currentResult.item);

    const fullGraph = this.toGraph(fullTree);
    const filteredGraphByCustomer = fullGraph.map((node) => this.filterNodeChildren(node, resultItems)).filter((node) => !!node);

    return this.fromGraph(filteredGraphByCustomer);
  }

  /**
   * Filter nodes based on the allowedNodes list
   * If the node is present in the list, all children are included without filtering.
   *
   * If the node is not present, the children are filtered.
   * If one or more children are not filtered, the node is retained.
   * Otherwise it is removed
   */
  private filterNodeChildren(node: IntradayGraphNode, allowedNodes: FlattenedIntradayNode[]): IntradayGraphNode | null {
    if (allowedNodes.includes(node.node)) {
      // Node is in result, include all children without filtering
      return node;
    }

    const filteredChildren = node.children.filter((child) => this.filterNodeChildren(child, allowedNodes));

    if (filteredChildren.length === 0) {
      // Lowest node or no children
      return allowedNodes.includes(node.node) ? node : null;
    }

    return {
      ...node,
      children: filteredChildren
    };
  }

  private toGraphRecursive(node: FlattenedIntradayNode, children: FlattenedIntradayNode[]): IntradayGraphNode {
    const currentChildren: IntradayGraphNode[] = [];

    let currentChildNode: FlattenedIntradayNode;
    let currentChildChildren: FlattenedIntradayNode[] = [];

    children.forEach((item) => {
      if (!currentChildNode) {
        currentChildNode = item;
      } else {
        if (item.level > currentChildNode.level) {
          currentChildChildren.push(item);
        } else {
          // Found higher level node
          currentChildren.push(this.toGraphRecursive(currentChildNode, currentChildChildren));
          currentChildNode = item;
          currentChildChildren = [];
        }
      }
    });

    if (currentChildNode) {
      currentChildren.push(this.toGraphRecursive(currentChildNode, currentChildChildren));
    }

    return {
      node,
      children: currentChildren
    };
  }
}

export function getNamePlatePower(control: IntradayControl): number {
  if (control.controlDirection === IntradayControlDirection.CONSUMPTION) {
    return Capacity.asKW(control.namePlatePower);
  } else {
    return -Capacity.asKW(control.namePlatePower);
  }
}
