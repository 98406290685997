import {
  DefaultPopoverEditPositionStrategyFactory,
  FocusDispatcher,
  PopoverEditPositionStrategyFactory
} from '@angular/cdk-experimental/popover-edit';
import { PositionStrategy } from '@angular/cdk/overlay';
import { FlatTreeControl } from '@angular/cdk/tree';
import { AfterViewInit, Component, Inject, Injectable, OnInit, TrackByFunction, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Parser } from 'expr-eval';
import {
  CustomValidators,
  Direction,
  FnErrorMatcher,
  IntradayControl,
  IntraDayControlDeals,
  IntradayControlDirection,
  MixinBase,
  OnDestroyMixin,
  TableFilterComponent
} from 'flex-app-shared';
import { fromPairs, get, has, isNil, omitBy, toPairs } from 'lodash-es';
import { Subscription } from 'rxjs';
import { debounceTime, filter, first, map, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { UpdateControlDealsCommand } from '../../state/intraday.actions';
import { IntradayFacade } from '../../state/intraday-facade.service';
import { EditSlotContext, IntradayState } from '../../state/intraday-state.service';
import {
  BaseFlattenedIntraDayNode,
  FlattenedIntradayControlNode,
  FlattenedIntradayLevel,
  FlattenedIntradayNode,
  IntradayDealControlsDataSource
} from './intraday-deal-controls-data-source';
import { IntradaySlotFocusDispatcher } from './intraday-slot-focus-dispatcher.service';

@Injectable()
export class NonPushDefaultPopoverEditPositionStrategyFactory extends DefaultPopoverEditPositionStrategyFactory {
  positionStrategyForCells(cells: HTMLElement[]): PositionStrategy {
    return this.overlay
      .position()
      .flexibleConnectedTo(cells[0])
      .withPush(false)
      .withGrowAfterOpen()
      .withViewportMargin(16)
      .withPositions([
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'top'
        }
      ]);
  }
}

@Component({
  selector: 'app-intraday-deal-dialog',
  templateUrl: './intraday-slot.component.html',
  styleUrls: ['./intraday-slot.component.scss'],
  providers: [
    {
      provide: PopoverEditPositionStrategyFactory,
      useClass: NonPushDefaultPopoverEditPositionStrategyFactory
    },
    {
      provide: FocusDispatcher,
      useClass: IntradaySlotFocusDispatcher
    }
  ]
})
export class IntradaySlotComponent extends OnDestroyMixin(MixinBase) implements OnInit, AfterViewInit {
  FlattenedIntraDayLevel = FlattenedIntradayLevel;
  IntraDayControlDirection = IntradayControlDirection;
  Direction = Direction;
  shouldShowErrors = false;
  errorStateMatcher = new FnErrorMatcher(() => this.shouldShowErrors);
  displayedColumns = ['description', 'availableCapacityPercentage', 'deal', 'availableCapacity', 'dealPrice'];

  editSlotContext$ = this.store.select(IntradayState.editSlotContext);
  slot$ = this.editSlotContext$.pipe(
    switchMap((slotContext) => this.store.select(IntradayState.slotWithFlags(slotContext.startDateTime, slotContext.toDateTime)))
  );

  dealTotalPerCustomer: any = {};
  dealTotalPerGridPoint: any = {};
  dealPriceCalculator = Parser.parse('MWhPrice / 1000 * dealVolume');
  dataSource = new IntradayDealControlsDataSource();
  treeControl = new FlatTreeControl<FlattenedIntradayNode>(
    (node) => node.level,
    (node) => node.level < FlattenedIntradayLevel.CONTROL
  );
  controls: IntradayControl[] = [];

  controlFormGroup = new UntypedFormGroup({});

  isExpandable = ((i: number, node: FlattenedIntradayNode) => {
    return this.treeControl.isExpandable(node);
  }).bind(this);
  lastFormValueChangedSubscription: Subscription = null;
  tableHasNoPadding$ = this.dataSource.data$.pipe(
    map((data) => !data.some((currentNode) => currentNode.level === FlattenedIntradayLevel.CUSTOMER))
  );
  @ViewChild('hideEmptyControlsToggle', { static: false, read: MatSlideToggle }) private readonly hideEmptyControlsToggle: MatSlideToggle;
  @ViewChild(TableFilterComponent, { static: false }) private readonly tableFilter: TableFilterComponent;

  constructor(
    public fb: UntypedFormBuilder,
    public intraDayFacade: IntradayFacade,
    public store: Store,
    public router: Router,
    @Inject(FocusDispatcher) private focusDispatcher: IntradaySlotFocusDispatcher
  ) {
    super();
    focusDispatcher.registerDataSource(this.dataSource);
  }

  trackBy: TrackByFunction<FlattenedIntradayNode> = (index, item) => {
    switch (item.level) {
      case FlattenedIntradayLevel.CUSTOMER:
        return item.customerId;
      case FlattenedIntradayLevel.GRID_POINT:
        return item.gridPointId;
      case FlattenedIntradayLevel.CONTROL:
        return item.controlId;
      default:
        return index;
    }
  };

  rowClick(node: FlattenedIntradayNode, event: Event): void {
    if (this.treeControl.isExpandable(node)) {
      this.treeControl.toggleDescendants(node);
    }
  }

  ngOnInit(): void {
    this.dataSource.registerTreeControl(this.treeControl);

    this.dataSource.data$.pipe(debounceTime(100), takeUntil(this.onDestroy$)).subscribe((result) => {
      // Update form validity when data changes
      Object.values(this.controlFormGroup.controls).forEach((control) => {
        control.updateValueAndValidity({
          emitEvent: false
        });
      });
    });

    this.editSlotContext$
      .pipe(
        filter((a) => !!a),
        switchMap((editSlotContext) =>
          this.intraDayFacade.controls$.pipe(
            withLatestFrom(
              this.intraDayFacade
                .getIntraDayControlDeals$(editSlotContext.direction, editSlotContext.startDateTime, editSlotContext.toDateTime)
                .pipe(first())
            )
          )
        ),
        takeUntil(this.onDestroy$)
      )
      .subscribe(([controls, currentDeals]) => {
        this.updateFormGroup(controls, currentDeals);
      });

    this.updateDealTotals();
  }

  ngAfterViewInit(): void {
    this.dataSource.registerToggleSubscription(this.hideEmptyControlsToggle);
    this.dataSource.filter = this.tableFilter;
  }

  sendUpdateCommand(direction: Direction, startDateTime: string, toDateTime: string, priceId: string): void {
    this.store.dispatch(
      new UpdateControlDealsCommand(
        startDateTime,
        toDateTime,
        direction,
        toPairs(omitBy(this.controlFormGroup.value, (value) => value === '')).map(([key, value]) => ({
          controlId: key,
          volume: value
        })),
        priceId
      )
    );
  }

  clear(): void {
    this.controlFormGroup.reset();
  }

  getDealPrice(editSlotContext: EditSlotContext, dealVolume: number): number | void {
    if (isNil(editSlotContext?.pricePerMW)) {
      return;
    }

    return this.dealPriceCalculator.evaluate({ MWhPrice: editSlotContext.pricePerMW, dealVolume: dealVolume || 0 });
  }

  getAvailableOffset(node: FlattenedIntradayNode): number {
    const totalDealVolume = this.getDealTotal(node);

    const totalRemaining = node.totalAvailableCapacity - totalDealVolume;

    if (totalRemaining > 0) {
      return totalRemaining;
    }

    return -totalDealVolume;
  }

  getDealTotal(node: FlattenedIntradayNode): number {
    switch (node.level) {
      case FlattenedIntradayLevel.CONTROL:
        return this.controlFormGroup.get(node.controlId).value || 0;
      case FlattenedIntradayLevel.CUSTOMER:
        return this.dealTotalPerCustomer[node.customerId];
      case FlattenedIntradayLevel.GRID_POINT:
        return this.dealTotalPerGridPoint[node.gridPointId];
    }
  }

  applyAvailableOffset(node: FlattenedIntradayNode, event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    const controlIds = BaseFlattenedIntraDayNode.getControlIds(node);
    const controlNodeMap = fromPairs(
      controlIds
        .map((controlId) => [controlId, this.dataSource.dataAfterFilter.find((currentNode) => currentNode.id === controlId)])
        .filter((a) => !!a[1])
    );
    const shouldIncrease = Object.values(controlNodeMap).some((currentNode) => this.getAvailableOffset(currentNode) > 0);

    controlIds.forEach((controlId) => {
      const currentNode: FlattenedIntradayControlNode = controlNodeMap[controlId] as FlattenedIntradayControlNode;

      if (!currentNode) {
        // Skip nodes that are not present in the filtered results
        return;
      }

      if (shouldIncrease) {
        this.controlFormGroup.get(controlId).setValue(currentNode.availableCapacity);
      } else {
        this.controlFormGroup.get(controlId).setValue(0);
      }
    });
  }

  back(): void {
    this.router.navigate(['intraday']);
  }

  updateFormGroup(controls: IntradayControl[], deals: IntraDayControlDeals): void {
    this.controlFormGroup = new UntypedFormGroup(
      fromPairs(
        controls.map((control) => {
          const initValue = has(deals, control.controlId) ? get(deals, control.controlId) : '';
          const existingControl = this.controlFormGroup.get(control.controlId);

          if (existingControl && existingControl.value !== initValue) {
            existingControl.setValue(initValue);
          }

          // Create a formGroup with the control id as key and a form control as Value
          // This makes it easier to identify the control and to look up the corresponding control object
          return [
            control.controlId,
            existingControl ||
              this.fb.control(initValue, [
                CustomValidators.numberOfDecimals(0, 0),
                CustomValidators.minOrZero(10),
                CustomValidators.maxFn(
                  () =>
                    this.dataSource.dataAfterFilter
                      ?.filter((data) => data.level === FlattenedIntradayLevel.CONTROL)
                      // @ts-ignore
                      .find((data) => data.id === control.controlId)?.maxAvailableCapacity
                )
              ])
          ];
        })
      )
    );
    if (this.lastFormValueChangedSubscription) {
      this.lastFormValueChangedSubscription.unsubscribe();
      this.lastFormValueChangedSubscription = null;
    }
    this.controlFormGroup.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.updateDealTotals();
      this.editSlotContext$
        .pipe(
          first(),
          filter((a) => !!a)
        )
        .subscribe((context) => {
          this.sendUpdateCommand(context.direction, context.startDateTime, context.toDateTime, context.priceId);
        });
    });
  }

  isNodeInvalid(node: FlattenedIntradayNode): boolean {
    switch (node.level) {
      case FlattenedIntradayLevel.CUSTOMER:
      case FlattenedIntradayLevel.GRID_POINT:
        return node.controlIds.some((controlId) => this.controlFormGroup.get(controlId)?.invalid);
      case FlattenedIntradayLevel.CONTROL:
        return this.controlFormGroup.get(node.controlId)?.invalid;
      default:
        return false;
    }
  }

  isNodeWarning(node: FlattenedIntradayNode): boolean {
    let control;

    switch (node.level) {
      case FlattenedIntradayLevel.CUSTOMER:
      case FlattenedIntradayLevel.GRID_POINT:
        return node.controlIds.some((controlId) => {
          const controlNode: FlattenedIntradayControlNode = this.dataSource.dataAfterFilter.find(
            (currentNode) => currentNode.id === controlId
          ) as FlattenedIntradayControlNode;
          control = this.controlFormGroup.get(controlId);
          return control?.valid && controlNode?.availableCapacity < control?.value;
        });
      case FlattenedIntradayLevel.CONTROL:
        control = this.controlFormGroup.get(node.controlId);
        return control?.valid && node.availableCapacity < control?.value;
      default:
        return false;
    }
  }

  private updateDealTotals(): void {
    this.dealTotalPerCustomer = {};
    this.dealTotalPerGridPoint = {};

    const controls = this.store.selectSnapshot(IntradayState.availableControls);

    controls.forEach((control) => {
      const currentDealValue = this.controlFormGroup.get(control.controlId)?.value || 0;

      const oldTotalPerCustomer = this.dealTotalPerCustomer[control.customerId] || 0;
      this.dealTotalPerCustomer[control.customerId] = oldTotalPerCustomer + currentDealValue;

      const oldTotalPerGridPoint = this.dealTotalPerGridPoint[control.gridPointId] || 0;
      this.dealTotalPerGridPoint[control.gridPointId] = oldTotalPerGridPoint + currentDealValue;
    });
  }
}
