import { Component, OnInit } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button[ph-flex-inline-icon-button]',
  templateUrl: './inline-icon-button.component.html',
  styleUrls: ['./inline-icon-button.component.scss']
})
export class InlineIconButtonComponent {
  constructor() {}
}
