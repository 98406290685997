import { AfterViewInit, Component, ContentChild, Inject, Input, OnInit, Optional } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DEFAULT_PERIOD, PeriodUnit } from '../default-period';
import { Observable, takeUntil } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { DateRangeFixedPeriodButtonGroupComponent } from '../date-range-fixed-period-button-group/date-range-fixed-period-button-group.component';
import { DateRangeFixedPeriodCombinedInputComponent } from '../date-range-fixed-period-combined-input/date-range-fixed-period-combined-input.component';
import { ObservableInputsMixin } from '../../../core/observable-inputs/observable-inputs';
import { OnDestroyMixin } from '../../../core/common/on-destroy.mixin';
import { MixinBase } from '../../../core/common/constructor-type.mixin';
import { SubjectProvider } from '../../../d3-graph/d3-graph/common';
import { DateRangeFixedPeriodCalendarHeaderComponent } from '../date-range-fixed-period-date-picker-header/date-range-fixed-period-calendar-header.component';
import { DateRangeFixedSelectedDateHelperService } from '../date-range-fixed-selected-date-helper.service';

@Component({
  selector: 'ph-flex-date-range-fixed-period',
  templateUrl: './date-range-fixed-period.component.html',
  styleUrls: ['./date-range-fixed-period.component.scss'],
  providers: [DateRangeFixedSelectedDateHelperService]
})
export class DateRangeFixedPeriodComponent extends ObservableInputsMixin(OnDestroyMixin(MixinBase)) implements OnInit, AfterViewInit {
  // @ts-ignore
  @Input() label: string = $localize`:@@dateRangeFixed-defaultLabel:Period`;

  label$ = this.oi.observe(() => this.label);

  selectedPeriodControl: UntypedFormControl;

  dateControl = new UntypedFormControl();

  selectedDate$ = this.dateControl.valueChanges.pipe(startWith(this.dateControl.value), takeUntil(this.onDestroy$));
  selectedPeriod$: Observable<PeriodUnit>;

  selectedPeriodProvider = new SubjectProvider<PeriodUnit>(this);

  @ContentChild(DateRangeFixedPeriodButtonGroupComponent)
  buttonGroupComponent: DateRangeFixedPeriodButtonGroupComponent;

  @ContentChild(DateRangeFixedPeriodCombinedInputComponent)
  combinedInputComponent: DateRangeFixedPeriodCombinedInputComponent;

  calendarHeaderComponent = DateRangeFixedPeriodCalendarHeaderComponent;

  constructor(@Inject(DEFAULT_PERIOD) @Optional() private defaultSelectedPeriod: PeriodUnit) {
    super();
  }

  ngOnInit(): void {
    this.selectedPeriodControl = new UntypedFormControl(this.defaultSelectedPeriod);

    this.selectedPeriod$ = this.selectedPeriodControl.valueChanges.pipe(
      startWith(this.selectedPeriodControl.value),
      takeUntil(this.onDestroy$)
    );

    this.selectedPeriodProvider.follow(this.selectedPeriod$);
  }

  /**
   * Get the selected range, which is a day, month or year
   */
  getSelectedPeriod(): PeriodUnit {
    return this.selectedPeriodControl.value;
  }

  setDate(date: Date): void {
    this.dateControl.setValue(date);
  }

  setSelectedPeriod(periodUnit: PeriodUnit): void {
    this.selectedPeriodControl.setValue(periodUnit);
  }

  ngAfterViewInit(): void {
    this.buttonGroupComponent.selectedPeriodControl = this.selectedPeriodControl;

    this.label$.subscribe((label) => (this.combinedInputComponent.label = label));
    this.combinedInputComponent.dateControl = this.dateControl;
    this.combinedInputComponent.dateRangeSelectedPeriod$ = this.selectedPeriodProvider.value$;
  }
}
