<ng-container [ngSwitch]="dealDisplayStatus()">
  <ng-container *ngSwitchCase="IntraDayDealDisplayStatus.CONFIRMED">
    <span class="confirmed" i18n="@@intraday-labelDealStatusConfirmed">Confirmed</span>
  </ng-container>
  <ng-container *ngSwitchCase="IntraDayDealDisplayStatus.PENDING">
    <span class="pending" i18n="@@intraday-labelDealStatusPending">Pending</span>
  </ng-container>
  <ng-container *ngSwitchCase="IntraDayDealDisplayStatus.FAILED">
    <span class="failed" i18n="@@intraday-labelDealStatusFailed">Failed</span>
  </ng-container>
</ng-container>
