<app-announcements [noMessages]="announcements.length === 0">
  <ng-container *ngFor="let announcement of announcements">
    <app-announcement
      [announcement]="announcement"
      [class.first-child-announcement]="announcements.length >= 1"
      [slim]="true"
      [unread]="isUnreadAnnouncement(announcement.id)"
      class="intraday"
    ></app-announcement>
  </ng-container>
</app-announcements>
