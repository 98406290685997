import { Component, OnInit } from '@angular/core';
import { IdconsAnnouncementsFacade, IdconsAnnouncementsPerDay } from '../../../store/idcons-announcements/idcons-announcements.facade';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { MixinBase, OnDestroyMixin } from 'flex-app-shared';

@Component({
  selector: 'app-messages',
  templateUrl: 'announcements-page.component.html'
})
export class AnnouncementsPageComponent extends OnDestroyMixin(MixinBase) implements OnInit {
  announcementsSortedByDateGroupedByDay$: Observable<IdconsAnnouncementsPerDay>;
  unreadAnnouncementsIds$: Observable<string[]>;

  constructor(public facade: IdconsAnnouncementsFacade, private location: Location) {
    super();
  }

  ngOnInit(): void {
    this.announcementsSortedByDateGroupedByDay$ = this.facade.announcementsSortedByDateGroupedByDay$.pipe(takeUntil(this.onDestroy$));

    this.unreadAnnouncementsIds$ = this.facade.unreadAnnouncements$.pipe(
      map((unreadAnnouncementsIds) => unreadAnnouncementsIds.map(({ id }) => id)),
      takeUntil(this.onDestroy$)
    );
  }

  goBack(): void {
    this.location.back();
  }
}
