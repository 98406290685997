import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IdconsAnnouncement } from './idcons-announcement';

@Injectable({
  providedIn: 'root'
})
export class IdconsAnnouncementService {
  uri = '/api/v1/idcons-announcements';

  constructor(private http: HttpClient) {}

  loadAnnouncements(): Observable<IdconsAnnouncement[]> {
    return this.http.get<IdconsAnnouncement[]>(`${this.uri}`);
  }
}
