<p i18n="@@intraday-textStepSummary" class="summary-box">
  Confirm <em>{{ getNumberOfDeals() }} orders</em> for a total price of <em>{{ getTotalPrice() | currency: 'EUR' }}</em
  >?
</p>

<p *ngIf="!(saveError$ | async)" class="summary-box warning" i18n="@@intraday-textsStepSummaryConflictingOrders">
  Attention: Conflicting orders are cancelled if one or more new orders are created for the same period.
</p>
<p *ngIf="saveError$ | async; let saveError" class="summary-box alert">
  <em>{{ saveError }}</em>
  <br />
  <ng-container i18n="@@intraday-textsStepSummaryAlertActionFallback">Try again or contact support.</ng-container>
</p>

<ng-template matStepLabel><ng-container i18n="@@intraday-labelStepSummary">Summary</ng-container></ng-template>
