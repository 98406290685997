import { Component, Input, ViewEncapsulation } from '@angular/core';
import { AdjustAvailability, AvailabilityType, Capacity } from 'flex-app-shared';

export enum AdjustAvailabilityDisplayType {
  FULL = 'FULL',
  SHORT = 'SHORT'
}

@Component({
  selector: 'app-adjust-availability',
  templateUrl: 'adjust-availability.component.html',
  styleUrls: ['./adjust-availability.component.scss']
})
export class AdjustAvailabilityComponent {
  AvailabilityType = AvailabilityType;
  Capacity = Capacity;
  DisplayType = AdjustAvailabilityDisplayType;

  @Input() value: AdjustAvailability;

  @Input() displayType: AdjustAvailabilityDisplayType = AdjustAvailabilityDisplayType.SHORT;
}
