import { Component, Inject, Optional, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogContent } from '@angular/material/dialog';
import {
  Control,
  ControlService,
  DateRangeFixedPeriodCombinedInputComponent,
  FILTER_FORM,
  GridPoint,
  MixinBase,
  OnDestroyMixin,
  PeriodUnit
} from 'flex-app-shared';
import { Observable, of } from 'rxjs';
import { map, shareReplay, switchMap, takeUntil } from 'rxjs/operators';
import { IntraDayHistoryFacade } from '../../state/history/intraday-history.facade';

@Component({
  selector: 'app-intraday-history-filters-form',
  templateUrl: './intraday-history-filters-form.component.html',
  styleUrls: ['./intraday-history-filters-form.component.scss']
})
export class IntradayHistoryFiltersFormComponent extends OnDestroyMixin(MixinBase) {
  availablePeriodUnits = [PeriodUnit.YEAR, PeriodUnit.MONTH, PeriodUnit.DAY];

  @ViewChild(DateRangeFixedPeriodCombinedInputComponent)
  dateRangeFixedPeriodCombinedInputComponent: DateRangeFixedPeriodCombinedInputComponent;

  selectedControl$: Observable<Control> = this.filterForm.get('controlId').valueChanges.pipe(
    switchMap((controlId) => (!controlId ? of(undefined) : this.controlService.getById(controlId))),
    shareReplay(1)
  );
  selectedControlCustomerId$: Observable<string> = this.selectedControl$.pipe(map((value) => value?.customerId));
  selectedControlGridPointIds$: Observable<string[]> = this.selectedControl$.pipe(
    map((value) => value?.gridPoints.map((gridPoint) => gridPoint.gridPointId))
  );
  selectedGridPoint$: Observable<GridPoint> = this.intraDayHistoryFacade.selectedGridPoint$;
  selectedGridPointCustomerId$: Observable<string> = this.selectedGridPoint$.pipe(map((value) => value?.customerId));

  constructor(
    @Inject(FILTER_FORM) public filterForm: UntypedFormGroup,
    private controlService: ControlService,
    @Optional() @Inject(MAT_DIALOG_DATA) private dialogData: any,
    private intraDayHistoryFacade: IntraDayHistoryFacade
  ) {
    super();
  }

  get inDialog(): boolean {
    return !!this.dialogData;
  }

  isSelected$(key: string): Observable<boolean> {
    return this.intraDayHistoryFacade.selectedFilters$.pipe(map((selectedFilters) => selectedFilters.includes(key)));
  }
}
