import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Store } from '@ngxs/store';
import {
  AsyncActionStatus,
  IncidentReserveAvailabilityDialogView,
  IncidentReserveAvailabilityState,
  IncidentReserveAvailabilityViewItem
} from './incident-reserve-availability.state';
import { combineLatestWith, Observable } from 'rxjs';
import { AdjustAvailability, CalendarPeriod, DateFnsDisplayFormatters, Money, normalizeToDate, R3ServiceAgreement } from 'flex-app-shared';
import {
  IncidentReserveAvailabilityChangeAvailabilityCommand,
  IncidentReserveAvailabilitySelectMonthCommand
} from './incident-reserve-availability.actions';
import { filter, first, map, skip, startWith } from 'rxjs/operators';
import { nl } from 'date-fns/locale';
import { format, isTomorrow } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class IncidentReserveAvailabilityFacade {
  itemPeriods$: Observable<CalendarPeriod[]> = this.store.select(IncidentReserveAvailabilityState.itemPeriods);

  selectableServiceAgreements$: Observable<R3ServiceAgreement[]> = this.store.select(
    IncidentReserveAvailabilityState.selectableServiceAgreements
  );

  currentView$ = this.store.select(IncidentReserveAvailabilityState.currentView);

  cardTitleDate$ = this.store.select(IncidentReserveAvailabilityState.selectedDateRange).pipe(
    map((value) => {
      if (!value) {
        return undefined;
      }

      const locale = this.locale === 'nl' ? nl : null;
      const isMonth = value.split('-').length === 2;

      return isMonth ? format(normalizeToDate(value), DateFnsDisplayFormatters.MONTH_NICE, { locale }) : value;
    })
  );

  selectedServiceAgreement$ = this.store.select(IncidentReserveAvailabilityState.selectedServiceAgreement);

  serviceAgreementsPeriodMin$ = this.store.select(IncidentReserveAvailabilityState.serviceAgreementsPeriodMin);
  serviceAgreementsPeriodMax$ = this.store.select(IncidentReserveAvailabilityState.serviceAgreementsPeriodMax);

  activations$ = this.store.select(IncidentReserveAvailabilityState.activationsForDateRange);
  activationMeasurements$ = this.store.select(IncidentReserveAvailabilityState.activationMeasurementData);

  isBusyUpdatingAvailability$ = this.store
    .select(IncidentReserveAvailabilityState.savingAvailabilityStatus)
    .pipe(map((status) => status && !status.done));

  isBusyFetchingActivationMeasurements$ = this.store.select(IncidentReserveAvailabilityState.activationMeasurementBusy);

  isBusy$ = this.store.select(IncidentReserveAvailabilityState.isBusy);

  isBusyOrSaving$ = this.isBusyUpdatingAvailability$.pipe(
    combineLatestWith(this.isBusy$),
    map(([a, b]) => a || b)
  );

  viewAvailabilities$ = this.store.select(IncidentReserveAvailabilityState.viewAvailabilities);

  /**
   * Return the error message of the next request.
   * It uses skip and startWith to prevent showing a stale error message, since it is not reset when the context where it was relevant (e.g. a component) is closed.
   */
  savingAvailabilityStatusError$ = this.store.select(IncidentReserveAvailabilityState.savingAvailabilityStatus).pipe(
    skip(1),
    startWith(AsyncActionStatus.initialValue()),
    map((status) => status?.errorMessage)
  );

  hasSelectedCustomer$ = this.store
    .select(IncidentReserveAvailabilityState.selectedCustomerId)
    .pipe(map((selectedCustomerId) => !!selectedCustomerId));

  earliestEditableDayDescriptor$ = this.store
    .select(IncidentReserveAvailabilityState.earliestEditableDay)
    .pipe(map((result) => (isTomorrow(result.toDate()) ? 'tomorrow' : 'day after tomorrow')));

  earliestEditableDay$ = this.store.select(IncidentReserveAvailabilityState.earliestEditableDay).pipe(map((result) => result.toDate()));

  constructor(private store: Store, @Inject(LOCALE_ID) private locale: string) {}

  itemAvailability(period: CalendarPeriod): Observable<IncidentReserveAvailabilityViewItem> {
    return this.store.select(IncidentReserveAvailabilityState.getAvailabilityForPeriod(period));
  }

  dialogData(period: CalendarPeriod): Observable<IncidentReserveAvailabilityDialogView> {
    return this.store.select(IncidentReserveAvailabilityState.incidentReserveAvailabilityDialogView(period));
  }

  updateAvailability(period: CalendarPeriod, upwards: AdjustAvailability, downwards: AdjustAvailability): Observable<AsyncActionStatus> {
    this.store.dispatch(new IncidentReserveAvailabilityChangeAvailabilityCommand(period, upwards, downwards));

    return this.store.select(IncidentReserveAvailabilityState.savingAvailabilityStatus).pipe(
      filter((status) => status?.done),
      first()
    );
  }

  selectMonth(period: CalendarPeriod): void {
    this.store.dispatch(new IncidentReserveAvailabilitySelectMonthCommand(period));
  }

  itemUpwardsAvailabilityFee$(period: CalendarPeriod): Observable<Money> {
    return this.store.select(IncidentReserveAvailabilityState.getAvailabilityFeeForPeriodAndDirection(period, 'upwards'));
  }

  itemDownwardsAvailabilityFee$(period: CalendarPeriod): Observable<Money> {
    return this.store.select(IncidentReserveAvailabilityState.getAvailabilityFeeForPeriodAndDirection(period, 'downwards'));
  }

  itemActivationCount$(period: CalendarPeriod): Observable<number> {
    return this.store.select(IncidentReserveAvailabilityState.getActivationCountForPeriod(period));
  }

  getSelectedServiceAgreementId(): string {
    return this.store.selectSnapshot(IncidentReserveAvailabilityState.currentActivationsServiceAgreementId);
  }
}
