import { Component, ContentChildren, Host, HostBinding, HostListener, Inject, Input, OnInit, Optional, QueryList } from '@angular/core';
import { FlexSideMenuItemChildComponent } from '../side-menu-item-child/side-menu-item-child.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ActivationEnd, Router, RouterEvent, RouterLink } from '@angular/router';
import { MixinBase } from '../../../core/common/constructor-type.mixin';
import { OnDestroyMixin } from '../../../core/common/on-destroy.mixin';
import { filter, takeUntil } from 'rxjs/operators';
import { FlexSideMenuComponent } from '../side-menu/side-menu.component';

@Component({
  selector: 'ph-flex-side-menu-item',
  templateUrl: './side-menu-item.component.html',
  styleUrls: ['./side-menu-item.component.scss'],
  animations: [
    trigger('childContainerExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', overflow: 'hidden' })),
      state('expanded', style({ height: '*', overflow: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class FlexSideMenuItemComponent extends OnDestroyMixin(MixinBase) implements OnInit {
  @Input()
  label: string;

  @Input()
  icon: string;

  @HostBinding('class.expanded')
  isExpanded: boolean = false;

  isActive: boolean = false;

  @HostBinding('class.expandable')
  get isExpandable(): boolean {
    return this.hasChildren();
  }

  @HostBinding('class.selected')
  get isSelected(): boolean {
    return this.isActive; // TODO add route based selection
  }

  @HostBinding('class.icon-only')
  get isIconOnly(): boolean {
    return !this.flexSideMenuComponent.isExpanded;
  }

  @ContentChildren(FlexSideMenuItemChildComponent)
  children: QueryList<FlexSideMenuItemChildComponent>;

  hasChildren(): boolean {
    return this.children.length > 0;
  }

  toggleExpand(): void {
    this.isExpanded = !this.isExpanded;
  }

  private get routerLink(): RouterLink | RouterLink {
    return this.routerLinkWithHref || this.routerLinkWithoutHref;
  }

  constructor(
    @Host() @Optional() private routerLinkWithoutHref: RouterLink,
    @Host() @Optional() private routerLinkWithHref: RouterLink,
    private router: Router,
    @Inject(FlexSideMenuComponent) private flexSideMenuComponent: FlexSideMenuComponent
  ) {
    super();

    router.events
      .pipe(
        filter((event: any) => event instanceof ActivationEnd),
        takeUntil(this.onDestroy$)
      )
      .subscribe(() => {
        this.updateIsActive();
      });
  }

  ngOnInit(): void {
    this.setItemActive();
  }

  private updateIsActive(): void {
    if (!this.routerLink) {
      return;
    }

    this.setItemActive();
  }

  setItemActive(): void {
    this.isActive = this.router.isActive(this.routerLink.urlTree, false);
  }
}
