import { IdconsAnnouncement, IdconsAnnouncementStateEnum } from '../../../shared/idcons/announcement/idcons-announcement';
import { isAfter, isBefore, parseISO, sub } from 'date-fns';

export const problemPeriodTimeWindowMinutes = 75;

export enum AdditionalAnnouncementState {
  ANNOUNCEMENT_EXPIRED = 'ANNOUNCEMENT_EXPIRED',
  ANNOUNCEMENT_BID_PERIOD = 'ANNOUNCEMENT_BID_PERIOD'
}

export const ComponentAnnouncementState = {
  ...AdditionalAnnouncementState,
  ...IdconsAnnouncementStateEnum
};

/**
 * Recomputes the actual state of the announcement, taking into account the actual problem and bid validity periods.
 * The announcement state is recomputed as follows:
 *
 * * If the current state is ANNOUNCEMENT_CLOSED or ANNOUNCEMENT_DELETED, the state is left as-is
 * * for all other current states:
 *   * if a bid validity period is available and now() is before its end time: ANNOUNCEMENT_BID_PERIOD (front end will
 *     show "bid before [end time of bid period]"
 *   * if a bid validity period is available and now() is after the bid period, but more than (or exactly) 75 minutes before
 *     then end time of the problem period: ANNOUNCEMENT_EXPIRED
 *   * if a bid validity period is available and now() is after the bid period, but less than 75 minutes before
 *     then end time of the problem period: ANNOUNCEMENT_CLOSED
 *   * if no bid validity period is available, and now() more than (or exactly) 75 minutes before
 *     then end time of the problem period: ANNOUNCEMENT_OPEN
 *   * if no bid validity period is available and now() less than 75 minutes before
 *     then end time of the problem period: ANNOUNCEMENT_CLOSED
 *   * if no bid validity period and no problem period is available: null: Front end will show "Unknown"
 *   * In all other cases the original state is returned.
 *
 *
 * The schema below explains the handling of problem and bid periods:
 *
 *  WITH BID VALIDITY PERIOD:
 *
 *                                                   75 min
 *                                                   before end of
 *                        Bid Period                 Problem Period       Problem Period
 *                     |--------------------|             |              |------------------------|
 *                                          |             |
 * State:    <--ANNOUNCEMENT_BID_PERIOD     | EXPIRED     | CLOSED -->
 *
 *
 *  WITHOUT BID VALIDITY PERIOD:
 *
 *                                                     75 min
 *                                                   before end of
 *                                                   Problem Period       Problem Period
 *                                                        |              |------------------------|
 *                                                        |
 * State:    <---ANNOUNCEMENT_OPEN                        | CLOSED -->
 *
 */
export function recomputeAnnouncementStateBasedOnTime(announcement: IdconsAnnouncement): any | null {
  if (
    announcement.announcementState === IdconsAnnouncementStateEnum.ANNOUNCEMENT_CLOSED ||
    announcement.announcementState === IdconsAnnouncementStateEnum.ANNOUNCEMENT_DELETED
  ) {
    return announcement.announcementState;
  }

  const bidValidityToDateTime = announcement.bidValidityPeriod?.toDateTime;
  const problemPeriodToDateTime = announcement.problemPeriod?.toDateTime;
  return bidValidityToDateTime
    ? getAnnouncementStateBidValidity(bidValidityToDateTime, problemPeriodToDateTime)
    : getAnnouncementStateProblemPeriod(problemPeriodToDateTime);
}

export function getAnnouncementStateBidValidity(
  bidValidityToDateTime: string,
  problemPeriodTime?: string
): keyof typeof ComponentAnnouncementState {
  if (!bidValidityToDateTime) {
    return;
  }

  const now = new Date();
  const parsedBidValidityToDateTime: Date = parseISO(bidValidityToDateTime);
  const problemPeriodToDateTimeWindow: Date = problemPeriodTime && convertProblemPeriodToTimeWindowDate(problemPeriodTime);

  // announcement received (now) is before -> endTime van bidValidityPeriod
  if (isBefore(now, parsedBidValidityToDateTime)) {
    return ComponentAnnouncementState.ANNOUNCEMENT_BID_PERIOD;
  }

  // now is before -> endTime problemPeriod - 75 min
  if (problemPeriodToDateTimeWindow && isBefore(now, problemPeriodToDateTimeWindow)) {
    return ComponentAnnouncementState.ANNOUNCEMENT_EXPIRED;
  }

  // after endTime problemPeriod - 75 min
  return ComponentAnnouncementState.ANNOUNCEMENT_CLOSED;
}

export function convertProblemPeriodToTimeWindowDate(time: string): Date {
  return sub(new Date(time), { minutes: problemPeriodTimeWindowMinutes });
}

export function getAnnouncementStateProblemPeriod(problemPeriodTime?: string): keyof typeof ComponentAnnouncementState {
  if (!problemPeriodTime) {
    return;
  }

  const now = new Date();
  const problemPeriodToDateTimeWindow: Date = convertProblemPeriodToTimeWindowDate(problemPeriodTime);

  if (isBefore(now, problemPeriodToDateTimeWindow)) {
    return ComponentAnnouncementState.ANNOUNCEMENT_OPEN;
  }

  if (isAfter(now, problemPeriodToDateTimeWindow)) {
    return ComponentAnnouncementState.ANNOUNCEMENT_CLOSED;
  }
}
