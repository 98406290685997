import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ph-flex-user-menu-trigger',
  templateUrl: './user-menu-trigger.component.html',
  styleUrls: ['./user-menu-trigger.component.scss']
})
export class FlexUserMenuTriggerComponent {
  @Input()
  username: string;

  constructor() {}
}
