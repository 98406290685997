import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule, RendererFactory2 } from '@angular/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import {
  ALLOWED_LANDING_PAGES,
  appInjectorProvider,
  AuthorityService,
  AVAILBLE_MENU_ITEMS,
  CONFIG_APPLICATION_TYPE,
  customLocaleEn,
  customLocaleNl,
  DefaultCustomerGuardService,
  FlexApiPrivateModule,
  FlexApplicationUpdateModule,
  FlexAuthModule,
  FlexCoreModule,
  FlexMotdModule,
  SideMenuModule,
  FlexSseStorageStoreModule,
  FlexAsyncMessagingModule,
  FlexWebSocketModule,
  FRONTEND_APP_INFO,
  matPaginatorIntlProvider,
  PRODUCTION_MODE,
  REQUIRE_AUTHENTICATION,
  SERVICE_WORKER_ENABLED,
  SUPPORTED_PAGE_TITLE_URIS,
  FlexIconsModule
} from 'flex-app-shared';
import { first } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { versionInfo } from '../environments/version-info';
import { NgxsStoreModule } from '../store/store.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { CoreModule } from './core/core.module';
import { IdconsAnnouncementsModule } from './idcons-announcements/idcons-announcements.module';
import { AcceptLanguageInterceptor } from './shared/accept-language/accept-language.interceptor';
import { UserPreferencesModule } from './shared/user-preferences/user-preferences.module';
import { FlexAppInsightsModule } from '../../../flex-app-shared/src/lib/app-insights/app-insights.module';
import { MAT_PAGINATOR_DEFAULT_OPTIONS } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig } from '@angular/material/dialog';
import { FlexScrollStrategyService } from '../../../flex-app-shared/src/lib/core/common/scroll-strategy';

export function getLocaleFromUrl(): string {
  const localeMappings = new Map([
    ['nl', 'nl'],
    ['en', 'en'],
    ['hq-en', 'en']
  ]);

  return localeMappings.get($localize.locale) || 'nl';
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FlexCoreModule.forRoot('Customer Portal', 'flex-app-customer-portal'),
    FlexAuthModule.forRoot(),
    FlexAppInsightsModule.forRoot(environment.enableAppInsights),
    FlexApiPrivateModule.forRoot(),
    FlexApplicationUpdateModule.forRoot(),
    FlexWebSocketModule.forRoot(),
    FlexAsyncMessagingModule.forRootWebsocket(),
    FlexMotdModule,
    FlexIconsModule,
    NgxsStoreModule,
    MatIconModule,
    MatSidenavModule,
    MatToolbarModule,
    MatMenuModule,
    MatSnackBarModule,
    CoreModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatMomentDateModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.serviceWorker }),
    IdconsAnnouncementsModule,
    FlexSseStorageStoreModule,
    UserPreferencesModule,
    SideMenuModule
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useFactory: getLocaleFromUrl
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AcceptLanguageInterceptor,
      multi: true
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' }
    },
    {
      provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
      useValue: {
        formFieldAppearance: 'fill'
      }
    },
    {
      provide: ALLOWED_LANDING_PAGES,
      useValue: ['imbalance-config', 'incident-reserve-availability', 'dispatching-schedule', 'intraday']
    },
    {
      provide: FRONTEND_APP_INFO,
      useValue: versionInfo
    },
    {
      provide: REQUIRE_AUTHENTICATION,
      useValue: environment.requireAuthentication
    },
    {
      provide: PRODUCTION_MODE,
      useValue: environment.production
    },
    {
      provide: SERVICE_WORKER_ENABLED,
      useValue: environment.serviceWorker
    },
    {
      provide: CONFIG_APPLICATION_TYPE,
      useValue: 'customer'
    },
    {
      provide: SUPPORTED_PAGE_TITLE_URIS,
      useValue: ['/dispatching-schedule', '/incident-reserve-availability', '/intraday', '/flex-overview', '/messages']
    },
    {
      provide: AVAILBLE_MENU_ITEMS,
      useValue: ['/dispatching-schedule', '/incident-reserve-availability', '/intraday', '/flex-overview']
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useFactory(service: FlexScrollStrategyService): MatDialogConfig {
        return {
          ...new MatDialogConfig(),
          scrollStrategy: service.flexScrollStrategy
        } as MatDialogConfig;
      },
      deps: [FlexScrollStrategyService]
    },
    appInjectorProvider,
    matPaginatorIntlProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    public rendererFactory: RendererFactory2,
    public authorityService: AuthorityService,
    public defaultCustomerGuardService: DefaultCustomerGuardService, // force instantiation for router activation subscription
    matIconRegistry: MatIconRegistry,
    router: Router
  ) {
    matIconRegistry.setDefaultFontSetClass('ic-inni');

    if (!environment.requireAuthentication) {
      this.showContent();
    } else {
      this.authorityService.authorities$.pipe(first()).subscribe(() => {
        this.showContent();
        // TODO what to do if this fails or never gets called?
      });
    }
  }

  private showContent(): void {
    // TODO replace preloader with angular preloader after angular is initialized and show a message indicating progress
    const renderer = this.rendererFactory.createRenderer(null, null);
    renderer.addClass(document.getElementById('preloader'), 'hide');
    renderer.removeClass(document.getElementById('app-root'), 'hide');
  }
}

registerLocaleData(customLocaleNl, 'nl');
registerLocaleData(customLocaleEn, 'en');
