import { Component, Inject, LOCALE_ID, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
  AvailabilityDirection,
  NewAvailabilityDialogComponent,
  NewAvailabilityDialogData
} from '../new-availability-dialog/new-availability-dialog.component';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { CalendarPeriod, MixinBase, OnDestroyMixin } from 'flex-app-shared';
import moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { IncidentReserveAvailabilityScreenDatasource } from './incident-reserve-availability-screen.datasource';
import { IncidentReserveAvailabilityFacade } from '../../../store/incident-reserve-availability/incident-reserve-availability.facade';
import { IncidentReserveAvailabilityView } from '../../../store/incident-reserve-availability/incident-reserve-availability.actions';
import { Observable } from 'rxjs';
import { getCountdownTimer$ } from './incident-reserve-availability-screen-countdown-timer';
import { addDays, addHours, addMinutes, startOfDay, subDays } from 'date-fns';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-incident-reserve-availability-screen',
  templateUrl: './incident-reserve-availability-screen.component.html',
  styleUrls: ['./incident-reserve-availability-screen.component.scss']
})
export class IncidentReserveAvailabilityScreenComponent extends OnDestroyMixin(MixinBase) {
  weekDays = [1, 2, 3, 4, 5, 6, 7].map((dayOfWeek) => moment().isoWeekday(dayOfWeek));
  AvailabilityDirection = AvailabilityDirection;
  incidentReserveAvailabilityView = IncidentReserveAvailabilityView;

  periodDatasource = new IncidentReserveAvailabilityScreenDatasource();

  cardTitleDate$: Observable<string> = this.facade.cardTitleDate$;

  isBusy$: Observable<boolean> = this.facade.isBusy$;

  @ViewChild('resetAvailabilityDialog')
  resetAvailabilityDialog: TemplateRef<any>;

  private mockNextDeadline$ = this.facade.earliestEditableDay$.pipe(map((result) => addMinutes(addHours(subDays(result, 1), 8), 30)));

  earliestEditableDay$ = this.facade.earliestEditableDay$;

  deadlineTimer$ = this.mockNextDeadline$.pipe(switchMap((deadline) => getCountdownTimer$(deadline)));

  constructor(
    public matDialog: MatDialog,
    public router: Router,
    public facade: IncidentReserveAvailabilityFacade,
    public fb: UntypedFormBuilder,
    @Inject(LOCALE_ID) public locale: string
  ) {
    super();
  }

  openEditDialog(day: Date): void {
    this.matDialog.open(NewAvailabilityDialogComponent, {
      data: {
        period: CalendarPeriod.fromDateAndTimeUnit(day, 'day'),
        enableDownwards: true,
        enableUpwards: true
      } as NewAvailabilityDialogData,
      width: '768px'
    });
  }
}
