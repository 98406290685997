import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IncidentReserveAvailabilityFacade } from '../../../store/incident-reserve-availability/incident-reserve-availability.facade';

@Component({
  selector: 'ph-service-agreement-display-label',
  templateUrl: './service-agreement-display-label.component.html',
  styleUrls: ['./service-agreement-display-label.component.scss']
})
export class ServiceAgreementDisplayLabelComponent implements OnInit {
  label$: Observable<string>;

  constructor(private facade: IncidentReserveAvailabilityFacade) {}

  ngOnInit(): void {
    this.label$ = this.facade.selectedServiceAgreement$.pipe(
      map((selectedServiceAgreement) => {
        if (selectedServiceAgreement) {
          return `${selectedServiceAgreement.label}`;
        }
      })
    );
  }
}
