<ng-container [formGroup]="formGroup">
  <mat-form-field>
    <ph-flex-autocomplete
      phFlexGopacsCustomerDataSource
      formControlName="customerId"
      [shouldOnlyEmitIds]="true"
      [shouldEmitNull]="true"
      #selectedCustomer
    ></ph-flex-autocomplete>
    <mat-label>Customer</mat-label>
  </mat-form-field>
  <mat-form-field>
    <ph-flex-autocomplete
      appGopacsGridPointDataSource
      [phFlexDataSourceFilterByCustomerId]="formGroup.get('customerId').valid && formGroup.get('customerId').value"
      phFlexDataSourceFilterClearValueIfNotSelectable
      phFlexDataSourceFilterRemoveSelectedValue
      formControlName="gridPointId"
      [shouldOnlyEmitIds]="true"
      [shouldEmitNull]="true"
      #selectedGridPoint
    ></ph-flex-autocomplete>
    <mat-label>Gridpoint</mat-label>
  </mat-form-field>

  {{ formGroup?.value | json }}
  <em (click)="showAllSelected()" class="clickable"
    >{{ (dataSource.allSelected$ | async)?.length || 0 }} components selected<ng-container
      *ngIf="(dataSource.notVisibleSelected$ | async)?.length; let notVisibleSelected"
      >, {{ notVisibleSelected }} hidden</ng-container
    ></em
  >

  <table mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <td mat-cell *matCellDef="let control">
        <mat-checkbox
          [checked]="dataSource.controlIdSelectionModel.isSelected(control.controlId)"
          (click)="$event.stopPropagation()"
          (change)="$event ? dataSource.controlIdSelectionModel.toggle(control.controlId) : null"
        ></mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="gridPointDescription">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Gridpoint description</th>
      <td mat-cell *matCellDef="let control">
        {{ control.gridPointDescription }}
      </td></ng-container
    >

    <ng-container matColumnDef="controlDescription">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Component description</th>
      <td mat-cell *matCellDef="let control">
        {{ control.controlDescription }}
      </td></ng-container
    >

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator [showFirstLastButtons]="true" [pageSize]="10" [pageSizeOptions]="[10, 20, 30, 50, 100]"></mat-paginator>
</ng-container>

<ng-template matStepLabel> Selection </ng-template>
