import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs';
import { IncidentReserveAvailabilityFacade } from '../../../store/incident-reserve-availability/incident-reserve-availability.facade';

@Component({
  selector: 'app-incident-reserve-activations-detail-screen',
  templateUrl: './incident-reserve-activations-detail-screen.component.html',
  styleUrls: ['./incident-reserve-activations-detail-screen.component.scss']
})
export class IncidentReserveActivationsDetailScreenComponent {
  problemPeriod$ = this.facade.activationMeasurements$.pipe(map((measurements) => measurements?.targetPeriod));

  constructor(public facade: IncidentReserveAvailabilityFacade) {}

  back(): void {
    history.back();
  }
}
