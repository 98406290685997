import { Directive, HostBinding, Inject, LOCALE_ID } from '@angular/core';

@Directive({
  selector: '[phFlexLocaleClass]'
})
export class LocaleClassDirective {
  @HostBinding('class.en')
  get en(): boolean {
    return this.locale === 'en';
  }

  @HostBinding('class.nl')
  get nl(): boolean {
    return this.locale === 'nl';
  }

  constructor(@Inject(LOCALE_ID) private locale: string) {}
}
