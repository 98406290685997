import { NgModule } from '@angular/core';
import { ToolbarTabsComponent } from './toolbar-tabs/toolbar-tabs.component';
import { RouterModule } from '@angular/router';
import { FlexCoreModule } from '../../core/core.module';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  declarations: [ToolbarTabsComponent],
  imports: [CommonModule, FlexCoreModule, RouterModule, MatSelectModule],
  exports: [ToolbarTabsComponent]
})
export class FlexToolbarTabsModule {}
