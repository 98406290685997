import { AfterViewInit, Component, Host, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatStep, MatStepLabel } from '@angular/material/stepper';
import { FlexNgXsFormSync, MixinBase, OnDestroyMixin } from 'flex-app-shared';
import { takeUntil } from 'rxjs/operators';
import { IntradayFacade } from '../../../../state/intraday-facade.service';
import { OrderDirection } from '../../../../state/order/intraday-order.state';
import { FormGroupProvider } from '../form-group-provider';

@Component({
  selector: 'app-step-direction',
  templateUrl: './step-direction.component.html',
  styleUrls: ['./step-direction.component.scss']
})
export class StepDirectionComponent extends OnDestroyMixin(MixinBase) implements FormGroupProvider, OnInit, AfterViewInit {
  allowedForIdconsTrueExplanationText = $localize`:@@intraday-allowedForIdconsTrueExplanationText:These intraday+ orders may be executed on the regular intraday market, or may be used for congestion management. If the deal is matched for congestion management delivery/consumption will be mandatory. This will be shown as 'Mandatory delivery' on the transactions page. Imbalance steering during the delivery period is only allowed if deals are not shown as mandatory delivery.`;
  allowedForIdconsFalseExplanationText = $localize`:@@intraday-allowedForIdconsFalseExplanationText:These intraday orders will only be executed on the regular intraday market. Delivery is not mandatory, so imbalance steering during the delivery time is allowed.`;

  OrderDirection = OrderDirection;

  @FlexNgXsFormSync('intraday.order.orderDirection', { initialSyncOnlyTruthyFormValues: false })
  formGroup = this.fb.group({
    orderDirection: [null, [Validators.required]],
    allowedForIdcons: [false]
  });

  @ViewChild(MatStepLabel) stepLabel: MatStepLabel;

  constructor(@Host() private matStep: MatStep, private fb: UntypedFormBuilder, public intradayFacade: IntradayFacade) {
    super();
  }

  getAllowedForIdconsExplanationText(): string {
    return this.formGroup.value?.allowedForIdcons ? this.allowedForIdconsTrueExplanationText : this.allowedForIdconsFalseExplanationText;
  }

  ngAfterViewInit(): void {
    this.matStep.stepLabel = this.stepLabel;
  }

  ngOnInit(): void {
    this.matStep.stepControl = this.formGroup;
    this.formGroup
      .get('orderDirection')
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe((_) => {
        this.intradayFacade.resetOrderVolumes();
      });
    this.intradayFacade.isIntradayPlusToggleDisabled$.pipe(takeUntil(this.onDestroy$)).subscribe((intradayPlusToggleDisabled) => {
      this.formGroup.patchValue({ allowedForIdcons: !intradayPlusToggleDisabled });
      if (intradayPlusToggleDisabled) {
        this.formGroup.get('allowedForIdcons')?.disable();
      } else {
        this.formGroup.get('allowedForIdcons')?.enable();
      }
    });
  }
}
