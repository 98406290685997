<h2 mat-dialog-title i18n="@@userPreferencesDialog-title">Notifications</h2>
<mat-dialog-content [formGroup]="dialogForm">
  <ph-flex-loading-context [isLoading]="!initialized">
    <div class="info" i18n="@@userPreferencesDialog-infoEmail">Receive e-mail notifications for:</div>
    <div>
      <mat-checkbox formControlName="intraDayOrdersRequestedEmailEnabled" i18n="@@userPreferencesDialog-requestForOrders"
        >Request for intraday+ orders
      </mat-checkbox>
    </div>
    <div>
      <mat-checkbox formControlName="intraDayOrderMatchedEmailEnabled" i18n="@@userPreferencesDialog-matchedOrders"
        >Matched orders
      </mat-checkbox>
    </div>
    <div class="info" i18n="@@userPreferencesDialog-infoSms">Receive SMS notifications for:</div>
    <div>
      <mat-checkbox formControlName="intraDayOrdersRequestedSmsEnabled" i18n="@@userPreferencesDialog-requestForOrders"
        >Request for intraday+ orders
      </mat-checkbox>
    </div>
  </ph-flex-loading-context>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-stroked-button class="primary" mat-dialog-close>
    <ph-flex-vertical-center>
      <mat-icon svgIcon="ic-old:close"></mat-icon>
      <span i18n="@@cancelButton">Cancel</span>
    </ph-flex-vertical-center>
  </button>
  <ph-flex-save-button (clickChanged)="handleSave()" [pending]="busy" [error]="error" [disabled]="dialogForm.invalid">
    <ph-flex-vertical-center> <mat-icon svgIcon="ic-old:save"></mat-icon><span i18n="@@saveButton">Save</span> </ph-flex-vertical-center>
  </ph-flex-save-button>
</mat-dialog-actions>
