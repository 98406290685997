import { Component, HostBinding, Inject, Input, LOCALE_ID, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
  AdjustAvailability,
  AvailabilityType,
  CalendarPeriod,
  dayChange$,
  MixinBase,
  Money,
  normalizeToDate,
  ObservableInputsMixin,
  OnDestroyMixin,
  temporalUnitToDateFormat
} from 'flex-app-shared';
import { IncidentReserveAvailabilityFacade } from '../../../store/incident-reserve-availability/incident-reserve-availability.facade';
import { IncidentReserveAvailabilityViewItem } from '../../../store/incident-reserve-availability/incident-reserve-availability.state';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { IncidentReserveAvailabilityView } from '../../../store/incident-reserve-availability/incident-reserve-availability.actions';
import { filter, first, map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import {
  AvailabilityDirection,
  NewAvailabilityDialogComponent,
  NewAvailabilityDialogData
} from '../new-availability-dialog/new-availability-dialog.component';
import { format, isFirstDayOfMonth } from 'date-fns';
import { nl } from 'date-fns/locale';

@Component({
  selector: 'app-availability-item',
  templateUrl: './availability-item.component.html',
  styleUrls: ['./availability-item.component.scss']
})
export class AvailabilityItemComponent extends ObservableInputsMixin(OnDestroyMixin(MixinBase)) implements OnChanges, OnInit {
  AvailabilityType = AvailabilityType;
  @Input() period: CalendarPeriod;

  period$ = this.oi.observe(() => this.period);

  private availability: IncidentReserveAvailabilityViewItem | null = null;
  availability$: null | Observable<IncidentReserveAvailabilityViewItem> = null;

  downwardsAvailabilityFee$: Observable<Money>;
  upwardsAvailabilityFee$: Observable<Money>;

  currentView$: Observable<IncidentReserveAvailabilityView> = this.facade.currentView$;
  isYearView$ = this.currentView$.pipe(map((currentView) => currentView === IncidentReserveAvailabilityView.YEAR));
  isToday$: Observable<boolean> = dayChange$.pipe(
    startWith(null),
    map(() => CalendarPeriod.contains(this.availability.period, new Date()))
  );
  activationCount$ = this.period$.pipe(switchMap((period) => this.facade.itemActivationCount$(period)));

  periodIndicator$ = new BehaviorSubject<string>(null);

  canEdit$: Observable<boolean>;

  private availabilitySubscription: Subscription;
  private currentView: IncidentReserveAvailabilityView | null = null;

  constructor(@Inject(LOCALE_ID) public locale: string, public facade: IncidentReserveAvailabilityFacade, public dialog: MatDialog) {
    super();
  }

  @HostBinding('class.current-availability-item') get isCurrent(): boolean {
    return this.availability?.isCurrent;
  }

  @HostBinding('class.disabled') get disabled(): boolean {
    return this.availability?.readOnly;
  }

  @HostBinding('class.period-padding') get isPeriodPadding(): boolean {
    return this.availability?.isPeriodPadding;
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges();
    if (changes.period) {
      this.availability$ = this.facade.itemAvailability(this.period);
      this.canEdit$ = this.availability$.pipe(
        map((availability) => {
          if (!availability) {
            return false;
          }

          return !availability.readOnly;
        })
      );
      this.downwardsAvailabilityFee$ = this.availability$.pipe(
        filter((a) => !!a),
        switchMap((availability) => this.facade.itemDownwardsAvailabilityFee$(availability.period))
      );
      this.upwardsAvailabilityFee$ = this.availability$.pipe(
        filter((a) => !!a),
        switchMap((availability) => this.facade.itemUpwardsAvailabilityFee$(availability.period))
      );
      if (this.availabilitySubscription) {
        this.availabilitySubscription.unsubscribe();
        this.availabilitySubscription = null;
      }
      this.availabilitySubscription = this.availability$.pipe(takeUntil(this.onDestroy$)).subscribe((value) => (this.availability = value));
    }
  }

  ngOnInit(): void {
    this.currentView$.pipe(takeUntil(this.onDestroy$)).subscribe((currentView) => (this.currentView = currentView));
    combineLatest([this.availability$, this.currentView$])
      .pipe(first())
      .subscribe(([availability, currentView]) => {
        this.periodIndicator$.next(this.formatPeriod(availability.period, currentView));
      });
  }

  formatPeriod(period: CalendarPeriod, currentView: IncidentReserveAvailabilityView): string {
    let dateFormat: string;
    const startDate = normalizeToDate(period.startDate);
    const locale = this.locale === 'nl' ? nl : null;

    switch (currentView) {
      case IncidentReserveAvailabilityView.MONTH:
        dateFormat = isFirstDayOfMonth(startDate) ? 'd MMM' : `${temporalUnitToDateFormat('day', 'numeric')}`;
        break;
      case IncidentReserveAvailabilityView.YEAR:
        dateFormat = `${temporalUnitToDateFormat('month', 'wide')}`;
        break;
      default:
        dateFormat = temporalUnitToDateFormat(CalendarPeriod.getView(period), 'abbreviated');
    }

    return format(startDate, dateFormat, { locale });
  }

  handleUpwardsEditClick(event: Event): void {
    this.openEditDialog(true, false);
    event.stopPropagation();
  }

  handleDownwardsEditClick(event: Event): void {
    this.openEditDialog(false, true);
    event.stopPropagation();
  }

  handleEditClick(event: Event): void {
    this.openEditDialog(true, true);
    event.stopPropagation();
  }

  handlePeriodClick(event: Event): void {
    if (this.currentView === IncidentReserveAvailabilityView.YEAR) {
      this.facade.selectMonth(this.period);
    }
  }

  private openEditDialog(enableUpwards: boolean, enableDownwards: boolean): void {
    this.dialog.open(NewAvailabilityDialogComponent, {
      data: {
        period: this.period,
        enableDownwards,
        enableUpwards
      } as NewAvailabilityDialogData,
      width: '768px'
    });
  }
}
