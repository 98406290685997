import { Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { DateRangeFixedPeriodCalendarHeaderComponent } from '../date-range-fixed-period-date-picker-header/date-range-fixed-period-calendar-header.component';
import { AVAILABLE_PERIOD_UNITS, PeriodUnit } from '../default-period';

@Component({
  selector: 'ph-flex-date-range-fixed-period-day',
  templateUrl: './date-range-fixed-period-day.component.html',
  styleUrls: ['./date-range-fixed-period-day.component.scss'],
  providers: [
    {
      provide: AVAILABLE_PERIOD_UNITS,
      useValue: [PeriodUnit.DAY, PeriodUnit.MONTH, PeriodUnit.YEAR]
    }
  ]
})
export class DateRangeFixedPeriodDayComponent {
  startView = 'month';

  @Input()
  label: string;

  @Input()
  control: UntypedFormControl;

  @Input()
  min: Date;

  @Input()
  max: Date;

  calendarHeaderComponent = DateRangeFixedPeriodCalendarHeaderComponent;
}
