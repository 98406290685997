import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostBinding,
  Inject,
  IterableDiffers,
  OnChanges,
  OnInit,
  Optional,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { MatHeaderRowDef } from '@angular/material/table';
import { CDK_TABLE } from '@angular/cdk/table';
import { OnDestroyMixin } from '../../../core/common/on-destroy.mixin';
import { TableSelectionModel } from '../table-selection-model';
import { pairwise, startWith, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[phFlexTableSelectionContextRow]',
  providers: [
    {
      provide: MatHeaderRowDef,
      useExisting: TableSelectionContextRowDirective
    }
  ]
})
export class TableSelectionContextRowDirective<T> extends OnDestroyMixin(MatHeaderRowDef) implements OnChanges {
  constructor(
    template: TemplateRef<any>,
    _differs: IterableDiffers,
    private viewContainer: ViewContainerRef,
    tableSelectionModel: TableSelectionModel<T>,
    @Inject(CDK_TABLE) @Optional() public _table?: any
  ) {
    super(template, _differs);

    _table.addHeaderRowDef(this);

    tableSelectionModel.contextColumns$
      .pipe(startWith(undefined), pairwise(), takeUntil(this.onDestroy$))
      .subscribe(([previousColumns, columns]) => {
        this.columns = columns;
        this.ngOnChanges({
          columns: {
            currentValue: columns,
            firstChange: previousColumns === undefined,
            previousValue: previousColumns,
            isFirstChange(): boolean {
              return previousColumns === undefined;
            }
          }
        });
      });
  }

  // Prerender fails to recognize that ngOnChanges in a part of this class through inheritance.
  // Explicitly define it so that the method is called as part of the Angular lifecycle.
  override ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }
}
