import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IntradayOrderStatus } from '../../../../shared/intraday-order/intraday-order.service';

@Component({
  selector: 'app-intraday-order-status',
  templateUrl: './intraday-order-status.component.html',
  styleUrls: ['./intraday-order-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntradayOrderStatusComponent {
  IntradayOrderStatus = IntradayOrderStatus;

  @Input() value: IntradayOrderStatus;

  matches(...matchingStatuses: IntradayOrderStatus[]): boolean {
    return matchingStatuses.includes(this.value);
  }
}
