import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { Store } from '@ngxs/store';
import {
  AppInjector,
  Direction,
  IntraDayDealDisplayStatus,
  IntradayDealStatus,
  MixinBase,
  OnDestroyMixin,
  PaginatedDataSource,
  PostPendingIntradayDeal,
  TableFilterComponent
} from 'flex-app-shared';
import { Subscription } from 'rxjs';
import { skip } from 'rxjs/operators';
import { IntradayState } from '../../state/intraday-state.service';
import { UpdateTodaysIntraDayDealsCommand } from '../../state/intraday.actions';

@Component({
  selector: 'app-intraday-deal-status-overview',
  templateUrl: './intraday-deal-status-overview.component.html',
  styleUrls: ['./intraday-deal-status-overview.component.scss']
})
export class IntradayDealStatusOverviewComponent extends OnDestroyMixin(MixinBase) implements OnInit {
  FILTER_ALL = 'ALL';

  Direction = Direction;
  IntraDayDealStatus = IntradayDealStatus;
  IntraDayDealDisplayStatus = IntraDayDealDisplayStatus;
  PostPendingIntraDayDeal = PostPendingIntradayDeal;

  today: Date = new Date();
  @Input()
  dealStatus: IntradayDealStatus | null;
  @Input()
  direction: Direction | null;

  dataSource: DealStatusDataSource;
  columns = [
    'dealDirection',
    'dealStatus',
    'created',
    'customerLegalName',
    'gridPointDescription',
    'controlDescription',
    'dealPeriodDay',
    'dealPeriod',
    'dealPrice',
    'dealCapacity',
    'dealAmount',
    'dealReference'
  ];
  @ViewChild(MatPaginator, { static: true }) private readonly paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) private readonly sort: MatSort;
  @ViewChild(TableFilterComponent, { static: true }) private readonly filter: TableFilterComponent;
  @ViewChild('categoryFilter', { static: true, read: MatSelect }) private readonly categoryFilter: MatSelect;
  @ViewChild('dealStatusFilter', { static: true, read: MatSelect }) private readonly statusFilter: MatSelect;
  @ViewChild('directionFilter', { static: true, read: MatSelect }) private readonly directionFilter: MatSelect;

  constructor() {
    super();
    this.dataSource = new DealStatusDataSource();
  }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filter = this.filter;
    this.dataSource.selectedCategory = this.categoryFilter;
    this.dataSource.selectedDealStatus = this.statusFilter;
    this.dataSource.selectedDirection = this.directionFilter;
    this.statusFilter.value = this.dealStatus ? this.dealStatus : 'ALL';
    this.directionFilter.value = this.direction ? this.direction : 'ALL';
  }
}

export class DealStatusDataSource extends PaginatedDataSource<PostPendingIntradayDeal> {
  selectedCustomerID: string;
  private store = AppInjector.get(Store);
  private lastSubscription: Subscription;
  private selectedCategoriesSubscription: Subscription;
  private selectedDealStatusSubscription: Subscription;
  private selectedDirectionSubscription: Subscription;

  constructor() {
    super();
  }

  private _selectedCategory: MatSelect;

  get selectedCategory(): MatSelect {
    return this._selectedCategory;
  }

  set selectedCategory(value: MatSelect) {
    this.registerCategorySelect(value);
  }

  private _selectedDealStatus: MatSelect;

  get selectedDealStatus(): MatSelect {
    return this._selectedDealStatus;
  }

  set selectedDealStatus(value: MatSelect) {
    this.registerDealStatusSelect(value);
  }

  private _selectedDirection: MatSelect;

  get selectedDirection(): MatSelect {
    return this._selectedDirection;
  }

  set selectedDirection(value: MatSelect) {
    this.registerDirectionSelect(value);
  }

  loadData(pageIndex?: number, pageSize?: number, searchTerm?: string, sort?: string): void {
    if (this.lastSubscription) {
      this.lastSubscription.unsubscribe();
      this.lastSubscription = null;
    }

    const category = this.selectedCategory?.value as string;
    const dealStatus = this.translateDealStatus(this.selectedDealStatus?.value);
    const direction = this.translateDirection(this.selectedDirection?.value);

    this.store.dispatch(
      new UpdateTodaysIntraDayDealsCommand(pageIndex, pageSize, searchTerm, sort, category, this.selectedCustomerID, dealStatus, direction)
    );

    this.lastSubscription = this.store
      .select(IntradayState.receivedDeals)
      .pipe(skip(1))
      .subscribe((result) => {
        const data: PostPendingIntradayDeal[] = result.content;
        this.dataSubject.next(data);
        this.updatePaginatorFromPaginatedResponse(result);
      });
  }

  protected registerCategorySelect(value: MatSelect): void {
    this._selectedCategory = value;
    if (this.selectedCategoriesSubscription) {
      this.selectedCategoriesSubscription.unsubscribe();
    }
    this.selectedCategoriesSubscription = this.selectedCategory.valueChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.internalLoadData();
    });
  }

  protected registerDealStatusSelect(value: MatSelect): void {
    this._selectedDealStatus = value;
    if (this.selectedDealStatusSubscription) {
      this.selectedDealStatusSubscription.unsubscribe();
    }
    this.selectedDealStatusSubscription = this.selectedDealStatus.valueChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.internalLoadData();
    });
  }

  protected registerDirectionSelect(value: MatSelect): void {
    this._selectedDirection = value;
    if (this.selectedDirectionSubscription) {
      this.selectedDirectionSubscription.unsubscribe();
    }
    this.selectedDirectionSubscription = this.selectedDirection.valueChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.internalLoadData();
    });
  }

  private translateDealStatus(dealStatus: string): IntradayDealStatus | null {
    if (dealStatus === 'ALL' || dealStatus === null || dealStatus === undefined) {
      return null;
    }
    return dealStatus as IntradayDealStatus;
  }

  private translateDirection(direction: string): Direction | null {
    if (direction === 'ALL' || direction === null || direction === undefined) {
      return null;
    }
    return direction as Direction;
  }
}
