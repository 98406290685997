import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {
  Direction,
  FlexTableFilterModule,
  FrontendPaginatedDataSource,
  FrontendPaginationHelper,
  MixinBase,
  OnDestroyMixin,
  OnDestroyProvider,
  PaginatedDataSource,
  SelectFilterProvider,
  SubjectProvider,
  TableFilterComponent,
  TableFilterProvider,
  TableSelectionAction,
  tableSelectionHeaders,
  TableSelectionModel
} from 'flex-app-shared';
import { get, orderBy } from 'lodash-es';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { map, skip, switchMap, takeUntil } from 'rxjs/operators';
import { IntradayOrder, IntradayOrderStatus, IntradayOrderStatusHelper } from '../../../shared/intraday-order/intraday-order.service';
import { IntradayFacade } from '../../state/intraday-facade.service';
import { CancelOrderConfirmationComponent } from '../../state/order/cancel-order-confirmation/cancel-order-confirmation.component';
import { MatSelect } from '@angular/material/select';
import { getOrderType, OrderType } from '../../intraday/intraday-deal-direction/intraday-deal-direction.component';
const FILTER_ALL = 'ALL';
export class OpenOrdersDataSource extends FrontendPaginatedDataSource<IntradayOrder> {
  private lastSubscription: Subscription;
  private selectedCategoryFilterProvider = new SelectFilterProvider(this);
  private orderTypeFilterProvider = new SelectFilterProvider(this);
  private orderStatusFilterProvider = new SelectFilterProvider(this);

  paginationHelper = new FrontendPaginationHelper<IntradayOrder>();

  setSelectedCategory(matSelect: MatSelect): void {
    this.selectedCategoryFilterProvider.register(matSelect);
  }

  setOrderTypeFilter(matSelect: MatSelect): void {
    this.orderTypeFilterProvider.register(matSelect);
  }

  setOrderStatusFilter(matSelect: MatSelect): void {
    this.orderStatusFilterProvider.register(matSelect);
  }

  constructor(onDestroyProvider: OnDestroyProvider, private facade: IntradayFacade) {
    super(onDestroyProvider);

    this.refreshDataAlwaysObservables.push(
      this.selectedCategoryFilterProvider.onChanges$,
      this.orderTypeFilterProvider.onChanges$,
      this.orderStatusFilterProvider.onChanges$
    );

    this.resetPaginatorObservables.push(
      this.selectedCategoryFilterProvider.onChanges$,
      this.orderTypeFilterProvider.onChanges$,
      this.orderStatusFilterProvider.onChanges$
    );

    combineLatest([this.facade.idconsOpenOrders$, this.loadDataSubject])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([newResult, loadDataInfo]) => {
        const { pageIndex, pageSize, searchTerm, sort } = loadDataInfo as any;

        let filteredResult = newResult || [];

        const orderTypeFilterValue = this.orderTypeFilterProvider.item?.value;
        const freeTextProperty = this.selectedCategoryFilterProvider.item?.value;
        const orderStatusFilterValue = this.orderStatusFilterProvider.item?.value;

        if (orderTypeFilterValue && orderTypeFilterValue !== FILTER_ALL) {
          filteredResult = filteredResult.filter((item) => this.getOrderTypeFromOrder(item) === orderTypeFilterValue);
        }

        if (orderStatusFilterValue && orderStatusFilterValue !== FILTER_ALL) {
          filteredResult = filteredResult.filter((item) => item.status === orderStatusFilterValue);
        }

        if (searchTerm) {
          filteredResult = filteredResult.filter((item) => get(item, freeTextProperty)?.toLowerCase()?.includes(searchTerm.toLowerCase()));
        }

        this.paginationHelper.updateData(filteredResult);

        this.dataSubject.next(this.paginationHelper.handleLoadData(pageIndex, pageSize, '', sort));
        this.updatePaginator(pageIndex, pageSize, this.paginationHelper.filteredData.length);
      });
  }

  private getOrderTypeFromOrder(order: IntradayOrder): OrderType {
    return getOrderType(order.direction, order.allowedForIdcons);
  }
}

@Component({
  selector: 'app-open-orders',
  templateUrl: './open-orders.component.html',
  styleUrls: ['./open-orders.component.scss']
})
export class OpenOrdersComponent extends OnDestroyMixin(MixinBase) implements AfterViewInit {
  Direction = Direction;
  OrderType = OrderType;
  OrderStatus = IntradayOrderStatus;
  FILTER_ALL = FILTER_ALL;

  TableSelectionAction = TableSelectionAction;
  dataSource: OpenOrdersDataSource;

  selectColumns = tableSelectionHeaders;

  headerColumnsProvider = new SubjectProvider<string[]>(this);
  headerColumns$ = this.headerColumnsProvider.value$;

  columns = [];

  @ViewChild(TableSelectionModel)
  private tableSelectionModel: TableSelectionModel<IntradayOrder>;

  @ViewChild(TableFilterComponent)
  private tableFilterComponent: TableFilterComponent;

  @ViewChild('orderTypeFilter')
  private orderTypeFilter: MatSelect;

  @ViewChild('categoryFilter')
  private categoryFilter: MatSelect;

  @ViewChild('orderStatusFilter')
  private orderStatusFilter: MatSelect;

  actionObservables = {
    [TableSelectionAction.DELETE]: of(null).pipe(
      switchMap(() =>
        this.intraDayFacade.cancelIntradayOrders(this.tableSelectionModel.getSelectedRows(), this.tableSelectionModel.allRowsSelected)
      )
    )
  };

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private intraDayFacade: IntradayFacade, private dialog: MatDialog) {
    super();
    this.dataSource = new OpenOrdersDataSource(this, intraDayFacade);
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.tableSelectionModel.followPaginatedDataSource(this.dataSource);

    this.dataSource.filter = this.tableFilterComponent;
    this.dataSource.setSelectedCategory(this.categoryFilter);
    this.dataSource.setOrderTypeFilter(this.orderTypeFilter);
    this.dataSource.setOrderStatusFilter(this.orderStatusFilter);

    this.columns = [
      'select',
      'direction',
      'status',
      'created',
      'customerLegalName',
      'gridPointDescription',
      'controlDescription',
      'dealPeriodDay',
      'dealPeriod',
      'price',
      'remainingCapacity',
      'amount'
    ];

    this.headerColumnsProvider.follow(
      this.tableSelectionModel.hasSelection$.pipe(map((hasSelections) => (hasSelections ? this.selectColumns : this.columns)))
    );
  }

  isOrderCancelable$(order: IntradayOrder): Observable<boolean> {
    return this.intraDayFacade.idconsDealingEnabled$.pipe(
      map((idconsDealingEnabled) => idconsDealingEnabled && IntradayOrderStatusHelper.isCancelable(order.status))
    );
  }

  cancelOrder(order: IntradayOrder): void {
    this.dialog.open(CancelOrderConfirmationComponent, {
      data: order.id
    });
  }

  trackBy(index: number, item: IntradayOrder): any {
    if (item) {
      return item.id;
    }
    return index;
  }
}
