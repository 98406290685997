import { Action, Selector, SelectorOptions, State, StateContext, Store } from '@ngxs/store';
import { Inject, Injectable } from '@angular/core';
import {
  IntraDayDealsHistoryPaginationUpdatedEvent,
  IntraDayDealsHistorySelectedFiltersChangedEvent,
  IntraDayHistoryActivate,
  IntraDayHistoryDeactivate,
  IntraDayHistoryFormFiltersChangedEvent,
  IntraDayHistoryReceivedEvent,
  UpdateIntraDayDealsHistoryCommand,
  UpdateIntraDayHistoryGridPointCommand
} from './intraday-history.actions';
import { RouterNavigation } from '@ngxs/router-plugin';
import {
  CUSTOMER_ID_SESSION_STORAGE_KEY,
  CUSTOMER_STORAGE_TOKEN,
  DateFnsParseFormatters,
  DISPATCH_SCHEDULE_GRID_POINT_ID_SESSION_STORAGE_KEY,
  GridPoint,
  GridPointService,
  IntradayDealStatus,
  IntradayService,
  NgXsFormModel,
  PaginatedResponse,
  PostPendingIntradayDeal
} from 'flex-app-shared';
import { UpdateFormValue } from '@ngxs/form-plugin';
import { tap } from 'rxjs/operators';
import { pick } from 'lodash-es';
import format from 'date-fns/format';

export class IntraDayHistoryStateModel {
  active = false;
  shouldReset = false;
  shouldInitialize = true;

  filterForm = NgXsFormModel.defaults({
    customerId: '',
    gridPointId: '',
    controlId: '',
    dealReference: '',
    dealPeriod: format(new Date(), DateFnsParseFormatters.MONTH),
    createdPeriod: format(new Date(), DateFnsParseFormatters.MONTH)
  });

  selectedFilters: string[] = [];

  showAllDealsForm = NgXsFormModel.defaults({
    showAllDeals: false
  });

  dealsBusy = false;
  deals: PaginatedResponse<PostPendingIntradayDeal>;
  hasIdconsDeals: boolean;

  pageIndex: number;
  pageSize: number;
  sort: any;
  filter: any;

  /**
   * The customerId used to fetch the current deals
   */
  currentCustomerId: string;

  /**
   * Last filter object used
   */
  currentFilter: any;

  currentGridPointId: string;
  currentGridPoint: GridPoint;

  /**
   * Temp value for comparison with updateFormValue
   */
  lastFormValue: any;
}

@State({
  name: 'history',
  defaults: new IntraDayHistoryStateModel()
})
@Injectable({
  providedIn: 'root'
})
@SelectorOptions({
  injectContainerState: false,
  suppressErrors: false
})
export class IntradayHistoryState {
  constructor(
    private intraDayService: IntradayService,
    private gridPointService: GridPointService,
    @Inject(CUSTOMER_STORAGE_TOKEN) private storage: Storage,
    private store: Store
  ) {}

  @Selector([IntradayHistoryState])
  static deals(state: IntraDayHistoryStateModel): PaginatedResponse<PostPendingIntradayDeal> {
    return state.deals;
  }

  @Selector([IntradayHistoryState])
  static baseFilters(state: IntraDayHistoryStateModel): any {
    return state.filterForm.model;
  }

  @Selector([IntradayHistoryState])
  static selectedFilters(state: IntraDayHistoryStateModel): string[] {
    return state.selectedFilters;
  }

  @Selector([IntradayHistoryState])
  static showAllDeals(state: IntraDayHistoryStateModel): any {
    return state.showAllDealsForm.model?.showAllDeals;
  }

  @Selector([IntradayHistoryState.baseFilters, IntradayHistoryState.showAllDeals, IntradayHistoryState.selectedFilters])
  static filters(baseFilters: any, showAllDeals: boolean, selectedFilters: string[]): any {
    const filter: any = pick(baseFilters ?? {}, selectedFilters);

    if (!showAllDeals) {
      filter.dealStatus = IntradayDealStatus.CONFIRMED;
    }

    return filter;
  }

  @Selector([IntradayHistoryState])
  static selectedGridPoint(state: IntraDayHistoryStateModel): GridPoint | null {
    return state.currentGridPoint;
  }

  @Selector([IntradayHistoryState])
  static active(state: IntraDayHistoryStateModel): boolean {
    return state.active;
  }

  @Selector([IntradayHistoryState])
  static dealsBusy(state: IntraDayHistoryStateModel): boolean {
    return state.dealsBusy;
  }

  @Action(IntraDayHistoryActivate)
  activateState({ patchState }: StateContext<IntraDayHistoryStateModel>): any {
    patchState({
      active: true
    });
  }

  @Action(IntraDayHistoryDeactivate)
  deactivateState({ patchState }: StateContext<IntraDayHistoryStateModel>): any {
    patchState({
      active: false, // We cannot clear the state here, since this can trigger a form update, which can cause a route change
      shouldReset: true
    });
  }

  @Action(RouterNavigation)
  routerInit(
    { getState, setState, patchState, dispatch }: StateContext<IntraDayHistoryStateModel>,
    { routerState }: RouterNavigation
  ): any {
    if (!getState().active) {
      if (getState().shouldReset) {
        setState(new IntraDayHistoryStateModel());
      }
      return;
    } else if (getState().shouldInitialize) {
      const gridPointId =
        (this.storage.getItem(CUSTOMER_ID_SESSION_STORAGE_KEY) &&
          this.storage.getItem(DISPATCH_SCHEDULE_GRID_POINT_ID_SESSION_STORAGE_KEY)) ||
        '';
      const customerId = this.storage.getItem(CUSTOMER_ID_SESSION_STORAGE_KEY) || '';

      patchState({
        shouldInitialize: false,
        filterForm: NgXsFormModel.patchModel(getState().filterForm, {
          customerId,
          gridPointId
        })
      });

      // Aside from updating state, also make sure the gridPoint with the given id is fetched, so we can make sure it matches the selected customer, or clear it if it doesn't.
      dispatch(new UpdateIntraDayHistoryGridPointCommand(gridPointId));
    }
  }

  @Action(IntraDayHistoryFormFiltersChangedEvent)
  handleTriggerUpdateIntraDayHistoryCommand({ getState, dispatch, patchState }: StateContext<IntraDayHistoryStateModel>): any {
    const filter = getState().filterForm.model;

    const modelCustomerId = filter.customerId;
    const modelGridPointId = filter.gridPointId;

    this.storage.setItem(CUSTOMER_ID_SESSION_STORAGE_KEY, modelCustomerId || '');

    if (getState().currentCustomerId && getState().currentCustomerId !== modelCustomerId) {
      // Customer ID has changed, reset gridPointId
      this.storage.setItem(DISPATCH_SCHEDULE_GRID_POINT_ID_SESSION_STORAGE_KEY, '');

      setTimeout(() => {
        // Patch state in setTimeout, otherwise it is ignored in ngXsFormSync due to updating being true.
        patchState({
          filterForm: NgXsFormModel.patchModel(getState().filterForm, {
            gridPointId: '',
            controlId: ''
          })
        });
        // Also clear the grid point data
        dispatch(new UpdateIntraDayHistoryGridPointCommand());
      });
    } else {
      this.storage.setItem(DISPATCH_SCHEDULE_GRID_POINT_ID_SESSION_STORAGE_KEY, modelGridPointId || '');
    }

    // Update gridPoint and control
    if (modelGridPointId !== getState().currentGridPointId) {
      dispatch(new UpdateIntraDayHistoryGridPointCommand(modelGridPointId));
    }

    return dispatch(new UpdateIntraDayDealsHistoryCommand());
  }

  @Action(UpdateIntraDayHistoryGridPointCommand, { cancelUncompleted: true })
  handleUpdateIntraDayHistoryGridPointCommand(
    { patchState, getState }: StateContext<IntraDayHistoryStateModel>,
    { gridPointId }: UpdateIntraDayHistoryGridPointCommand
  ): any {
    if (!gridPointId) {
      // Clear grid point data
      patchState({
        currentGridPointId: null,
        currentGridPoint: null
      });
      return;
    }

    return this.gridPointService.getById(gridPointId).pipe(
      tap((result) => {
        const currentCustomerId = getState().filterForm.model.customerId;
        if (currentCustomerId && result.customerId !== currentCustomerId) {
          // Grid point mismatch with customer, reset grid point id

          patchState({
            filterForm: NgXsFormModel.patchModel(getState().filterForm, {
              gridPointId: ''
            }),

            currentGridPointId: null,
            currentGridPoint: null
          });

          // Also reset session storage
          this.storage.setItem(DISPATCH_SCHEDULE_GRID_POINT_ID_SESSION_STORAGE_KEY, '');
        } else {
          patchState({
            currentGridPointId: gridPointId,
            currentGridPoint: result
          });
        }
      })
    );
  }

  @Action(IntraDayDealsHistoryPaginationUpdatedEvent)
  handleUpdateIntraDayDealsHistoryPaginationUpdatedEvent(
    { patchState, dispatch }: StateContext<IntraDayHistoryStateModel>,
    { pageIndex, pageSize, sort }: IntraDayDealsHistoryPaginationUpdatedEvent
  ): any {
    patchState({
      pageIndex,
      pageSize,
      sort
    });
    dispatch(new UpdateIntraDayDealsHistoryCommand());
  }

  @Action(UpdateIntraDayDealsHistoryCommand, { cancelUncompleted: true })
  handleUpdateIntraDayHistoryDeals(
    { dispatch, getState, patchState }: StateContext<IntraDayHistoryStateModel>,
    action: UpdateIntraDayDealsHistoryCommand
  ): any {
    // Get data from the action, if available, and otherwise use the last value from state
    let pageIndex = getState().pageIndex;
    const pageSize = getState().pageSize;
    const sort = getState().sort;
    const filter = this.store.selectSnapshot(IntradayHistoryState.filters);

    if (!pageSize) {
      // Fallback, in case this gets called without any data, we should not send the request since we will potentially get a LOT of data.
      console.warn('ignored UpdateIntraDayDealsHistoryCommand action: ', action);
      return;
    }

    if (filter !== getState().currentFilter) {
      pageIndex = 0;

      // Reset paginator
      patchState({
        pageIndex
      });
    }

    patchState({
      dealsBusy: true,
      currentCustomerId: null
    });

    return this.intraDayService.getFilteredDeals(pageIndex, pageSize, sort, filter).subscribe({
      next: (result) => {
        patchState({
          dealsBusy: false,
          currentFilter: filter,
          currentCustomerId: filter?.customerId
        });
        dispatch(new IntraDayHistoryReceivedEvent(result.deals, result.unpagedContainsMandatoryDeals));
      },
      error: () => {
        patchState({
          dealsBusy: false
        });
      }
    });
  }

  @Action(IntraDayDealsHistorySelectedFiltersChangedEvent)
  handleIntraDayDealsHistorySelectedFiltersChangedEvent(
    { patchState, dispatch }: StateContext<IntraDayHistoryStateModel>,
    { selectedFilters }: IntraDayDealsHistorySelectedFiltersChangedEvent
  ): any {
    patchState({
      selectedFilters
    });
    dispatch(new IntraDayHistoryFormFiltersChangedEvent());
  }

  @Action(IntraDayHistoryReceivedEvent)
  handleIntraDayHistoryReceivedEvent(
    { patchState, getState }: StateContext<IntraDayHistoryStateModel>,
    { deals, unpagedContainsMandatoryDeals }: IntraDayHistoryReceivedEvent
  ): any {
    if (!getState().active) {
      return;
    }

    patchState({
      dealsBusy: false,
      deals,
      hasIdconsDeals: unpagedContainsMandatoryDeals
    });
  }

  @Action(UpdateFormValue)
  handleUpdateFormValue({ getState, dispatch, patchState }: StateContext<IntraDayHistoryStateModel>, { payload }: UpdateFormValue): any {
    if (!getState().active) {
      return;
    }
    if (payload.path.includes('history.filterForm')) {
      if (!payload.value.customerId && getState().lastFormValue?.customerId) {
        // CustomerId was cleared, resetting gridPointId and controlId
        patchState({
          filterForm: NgXsFormModel.patchModel(getState().filterForm, {
            gridPointId: null,
            controlId: null
          })
        });
      }
      patchState({
        lastFormValue: payload.value
      });
    }

    if (payload.path.includes('history.filterForm') || payload.path.includes('history.showAllDeals')) {
      dispatch(new IntraDayHistoryFormFiltersChangedEvent());
    }
  }
}
