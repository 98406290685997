import { IdconsAnnouncement } from '../../app/shared/idcons/announcement/idcons-announcement';
import { AsyncMessage } from 'flex-app-shared';

export class LoadIdconsAnnouncementsCommand {
  public static type: string = '[Idcons Announcements] Load announcements command';
}

export class IdconsAnnouncementsLoadedEvent {
  public static type: string = '[Idcons Announcements] Announcements loaded event';

  constructor(public announcements: IdconsAnnouncement[]) {}
}

export class IdconsActiveAnnouncementsMarkAsReadCommand {
  public static type: string = '[Idcons Announcements] Mark all provided announcement ids as read';

  constructor(public activeAnnouncements: string[]) {}
}

export class IdconsAnnouncementsMarkAllAsReadCommand {
  public static type: string = '[Idcons Announcements] Mark all announcements as read';

  constructor() {}
}

export class IdconsAnnouncementSseMessageReceived {
  public static type: string = '[Idcons Announcements] SseMessage received';

  constructor(public message: AsyncMessage) {}
}
