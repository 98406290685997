import { Component, Input } from '@angular/core';

@Component({
  selector: 'ph-flex-number',
  template: `{{ value | number: digitsInfo }}`
})
export class FlexNumberComponent {
  @Input() value: number;
  @Input() digitsInfo: string = '0.0-0';
}
