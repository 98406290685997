import { Location } from '@angular/common';
import { Component, OnInit, TrackByFunction, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthorityService, IntradayService, MixinBase, OnDestroyMixin, VIEW_INTRA_DAY_IDCONS_ORDER } from 'flex-app-shared';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { FlattenedIntradayNode } from '../intraday/intraday-slot/intraday-deal-controls-data-source';
import { IntradayHistoryState } from '../state/history/intraday-history-state.service';
import { IntraDayHistoryFacade } from '../state/history/intraday-history.facade';
import { IntradayFacade } from '../state/intraday-facade.service';
import { IntradayHistoryFiltersFormComponent } from './intraday-history-filters-form/intraday-history-filters-form.component';
import { IntraDayHistoryDataSource } from './intraday-history.datasource';

type Filter = {
  label: string;
  key: string;
  selected: boolean;
};

@Component({
  selector: 'app-intraday-history',
  templateUrl: './intraday-history.component.html',
  styleUrls: ['./intraday-history.component.scss']
})
export class IntradayHistoryComponent extends OnDestroyMixin(MixinBase) implements OnInit {
  allColumns = [
    'dealDirection',
    'dealStatus',
    'orderType',
    'created',
    'customerLegalName',
    'gridPointDescription',
    'controlDescription',
    'priceWindowPeriodDay',
    'dealPeriod',
    'dealPrice',
    'dealCapacity',
    'dealAmount',
    'dealReference'
  ];

  IntradayHistoryFiltersFormComponent = IntradayHistoryFiltersFormComponent;

  dataSource: IntraDayHistoryDataSource;
  displayedColumns$: Observable<string[]>;
  busy = false;

  dealsBusy$ = this.store.select(IntradayHistoryState.dealsBusy);
  @ViewChild(MatPaginator, { static: true }) private readonly paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) private readonly sort: MatSort;
  @ViewChild(MatSelect, { static: true }) private readonly categories: MatSelect;

  constructor(
    private activatedRoute: ActivatedRoute,
    private intraDayService: IntradayService,
    private intraDayFacade: IntradayFacade,
    private intraDayHistoryFacade: IntraDayHistoryFacade,
    private authorityService: AuthorityService,
    private fb: UntypedFormBuilder,
    private store: Store,
    private location: Location
  ) {
    super();
  }

  ngOnInit(): void {
    this.dataSource = new IntraDayHistoryDataSource(this.intraDayService);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.displayedColumns$ = this.dataSource.data$.pipe(
      debounceTime(50),
      switchMap(() =>
        combineLatest([this.authorityService.hasAuthorities(VIEW_INTRA_DAY_IDCONS_ORDER), this.intraDayHistoryFacade.showAllDeals$])
      ),
      map(([hasViewIdcons, showAllDeals]) => {
        let columns = this.allColumns;
        if (!(hasViewIdcons || this.intraDayFacade.getShowOrderTypeColumn())) {
          columns = columns.filter((column) => column !== 'orderType');
        }
        if (!showAllDeals) {
          columns = columns.filter((column) => column !== 'dealStatus');
        }
        return columns;
      })
    );
  }

  goBack(): void {
    this.location.back();
  }

  downloadData(): void {
    this.busy = true;
    this.dataSource.downloadData().subscribe(() => (this.busy = false));
  }

  downloadDataButtonDisabled(): boolean {
    return this.dataSource.isEmpty() || this.busy;
  }

  trackBy: TrackByFunction<FlattenedIntradayNode> = (index, item) => {
    return item?.id ?? index;
  };
}
