<div [ngSwitch]="isStale" class="mat-mdc-card" [class.expired]="isExpired" [class.close-to-expired]="isCloseToExpired">
  <ng-container *ngSwitchCase="false" i18n="@@intraday-countdownSummary">
    Current prices will remain valid for
    <ng-template [ngTemplateOutlet]="timer"></ng-template>
    minutes.
  </ng-container>
  <ng-container *ngSwitchDefault>
    <ng-container i18n="@@intraday-countdownNoPricesAvailable"> No prices available. </ng-container>
  </ng-container>
</div>

<ng-template #timer>
  <span>{{ minutes | number : '2.0-0' }}:{{ seconds | number : '2.0-0' }}</span>
</ng-template>
