import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Inject, Optional, ViewEncapsulation } from '@angular/core';
import { MatCalendar, MatCalendarHeader, MatCalendarView, MatDatepickerIntl } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';

@Component({
  selector: 'app-custom-date-picker-header',
  templateUrl: './custom-date-picker-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class CustomDatePickerHeaderComponent<D> extends MatCalendarHeader<D> {
  constructor(
    _intl: MatDatepickerIntl,
    @Inject(forwardRef(() => MatCalendar)) calendar: MatCalendar<D>,
    @Optional() _dateAdapter: DateAdapter<D>,
    @Optional() @Inject(MAT_DATE_FORMATS) _dateFormats: MatDateFormats,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(_intl, calendar, _dateAdapter, _dateFormats, changeDetectorRef);
  }

  /** Handles user clicks on the period label. */
  currentPeriodClicked(): void {
    let currentView: MatCalendarView;
    switch (this.calendar.currentView) {
      case 'month':
        currentView = 'year';
        break;
      case 'year':
        currentView = 'multi-year';
        break;
      case 'multi-year':
        currentView = 'year';
        break;
    }
    this.calendar.currentView = currentView;
  }
}
