import { ScaleContinuousNumeric } from 'd3-scale';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import * as d3 from 'd3';
import { MixinBase } from '../../../core/common/constructor-type.mixin';
import { Margins, SubjectProvider } from '../common';
import { D3GraphScaleProvider } from './d3-graph-scale-provider';
import { DestroyableMixin, OnDestroyMixin, OnDestroyProvider } from '../../../core/common/on-destroy.mixin';
import { first, last } from 'lodash-es';
import { expectObservableValue } from '../../../core/common/expect-observable-value';

export type DataPointLine = { date: Date; value: number };
export type DataPointStep = { startDate: Date; toDate: Date; value: number };

export class LineScaleProvider
  extends DestroyableMixin(OnDestroyMixin(MixinBase))
  implements D3GraphScaleProvider<ScaleContinuousNumeric<number, number>, number>
{
  static defaultHeight = 300;
  scale: ScaleContinuousNumeric<number, number>;

  private heightProvider = new SubjectProvider<number>(this, new BehaviorSubject<number>(LineScaleProvider.defaultHeight));
  private marginsProvider = new SubjectProvider(this, new BehaviorSubject<Pick<Margins, 'bottom' | 'top'>>({ bottom: 0, top: 0 }));
  private ticksProvider = new SubjectProvider<number[]>(this);
  private defaultNumericDomainProvider = new SubjectProvider<[number, number]>(this, new BehaviorSubject([0, 0]));

  ticks$: Observable<number[]> = this.ticksProvider.value$;

  private ticksDomain$ = this.ticks$.pipe(map((ticks) => [first(ticks), last(ticks)]));

  scale$: Observable<any> = combineLatest([this.heightProvider.value$, this.marginsProvider.value$, this.ticksDomain$]).pipe(
    map(([height, margins, domain]) => {
      const scaleRange = [margins.top, height + margins.top];
      return d3.scaleLinear().domain(domain).range(scaleRange);
    }),
    shareReplay(1)
  );

  constructor(onDestroyProvider: OnDestroyProvider) {
    super();
    this.registerOnDestroyProvider(onDestroyProvider);
    this.scale$.subscribe((scale) => {
      this.scale = scale;
    });

    expectObservableValue(this.heightProvider.value$, 'No heightProvider in LineScaleProvider');
    expectObservableValue(this.marginsProvider.value$, 'No marginsProvider in LineScaleProvider');
    expectObservableValue(this.ticksDomain$, 'No ticksDomain$ in LineScaleProvider');
  }

  setHeight(height: number): void {
    this.heightProvider.next(height || this.heightProvider.value);
  }

  setMargin(margin: Partial<Pick<Margins, 'bottom' | 'top'>>): void {
    this.marginsProvider.next({
      ...this.marginsProvider.value,
      ...margin
    });
  }

  setTicks$(ticks$: Observable<number[]>): void {
    this.ticksProvider.follow(ticks$);
  }

  setDefaultDomain(defaultDomain: [number, number]): void {
    this.defaultNumericDomainProvider.next(defaultDomain);
  }

  destroy(): void {
    this.ngOnDestroy();
  }
}
