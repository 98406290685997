import { Component, ElementRef, EventEmitter, HostListener, Inject, Input, LOCALE_ID, OnInit, Output, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  AuthService,
  CurrentPageService,
  MANAGE_API_KEY,
  MANAGE_USER_PREFERENCES,
  MixinBase,
  OnDestroyMixin,
  VIEW_INTRA_DAY_IDCONS_ORDER
} from 'flex-app-shared';
import { default as theme } from 'projects/flex-app-shared/design-tokens/theme.json';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { DocumentClickService } from '../../../../../flex-app-shared/src/lib/core/common/document-click.service';
import { IdconsAnnouncementsFacade } from '../../../store/idcons-announcements/idcons-announcements.facade';
import { IdconsAnnouncement } from '../../shared/idcons/announcement/idcons-announcement';
import { UserPreferencesDialogComponent } from '../../shared/user-preferences/user-preferences-dialog/user-preferences-dialog.component';
import { FlexScrollStrategyService } from '../../../../../flex-app-shared/src/lib/core/common/scroll-strategy';

const desktopBreakpoint = 768; // px

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends OnDestroyMixin(MixinBase) implements OnInit {
  VIEW_IDCONS_INTRA_DAY_ORDER = VIEW_INTRA_DAY_IDCONS_ORDER;
  MANAGE_API_KEY = MANAGE_API_KEY;
  MANAGE_USER_PREFERENCES = MANAGE_USER_PREFERENCES;

  viewWidth: number;
  title = 'flexapp-customer-portal';

  activeAnnouncements$: Observable<IdconsAnnouncement[]>;
  unreadAnnouncementsIds$: Observable<string[]>;

  @Input()
  menuItems: string[] = [];

  @Input()
  username: string;

  @Output()
  appLogout: EventEmitter<any> = new EventEmitter<string>();

  constructor(
    public currentPageService: CurrentPageService,
    private documentClickService: DocumentClickService,
    public dialog: MatDialog,
    @Inject(LOCALE_ID) public locale: string,
    public facade: IdconsAnnouncementsFacade,
    public router: Router,
    public authService: AuthService
  ) {
    super();
  }

  get desktopSidenavOpen(): boolean {
    return this.viewWidth > desktopBreakpoint;
  }

  get showHamburger(): boolean {
    return !this.desktopSidenavOpen;
  }

  @HostListener('window:resize')
  onResize(): void {
    this.viewWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  }

  @HostListener('document:click', ['$event'])
  handleDocumentClick(event: MouseEvent): void {
    this.documentClickService.handleDocumentClick(event.target as HTMLElement);
  }

  switchLocale(locale: string): void {
    window.location.href = `${document.baseURI.split('/').slice(0, 3).concat([locale, '']).join('/')}`;
  }

  openUserPreferencesDialog(): void {
    this.dialog.open(UserPreferencesDialogComponent, {
      data: {},
      width: `${theme.layout.width.xs}px`
    });
  }

  changePassword(): void {
    this.authService.changePasswordFlow();
  }

  ngOnInit(): void {
    this.onResize();

    this.activeAnnouncements$ = this.facade.activeAnnouncements$.pipe(takeUntil(this.onDestroy$));

    this.unreadAnnouncementsIds$ = this.facade.unreadAnnouncements$.pipe(
      map((unreadAnnouncementsIds) => unreadAnnouncementsIds.map(({ id }) => id)),
      takeUntil(this.onDestroy$)
    );
  }

  logout(): any {
    this.appLogout.emit();
  }

  handleAnnouncementDialogAction(submitted?: boolean): void {
    if (submitted) {
      this.router.navigate(['/messages']);
    }

    // Only mark messages as unread when closing the dialog.
    // This will maintain unread status on the messages screen.
    if (!submitted) {
      this.facade.markAllAnnouncementsAsRead();
    }
  }
}
