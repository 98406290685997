<form [formGroup]="filterForm">
  <ph-flex-date-range-fixed-period
    *ngIf="!inDialog"
    formControlName="dateRange"
    phFlexDateRangeFixedPeriodStringValueAccessor
    phFlexDateRangeFixedPeriodToolbar
    phFlexDateRangeFixedPeriodDefaultYear
    class="ph-flex-mat-form-field-group-inline"
  >
    <ph-flex-date-range-fixed-period-button-group
      [availablePeriodUnits]="availablePeriodUnits"
    ></ph-flex-date-range-fixed-period-button-group>
    <ph-flex-date-range-fixed-period-combined-input
      [min]="min$ | async"
      [max]="max$ | async"
    ></ph-flex-date-range-fixed-period-combined-input>
  </ph-flex-date-range-fixed-period>
  <ph-flex-date-range-fixed-period
    *ngIf="inDialog"
    formControlName="dateRange"
    phFlexDateRangeFixedPeriodStringValueAccessor
    phFlexDateRangeFixedPeriodDialog
    phFlexDateRangeFixedPeriodDefaultYear
  >
    <ph-flex-date-range-fixed-period-button-group
      [availablePeriodUnits]="availablePeriodUnits"
    ></ph-flex-date-range-fixed-period-button-group>
    <ph-flex-date-range-fixed-period-combined-input></ph-flex-date-range-fixed-period-combined-input>
  </ph-flex-date-range-fixed-period>
  <div [ngClass]="{ 'ph-flex-mat-form-field-group-inline': !inDialog }">
    <mat-form-field phFlexInputTheme>
      <mat-label i18n="@@incidentReserveAvailability-customerSelectionLabel">Company name</mat-label>
      <ph-flex-autocomplete
        phFlexCustomerDataSource
        [shouldEmitNull]="true"
        [shouldOnlyEmitIds]="true"
        formControlName="customerId"
        [shouldAutoSelectFirstAvailableResult]="true"
        [shouldAutoSelectIfOnlyOneResult]="true"
        placeholder="Select customer"
        i18n-placeholder="@@incidentReserveAvailability-customerSelectionPlaceholder"
      ></ph-flex-autocomplete>
    </mat-form-field>
    <!-- Contract selection -->
    <mat-form-field phFlexInputTheme class="col-0 contract-select ph-flex-mat-form-field-wide">
      <mat-label i18n="@@incidentReserveAvailability-serviceAgreementSelectionLabel">Service agreement </mat-label>
      <mat-select formControlName="serviceAgreementId">
        <mat-option
          class="ph-flex-mat-option-dark"
          *ngFor="let serviceAgreement of selectableServiceAgreements$ | async"
          [value]="serviceAgreement.serviceAgreementId"
          >{{ serviceAgreement.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</form>
