import { TemplatePortal } from '@angular/cdk/portal';
import {
  AfterViewInit,
  ApplicationRef,
  Component,
  ComponentFactoryResolver,
  Input,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
  ViewContainerRef
} from '@angular/core';
import { select, Selection } from 'd3';
import { BaseType } from 'd3-selection';
import { addSeconds, differenceInSeconds, parseISO, startOfHour } from 'date-fns';
import {
  AxisAttachHelper,
  D3GraphCursor,
  D3GraphLine,
  D3GraphLineAxis,
  D3GraphScaleBandVerticalLines,
  D3GraphScaleLineHorizontalLines,
  D3GraphXAxisLabels,
  DataPointLine,
  DateTicksHelper,
  defaultAllowedSteps,
  Direction,
  FlexResizeObserverDirective,
  getAllTicksZeroAligned,
  getGapsOfMultipleHoursAsOneHour,
  LineScaleProvider,
  MixinBase,
  ObservableInputsMixin,
  OnDestroyMixin,
  PriceHistoryJson,
  SeriesAttachHelper,
  SubjectProvider,
  theme,
  TimeSlot,
  TimeSlotWithGapsScaleProvider,
  TooltipComponent
} from 'flex-app-shared';
import { first, flatMap, last } from 'lodash-es';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

const priceLabel = $localize`:@@intraday-yAxisLabelPrice:Price (€/MWh)`;

@Component({
  selector: 'app-intraday-price-history-graph',
  templateUrl: './intraday-price-history-graph.component.html',
  styleUrls: ['./intraday-price-history-graph.component.scss']
})
export class IntradayPriceHistoryGraphComponent extends ObservableInputsMixin(OnDestroyMixin(MixinBase)) implements OnInit, AfterViewInit {
  static marginLeft = 100;
  static marginRight = 20;
  static yAxisOffset = 30;
  static graphHeight = 360;
  static yAxisTicks = 7;
  static minGapTimeInSeconds = 60 * 60;
  Direction = Direction;

  dataProvider = new SubjectProvider<PriceHistoryJson>(this);

  @Input()
  data: PriceHistoryJson;
  data$ = this.oi.observe(() => this.data).pipe(filter((a) => !!a));

  @Input()
  direction: Direction;
  direction$ = this.oi.observe(() => this.direction).pipe(filter((a) => !!a));

  ticksHelper: DateTicksHelper;
  xScaleProvider: TimeSlotWithGapsScaleProvider;
  yScaleProvider: LineScaleProvider;

  d3GraphHorizontalLines: D3GraphScaleLineHorizontalLines;
  d3GraphVerticalLines: D3GraphScaleBandVerticalLines;

  xAxisLabels: D3GraphXAxisLabels;
  yAxisPrices: D3GraphLineAxis;
  yAxisPricesAttachHelper: AxisAttachHelper;

  lineIntradayPrice: D3GraphLine;
  lineIntradayPriceAttachHelper: SeriesAttachHelper;
  dashedLineDayAheadPrice: D3GraphLine;
  dashedLineDayAheadPriceAttachHelper: SeriesAttachHelper;

  mainSvg: Selection<BaseType, any, any, any>;
  cursor: D3GraphCursor;
  tooltipRows: string[] = ['dayAheadPrice', 'intradayPrice'];

  intradayPriceData$ = combineLatest([this.direction$, this.data$]).pipe(
    map(([direction, data]) => (direction === Direction.PRODUCTION ? data.intraDayBidPrices : data.intraDayAskPrices))
  );
  intradayPriceTimeslots$ = this.intradayPriceData$.pipe(map((data) => data?.map((price) => price.validPeriod)));

  dayAheadPriceData$: Observable<DataPointLine[]>;

  dataTimeSlot$: Observable<TimeSlot> = this.intradayPriceData$.pipe(
    map((data) => {
      const firstPeriodStart = first(data)?.validPeriod.startDateTime;
      const lastPeriodEnd = last(data)?.validPeriod.toDateTime;

      return {
        startDateTime: firstPeriodStart,
        toDateTime: lastPeriodEnd
      };
    })
  );

  @ViewChild(FlexResizeObserverDirective)
  private d3RootResizeObserver: FlexResizeObserverDirective;

  @ViewChild('tooltipTemplate')
  private tooltipTemplate: TemplateRef<any>;

  @ViewChildren(TooltipComponent, { read: FlexResizeObserverDirective })
  private resizeObserverDirectiveQueryList: QueryList<FlexResizeObserverDirective>;

  constructor(private viewContainerRef: ViewContainerRef, private cfr: ComponentFactoryResolver, private appRef: ApplicationRef) {
    super();
  }

  ngOnInit(): void {
    this.yScaleProvider = new LineScaleProvider(this);

    this.xScaleProvider = new TimeSlotWithGapsScaleProvider(this);
    this.dayAheadPriceData$ = combineLatest([this.data$, this.xScaleProvider.scale$]).pipe(
      map(([data, scale]) => [
        {
          date: scale.domain()[0],
          value: data.dayAheadPrice
        },
        {
          date: scale.domain()[1],
          value: data.dayAheadPrice
        }
      ])
    );

    this.xAxisLabels = new D3GraphXAxisLabels(this);

    this.yAxisPrices = new D3GraphLineAxis(this, 'y-axis-prices', priceLabel);
    this.yAxisPricesAttachHelper = new AxisAttachHelper(this, this.yAxisPrices);

    this.lineIntradayPrice = new D3GraphLine(this);
    this.lineIntradayPriceAttachHelper = new SeriesAttachHelper(this, this.lineIntradayPrice);

    this.dashedLineDayAheadPrice = new D3GraphLine();
    this.dashedLineDayAheadPriceAttachHelper = new SeriesAttachHelper(this, this.dashedLineDayAheadPrice);

    this.ticksHelper = new DateTicksHelper(this.xScaleProvider.scale$);

    this.d3GraphHorizontalLines = new D3GraphScaleLineHorizontalLines();
    this.d3GraphVerticalLines = new D3GraphScaleBandVerticalLines(this);

    this.cursor = new D3GraphCursor(this);

    this.xScaleProvider.setTimeSlot$(this.dataTimeSlot$);
    this.xScaleProvider.setMargin({
      right: IntradayPriceHistoryGraphComponent.marginRight,
      left: IntradayPriceHistoryGraphComponent.marginLeft
    });

    const gaps$ = this.intradayPriceTimeslots$.pipe(map((timeSlots) => getGapsOfMultipleHoursAsOneHour(timeSlots)));

    this.xScaleProvider.setGaps$(gaps$);

    this.d3GraphVerticalLines.setYScale$(this.yScaleProvider.scale$);
    this.d3GraphVerticalLines.setXScale$(this.xScaleProvider.scale$);
    this.d3GraphHorizontalLines.setYScale$(this.yScaleProvider.scale$);
    this.d3GraphHorizontalLines.setXScale$(this.xScaleProvider.scale$);
    this.d3GraphHorizontalLines.setBaselinePositions$(
      SeriesAttachHelper.baselineYPositions$([this.dashedLineDayAheadPriceAttachHelper, this.lineIntradayPriceAttachHelper])
    );
    this.d3GraphHorizontalLines.setGaps$(
      gaps$.pipe(
        map((gaps) =>
          gaps.map((gap) => {
            const parsedStartDateTime = parseISO(gap.startDateTime);
            const diff = differenceInSeconds(parseISO(gap.toDateTime), parsedStartDateTime) / 2;
            return addSeconds(parsedStartDateTime, diff);
          })
        )
      )
    );

    this.xAxisLabels.setYScale$(this.yScaleProvider.scale$);
    this.xAxisLabels.setXScale$(this.xScaleProvider.scale$);
    this.xAxisLabels.setTicks$(
      this.ticksHelper.ticks$.pipe(map((ticks) => ticks.filter((tick) => differenceInSeconds(tick, startOfHour(tick)) === 0)))
    );
    this.xAxisLabels.setDateFormat$(this.ticksHelper.labelFormat$);

    this.yAxisPrices.setTicks$(this.yScaleProvider.ticks$);
    this.yAxisPrices.setXScale$(this.xScaleProvider.scale$);
    this.yAxisPrices.setYScale$(this.yScaleProvider.scale$);

    const lineIntradayPriceData = this.intradayPriceData$.pipe(
      map((data) =>
        flatMap(data, (current) => [
          {
            date: parseISO(current.validPeriod.startDateTime),
            value: current.price
          },
          {
            date: parseISO(current.validPeriod.toDateTime),
            value: current.price
          }
        ])
      )
    );

    this.lineIntradayPrice.setXScale$(this.xScaleProvider.scale$);
    this.lineIntradayPrice.setYScaleProvider(this.yScaleProvider);
    if (this.direction === Direction.CONSUMPTION) {
      this.lineIntradayPrice.setConfig({ lineColor: theme.color.markets.intraday['regular-consumption'] });
    } else {
      this.lineIntradayPrice.setConfig({ lineColor: theme.color.markets.intraday['regular-production'] });
    }
    this.lineIntradayPrice.setData$(lineIntradayPriceData);

    this.dashedLineDayAheadPrice.setXScale$(this.xScaleProvider.scale$);
    this.dashedLineDayAheadPrice.setYScaleProvider(this.yScaleProvider);
    this.dashedLineDayAheadPrice.setData$(this.dayAheadPriceData$);
    this.dashedLineDayAheadPrice.setConfig({
      lineColor: theme.color.markets['day-ahead'].regular,
      strokeDasharray: [6, 4]
    });

    const priceDomain$ = combineLatest([lineIntradayPriceData, this.dayAheadPriceData$]).pipe(map((data) => flatMap(data)));

    const syncedDomains$ = combineLatest([priceDomain$]).pipe(
      map((domains) => {
        return getAllTicksZeroAligned(
          domains.map((domain) => domain.map((obj) => obj.value)),
          6,
          defaultAllowedSteps,
          0.8
        );
      })
    );

    this.yScaleProvider.setTicks$(syncedDomains$.pipe(map((ticks) => ticks[0].reverse())));

    this.yScaleProvider.setMargin({
      top: IntradayPriceHistoryGraphComponent.yAxisOffset
    });
    this.yScaleProvider.setDefaultDomain([1000, -1000]);

    // Init cursor
    this.cursor.setYScale$(this.yScaleProvider.scale$);
    this.cursor.setXScale$(this.xScaleProvider.scale$);
    this.cursor.setCfr(this.cfr);
    this.cursor.setAppRef(this.appRef);
    this.cursor.setConfig({ contentWidth: 250, contentHeight: 64 });
  }

  registerSvgRoot(mainSvg: Selection<BaseType, any, any, any>): void {
    this.mainSvg = mainSvg;

    this.updateWidth((mainSvg.node() as any).getBoundingClientRect() as DOMRectReadOnly);
    this.updateHeight();

    this.d3RootResizeObserver.contentRect$.pipe(takeUntil(this.onDestroy$)).subscribe((contentRect) => {
      this.updateWidth(contentRect);
      this.updateHeight();
    });

    this.yScaleProvider.scale$.pipe(takeUntil(this.onDestroy$)).subscribe((yScale) => {
      // Get yScale height, add some spacing for the labels, and set the svg height.
      this.mainSvg.attr('height', yScale.range()[1] + 45);
    });

    this.initD3();
  }

  updateWidth(contentRect: DOMRect): void {
    const width = contentRect.width;
    this.xScaleProvider.setWidth(width);

    const xMargins = IntradayPriceHistoryGraphComponent.marginLeft + IntradayPriceHistoryGraphComponent.marginRight;
    select('#clip rect').attr('width', width - xMargins);
  }

  updateHeight(): void {
    this.yScaleProvider.setHeight(IntradayPriceHistoryGraphComponent.graphHeight);
  }

  ngAfterViewInit(): void {
    const templatePortal = new TemplatePortal(this.tooltipTemplate, this.viewContainerRef);
    this.cursor.setPortal(templatePortal);
  }

  private initD3(): void {
    this.xAxisLabels.attach(this.mainSvg);
    this.yAxisPrices.attach(this.mainSvg);
    this.d3GraphVerticalLines.attach(this.mainSvg);
    this.d3GraphHorizontalLines.attach(this.mainSvg);

    this.lineIntradayPriceAttachHelper.attach(this.mainSvg);
    this.dashedLineDayAheadPrice.attach(this.mainSvg);

    this.cursor.attach(this.mainSvg);
    this.cursor.setOverlay(this.mainSvg);
  }

  getDayAheadPrice$(): Observable<number> {
    return this.data$.pipe(map((data) => data.dayAheadPrice));
  }

  getIntradayPriceForDate$(date: Date): Observable<number> {
    return this.lineIntradayPrice.getYDomainValue$(date);
  }
}
