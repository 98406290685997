import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ToolbarTab } from '../../../../flex-app-shared/src/lib/material/toolbar-tabs/toolbar-tabs/toolbar-tabs.component';
import { filter, startWith } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { combineLatest, map } from 'rxjs';
import { IntradayHistoryFiltersFormComponent } from './intraday-history/intraday-history-filters-form/intraday-history-filters-form.component';
import { DateFnsParseFormatters, FlexNgXsFormSync, MixinBase, OnDestroyMixin } from 'flex-app-shared';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { Store } from '@ngxs/store';
import { IntradayState } from './state/intraday-state.service';
import {
  IntraDayDealsHistorySelectedFiltersChangedEvent,
  IntraDayHistoryDownloadDealsCommand
} from './state/history/intraday-history.actions';
import { IntradayHistoryState } from './state/history/intraday-history-state.service';
import format from 'date-fns/format';

@Component({
  selector: 'app-intraday-toolbar-container',
  templateUrl: './intraday-toolbar-container.component.html',
  styleUrls: ['./intraday-toolbar-container.component.scss']
})
export class IntradayToolbarContainerComponent extends OnDestroyMixin(MixinBase) {
  IntradayHistoryFiltersFormComponent = IntradayHistoryFiltersFormComponent;

  @FlexNgXsFormSync('intraday.history.filterForm', { useRawValue: true })
  filterForm = this.fb.group({
    customerId: { value: '', disabled: false },
    gridPointId: { value: '', disabled: false },
    controlId: { value: '', disabled: true },
    dealReference: { value: '', disabled: true },
    dealPeriod: {
      value: '',
      disabled: false
    },
    createdPeriod: {
      value: '',
      disabled: true
    }
  });

  @FlexNgXsFormSync('intraday.history.showAllDealsForm')
  showAllDealsForm = this.fb.group({
    showAllDeals: false
  });
  showAllDealsControl: UntypedFormControl = this.showAllDealsForm.get('showAllDeals') as UntypedFormControl;

  tabs: ToolbarTab[] = [
    {
      path: '/intraday/trading',
      label: $localize`:@@intraday-slotsTabLabel:Orders`
    },
    {
      path: '/intraday/history',
      label: $localize`:@@intraday-historyTabLabel:Transactions`
    }
  ];

  @ViewChild(RouterOutlet) routerOutlet: RouterOutlet;
  shouldShowBackButton$ = this.store.select(IntradayState.slotsPageActive);
  private routeData$ = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    startWith(null), // Trigger check without router event
    map((event) => {
      return this.routerOutlet?.activatedRouteData;
    })
  );
  shouldShowTabs$ = combineLatest([this.routeData$, this.store.select(IntradayState.slotsPageActive)]).pipe(
    map(([data, slotsPageActive]) => !slotsPageActive && !data?.showBackButton)
  );
  shouldShowFilters$ = this.store.select(IntradayHistoryState.active);

  constructor(
    private location: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private store: Store
  ) {
    super();
  }
  back(): void {
    this.location.back();
  }

  filtersChanged(selectedFilters: string[]): void {
    this.store.dispatch(new IntraDayDealsHistorySelectedFiltersChangedEvent(selectedFilters));
  }
}
