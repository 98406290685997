import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IntradayFacade } from '../../intraday-facade.service';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-cancel-order-confirmation',
  templateUrl: './cancel-order-confirmation.component.html',
  styleUrls: ['./cancel-order-confirmation.component.scss']
})
export class CancelOrderConfirmationComponent {
  error$ = this.facade.idconsCancelError$;
  pending$ = this.facade.idconsCancelPending$;

  hasConfirmed = false;
  isOrderCancelable$ = this.facade.isOrderIdCancelable$(this.orderId).pipe(
    takeWhile(() => {
      return !this.hasConfirmed;
    })
  );

  constructor(
    public facade: IntradayFacade,
    @Inject(MAT_DIALOG_DATA) public orderId: string,
    private dialogRef: MatDialogRef<CancelOrderConfirmationComponent>
  ) {}

  handleCancel(): void {
    this.hasConfirmed = true;
    this.facade.cancelIntradayOrder(this.orderId, this.dialogRef);
  }
}
