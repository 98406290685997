import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageTitleComponent } from './page-title/page-title.component';
import { MatIconModule } from '@angular/material/icon';
import { SideMenuItemComponent } from './side-menu-item/side-menu-item.component';
import { FlexAuthModule, FlexCoreModule, FlexMotdModule, SideMenuModule, TopBarModule } from 'flex-app-shared';
import { AppRoutingModule } from '../app-routing.module';
import { HeaderComponent } from './header/header.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { IdconsAnnouncementsModule } from '../idcons-announcements/idcons-announcements.module';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { HttpClientModule } from '@angular/common/http';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { FlexDialogModule } from '../../../../flex-app-shared/src/lib/material/dialog/dialog.module';

@NgModule({
  declarations: [PageTitleComponent, SideMenuItemComponent, HeaderComponent, SideMenuComponent],
  imports: [
    CommonModule,
    MatIconModule,
    SideMenuModule,
    AppRoutingModule,
    HttpClientModule,
    MatToolbarModule,
    FlexMotdModule,
    IdconsAnnouncementsModule,
    MatMenuModule,
    FlexAuthModule,
    MatButtonModule,
    TopBarModule,
    FlexCoreModule,
    MatSidenavModule,
    FlexDialogModule
  ],
  exports: [PageTitleComponent, SideMenuItemComponent, HeaderComponent, SideMenuComponent]
})
export class CoreModule {}
