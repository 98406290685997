import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TopBarService {
  private openMobileSidebarSubject = new Subject<void>();

  openMobileSidebar$ = this.openMobileSidebarSubject.asObservable();

  openMobileSidebar(): void {
    this.openMobileSidebarSubject.next();
  }
}
