import { DynamicDataHelper } from '../common';
import { EnterElement, select, Selection } from 'd3';
import { BaseType } from 'd3-selection';
import { D3GraphScaleProvider } from '../scales/d3-graph-scale-provider';
import { combineLatest, EMPTY, Observable, ReplaySubject, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { OnDestroyProvider } from '../../../core/common/on-destroy.mixin';
import { D3GraphDynamicScaleProvider } from '../scales/d3-graph-dynamic-scale-provider';
import { D3GraphSeriesSubModule } from '../submodules/d3-graph-submodule';

export interface MultiSeriesDatum<SubModule, LineData> {
  /**
   * Submodule instance for the given datum.
   * Will be created by a D3GraphMultiHelper and will be used by the MultiSeriesAttachHelper
   */
  subModuleInstance?: SubModule;
  detachFn?: () => void;
  show?: boolean;
  lineData: LineData[];
}

export abstract class D3GraphMultiSeriesDataHelper<
  Datum extends MultiSeriesDatum<SubModule, SubModuleDatum>,
  SupportedYScale,
  SubModule extends D3GraphSeriesSubModule<any, D3GraphScaleProvider<SupportedYScale, number>, any, SubModuleDatum>,
  SubModuleDatum
> extends DynamicDataHelper<Datum> {
  onDestroy$: Subject<any> = new Subject();
  dynamicYScaleProvider = new D3GraphDynamicScaleProvider<any, any>(this);
  protected class: string = 'heat-buffer-series';
  protected nodeName: string = 'g';
  protected dataSubject = new ReplaySubject<Datum[]>(1);
  protected hasData$ = this.dataSubject.pipe(
    // Return true if all subModuleInstances have data, otherwise return false
    switchMap((data) => {
      const observables = data.map((d) => d?.subModuleInstance?.hasData$).filter((hasData$) => !!hasData$);

      if (observables.length === 0) {
        return EMPTY;
      }

      return combineLatest(observables).pipe(map((results) => results.every((a) => a)));
    })
  );
  canUseData$: Observable<boolean> = this.hasData$;

  protected constructor(
    onDestroyProvider: OnDestroyProvider,
    parent: Selection<EnterElement, any, BaseType, any>,
    private config$: Observable<any>,
    private xScale$: Observable<any>,
    private yScaleProvider: D3GraphScaleProvider<SupportedYScale, number>
  ) {
    super(parent);

    onDestroyProvider.onDestroy$.subscribe(() => {
      this.onDestroy$.next(null);
      this.onDestroy$.complete();
    });
  }

  setData(data: Datum[]): void {
    this.dataSubject.next(data);
    const selectAllWithData = this.parent.selectAll(this.selector).data(data, (d: Datum) => this.identifyDatum(d));

    this.exit(selectAllWithData);
    this.enter(selectAllWithData);
    this.update(selectAllWithData);
  }

  protected setDynamic(selectAllWithData: Selection<BaseType, Datum, BaseType, any>): void {
    const that = this;
    selectAllWithData.each(function (d: Datum): void {
      if (d.show && !d.detachFn) {
        // Should show, but not yet attached
        // eslint-disable-next-line no-invalid-this
        d.detachFn = d.subModuleInstance.attach(select(this));
        d.subModuleInstance.setXScale$(that.xScale$);
        d.subModuleInstance.setYScaleProvider(that.yScaleProvider);
        d.subModuleInstance.setConfig$(that.config$);
        d.subModuleInstance.setData(d.lineData);
      } else if (!d.show && d.detachFn) {
        // Should not show, but is still attached
        d.detachFn();
        d.detachFn = undefined;
      } else {
        // FA-3570 Set data correctly (initial fix was to remove all elements, but updating data is better)
        d.subModuleInstance.setData(d.lineData);
      }
    });
  }

  protected setStatic(selectAllWithData: Selection<BaseType, Datum, BaseType, any>): void {
    const that = this;
    selectAllWithData.each(function (d: Datum): void {
      d.subModuleInstance = that.getSubModuleInstance();
      d.subModuleInstance.setXScale$(that.xScale$);
      d.subModuleInstance.setYScaleProvider(that.yScaleProvider);
      d.subModuleInstance.setConfig$(that.config$);
      d.subModuleInstance.setData(d.lineData);

      if (d.show) {
        // eslint-disable-next-line no-invalid-this
        d.detachFn = d.subModuleInstance.attach(select(this));
      }
    });
  }

  /**
   * Return a new instance of SubModule to be called in setStatic
   */
  protected abstract getSubModuleInstance(): SubModule;

  /**
   * Get key to identify datum for selectAll().datum()
   */
  protected abstract identifyDatum(datum: Datum): string;

  protected exit(selectAllWithData: any): void {
    selectAllWithData
      .exit()
      .each((datum) => datum?.detatchFn && datum.detachFn())
      .remove();
  }
}
