import { AppInjector, CalendarPeriod, CalendarPeriodDataSource } from 'flex-app-shared';
import { CollectionViewer } from '@angular/cdk/collections';
import { Observable } from 'rxjs';
import { IncidentReserveAvailabilityFacade } from '../../../store/incident-reserve-availability/incident-reserve-availability.facade';

export class IncidentReserveAvailabilityScreenDatasource extends CalendarPeriodDataSource {
  facade = AppInjector.get(IncidentReserveAvailabilityFacade);

  connect(collectionViewer: CollectionViewer): Observable<CalendarPeriod[] | ReadonlyArray<CalendarPeriod>> {
    return this.facade.itemPeriods$;
  }
}
