import { AfterViewInit, Directive, ElementRef, Inject, Input } from '@angular/core';
import { MixinBase, OnDestroyMixin } from 'flex-app-shared';
import { FlattenedIntradayNode } from './intraday-deal-controls-data-source';
import { EditEventDispatcher, FocusDispatcher } from '@angular/cdk-experimental/popover-edit';
import { filter, takeUntil } from 'rxjs/operators';
import { CELL_SELECTOR, closest } from './angular-material-exports';
import { IntradaySlotFocusDispatcher } from './intraday-slot-focus-dispatcher.service';

@Directive({
  selector: '[appSlotFocus]'
})
export class IntradaySlotFocusDirective extends OnDestroyMixin(MixinBase) implements AfterViewInit {
  @Input('appSlotFocus') targetNode: FlattenedIntradayNode;

  constructor(
    protected readonly editEventDispatcher: EditEventDispatcher<Element | null>,
    @Inject(FocusDispatcher) protected readonly intraDaySlotFocusDispatcher: IntradaySlotFocusDispatcher,
    protected readonly elementRef: ElementRef<HTMLElement>
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.intraDaySlotFocusDispatcher.focusedNode$
      .pipe(
        filter((currentNode) => currentNode.id === this.targetNode.id),
        takeUntil(this.onDestroy$)
      )
      .subscribe(() => {
        this.editEventDispatcher.editing.next(closest(this.elementRef.nativeElement, CELL_SELECTOR));
      });
  }
}
