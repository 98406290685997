import { Component, Input } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map, startWith, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ObservableInputsMixin } from '../../../core/observable-inputs/observable-inputs';
import { OnDestroyMixin } from '../../../core/common/on-destroy.mixin';
import { MixinBase } from '../../../core/common/constructor-type.mixin';
import { handleStateToRouteSync, theme } from 'projects/flex-app-shared/src/public-api';
import { isArray } from 'lodash-es';

export interface ToolbarTab {
  path: string | string[];
  label: string;
}

type widthBreakPoints = 'xs' | 's' | 'm' | 'l' | 'xl';

@Component({
  selector: 'ph-flex-toolbar-tabs',
  templateUrl: './toolbar-tabs.component.html',
  styleUrls: ['./toolbar-tabs.component.scss']
})
export class ToolbarTabsComponent extends ObservableInputsMixin(OnDestroyMixin(MixinBase)) {
  @Input()
  tabs: ToolbarTab[] = [];

  /**
   * Will collapse individual tabs to a single tab with dropdown when hit
   */
  @Input()
  breakPoint: widthBreakPoints = 's';
  breakPoint$ = this.oi.observe(() => this.breakPoint);

  private tabs$ = this.oi.observe(() => this.tabs);

  activeTabRoutePath$ = this.router.events.pipe(
    startWith(null),
    switchMap(() => this.tabs$),
    map((tabs) => tabs.find((tab) => this.isTabActive(tab))?.path)
  );

  tabsExpandedBreakpointMatches$ = this.breakPoint$.pipe(
    map((breakPoint) => `(min-width: ${theme.layout.width[breakPoint] || theme.layout.width.s}px)`),
    switchMap((breakPointQuery) => this.breakPointObserver.observe(breakPointQuery)),
    map((state) => state.matches)
  );

  constructor(private breakPointObserver: BreakpointObserver, private router: Router) {
    super();
  }

  selectUpdated($event: any): void {
    this.router.navigate([$event]);
  }

  handleClick(tab: ToolbarTab): void {
    const paths = isArray(tab.path) ? tab.path : [tab.path];

    handleStateToRouteSync(this.router, paths);
  }

  isTabActive(tab: ToolbarTab): boolean {
    const paths = isArray(tab.path) ? tab.path : [tab.path];
    return paths.some((path) =>
      this.router.isActive(path, {
        paths: 'subset',
        queryParams: 'subset',
        fragment: 'ignored',
        matrixParams: 'ignored'
      })
    );
  }
}
