import {
  AfterViewInit,
  ApplicationRef,
  Component,
  ComponentFactoryResolver,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
  ViewContainerRef
} from '@angular/core';
import {
  AxisAttachHelper,
  Capacity,
  D3GraphCursor,
  D3GraphLine,
  D3GraphLineAxis,
  D3GraphScaleBandVerticalLines,
  D3GraphScaleLineHorizontalLines,
  D3GraphSteppedLine,
  D3GraphXAxisLabels,
  DataPointStep,
  DateTicksHelper,
  defaultAllowedSteps,
  FlexResizeObserverDirective,
  getAllTicksZeroAligned,
  LineScaleProvider,
  MixinBase,
  MultiSeriesAttachHelper,
  OnDestroyMixin,
  OperationMeasurement,
  SeriesAttachHelper,
  SubjectProvider,
  theme,
  TimeSlotScaleProvider,
  TooltipComponent
} from 'flex-app-shared';
import { select, Selection } from 'd3';
import { BaseType } from 'd3-selection';
import { filter, map, takeUntil } from 'rxjs/operators';
import { parseISO } from 'date-fns';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { flatMap } from 'lodash-es';
import { ChartStageBackgroundSubmodule } from './chart-stage-background.submodule';
import { IncidentReserveAvailabilityFacade } from '../../../../store/incident-reserve-availability/incident-reserve-availability.facade';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { TemplatePortal } from '@angular/cdk/portal';

function measurementsToDataPointSteps(measurements: OperationMeasurement[]): DataPointStep[] {
  return measurements.map((measurement) => ({
    startDate: parseISO(measurement.period.startDateTime),
    toDate: parseISO(measurement.period.toDateTime),
    value: Capacity.asMW(measurement.capacity)
  }));
}

const powerLabel = $localize`:@@incidentReserve-yAxisLabelVolume:Power (MW)`;

@Component({
  selector: 'app-incident-reserve-activations-detail-screen-chart',
  templateUrl: './incident-reserve-activations-detail-screen-chart.component.html',
  styleUrls: ['./incident-reserve-activations-detail-screen-chart.component.scss']
})
export class IncidentReserveActivationsDetailScreenChartComponent extends OnDestroyMixin(MixinBase) implements OnInit, AfterViewInit {
  static marginLeft = 170;
  static marginRight = 140;
  static yAxisOffset = 30;
  static graphHeight = 360;
  static yAxisTicks = 7;

  tooltipRows = ['baselineSeries', 'deliveryTargetSeries', 'measurementSeries'];

  yScaleProviderVolume: LineScaleProvider;
  yAxisVolumes: D3GraphLineAxis;
  yAxisVolumesAttachHelper: AxisAttachHelper;

  xScaleProvider: TimeSlotScaleProvider;
  xAxisLabels: D3GraphXAxisLabels;

  d3GraphHorizontalLines: D3GraphScaleLineHorizontalLines;
  d3GraphVerticalLines: D3GraphScaleBandVerticalLines;

  measurementLine: D3GraphSteppedLine;
  measurementLineAttachHelper: SeriesAttachHelper;

  targetLine: D3GraphLine;
  targetLineAttachHelper: SeriesAttachHelper;

  baselineLine: D3GraphLine;
  baselineLineAttachHelper: SeriesAttachHelper;

  stageBackgrounds: ChartStageBackgroundSubmodule;

  ticksHelper: DateTicksHelper;

  private invertVolumesProvider = new SubjectProvider<boolean>(this, new BehaviorSubject(true));

  private mainSvg: Selection<BaseType, any, any, any>;
  private cursor: D3GraphCursor;

  @ViewChild(FlexResizeObserverDirective)
  private d3RootResizeObserver: FlexResizeObserverDirective;

  @ViewChild('tooltipTemplate')
  private tooltipTemplate: TemplateRef<any>;

  @ViewChildren(TooltipComponent, { read: FlexResizeObserverDirective })
  private resizeObserverDirectiveQueryList: QueryList<FlexResizeObserverDirective>;

  private data$ = combineLatest([this.facade.activationMeasurements$, this.invertVolumesProvider.value$]).pipe(
    filter(([data, inverted]) => !!data),
    map(([data, inverted]) => {
      if (inverted) {
        return {
          ...data,
          baselinePower: Capacity.invert(data.baselinePower),
          deliveryTargetPower: Capacity.invert(data.deliveryTargetPower),
          measurements: data.measurements.map((measurement) => ({
            period: measurement.period,
            capacity: Capacity.invert(measurement.capacity)
          }))
        };
      }
      return data;
    })
  );

  constructor(
    private facade: IncidentReserveAvailabilityFacade,
    private viewContainerRef: ViewContainerRef,
    private cfr: ComponentFactoryResolver,
    private appRef: ApplicationRef
  ) {
    super();
  }

  ngAfterViewInit(): void {
    const templatePortal = new TemplatePortal(this.tooltipTemplate, this.viewContainerRef);
    this.cursor.setPortal(templatePortal);
  }

  ngOnInit(): void {
    this.yScaleProviderVolume = new LineScaleProvider(this);
    this.xScaleProvider = new TimeSlotScaleProvider(this);

    this.yAxisVolumes = new D3GraphLineAxis(this, 'y-axis-volumes', powerLabel);
    this.yAxisVolumesAttachHelper = new AxisAttachHelper(this, this.yAxisVolumes);

    this.ticksHelper = new DateTicksHelper(this.xScaleProvider.scale$);
    this.xAxisLabels = new D3GraphXAxisLabels(this);

    this.d3GraphHorizontalLines = new D3GraphScaleLineHorizontalLines();
    this.d3GraphVerticalLines = new D3GraphScaleBandVerticalLines(this);

    this.stageBackgrounds = new ChartStageBackgroundSubmodule(this);

    this.targetLine = new D3GraphLine(this);
    this.targetLineAttachHelper = new SeriesAttachHelper(this, this.targetLine);

    this.baselineLine = new D3GraphLine(this);
    this.baselineLineAttachHelper = new SeriesAttachHelper(this, this.baselineLine);

    this.measurementLine = new D3GraphSteppedLine(this);
    this.measurementLineAttachHelper = new SeriesAttachHelper(this, this.measurementLine);

    this.cursor = new D3GraphCursor(this);

    this.xScaleProvider.setTimeSlot$(this.data$.pipe(map((data) => data.period)));
    this.xScaleProvider.setMargin({
      right: IncidentReserveActivationsDetailScreenChartComponent.marginRight,
      left: IncidentReserveActivationsDetailScreenChartComponent.marginLeft
    });

    this.xAxisLabels.setYScale$(this.yScaleProviderVolume.scale$);
    this.xAxisLabels.setXScale$(this.xScaleProvider.scale$);
    this.xAxisLabels.setDateFormat$(this.ticksHelper.labelFormat$);
    this.xAxisLabels.setTicks$(this.ticksHelper.ticks$);
    this.xAxisLabels.setMarginTop(20);

    this.yAxisVolumes.setYScale$(this.yScaleProviderVolume.scale$);
    this.yAxisVolumes.setXScale$(this.xScaleProvider.scale$);

    this.d3GraphVerticalLines.setXScale$(this.xScaleProvider.scale$);
    this.d3GraphVerticalLines.setYScale$(this.yScaleProviderVolume.scale$);
    this.d3GraphVerticalLines.setConfig({
      strokeWidthPx: 1,
      strokeColor: '#a3b0ba'
    });

    this.stageBackgrounds.setXScale$(this.xScaleProvider.scale$);
    this.stageBackgrounds.setYScale$(this.yScaleProviderVolume.scale$);
    this.stageBackgrounds.setData$(this.data$);

    const measurementData$ = this.data$.pipe(
      filter((a) => !!a),
      map((data) => measurementsToDataPointSteps(data.measurements as OperationMeasurement[]))
    );

    const seriesAttachHelpersForBaseline: (SeriesAttachHelper<any, any, any, any> | MultiSeriesAttachHelper<any, any, any, any>)[] = [
      this.measurementLineAttachHelper
    ];

    const baseLineYPositions$ = SeriesAttachHelper.baselineYPositions$(seriesAttachHelpersForBaseline);

    this.d3GraphHorizontalLines.setBaselinePositions$(baseLineYPositions$);
    this.d3GraphHorizontalLines.setXScale$(this.xScaleProvider.scale$);
    this.d3GraphHorizontalLines.setYScale$(this.yScaleProviderVolume.scale$);
    this.d3GraphHorizontalLines.setNumberOfTicks(IncidentReserveActivationsDetailScreenChartComponent.yAxisTicks);

    this.measurementLine.setXScale$(this.xScaleProvider.scale$);
    this.measurementLine.setYScaleProvider(this.yScaleProviderVolume);
    this.measurementLine.setData$(measurementData$);
    this.measurementLine.setConfig({
      fillColor: 'transparent',
      lineColor: theme.color.graphs.primary.dark
    });

    const targetData$ = this.data$.pipe(
      map((data) => [
        {
          value: Capacity.asMW(data.deliveryTargetPower),
          date: parseISO(data.period.startDateTime)
        },
        {
          value: Capacity.asMW(data.deliveryTargetPower),
          date: parseISO(data.period.toDateTime)
        }
      ])
    );
    this.targetLine.setXScale$(this.xScaleProvider.scale$);
    this.targetLine.setYScaleProvider(this.yScaleProviderVolume);
    this.targetLine.setData$(targetData$);
    this.targetLine.setConfig({
      lineColor: theme.color.graphs.primary.light,
      strokeDasharray: [6, 4]
    });

    const baseLineData$ = this.data$.pipe(
      map((data) => [
        {
          value: Capacity.asMW(data.baselinePower),
          date: parseISO(data.period.startDateTime)
        },
        {
          value: Capacity.asMW(data.baselinePower),
          date: parseISO(data.period.toDateTime)
        }
      ])
    );
    this.baselineLine.setXScale$(this.xScaleProvider.scale$);
    this.baselineLine.setYScaleProvider(this.yScaleProviderVolume);
    this.baselineLine.setData$(baseLineData$);
    this.baselineLine.setConfig({
      lineColor: theme.color.ui.support.grey,
      strokeDasharray: [6, 4]
    });

    const volumeDomain$ = combineLatest([measurementData$, baseLineData$, targetData$]).pipe(map((data) => flatMap(data)));

    const syncedDomains$ = combineLatest([volumeDomain$]).pipe(
      map((domains) => {
        return getAllTicksZeroAligned(
          domains.map((domain) => domain.map((obj) => obj.value)),
          6,
          defaultAllowedSteps,
          0.8
        );
      })
    );

    this.yAxisVolumes.setTicks$(syncedDomains$.pipe(map((ticks) => ticks[0].reverse())));
    this.yScaleProviderVolume.setTicks$(syncedDomains$.pipe(map((ticks) => ticks[0].reverse())));

    this.yScaleProviderVolume.setMargin({
      top: IncidentReserveActivationsDetailScreenChartComponent.yAxisOffset
    });
    this.yScaleProviderVolume.setDefaultDomain([1000, -1000]);

    this.cursor.setYScale$(this.yScaleProviderVolume.scale$);
    this.cursor.setXScale$(this.xScaleProvider.scale$);
    this.cursor.setCfr(this.cfr);
    this.cursor.setAppRef(this.appRef);
  }

  registerSvgRoot(mainSvg: Selection<BaseType, any, any, any>): void {
    this.mainSvg = mainSvg;

    this.d3RootResizeObserver.contentRect$.pipe(takeUntil(this.onDestroy$)).subscribe((contentRect) => {
      this.updateWidth(contentRect);
      this.updateHeight();
    });

    this.yScaleProviderVolume.scale$.pipe(takeUntil(this.onDestroy$)).subscribe((yScale) => {
      // Get yScale height, add some spacing for the labels, and set the svg height.
      this.mainSvg.attr('height', yScale.range()[1] + 45);
    });

    this.initD3();
  }

  updateWidth(contentRect: DOMRect): void {
    const width = contentRect.width;
    this.xScaleProvider.setWidth(width);

    const xMargins =
      IncidentReserveActivationsDetailScreenChartComponent.marginLeft + IncidentReserveActivationsDetailScreenChartComponent.marginRight;
    select('#clip rect').attr('width', width - xMargins);
  }

  updateHeight(): void {
    this.yScaleProviderVolume.setHeight(IncidentReserveActivationsDetailScreenChartComponent.graphHeight);
  }

  onYAxisToggleChange($event: MatSlideToggleChange): void {
    this.invertVolumesProvider.next(!$event.checked);
  }

  getMeasurementForDate(date: Date): Observable<number> {
    return this.measurementLine.getYDomainValue$(date);
  }

  getBaselineValueForDate(date: Date): Observable<number> {
    return this.baselineLine.getYDomainValue$(date);
  }

  getDeliveryTargetValueForDate(date: Date): Observable<number> {
    return this.targetLine.getYDomainValue$(date);
  }

  getDeliveredValueForDate(date: Date): Observable<number> {
    return combineLatest([this.data$, this.getMeasurementForDate(date)]).pipe(
      map(([data, measurementValue]) => measurementValue - Capacity.asMW(data.deliveryTargetPower))
    );
  }

  private initD3(): void {
    this.stageBackgrounds.attach(this.mainSvg);
    this.xAxisLabels.attach(this.mainSvg);
    this.yAxisVolumes.attach(this.mainSvg);
    this.d3GraphVerticalLines.attach(this.mainSvg);
    this.d3GraphHorizontalLines.attach(this.mainSvg);
    this.targetLineAttachHelper.attach(this.mainSvg);
    this.baselineLineAttachHelper.attach(this.mainSvg);
    this.measurementLineAttachHelper.attach(this.mainSvg);

    this.cursor.attach(this.mainSvg);
    this.cursor.setOverlay(this.mainSvg);
  }
}
