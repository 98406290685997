<mat-drawer-container [hasBackdrop]="hasBackdrop$ | async" [@.disabled]="true">
  <mat-drawer [mode]="modeSubject | async" class="fixed">
    <ph-flex-side-menu [isExpandable]="isExpandable" [(isExpanded)]="isExpanded">
      <img slot="logo" src="assets/powerhouse_logo.svg" alt="Powerhouse logo" />
      <img slot="logo-small" src="assets/powerhouse_logo-spacing-b-icon.svg" alt="Powerhouse emblem" />
      <ng-container *ngFor="let menuItem of menuItems">
        <app-side-menu-item [menuRoute]="menuItem" [phFlexAuthShow]="authoritiesForMenuItem(menuItem)"></app-side-menu-item>
      </ng-container>
    </ph-flex-side-menu>
  </mat-drawer>
  <mat-drawer-content>
    <app-header [menuItems]="menuItems" [username]="userName$ | async" (appLogout)="logout()"></app-header>
    <div class="page-content">
      <router-outlet></router-outlet>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
