import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IdconsAnnouncementsRoutingModule } from './idcons-announcements-routing.module';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSortModule } from '@angular/material/sort';
import { MatButtonModule } from '@angular/material/button';
import { FlexCoreModule, FlexLoadingContextModule, FlexNotificationModule } from 'flex-app-shared';
import { MatBadgeModule } from '@angular/material/badge';
import { IdconsAnnouncementDetailDialogComponent } from './idcons-announcement-detail-dialog/idcons-announcement-detail-dialog.component';
import { IntraDayModule } from '../intraday/intraday.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { IncidentReserveModule } from '../incident-reserve/incident-reserve.module';
import { AnnouncementsComponent } from './announcements/announcements.component';
import { AnnouncementComponent } from './announcements/announcement/announcement.component';
import { AnnouncementsPageComponent } from './announcements-page/announcements-page.component';
import { IdconsAnnouncementsPerDayComponent } from './idcons-announcements-per-day/idcons-announcements-per-day.component';
import { IdconsAnnouncementsComponent } from './idcons-announcements/idcons-announcements.component';

// @ts-ignore
@NgModule({
  imports: [
    CommonModule,
    IdconsAnnouncementsRoutingModule,
    FlexLoadingContextModule,
    MatCardModule,
    MatIconModule,
    MatToolbarModule,
    MatTableModule,
    MatTooltipModule,
    MatDialogModule,
    MatSortModule,
    MatButtonModule,
    MatBadgeModule,
    IntraDayModule,
    FlexCoreModule,
    MatExpansionModule,
    IncidentReserveModule,
    FlexNotificationModule
  ],
  exports: [IdconsAnnouncementsPerDayComponent, IdconsAnnouncementsComponent],
  declarations: [
    AnnouncementsPageComponent,
    IdconsAnnouncementsPerDayComponent,
    IdconsAnnouncementsComponent,
    IdconsAnnouncementDetailDialogComponent,
    AnnouncementsComponent,
    AnnouncementComponent
  ]
})
export class IdconsAnnouncementsModule {}
