<mat-dialog-content>
  <ng-container i18n="@@intraday-idconsOrderCancel" *ngIf="isOrderCancelable$ | async; else orderNotCancelable"
    >Are you sure you want to cancel this order?</ng-container
  >
</mat-dialog-content>

<mat-dialog-actions>
  <ng-container *ngIf="isOrderCancelable$ | async; else orderNotCancelableButtons">
    <button mat-stroked-button matDialogClose i18n="@@buttonNo">No</button>
    <ph-flex-save-button
      (clickChanged)="handleCancel()"
      [error]="error$ | async"
      [pending]="pending$ | async"
      i18n="@@buttonYes"
      [shouldGetInitialFocus]="true"
      >Yes</ph-flex-save-button
    >
  </ng-container>
</mat-dialog-actions>

<ng-template #orderNotCancelable>
  <ng-container i18n="@@intraday-idconsOrderCancelNotPossible">This order cannot be cancelled at this time.</ng-container>
</ng-template>
<ng-template #orderNotCancelableButtons><button mat-stroked-button matDialogClose i18n="@@buttonOk">Ok</button></ng-template>
