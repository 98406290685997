import { Directive, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { CdkPortal } from '@angular/cdk/portal';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ArrowSize, FlexTooltipArrowComponent, FormControlErrorTriggerBehavior } from './tooltip.directive';
import { MixinBase } from '../../core/common/constructor-type.mixin';
import { OnDestroyMixin, OnDestroyProvider } from '../../core/common/on-destroy.mixin';
import { ErrorStateMatcher } from '@angular/material/core';
import { FlexTooltipContainerComponent } from './flex-tooltip-container.component';
import { ComponentPortalOverlayManager } from './component-portal-overlay-manager';
import { EmbeddedComponentOverlayManager } from './embedded-component-overlay-manager';
import { Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[phFlexControlErrorOverlay]'
})
export class ControlErrorOverlayDirective extends OnDestroyMixin(MixinBase) implements OnInit, OnChanges {
  @Input('phFlexControlErrorOverlay') input: CdkPortal;
  @Input() phFlexControlErrorOverlayControl: AbstractControl;
  @Input() phFlexControlErrorOverlayForceShow: boolean;
  @Input() phFlexControlErrorOverlayClasses: string[] = [];
  @Input() context: any = {};
  private arrowSizePx = ArrowSize.SMALL;
  private arrowInset = 2;
  private mainPortalOverlayManager: EmbeddedComponentOverlayManager;
  private mainPortalTriggerBehavior: FormControlErrorTriggerBehavior;
  private arrowPortalOverlayManager: ComponentPortalOverlayManager;
  private arrowPortalTriggerBehavior: FormControlErrorTriggerBehavior;

  private controlStatusHelper = new ControlStatusSubscriptionHelper(() => {
    this.refreshClasses();
  }, this);

  constructor(private overlay: Overlay, private elementRef: ElementRef, private errorStateMatcher: ErrorStateMatcher) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.input) {
      if (this.mainPortalOverlayManager) {
        this.mainPortalOverlayManager.destroy();
        this.mainPortalTriggerBehavior.destroy();
      }

      // Register context to be injected into the template
      this.input.context = {
        control: this.phFlexControlErrorOverlayControl,
        ...this.context
      };

      this.mainPortalOverlayManager = new EmbeddedComponentOverlayManager(this, this.getMainOverlayRef());
      this.mainPortalOverlayManager.registerEmbeddedComponent(FlexTooltipContainerComponent, this.input);
      this.mainPortalTriggerBehavior = new FormControlErrorTriggerBehavior()
        .registerErrorStateMatcher(this.errorStateMatcher)
        .registerOnDestroyProvider(this)
        .registerPortalOverlayManager(this.mainPortalOverlayManager)
        .registerFormControl(this.phFlexControlErrorOverlayControl)
        .setForceShow(this.phFlexControlErrorOverlayForceShow)
        .attach();
    }
    if (changes.context) {
      this.input.context = {
        control: this.phFlexControlErrorOverlayControl,
        ...this.context
      };
      this.mainPortalOverlayManager.registerEmbeddedComponent(FlexTooltipContainerComponent, this.input);
    }
    if (changes.phFlexControlErrorOverlayForceShow) {
      this.arrowPortalTriggerBehavior?.setForceShow(this.phFlexControlErrorOverlayForceShow);
      this.mainPortalTriggerBehavior?.setForceShow(this.phFlexControlErrorOverlayForceShow);
    }
    if (changes.phFlexControlErrorOverlayClasses) {
      this.refreshClasses();
    }
    if (changes.phFlexControlErrorOverlayControl) {
      this.controlStatusHelper.register(this.phFlexControlErrorOverlayControl);
    }
  }

  private refreshClasses(): void {
    this.arrowPortalOverlayManager?.setPanelClasses(this.getArrowOverlayClasses());
    this.mainPortalOverlayManager?.setPanelClasses(this.getMainOverlayClasses());
  }

  ngOnInit(): void {
    this.arrowPortalOverlayManager = new ComponentPortalOverlayManager(this, this.getArrowOverlayRef());
    this.arrowPortalOverlayManager.registerComponent(FlexTooltipArrowComponent);

    this.arrowPortalTriggerBehavior = new FormControlErrorTriggerBehavior()
      .registerErrorStateMatcher(this.errorStateMatcher)
      .registerOnDestroyProvider(this)
      .registerPortalOverlayManager(this.arrowPortalOverlayManager)
      .registerFormControl(this.phFlexControlErrorOverlayControl)
      .setForceShow(this.phFlexControlErrorOverlayForceShow)
      .attach();
  }

  private getStateClass(): string {
    if (this.phFlexControlErrorOverlayControl.invalid) {
      return 'error';
    }
    return 'valid';
  }

  private getMainOverlayRef(): OverlayRef {
    const mainPositionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.elementRef)
      .withFlexibleDimensions(false)
      .withPush(false)
      .withPositions([
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom',
          offsetY: -this.arrowSizePx + this.arrowInset
        }
      ]);

    return this.overlay.create({
      panelClass: this.getMainOverlayClasses(),
      positionStrategy: mainPositionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.reposition()
    });
  }

  private getMainOverlayClasses(): string[] {
    return ['flex-pop-over-context', this.getStateClass(), ...this.phFlexControlErrorOverlayClasses];
  }

  private getArrowOverlayClasses(): string[] {
    return ['flex-pop-over-context-arrow', this.getStateClass(), ...this.phFlexControlErrorOverlayClasses, 'small'];
  }

  private getArrowOverlayRef(): OverlayRef {
    const arrowPositionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.elementRef)
      .withFlexibleDimensions(false)
      .withPush(false)
      .withPositions([
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom',
          offsetY: this.arrowInset
        }
      ]);

    return this.overlay.create({
      panelClass: this.getArrowOverlayClasses(),
      positionStrategy: arrowPositionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.reposition()
    });
  }
}

class ControlStatusSubscriptionHelper {
  protected subscription: Subscription;

  constructor(protected fn: (control: AbstractControl) => void, protected onDestroyProvider: OnDestroyProvider) {}

  register(control: AbstractControl): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = control.statusChanges.pipe(takeUntil(this.onDestroyProvider.onDestroy$)).subscribe(() => this.fn(control));
  }
}
