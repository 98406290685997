import { Destroyable, OnDestroyProvider } from '../../core/common/on-destroy.mixin';
import { Portal } from '@angular/cdk/portal';
import { OverlayRef } from '@angular/cdk/overlay';

export abstract class PortalOverlayManager extends Destroyable {
  protected portal: Portal<any>;
  protected isDisabled: boolean = false;
  protected panelClasses = [];

  constructor(onDestroyProvider: OnDestroyProvider, protected overlayRef: OverlayRef) {
    super(onDestroyProvider);
  }

  get isOpen(): boolean {
    return this.overlayRef?.hasAttached();
  }

  set disabled(v: boolean) {
    this.isDisabled = v;
    if (this.isDisabled) {
      this.close();
    }
  }

  get disabled(): boolean {
    return this.isDisabled;
  }

  close(): void {
    if (!this.isOpen) {
      return;
    }

    this.overlayRef.detach();
  }

  open(): void {
    if (this.isDisabled) {
      // Do not open if disabled
      return;
    }

    if (this.isOpen) {
      // Delay updatePosition to let UI update finish before position update
      setTimeout(() => this.overlayRef?.updatePosition());
    }

    if (this.isOpen || !this.overlayRef) {
      return;
    }

    this.close();
    this.overlayRef.attach(this.portal);
  }

  toggle(): void {
    if (this.isOpen) {
      this.close();
    } else {
      this.open();
    }
  }

  setPanelClasses(classes: string[]): void {
    this.overlayRef.removePanelClass(this.panelClasses);
    this.overlayRef.addPanelClass(classes);
    this.panelClasses = classes;
  }

  destroy(): void {
    this.close();
    this.overlayRef.dispose();
    this.overlayRef = null;

    // TODO is this needed?
    if (this.portal?.isAttached) {
      this.portal.detach();
    }
  }
}
