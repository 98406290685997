import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CdkTreeModule } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPopoverEditModule } from '@angular/material-experimental/popover-edit';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { NgxsModule } from '@ngxs/store';
import {
  FlexApiPrivateModule,
  FlexAuthModule,
  FlexAutocompleteModule,
  FlexButtonsModule,
  FlexCoreModule,
  FlexDateRangeFixedPeriodModule,
  FlexDateRangeModule,
  FlexErrorMessagesModule,
  FlexFiltersModule,
  FlexInfoIconModule,
  FlexLoadingContextModule,
  FlexNumberInputModule,
  FlexPopOverContextModule,
  FlexSaveButtonModule,
  FlexTableFilterModule,
  FlexTableMultiSelectModule,
  FlexToolbarTabsModule
} from 'flex-app-shared';
import { IntradayHistoryFiltersFormComponent } from './intraday-history/intraday-history-filters-form/intraday-history-filters-form.component';
import { IntradayHistoryComponent } from './intraday-history/intraday-history.component';
import { IntradayRoutingModule } from './intraday-routing.module';
import { IntradayToolbarContainerComponent } from './intraday-toolbar-container.component';
import { CountdownComponent } from './intraday/countdown/countdown.component';
import { IntradayDealDirectionComponent } from './intraday/intraday-deal-direction/intraday-deal-direction.component';
import { IntradayDealDisplayStatusComponent } from './intraday/intraday-deal-display-status/intraday-deal-display-status.component';
import { IntradayDealMarketComponent } from './intraday/intraday-deal-market/intraday-deal-market.component';
import { IntradayDealStatusOverviewComponent } from './intraday/intraday-deal-status-overview/intraday-deal-status-overview.component';
import { IntradayDealStatusComponent } from './intraday/intraday-deal-status/intraday-deal-status.component';
import { IntradayPriceHistoryDialogComponent } from './intraday/intraday-price-history-dialog/intraday-price-history-dialog.component';
import { IntradayPriceHistoryGraphComponent } from './intraday/intraday-price-history-graph/intraday-price-history-graph.component';
import { IntradaySlotDeltaIconComponent } from './intraday/intraday-slot-delta-icon/intraday-slot-delta-icon.component';
import { IntradaySlotOverviewComponent } from './intraday/intraday-slot-overview/intraday-slot-overview.component';
import { IntradaySlotFocusDirective } from './intraday/intraday-slot/intraday-slot-focus.directive';
import { IntradaySlotComponent } from './intraday/intraday-slot/intraday-slot.component';
import { IntraDayComponent } from './intraday/intraday.component';
import { CreateOrderDialogComponent } from './order/create-order-dialog/create-order-dialog.component';
import { StepDirectionComponent } from './order/create-order-dialog/steps/step-direction/step-direction.component';
import { StepPriceComponent } from './order/create-order-dialog/steps/step-price/step-price.component';
import {
  GopacsCustomerDatasourceDirective,
  GopacsGridPointDatasourceDirective,
  StepSelectionComponent
} from './order/create-order-dialog/steps/step-selection/step-selection.component';
import { StepSummaryComponent } from './order/create-order-dialog/steps/step-summary/step-summary.component';
import { HourSelectorComponent } from './order/create-order-dialog/steps/step-time/hour-selector/hour-selector.component';
import { StepTimeComponent } from './order/create-order-dialog/steps/step-time/step-time.component';
import { StepVolumeComponent } from './order/create-order-dialog/steps/step-volume/step-volume.component';
import { IntradayOrderStatusComponent } from './order/open-orders/idcons-order-status/intraday-order-status.component';
import { OpenOrdersComponent } from './order/open-orders/open-orders.component';
import { IntradayHistoryState } from './state/history/intraday-history-state.service';
import { IntradayState } from './state/intraday-state.service';
import { CancelOrderConfirmationComponent } from './state/order/cancel-order-confirmation/cancel-order-confirmation.component';
import { IntradayOrderState } from './state/order/intraday-order.state';
import { OrderCreatedSnackbarComponent } from './state/order/order-created-snackbar/order-created-snackbar.component';
import { FlexTableContainerModule } from '../../../../flex-app-shared/src/lib/material/table-container/table-container.module';

@NgModule({
  declarations: [
    IntraDayComponent,
    IntradaySlotComponent,
    IntradayHistoryComponent,
    CountdownComponent,
    IntradaySlotOverviewComponent,
    IntradaySlotDeltaIconComponent,
    IntradayDealStatusComponent,
    IntradayDealStatusOverviewComponent,
    IntradayDealDisplayStatusComponent,
    IntradayDealMarketComponent,
    IntradayDealDirectionComponent,
    IntradaySlotFocusDirective,
    CreateOrderDialogComponent,
    StepDirectionComponent,
    StepSelectionComponent,
    StepTimeComponent,
    StepVolumeComponent,
    StepPriceComponent,
    OpenOrdersComponent,
    StepSummaryComponent,
    OrderCreatedSnackbarComponent,
    CancelOrderConfirmationComponent,
    GopacsCustomerDatasourceDirective,
    GopacsGridPointDatasourceDirective,
    IntradayOrderStatusComponent,
    HourSelectorComponent,
    IntradayHistoryFiltersFormComponent,
    IntradayPriceHistoryGraphComponent,
    IntradayPriceHistoryDialogComponent,
    IntradayToolbarContainerComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatTableModule,
    NgxsModule.forFeature([IntradayState, IntradayOrderState, IntradayHistoryState]),
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    FlexNumberInputModule,
    FlexCoreModule,
    FlexSaveButtonModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatInputModule,
    MatSortModule,
    MatToolbarModule,
    FlexTableFilterModule,
    MatPaginatorModule,
    FlexAutocompleteModule,
    FlexApiPrivateModule,
    FormsModule,
    MatSelectModule,
    MatPopoverEditModule,
    CdkTreeModule,
    OverlayModule,
    RouterModule,
    MatTooltipModule,
    MatCheckboxModule,
    IntradayRoutingModule,
    FlexPopOverContextModule,
    PortalModule,
    MatSlideToggleModule,
    FlexCoreModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatStepperModule,
    MatButtonToggleModule,
    FlexAuthModule,
    FlexErrorMessagesModule,
    FlexFiltersModule,
    FlexDateRangeModule,
    FlexDateRangeFixedPeriodModule,
    FlexLoadingContextModule,
    FlexToolbarTabsModule,
    FlexTableMultiSelectModule,
    FlexButtonsModule,
    FlexInfoIconModule,
    FlexTableContainerModule
  ],
  exports: [IntraDayComponent, IntradayHistoryComponent, IntradayDealDirectionComponent]
})
export class IntraDayModule {}
