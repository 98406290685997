import { Directive } from '@angular/core';
import { DEFAULT_PERIOD, PeriodUnit } from '../default-period';

@Directive({
  selector: '[phFlexDateRangeFixedPeriodDefaultYear]',
  providers: [
    {
      provide: DEFAULT_PERIOD,
      useValue: PeriodUnit.YEAR
    }
  ]
})
export class DateRangeFixedPeriodDefaultYearDirective {}
