import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { FlexCoreModule, FlexLoadingContextModule, FlexSaveButtonModule } from 'flex-app-shared';
import { UserPreferencesDialogComponent } from './user-preferences-dialog/user-preferences-dialog.component';

@NgModule({
  declarations: [UserPreferencesDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    FlexLoadingContextModule,
    FlexSaveButtonModule,
    MatIconModule,
    MatButtonModule,
    FlexCoreModule
  ],
  exports: [UserPreferencesDialogComponent]
})
export class UserPreferencesModule {}
