<div class="ph-flex-form-group-container" [formGroup]="formGroup">
  <div>
    <mat-form-field (keyup.enter)="stepper.next()">
      <mat-label i18n="@@intraday-tableHeaderPrice">Price</mat-label>
      <ph-flex-number-input formControlName="pricePerMWh"></ph-flex-number-input>
      <span matTextSuffix>€/MWh</span>
      <mat-error *ngIf="formGroup.get('pricePerMWh').errors?.required" i18n="@@intraday-errorOrderPriceRequired">
        Order price is required
      </mat-error>
      <mat-error *ngIf="formGroup.get('pricePerMWh').errors?.min" i18n="@@intraday-errorOrderPriceTooLow">
        Enter a price higher than -9999.99
      </mat-error>
      <mat-error *ngIf="formGroup.get('pricePerMWh').errors?.max" i18n="@@intraday-errorOrderPriceTooHigh">
        Enter a price lower than 9999.99
      </mat-error>
      <mat-error *ngIf="formGroup.get('pricePerMWh').errors?.invalidNumberOfDecimals" i18n="@@intraday-errorOrderPriceTooManyDecimals">
        Enter a price with 2 decimals
      </mat-error>
    </mat-form-field>
  </div>
</div>

<ng-template matStepLabel>
  <ng-container *ngIf="formGroup.invalid || isCurrentStepSelected()" i18n="@@intraday-tableHeaderPrice">Price </ng-container>
  <ng-container *ngIf="formGroup.valid && !isCurrentStepSelected()">
    {{ formGroup.get('pricePerMWh').value | number : '1.2' }} €/MWh
  </ng-container>
</ng-template>
