import { Injectable } from '@angular/core';
import { AnnouncementsPageComponent } from './announcements-page/announcements-page.component';
import { UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { IdconsAnnouncementsFacade } from '../../store/idcons-announcements/idcons-announcements.facade';

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateIdconsAnnouncementsPage {
  constructor(public facade: IdconsAnnouncementsFacade) {}

  canDeactivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.facade.markAllAnnouncementsAsRead();

    return true;
  }
}
