import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ph-flex-toolbar-tab',
  templateUrl: './toolbar-tab.component.html',
  styleUrls: ['./toolbar-tab.component.scss']
})
export class ToolbarTabComponent {
  @HostBinding('class.active')
  @Input()
  active: boolean;

  constructor() {}
}
