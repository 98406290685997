import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SideMenuService {
  private expandedSidebarSubject = new Subject<void>();

  expandedSidebar$ = this.expandedSidebarSubject.asObservable();

  expandSidebar(): void {
    this.expandedSidebarSubject.next();
  }
}
