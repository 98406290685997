export class PaginatedResponse<T> {
  content?: T[];
  empty?: boolean;
  first?: boolean;
  last?: boolean;
  number?: number;
  numberOfElements?: number;
  pageable: any;
  size?: number;
  sort?: any;
  totalElements: number;
  totalPages?: number;

  static updateContent<T>(paginatedResponse: PaginatedResponse<T>, newContent: T[]): PaginatedResponse<T> {
    const contentDiff = newContent.length - paginatedResponse.content.length;
    return {
      ...paginatedResponse,
      totalElements: paginatedResponse.totalElements + contentDiff,
      content: newContent
    };
  }

  static addContent<T>(paginatedResponse: PaginatedResponse<T>, toAdd: T[]): PaginatedResponse<T> {
    return PaginatedResponse.updateContent(paginatedResponse, [...paginatedResponse.content, ...toAdd]);
  }
}
