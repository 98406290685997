import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import eachHourOfInterval from 'date-fns/eachHourOfInterval';
import { DocumentClickService, MixinBase, OnDestroyMixin, OnDestroyProvider, TimeSlot } from 'flex-app-shared';
import { takeUntil } from 'rxjs/operators';
import isBefore from 'date-fns/isBefore';
import { addHours, getHours, getMilliseconds, getMinutes, getSeconds, Interval, isWithinInterval, set, toDate } from 'date-fns';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import isSameDay from 'date-fns/isSameDay';
import startOfDay from 'date-fns/startOfDay';
import { ReplaySubject } from 'rxjs';
import { endOfDay } from 'date-fns/fp';
import { NumberRange } from 'range-ts';

/**
 * Outputs an Interval
 */
@Component({
  selector: 'app-hour-selector',
  templateUrl: './hour-selector.component.html',
  styleUrls: ['./hour-selector.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: HourSelectorComponent, multi: true },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => HourSelectorComponent)
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HourSelectorComponent extends OnDestroyMixin(MixinBase) implements OnInit, ControlValueAccessor, OnChanges, Validator {
  rowSize = 8;
  numberOfRows = 3;

  blockStartHours = [];

  /**
   * Represents the number range that is currently editable. If falsy, no hours are selectable
   */
  @Input()
  editableNumberRange: NumberRange;

  /**
   * The day for which hours can be selected
   */
  @Input() targetDay: Date = new Date();
  @Output() targetDayChanged = new EventEmitter<Date>();
  helper = new HourSelectionHelper(this);

  isDisabled = false;
  rows: any;

  constructor(private documentClickService: DocumentClickService, private elRef: ElementRef, private cdr: ChangeDetectorRef) {
    super();
  }

  _validatorOnChange = () => {};

  validate(control: AbstractControl): ValidationErrors {
    const timeSlotValue = this.helper.getSelectedTimeSlot();
    if (timeSlotValue && (!this.editableNumberRange || !this.editableNumberRange.encloses(TimeSlot.toNumberRange(timeSlotValue)))) {
      return {
        selectedTimeSlotOutsideEditableRange: true
      };
    }
  }

  registerOnValidatorChange?(fn: () => void): void {
    this._validatorOnChange = fn;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.targetDay) {
      this.updateBlockStartHours();
      this.updateRows();
    }
    if (this.editableNumberRange) {
      this._validatorOnChange();
    }
  }

  writeValue(obj: Interval): void {
    // TODO should this be changed to TimeSlot?
    // Set targetDay if different from targetDay
    if (!obj) {
      this.helper.setValue(obj);
      return;
    }

    if (!isSameDay(obj.start, obj.end)) {
      // Invalid interval
      console.warn('got invalid interval for app hour selector', this, obj);
      return;
    }

    if (!this.targetDay || !isSameDay(this.targetDay, obj.start)) {
      this.targetDay = startOfDay(obj.start);
      this.targetDayChanged.emit(this.targetDay);
    }

    this.helper.setValue(obj);
  }

  registerOnChange(fn: any): void {
    this.helper.onChange$.pipe(takeUntil(this.onDestroy$)).subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    // TODO
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  ngOnInit(): void {
    this.updateBlockStartHours();
    this.updateRows();

    this.documentClickService
      .outsideElementClicked$(this.elRef)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        // Since this click originates from outside the component, we need to trigger an update through cdr.
        const hasUpdated = this.helper.handleOutsideClick();

        if (hasUpdated) {
          this.cdr.detectChanges();
        }
      });
  }

  getRows(): Date[][] {
    const rows = [];
    for (let i = 0; i < this.numberOfRows; i++) {
      rows.push(this.getRow(i));
    }
    return rows;
  }

  getRow(index: number): Date[] {
    const end = index === this.numberOfRows - 1 ? undefined : this.rowSize * (index + 1) + 1;
    return this.blockStartHours.slice(this.rowSize * index, end);
  }

  trackByHour(index: number, item: Date): any {
    if (!item) {
      return index;
    }
    return item;
  }

  isHourDisabled(hour: Date): boolean {
    if (!this.editableNumberRange) {
      return true;
    }

    return !this.editableNumberRange.contains(hour.valueOf());
  }

  handleHourClick(hour: Date, event: MouseEvent): void {
    event.stopPropagation();

    if (this.isHourDisabled(hour)) {
      return;
    }
    this.helper.handleInsideClick(hour);
  }

  private updateBlockStartHours(): void {
    this.blockStartHours = eachHourOfInterval({
      start: startOfDay(this.targetDay),
      end: addHours(endOfDay(this.targetDay), 1) // Add one hour to get the 24th hour in a regular day
    });
    this.helper.setDay(this.targetDay);
  }

  private updateRows(): void {
    this.rows = this.getRows();
  }
}

class HourSelectionHelper {
  onChangeFn: any;
  private onChangeSubject = new ReplaySubject<TimeSlot>(1);
  onChange$ = this.onChangeSubject.asObservable();

  private isSelecting = false;
  private selectionStart: Date;
  private selectionEnd: Date;
  private lastHover: Date;

  constructor(onDestroyProvider: OnDestroyProvider) {
    onDestroyProvider.onDestroy$.subscribe(() => {
      this.onChangeSubject.complete();
    });
  }

  handleInsideClick(hourStart: Date): void {
    if (this.isSelecting) {
      this.selectionEnd = hourStart;
      this.isSelecting = false;
      this.callOnChange();
    } else {
      this.selectionStart = hourStart;
      this.isSelecting = true;
      this.selectionEnd = null;
      this.callOnChange();
    }
  }

  handleOutsideClick(): boolean {
    if (this.isSelecting) {
      this.isSelecting = false;
      return true;
    }
    return false;
  }

  handleMouseOver(hourStart: Date): void {
    this.lastHover = hourStart;
  }

  handleMouseOut(): void {
    this.lastHover = null;
  }

  clear(): void {
    this.selectionStart = null;
    this.selectionEnd = null;
    this.isSelecting = false;
    this.callOnChange();
  }

  setValue(interval: Interval): void {
    if (!interval) {
      this.clear();
    } else {
      this.selectionStart = toDate(interval.start);
      this.selectionEnd = toDate(interval.end);
      this.callOnChange();
    }
  }

  setDay(date: Date): void {
    if (this.selectionStart && !isSameDay(this.selectionStart, date)) {
      this.clear();
    }
  }

  getSelectedInterval(): Interval | null {
    if (this.selectionStart && this.selectionEnd) {
      if (isBefore(this.selectionStart, this.selectionEnd)) {
        return {
          start: this.selectionStart,
          end: this.selectionEnd
        };
      } else {
        return {
          start: this.selectionEnd,
          end: this.selectionStart
        };
      }
    } else if (this.selectionStart) {
      // Select one hour based on selectionStart
      return {
        start: this.selectionStart,
        end: this.selectionStart
      };
    }
    return null;
  }

  getSelectedTimeSlot(): TimeSlot | null {
    const selectedInterval = this.getSelectedInterval();
    if (!selectedInterval) {
      return null;
    }

    return {
      startDateTime: toDate(selectedInterval.start).toISOString(),
      toDateTime: addHours(selectedInterval.end, 1).toISOString()
    };
  }

  getHoverInterval(): Interval | null {
    if (!this.isSelecting) {
      return null;
    }

    if (this.selectionStart && this.lastHover) {
      if (isBefore(this.selectionStart, this.lastHover)) {
        return {
          start: this.selectionStart,
          end: this.lastHover
        };
      } else {
        return {
          start: this.lastHover,
          end: this.selectionStart
        };
      }
    }
    return null;
  }

  isHover(hour: Date): boolean {
    const hoverInterval = this.getHoverInterval();

    if (!hoverInterval) {
      return false;
    }

    return isWithinInterval(hour, hoverInterval);
  }

  isSelected(hour: Date): boolean {
    const selectedInterval = this.getSelectedInterval();

    if (!selectedInterval) {
      if (this.selectionStart && this.selectionStart === hour) {
        return true;
      }

      return false;
    }

    return isWithinInterval(hour, selectedInterval);
  }

  private syncDates(sourceDate: Date, targetDate: Date): Date {
    return set(sourceDate, {
      hours: getHours(targetDate),
      minutes: getMinutes(targetDate),
      seconds: getSeconds(targetDate),
      milliseconds: getMilliseconds(targetDate)
    });
  }

  private callOnChange(): void {
    this.onChangeSubject.next(this.getSelectedTimeSlot());
  }
}
