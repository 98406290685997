import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { CdkColumnDef } from '@angular/cdk/table';
import { MatColumnDef, MatHeaderCellDef } from '@angular/material/table';
import { TableSelectionAction } from '../table-selection-action';
import { selectActionsColumn } from '../table-selection-header-columns';
import { TableSelectionModel } from '../table-selection-model';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { DialogRef } from '@angular/cdk/dialog';
import { get, has } from 'lodash-es';

export interface TableActionEvent<T> {
  /**
   * The action that is performed
   */
  action: TableSelectionAction;

  /**
   * The (known) affected items
   */
  affectedItems: T[];

  /**
   * True when ALL items should be affected.
   * This can be sent to the backend if the data source is backend paginated.
   */
  allItemsAffected: boolean;
}

@Component({
  selector: 'ph-flex-table-selection-actions-cell',
  styleUrls: ['./table-selection-actions-cell.component.scss'],
  templateUrl: './table-selection-actions-cell.component.html',
  providers: [
    { provide: CdkColumnDef, useExisting: TableSelectionActionsCellComponent },
    { provide: 'MAT_SORT_HEADER_COLUMN_DEF', useExisting: TableSelectionActionsCellComponent }
  ]
})
export class TableSelectionActionsCellComponent<T> extends MatColumnDef implements OnInit {
  TableSelectionAction = TableSelectionAction;

  actionBusy = false;
  actionError = null;

  @ViewChild(MatHeaderCellDef)
  headerCell;

  @ViewChild('confirmDeleteDialog')
  confirmDeleteDialogTemplate: TemplateRef<any>;

  @Input()
  actions: TableSelectionAction[] = [];

  /**
   * Map that contains observables, which will be subscribed to when the action is performed.
   * Used for the save-button.
   */
  @Input()
  actionObservables: { [key: string]: Observable<any> } = {};

  @Output()
  action = new EventEmitter<TableActionEvent<T>>();

  constructor(public tableSelectionModel: TableSelectionModel<T>, public matDialog: MatDialog) {
    super();
  }

  ngOnInit(): void {
    this.name = selectActionsColumn;
  }

  getSelectedRows(): T[] {
    return this.tableSelectionModel.getSelectedRows();
  }

  getSelectedRowCount$(): Observable<number> {
    return this.tableSelectionModel.getSelectedRowCount$();
  }

  getAllRowsSelected(): boolean {
    return this.tableSelectionModel.allRowsSelected;
  }

  getTotalRowCount$(): Observable<number> {
    return this.tableSelectionModel.totalRowCount$;
  }

  performDialogAction(dialogRef: DialogRef<any>, action: TableSelectionAction): void {
    if (has(this.actionObservables, action)) {
      // Custom observable found, subscribe to it
      this.actionBusy = true;
      this.actionError = null;

      get(this.actionObservables, action).subscribe({
        next: () => {
          this.actionBusy = false;
          this.tableSelectionModel.clear();
          dialogRef.close(true);
        },
        error: (error) => {
          this.actionBusy = false;
          this.actionError = error;
        }
      });
    } else {
      dialogRef.close(true);
    }
  }

  actionButtonClicked(action: TableSelectionAction): void {
    switch (action) {
      case TableSelectionAction.DELETE:
        this.matDialog
          .open(this.confirmDeleteDialogTemplate, { data: { action } })
          .afterClosed()
          .subscribe((result) => {
            if (result) {
              this.action.emit({
                action,
                affectedItems: this.getSelectedRows(),
                allItemsAffected: this.getAllRowsSelected()
              });
            }
          });
        break;
      default:
        this.action.emit({
          action,
          affectedItems: this.getSelectedRows(),
          allItemsAffected: this.getAllRowsSelected()
        });
    }
  }
}
