import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Direction, IntraDayPriceDelta, IntraDaySlot, IntraDaySlotWithFlags } from 'flex-app-shared';

@Component({
  selector: 'app-intraday-slot-delta-icon',
  templateUrl: './intraday-slot-delta-icon.component.html',
  styleUrls: ['./intraday-slot-delta-icon.component.scss']
})
export class IntradaySlotDeltaIconComponent implements OnChanges {
  IntraDayPriceDelta = IntraDayPriceDelta;

  priceDelta: IntraDayPriceDelta;
  priceDeltaAmount: number;
  isPositive: boolean;
  isNegative: boolean;
  isEqual: boolean;

  @Input()
  direction: Direction;

  @Input()
  slot: IntraDaySlotWithFlags;

  ngOnChanges(changes: SimpleChanges): void {
    this.priceDeltaAmount = this.getPriceDeltaAmount();
    this.priceDelta = this.getPriceDelta();

    this.isPositive = this.getIsPositive();
    this.isNegative = this.getIsNegative();
    this.isEqual = this.getIsEqual();
  }

  shouldShowPrices(): boolean {
    if (!this.priceDelta || !this.direction) {
      return false;
    }

    return IntraDaySlot.canBeDealt(this.slot, this.direction);
  }

  private getPriceDeltaAmount(): number {
    switch (this.direction) {
      case Direction.CONSUMPTION:
        return this.slot?.askPriceDeltaAmount;
      case Direction.PRODUCTION:
        return this.slot?.bidPriceDeltaAmount;
      default:
        return null;
    }
  }

  private getIsPositive(): boolean {
    switch (this.direction) {
      case Direction.CONSUMPTION:
        return this.slot?.askPriceDelta === IntraDayPriceDelta.LOWER;
      case Direction.PRODUCTION:
        return this.slot?.bidPriceDelta === IntraDayPriceDelta.HIGHER;
      default:
        return false;
    }
  }

  private getIsNegative(): boolean {
    switch (this.direction) {
      case Direction.CONSUMPTION:
        return this.slot?.askPriceDelta === IntraDayPriceDelta.HIGHER;
      case Direction.PRODUCTION:
        return this.slot?.bidPriceDelta === IntraDayPriceDelta.LOWER;
      default:
        return false;
    }
  }

  private getIsEqual(): boolean {
    switch (this.direction) {
      case Direction.CONSUMPTION:
        return this.slot?.askPriceDelta === IntraDayPriceDelta.EQUAL;
      case Direction.PRODUCTION:
        return this.slot?.bidPriceDelta === IntraDayPriceDelta.EQUAL;
      default:
        return false;
    }
  }

  private getPriceDelta(): IntraDayPriceDelta {
    switch (this.direction) {
      case Direction.CONSUMPTION:
        return this.slot?.askPriceDelta;
      case Direction.PRODUCTION:
        return this.slot?.bidPriceDelta;
      default:
        return null;
    }
  }

  getIcon(): string {
    switch (this.priceDelta) {
      case IntraDayPriceDelta.EQUAL:
        return 'ic-old:arrow_forward';
      case IntraDayPriceDelta.LOWER:
        return 'ic-old:arrow-down-right';
      case IntraDayPriceDelta.HIGHER:
        return 'ic-old:arrow-up-right';
    }
  }
}
