<ph-flex-toolbar class="sticky"></ph-flex-toolbar>

<div class="ph-g-container-page">
  <div class="ph-g-card-body-main">
    <ph-flex-loading-context [isLoading]="facade.isBusyFetching$ | async">
      <app-idcons-announcements-per-day
        [announcementsPerDay]="announcementsSortedByDateGroupedByDay$ | async"
        [unreadAnnouncementsIds]="unreadAnnouncementsIds$ | async"
      ></app-idcons-announcements-per-day>
    </ph-flex-loading-context>
  </div>
</div>
