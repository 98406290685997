<div [@.disabled]="disableAnimation">
  <mat-expansion-panel (click)="markAnnouncementAsRead(announcement)" [expanded]="false" phFlexLocaleClass>
    <mat-expansion-panel-header collapsedHeight="{{ announcementHeight }}px" expandedHeight="{{ announcementHeight }}px">
      <div class="announcement-panel-header">
        <div class="type">
          <mat-icon svgIcon="ic-new:plus-circle"></mat-icon>
          <p>Intraday +</p>
        </div>
        <div [matTooltip]="announcement.requestAreaDescriptionBuyOrders" class="request-buy">
          <span><mat-icon svgIcon="ic-new:arrow-down"></mat-icon></span>
          <div class="request-buy-text">
            <span class="overflow">
              <ng-container *ngIf="announcement.requestAreaDescriptionBuyOrders">
                {{ announcement.requestAreaDescriptionBuyOrders }}
              </ng-container>
              <ng-container
                *ngIf="!announcement.requestAreaDescriptionBuyOrders"
                i18n="@@idconsAnnouncement-requestAreaDescriptionNotSpecified"
              >
                Not specified
              </ng-container>
            </span>
          </div>
        </div>
        <div [matTooltip]="announcement.requestAreaDescriptionSellOrders" class="request-sell">
          <span><mat-icon svgIcon="ic-new:arrow-up"></mat-icon></span>
          <div class="request-sell-text">
            <span class="overflow">
              <ng-container *ngIf="announcement.requestAreaDescriptionSellOrders">
                {{ announcement.requestAreaDescriptionSellOrders }}
              </ng-container>
              <ng-container
                *ngIf="!announcement.requestAreaDescriptionSellOrders"
                i18n="@@idconsAnnouncement-requestAreaDescriptionNotSpecified"
              >
                Not specified
              </ng-container>
            </span>
          </div>
        </div>
        <div class="period">
          <ng-container *ngTemplateOutlet="period; context: { $implicit: announcement.problemPeriod }"></ng-container>
        </div>
        <div class="status">
          <ng-container *ngTemplateOutlet="status; context: { $implicit: announcementState }"></ng-container>
        </div>
      </div>
    </mat-expansion-panel-header>

    <div class="announcement-meta announcement-meta-top">
      <div [matTooltip]="announcement.requestAreaDescriptionBuyOrders" class="request-buy">
        <span class="label" i18n="@@idconsAnnouncement-requestAreaDescriptionBuy">Buy</span>
        <div class="value request-buy-text">
          <ng-container *ngIf="announcement.requestAreaDescriptionBuyOrders">
            {{ announcement.requestAreaDescriptionBuyOrders }}
          </ng-container>
          <ng-container
            *ngIf="!announcement.requestAreaDescriptionBuyOrders"
            i18n="@@idconsAnnouncement-requestAreaDescriptionNotSpecified"
          >
            Not specified
          </ng-container>
        </div>
      </div>
      <div [matTooltip]="announcement.requestAreaDescriptionSellOrders" class="request-sell">
        <span class="label" i18n="@@idconsAnnouncement-requestAreaDescriptionSell">Sell</span>
        <div class="value request-sell-text">
          <ng-container *ngIf="announcement.requestAreaDescriptionSellOrders">
            {{ announcement.requestAreaDescriptionSellOrders }}
          </ng-container>
          <ng-container
            *ngIf="!announcement.requestAreaDescriptionSellOrders"
            i18n="@@idconsAnnouncement-requestAreaDescriptionNotSpecified"
          >
            Not specified
          </ng-container>
        </div>
      </div>
    </div>

    <div class="announcement-description">
      <p>
        <ng-container *ngIf="announcement.message">{{ announcement.message }}</ng-container>
        <ng-container *ngIf="!announcement.message" i18n="@@idconsAnnouncement-messageNotSpecified">Not specified </ng-container>
      </p>
    </div>

    <div class="announcement-meta announcement-meta-bottom">
      <div class="volume">
        <span *ngIf="announcement.problemProfileMin !== undefined || announcement.problemProfileMax !== undefined">
          <span class="label" i18n="@@idconsAnnouncement-labelVolume">Volume:</span>&ngsp;
          <span class="value">
            <ng-container *ngTemplateOutlet="volume; context: { $implicit: announcement.problemProfileMin }"></ng-container>
            <ng-container *ngIf="announcement.problemProfileMin !== undefined && announcement.problemProfileMax !== undefined">
              ~
            </ng-container>
            <ng-container *ngTemplateOutlet="volume; context: { $implicit: announcement.problemProfileMax }"></ng-container>
          </span>
        </span>
      </div>
      <div>
        <span class="label" i18n="@@idconsAnnouncement-labelGridOperator">Grid operator:</span>&ngsp;
        <span class="value">{{ announcement.organisationName }}</span>
      </div>
      <div>
        <span class="label created" i18n="@@idconsAnnouncement-labelCreated">Created:</span>&ngsp;
        <span class="value">{{ announcement.createdDateTime | date: 'medium' }}</span>
      </div>
      <div>
        <span class="label" i18n="@@idconsAnnouncement-labelAnnouncementType">Type:</span>&ngsp;
        <span [ngSwitch]="announcement.type" class="value">
          <ng-container
            *ngSwitchCase="IdConsAnnouncementType.CONGESTIONMANAGEMENT"
            i18n="@@idconsAnnouncement-announcementTypeCongestionManagement"
            >Congestion management
          </ng-container>
          <ng-container
            *ngSwitchCase="IdConsAnnouncementType.TRANSPORTPROBLEM"
            i18n="@@idconsAnnouncement-announcementTypeTransportProblem"
          >
            Transport problem
          </ng-container>
          <ng-container *ngSwitchCase="IdConsAnnouncementType.FREE_TEXT" i18n="@@idconsAnnouncement-announcementTypeFreeText">
            Free text
          </ng-container>
          <ng-container *ngSwitchDefault i18n="@@idconsAnnouncement-announcementTypeUnknown">Unknown</ng-container>
        </span>
      </div>
    </div>
  </mat-expansion-panel>
</div>

<ng-template #volume let-capacity>
  <ng-container *ngIf="capacity?.value !== undefined">
    <ng-container *ngIf="Capacity.isGW(capacity)">{{ capacity | capacity: 'GW' }}</ng-container>
    <ng-container *ngIf="!Capacity.isGW(capacity)">{{ capacity | capacity }}</ng-container>
  </ng-container>
</ng-template>

<ng-template #period let-period>
  <ng-container *ngIf="period">
    <span>{{ period.startDateTime | date: 'd MMM H:mm' }}</span>
    <span class="period-divider"></span>
    <span>{{ period.toDateTime | date: 'd MMM H:mm' }}</span>
  </ng-container>
</ng-template>

<ng-template #status let-announcementState>
  <ng-container *ngIf="announcementState; else statusUnknown">
    <ng-container [ngSwitch]="announcementState">
      <span *ngSwitchCase="ComponentAnnouncementState.ANNOUNCEMENT_CLOSED" class="tag closed" i18n="@@idconsAnnouncement-statusClosed">
        Closed
      </span>
      <span *ngSwitchCase="ComponentAnnouncementState.ANNOUNCEMENT_OPEN" class="tag open">
        <span i18n="@@idconsAnnouncement-statusOpen">Open</span>
      </span>
      <span *ngSwitchCase="ComponentAnnouncementState.ANNOUNCEMENT_BID_PERIOD" class="tag open">
        <span i18n="@@idconsAnnouncement-statusBidPeriod">Bid before</span>
        {{ announcement.bidValidityPeriod.toDateTime | date: 'shortTime' }}
      </span>
      <span *ngSwitchCase="ComponentAnnouncementState.ANNOUNCEMENT_EXPIRED" class="tag expired" i18n="@@idconsAnnouncement-statusExpired">
        Expired
      </span>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #statusUnknown>
  <span class="tag unknown" i18n="@@idconsAnnouncement-statusUnknown">unknown</span>
</ng-template>
