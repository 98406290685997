import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UpdateFormValue } from '@ngxs/form-plugin';
import { RouterNavigation } from '@ngxs/router-plugin';
import { Action, createSelector, Selector, SelectorOptions, State, StateContext, Store } from '@ngxs/store';
import { endOfDay, isBefore, parseISO, startOfDay } from 'date-fns';
import {
  AdjustAvailability,
  AppInjector,
  AsyncMessageReceivedEvent,
  AuthorityService,
  AvailabilityDetails,
  AvailabilityType,
  CalendarPeriod,
  Capacity,
  findNotNullParameterValue,
  formatAsISO,
  getRetryConfig,
  handleStateToRouteSync,
  inferPeriodUnitFromStringFormat,
  MessageType,
  Money,
  NgXsFormModel,
  normalizeToDate,
  OperationMeasurements,
  OperationService,
  PeriodUnit,
  PeriodViewType,
  R3Activation,
  R3Availabilities,
  R3Availability,
  R3AvailabilityFee,
  R3ServiceAgreement,
  R3TsoAgreement,
  ServiceAgreementService,
  TimeSlot
} from 'flex-app-shared';
import { flatMap, fromPairs, isEqual, sortBy, uniq, uniqWith } from 'lodash-es';
import moment, { Moment } from 'moment';
import { NumberRange, RangeMap } from 'range-ts';
import { PartialObserver } from 'rxjs';
import { retry, switchMap, tap } from 'rxjs/operators';
import {
  ImbalanceReserveAvailabilityActivate,
  ImbalanceReserveAvailabilityActivationMeasurementsUpdatedEvent,
  ImbalanceReserveAvailabilityActivationsUpdatedEvent,
  ImbalanceReserveAvailabilityDeactivate,
  ImbalanceReserveAvailabilityFeesUpdatedEvent,
  ImbalanceReserveAvailabilityLoadedEvent,
  ImbalanceReserveAvailabilityRefreshActivationCommand,
  ImbalanceReserveAvailabilityRefreshActivationsCommand,
  ImbalanceReserveAvailabilityRefreshCommand,
  ImbalanceReserveAvailabilityRefreshFeesCommand,
  IncidentReserveAvailabilityChangeAvailabilityCommand,
  IncidentReserveAvailabilitySelectMonthCommand,
  IncidentReserveAvailabilityView
} from './incident-reserve-availability.actions';
import { getDefaultServiceAgreement } from './service-agreement-selection';

abstract class IncidentReserveAvailabilityViewItemAuctionResult {
  tsoAuctionClosed: boolean;
  hasMatchedUpwardsTsoAgreement: boolean;
  hasMatchedDownwardsTsoAgreement: boolean;
}

export class IncidentReserveAvailabilityViewItem extends IncidentReserveAvailabilityViewItemAuctionResult {
  period: CalendarPeriod;
  capacityUpward: AvailabilityDetails | null;
  capacityUpwardVariable: boolean;
  capacityDownward: AvailabilityDetails | null;
  capacityDownwardVariable: boolean;
  hasOtherServiceAgreementsWithAvailabilities: boolean;
  readOnly: boolean;
  partialReadOnly: boolean; // Some of the period is read only
  noAvailabilities: boolean; // True if there are no mandatory availabilities TODO change when availability types are introduced
  noContract: boolean;
  periodCoveredBySelectedServiceAgreement: boolean;
  hasHourlyAvailabilities: boolean;
  isPeriodPadding: boolean;
  isCurrent: boolean; // True for today and future
}

export class IncidentReserveAvailabilityDialogViewForDirection {
  defaultPeriod: CalendarPeriod | null;
  defaultType: AvailabilityType;
  defaultCapacityMW: number | null;
  maxCapacityMW: number;
}

export class IncidentReserveAvailabilityDialogView {
  downward: IncidentReserveAvailabilityDialogViewForDirection;
  upward: IncidentReserveAvailabilityDialogViewForDirection;
  maxPeriod: CalendarPeriod;
  initialPeriod: CalendarPeriod;
}

export class AsyncActionStatus {
  readonly errorMessage: string | null;
  readonly success: boolean;

  private constructor(success: boolean, errorMessage: string | null) {
    this.success = success;
    this.errorMessage = errorMessage;
  }

  /**
   * Is done, either due to failure or success
   */
  get done(): boolean {
    return this.success || this.failure;
  }

  /**
   * Is done with failed status
   */
  get failure(): boolean {
    return !!this.errorMessage;
  }

  static partialObserverFactory<T>(cb: (newStatus: AsyncActionStatus) => any): PartialObserver<T> {
    cb(AsyncActionStatus.initialValue());

    return {
      error: (error) => cb(new AsyncActionStatus(false, error?.statusText || error?.status)),
      complete: () => cb(new AsyncActionStatus(true, null))
    };
  }

  static initialValue(): AsyncActionStatus {
    return new AsyncActionStatus(false, null);
  }
}

export class R3AvailabilityStateModel {
  active: boolean = false;
  shouldReset: boolean = false;

  availabilities: R3Availability[] = [];
  upwardsAvailabilityFees: R3AvailabilityFee[] = [];
  downwardsAvailabilityFees: R3AvailabilityFee[] = [];
  activations: R3Activation[] = [];
  serviceAgreements: R3ServiceAgreement[] = [];
  tsoAgreements: R3TsoAgreement[] = [];

  filters = NgXsFormModel.defaults({
    customerId: null,
    serviceAgreementId: null,
    dateRange: null
  });

  // The customerId matching the currently available data
  currentCustomerId: string;
  currentAvailabilityFeesServiceAgreementId: string;
  currentActivationsServiceAgreementId: string;

  // Inputs for activation measurement (by service agreement)
  currentActivationId: string;
  currentActivationServiceAgreementId: string;
  activationMeasurementData: OperationMeasurements;
  activationMeasurementBusy: boolean;

  savingAvailabilityStatus: AsyncActionStatus | null;
  isBusyFetchingAvailabilities = false;
  isBusyFetchingAvailabilityFees = false;
  isBusyFetchingActivations = false;

  earliestEditableDay: Moment = null;
  earliestEditableDayEditableUntil: Date = null;
  earliestAuctionDay: Date = null;

  pageSubPath: 'availability' | 'activations' = 'availability';
}

@State({
  name: 'imbalanceReserveAvailability',
  defaults: new R3AvailabilityStateModel()
})
@Injectable({
  providedIn: 'root'
})
@SelectorOptions({
  injectContainerState: false,
  suppressErrors: false
})
export class IncidentReserveAvailabilityState {
  private path = '/incident-reserve-availability';

  constructor(
    private store: Store,
    private serviceAgreementService: ServiceAgreementService,
    private operationService: OperationService,
    private router: Router
  ) {}

  @Selector([IncidentReserveAvailabilityState])
  static active(state: R3AvailabilityStateModel): boolean {
    return state.active;
  }

  @Selector([IncidentReserveAvailabilityState])
  static upwardsAvailabilityFees(state: R3AvailabilityStateModel): R3AvailabilityFee[] {
    return state.upwardsAvailabilityFees;
  }

  @Selector([IncidentReserveAvailabilityState])
  static downwardsAvailabilityFees(state: R3AvailabilityStateModel): R3AvailabilityFee[] {
    return state.downwardsAvailabilityFees;
  }

  static availabilityFeesRangeMap(direction: 'upwards' | 'downwards'): (...args: any) => RangeMap<Money> {
    const selector =
      direction === 'upwards'
        ? IncidentReserveAvailabilityState.upwardsAvailabilityFees
        : IncidentReserveAvailabilityState.downwardsAvailabilityFees;

    return createSelector([selector], (availabilityFees) => {
      const rangeMap = new RangeMap<Money | null>();

      availabilityFees.forEach((availabilityFee) => {
        rangeMap.put(TimeSlot.toNumberRange(availabilityFee.period), availabilityFee.fee);
      });

      return rangeMap;
    });
  }

  @Selector([IncidentReserveAvailabilityState])
  static tsoAgreements(state: R3AvailabilityStateModel): R3TsoAgreement[] {
    return state.tsoAgreements;
  }

  @Selector([IncidentReserveAvailabilityState.tsoAgreements])
  static tsoAgreementsRangeMap(tsoAgreements: R3TsoAgreement[]): RangeMap<{ downwards: boolean; upwards: boolean }> {
    const rangeMap = new RangeMap<{ downwards: boolean; upwards: boolean }>();

    tsoAgreements.forEach((tsoAgreement) =>
      rangeMap.putCoalescing(CalendarPeriod.toNumberRange(tsoAgreement.period), {
        downwards: tsoAgreement.downwards,
        upwards: tsoAgreement.upwards
      })
    );

    return rangeMap;
  }

  @Selector([IncidentReserveAvailabilityState])
  static earliestEditableDay(state: R3AvailabilityStateModel): Moment {
    if (!state?.earliestEditableDay) {
      // Default is the day after tomorrow
      return moment().add(2, 'days').startOf('day');
    }

    return moment(state.earliestEditableDay).startOf('day');
  }

  @Selector([IncidentReserveAvailabilityState])
  static earliestAuctionDay(state: R3AvailabilityStateModel): Date {
    if (!state?.earliestAuctionDay) {
      // Default today
      return startOfDay(new Date());
    }

    return startOfDay(normalizeToDate(state.earliestAuctionDay));
  }

  @Selector([IncidentReserveAvailabilityState])
  static activationMeasurementBusy(state: R3AvailabilityStateModel): boolean {
    return state.activationMeasurementBusy;
  }

  @Selector([IncidentReserveAvailabilityState])
  static savingAvailabilityStatus(state: R3AvailabilityStateModel): AsyncActionStatus {
    return state.savingAvailabilityStatus;
  }

  @Selector([IncidentReserveAvailabilityState])
  static isBusy(state: R3AvailabilityStateModel): boolean {
    return state.isBusyFetchingAvailabilities || state.isBusyFetchingActivations || state.isBusyFetchingAvailabilityFees;
  }

  @Selector([IncidentReserveAvailabilityState])
  static activationMeasurementData(state: R3AvailabilityStateModel): OperationMeasurements {
    return state.activationMeasurementData;
  }

  @Selector([IncidentReserveAvailabilityState])
  static availabilities(state: R3AvailabilityStateModel): R3Availability[] {
    return state.availabilities;
  }

  @Selector([IncidentReserveAvailabilityState])
  static activations(state: R3AvailabilityStateModel): R3Activation[] {
    return state.activations;
  }

  @Selector([IncidentReserveAvailabilityState.activations])
  static activationCountRangeMap(activations: R3Activation[]): RangeMap<number[]> {
    const rangeMap = new RangeMap<number[]>();
    rangeMap.put(NumberRange.all(), []);

    activations.forEach((activation) => {
      const subRange = rangeMap.subRangeMap(
        CalendarPeriod.toNumberRange({
          startDate: activation.period.startDateTime,
          endDate: activation.period.toDateTime
        })
      );

      [...subRange.asMapOfRanges().entries()].forEach(([key, value]) =>
        rangeMap.putCoalescing(key, [...value, activation.operationReference])
      );
    });

    return rangeMap;
  }

  @Selector([IncidentReserveAvailabilityState.selectedDateRange, IncidentReserveAvailabilityState.viewPeriodType])
  static selectedDateRangeAsCalendarPeriod(selectedDateRange: string, viewPeriodType: PeriodViewType): CalendarPeriod {
    if (viewPeriodType === 'paddedMonth') {
      return CalendarPeriod.fromDateAndTimeUnit(selectedDateRange, 'month');
    } else {
      return CalendarPeriod.fromDateAndTimeUnit(selectedDateRange, viewPeriodType);
    }
  }

  @Selector([IncidentReserveAvailabilityState.activations])
  static activationsRangeMap(activations: R3Activation[]): RangeMap<R3Activation[]> {
    const activationsRangeMap = new RangeMap<R3Activation[]>();
    activationsRangeMap.putCoalescing(NumberRange.all(), []);
    activations.forEach((activation) => {
      [
        ...activationsRangeMap
          .subRangeMap(
            CalendarPeriod.toNumberRange({
              startDate: activation.period.startDateTime,
              endDate: activation.period.toDateTime
            })
          )
          .asMapOfRanges()
          .entries()
      ].forEach(([key, value]) => {
        activationsRangeMap.putCoalescing(key, [...value, activation]);
      });
    });
    return activationsRangeMap;
  }

  @Selector([
    IncidentReserveAvailabilityState.activationsRangeMap,
    IncidentReserveAvailabilityState.selectedDateRangeAsCalendarPeriod,
    IncidentReserveAvailabilityState.selectedServiceAgreementId
  ])
  static activationsForDateRange(
    activationsRangeMap: RangeMap<R3Activation>,
    dateRange: CalendarPeriod,
    selectedServiceAgreementId: string
  ): R3Activation[] {
    if (!selectedServiceAgreementId) {
      return [];
    }
    return flatMap([...activationsRangeMap.subRangeMap(CalendarPeriod.toNumberRange(dateRange)).asMapOfRanges().values()]);
  }

  @Selector([IncidentReserveAvailabilityState.serviceAgreementsRangeMap])
  static serviceAgreementsPeriodSpan(serviceAgreementsRangeMap: RangeMap<R3ServiceAgreement>): CalendarPeriod | null {
    const calendarPeriods = CalendarPeriod.asTimeUnit(CalendarPeriod.fromNumberRange(serviceAgreementsRangeMap.span()), 'day').sort();
    if (!calendarPeriods || !serviceAgreementsRangeMap || calendarPeriods.length === 0) {
      return null;
    }

    return {
      startDate: calendarPeriods[0].startDate,
      endDate: calendarPeriods[calendarPeriods.length - 1].endDate
    };
  }

  @Selector([IncidentReserveAvailabilityState.serviceAgreementsPeriodSpan])
  static serviceAgreementsPeriodMin(period: CalendarPeriod | null): Date | null {
    return normalizeToDate(period?.startDate);
  }

  @Selector([IncidentReserveAvailabilityState.serviceAgreementsPeriodSpan])
  static serviceAgreementsPeriodMax(period: CalendarPeriod | null): Date | null {
    return normalizeToDate(period?.endDate);
  }

  /**
   * The customerId of the data that is currently shown/used by all other selectors
   */
  @Selector([IncidentReserveAvailabilityState])
  static selectedCustomerId(state: R3AvailabilityStateModel): string {
    return state.filters.model.customerId;
  }

  /**
   * The customerId of the data that is currently shown/used by all other selectors
   */
  @Selector([IncidentReserveAvailabilityState])
  static currentCustomerId(state: R3AvailabilityStateModel): string {
    return state.currentCustomerId;
  }

  @Selector([IncidentReserveAvailabilityState])
  static currentActivationId(state: R3AvailabilityStateModel): string {
    return state.currentActivationId;
  }

  @Selector([IncidentReserveAvailabilityState])
  static selectedDateRange(state: R3AvailabilityStateModel): string {
    return state.filters.model.dateRange;
  }

  @Selector([IncidentReserveAvailabilityState.serviceAgreements])
  static serviceAgreementYears(serviceAgreements: R3ServiceAgreement[]): number[] {
    return uniq(
      flatMap(serviceAgreements, (serviceAgreement) =>
        CalendarPeriod.asTimeUnit(serviceAgreement.period, 'year').map((period) => moment(period.startDate).year())
      )
    );
  }

  @Selector([IncidentReserveAvailabilityState.selectedDateRange, IncidentReserveAvailabilityState.serviceAgreementsRangeMap])
  static selectableMonths(selectedDateRange: string, serviceAgreementsRangeMap: RangeMap<R3ServiceAgreement>): number[] {
    if (!selectedDateRange || !serviceAgreementsRangeMap) {
      return [];
    }

    const yearView = CalendarPeriod.fromDateAndTimeUnit(selectedDateRange, 'year');
    const serviceAgreementsForView = serviceAgreementsRangeMap.subRangeMap(CalendarPeriod.toNumberRange(yearView));

    const calendarPeriods = CalendarPeriod.asTimeUnit(CalendarPeriod.fromNumberRange(serviceAgreementsForView.span()), 'month');
    return calendarPeriods.map((calendarPeriod) => moment(calendarPeriod.startDate).month());
  }

  @Selector([IncidentReserveAvailabilityState.selectedDateRange])
  static currentView(dateRange: string): IncidentReserveAvailabilityView {
    return inferPeriodUnitFromStringFormat(dateRange) === PeriodUnit.YEAR
      ? IncidentReserveAvailabilityView.YEAR
      : IncidentReserveAvailabilityView.MONTH;
  }

  /**
   * Get the currently selected service agreement id from the FILTERS
   */
  @Selector([IncidentReserveAvailabilityState])
  static selectedServiceAgreementId(state: R3AvailabilityStateModel): string {
    return state.filters.model.serviceAgreementId;
  }

  /**
   * Get the currently selected service agreement id of the operation detail/measurements
   */
  static currentActivationServiceAgreementId(state: R3AvailabilityStateModel): string {
    return state.currentActivationServiceAgreementId;
  }

  @Selector([IncidentReserveAvailabilityState])
  static currentAvailabilityFeesServiceAgreementId(state: R3AvailabilityStateModel): string {
    return state.currentAvailabilityFeesServiceAgreementId;
  }

  @Selector([IncidentReserveAvailabilityState])
  static currentActivationsServiceAgreementId(state: R3AvailabilityStateModel): string {
    return state.currentActivationsServiceAgreementId;
  }

  @Selector([IncidentReserveAvailabilityState.selectedServiceAgreementId, IncidentReserveAvailabilityState.serviceAgreements])
  static selectedServiceAgreement(serviceAgreementId: string, serviceAgreements: R3ServiceAgreement[]): R3ServiceAgreement {
    return serviceAgreements.find((serviceAgreement) => serviceAgreement.serviceAgreementId === serviceAgreementId);
  }

  @Selector([IncidentReserveAvailabilityState.selectedServiceAgreement])
  static selectedServiceAgreementDownwardAvailability(serviceAgreement: R3ServiceAgreement): AdjustAvailability {
    return AdjustAvailability.fromDefaultCapacity(serviceAgreement?.maxCapacityDownward);
  }

  @Selector([IncidentReserveAvailabilityState.selectedServiceAgreement])
  static selectedServiceAgreementUpwardAvailability(serviceAgreement: R3ServiceAgreement): AdjustAvailability {
    return AdjustAvailability.fromDefaultCapacity(serviceAgreement?.maxCapacityUpward);
  }

  @Selector([IncidentReserveAvailabilityState.serviceAgreements, IncidentReserveAvailabilityState.selectedDateRange])
  static selectableServiceAgreements(serviceAgreements: R3ServiceAgreement[]): R3ServiceAgreement[] {
    return serviceAgreements;
  }

  @Selector([IncidentReserveAvailabilityState])
  static serviceAgreements(state: R3AvailabilityStateModel): R3ServiceAgreement[] {
    return state.serviceAgreements;
  }

  @Selector([IncidentReserveAvailabilityState.currentView])
  static itemPeriodType(currentView: IncidentReserveAvailabilityView): 'month' | 'day' {
    return currentView === IncidentReserveAvailabilityView.YEAR ? 'month' : 'day';
  }

  @Selector([IncidentReserveAvailabilityState.currentView])
  static viewPeriodType(currentView: IncidentReserveAvailabilityView): PeriodViewType {
    return currentView === IncidentReserveAvailabilityView.YEAR ? 'year' : 'paddedMonth';
  }

  @Selector([IncidentReserveAvailabilityState.selectedDateRange, IncidentReserveAvailabilityState.viewPeriodType])
  static viewPeriod(selectedDateRange: string, viewPeriodType: PeriodViewType): CalendarPeriod {
    if (viewPeriodType === 'paddedMonth') {
      return CalendarPeriod.fromDateAndTimeUnit(selectedDateRange, 'month', 'isoWeek');
    } else {
      return CalendarPeriod.fromDateAndTimeUnit(selectedDateRange, viewPeriodType);
    }
  }

  @Selector([IncidentReserveAvailabilityState.selectedDateRange, IncidentReserveAvailabilityState.viewPeriodType])
  static viewPeriodWithoutPadding(selectedDateRange: string, viewPeriodType: PeriodViewType): CalendarPeriod {
    if (viewPeriodType === 'paddedMonth') {
      return CalendarPeriod.fromDateAndTimeUnit(selectedDateRange, 'month');
    } else {
      return CalendarPeriod.fromDateAndTimeUnit(selectedDateRange, viewPeriodType);
    }
  }

  @Selector([IncidentReserveAvailabilityState.selectedDateRange, IncidentReserveAvailabilityState.viewPeriodType])
  static editPeriod(selectedDateRange: Moment, viewPeriodType: PeriodViewType): CalendarPeriod {
    if (viewPeriodType === 'paddedMonth') {
      return CalendarPeriod.fromDateAndTimeUnit(selectedDateRange, 'month');
    } else {
      return CalendarPeriod.fromDateAndTimeUnit(selectedDateRange, viewPeriodType);
    }
  }

  /**
   * Return a list of CalendarPeriods for the current viewPeriod with a duration defined by itemPeriodType
   */
  @Selector([
    IncidentReserveAvailabilityState.viewPeriod,
    IncidentReserveAvailabilityState.itemPeriodType,
    IncidentReserveAvailabilityState.selectedServiceAgreementId
  ])
  static itemPeriods(viewPeriod: CalendarPeriod, itemPeriodType: 'month' | 'year', selectedServiceAgreemeentId: string): CalendarPeriod[] {
    if (!selectedServiceAgreemeentId) {
      return [];
    }

    return CalendarPeriod.asTimeUnit(viewPeriod, itemPeriodType);
  }

  /**
   * Select the RangeMap representing all availabilities for the currently selected service agreement.
   * When no service agreement is selected an empty RangeMap is returned.
   */
  @Selector([IncidentReserveAvailabilityState.availabilities, IncidentReserveAvailabilityState.selectedServiceAgreementId])
  static availabilitiesRangeMap(availabilities: R3Availability[], selectedServiceAgreementId: string): RangeMap<R3Availability> {
    const availabilityRangeMap = new RangeMap<R3Availability>();

    availabilityRangeMap.put(NumberRange.all(), {
      serviceAgreementId: selectedServiceAgreementId,
      period: null,
      hasHourlyAvailabilities: false,
      downwards: {
        availabilityType: AvailabilityType.OFF,
        capacity: Capacity.MW(0),
        hasHourlyAvailabilities: false
      },
      upwards: {
        availabilityType: AvailabilityType.OFF,
        capacity: Capacity.MW(0),
        hasHourlyAvailabilities: false
      }
    });

    // Return an empty rangeMap if there is no service agreement selected
    if (!selectedServiceAgreementId) {
      return availabilityRangeMap;
    }

    availabilities
      .filter((availability) => availability.serviceAgreementId === selectedServiceAgreementId)
      .forEach((availability) => availabilityRangeMap.put(CalendarPeriod.toNumberRange(availability.period), availability));
    return availabilityRangeMap;
  }

  /**
   * Select the RangeMap representing if an availability of not selected service agreements is present.
   */
  @Selector([IncidentReserveAvailabilityState.availabilities, IncidentReserveAvailabilityState.selectedServiceAgreementId])
  static notSelectedAvailabilitiesRangeMap(availabilities: R3Availability[], selectedServiceAgreementId: string): RangeMap<boolean> {
    const availabilityRangeMap = new RangeMap<boolean>();

    availabilities
      .filter((availability) => availability.serviceAgreementId !== selectedServiceAgreementId || !selectedServiceAgreementId)
      .forEach((availability) => availabilityRangeMap.putCoalescing(CalendarPeriod.toNumberRange(availability.period), true));
    return availabilityRangeMap;
  }

  /**
   * Select the RangeMap representing all serviceAgreements for the selected customer.
   */
  @Selector([IncidentReserveAvailabilityState.serviceAgreements])
  static serviceAgreementsRangeMap(serviceAgreements: R3ServiceAgreement[]): RangeMap<R3ServiceAgreement> {
    const serviceAgreementRangeMap = new RangeMap<R3ServiceAgreement>();
    serviceAgreements.forEach((serviceAgreement) =>
      serviceAgreementRangeMap.put(CalendarPeriod.toNumberRange(serviceAgreement.period), serviceAgreement)
    );
    return serviceAgreementRangeMap;
  }

  static getAvailabilityForPeriod(itemPeriod: CalendarPeriod): (...args: any) => IncidentReserveAvailabilityViewItem {
    return createSelector(
      [
        IncidentReserveAvailabilityState.availabilitiesRangeMap,
        IncidentReserveAvailabilityState.notSelectedAvailabilitiesRangeMap,
        IncidentReserveAvailabilityState.selectedServiceAgreement,
        IncidentReserveAvailabilityState.tsoAgreementsRangeMap,
        IncidentReserveAvailabilityState.earliestEditableDay,
        IncidentReserveAvailabilityState.earliestAuctionDay,
        IncidentReserveAvailabilityState.viewPeriodWithoutPadding
      ],
      (
        availabilityRangeMap: RangeMap<R3Availability>,
        otherAvailabilitiesRangeMap: RangeMap<boolean>,
        selectedServiceAgreement: R3ServiceAgreement,
        tsoAgreementsRangeMap: RangeMap<{ upwards: boolean; downwards: boolean }>,
        earliestEditableDay: Moment,
        earliestAuctionDay: Date,
        viewPeriodWithoutPadding: CalendarPeriod
      ) => {
        if (!selectedServiceAgreement) {
          return null;
        }

        const itemPeriodRange = CalendarPeriod.toNumberRange(itemPeriod);
        const subRangeMap = availabilityRangeMap.subRangeMap(itemPeriodRange);
        const matchedAvailabilities = [...subRangeMap.asMapOfRanges().entries()].map(([key, value]) => {
          return {
            ...value,
            period: CalendarPeriod.fromNumberRange(key)
          };
        });

        const hasOtherServiceAgreements = otherAvailabilitiesRangeMap.subRangeMap(itemPeriodRange).asMapOfRanges().size > 0;

        const readOnlyCutOffMatched = moment(itemPeriod.endDate).isBefore(earliestEditableDay);
        const partialReadOnlyCutOffMatched =
          moment(itemPeriod.startDate).isSameOrBefore(earliestEditableDay) && moment(itemPeriod.endDate).isAfter(earliestEditableDay);

        const serviceAgreementPeriod = CalendarPeriod.toNumberRange(selectedServiceAgreement.period);
        const matchesServiceAgreement = !selectedServiceAgreement ? false : serviceAgreementPeriod.encloses(itemPeriodRange);
        const readOnly = (readOnlyCutOffMatched && !partialReadOnlyCutOffMatched) || !matchesServiceAgreement;
        const partialReadOnly = partialReadOnlyCutOffMatched && !readOnly;

        const hasHourlyAvailabilities = matchedAvailabilities.some((matchedAvailability) => matchedAvailability.hasHourlyAvailabilities);

        const toSoAgreementsSubRangeMap = tsoAgreementsRangeMap.subRangeMap(itemPeriodRange);
        const matchedTsoAgreements = [...toSoAgreementsSubRangeMap.asMapOfRanges().values()];

        const tsoAuctionClosed = isBefore(normalizeToDate(itemPeriod.startDate), earliestAuctionDay);
        const hasMatchedUpwardsTsoAgreement = matchedTsoAgreements.some((matchedTsoAgreement) => matchedTsoAgreement.upwards);
        const hasMatchedDownwardsTsoAgreement = matchedTsoAgreements.some((matchedTsoAgreement) => matchedTsoAgreement.downwards);

        const isCurrent = isBefore(new Date(), endOfDay(normalizeToDate(itemPeriod.endDate)));

        const isPeriodPadding = !CalendarPeriod.toNumberRange(viewPeriodWithoutPadding).encloses(itemPeriodRange);

        let capacityDownward: AvailabilityDetails;
        let capacityUpward: AvailabilityDetails;

        if (matchedAvailabilities.length === 0) {
          return {
            capacityDownward: null,
            capacityDownwardVariable: false,
            capacityUpward: null,
            capacityUpwardVariable: false,
            period: itemPeriod,
            hasOtherServiceAgreementsWithAvailabilities: hasOtherServiceAgreements,
            readOnly,
            noAvailabilities: true,
            noContract: !matchesServiceAgreement && !hasOtherServiceAgreements,
            periodCoveredBySelectedServiceAgreement: matchesServiceAgreement,
            hasHourlyAvailabilities,
            partialReadOnly,
            tsoAuctionClosed,
            hasMatchedUpwardsTsoAgreement,
            hasMatchedDownwardsTsoAgreement,
            isPeriodPadding,
            isCurrent
          } as IncidentReserveAvailabilityViewItem;
        } else if (matchedAvailabilities.length === 1 && isEqual(itemPeriod, matchedAvailabilities[0].period)) {
          // Exactly the correct period for the entire duration of the calendarPeriod
          capacityDownward = {
            ...matchedAvailabilities[0].downwards,
            capacity: matchedAvailabilities[0].downwards.capacity
          };
          capacityUpward = {
            ...matchedAvailabilities[0].upwards,
            capacity: matchedAvailabilities[0].upwards.capacity
          };

          return {
            capacityDownward,
            capacityDownwardVariable: false,
            capacityUpward,
            capacityUpwardVariable: false,
            period: itemPeriod,
            hasOtherServiceAgreementsWithAvailabilities: hasOtherServiceAgreements,
            readOnly,
            noAvailabilities: !AdjustAvailability.hasAvailability(capacityDownward) && !AdjustAvailability.hasAvailability(capacityUpward),
            noContract: !matchesServiceAgreement && !hasOtherServiceAgreements,
            periodCoveredBySelectedServiceAgreement: matchesServiceAgreement,
            hasHourlyAvailabilities,
            partialReadOnly,
            tsoAuctionClosed,
            hasMatchedUpwardsTsoAgreement,
            hasMatchedDownwardsTsoAgreement,
            isPeriodPadding,
            isCurrent
          } as IncidentReserveAvailabilityViewItem;
        }

        // Multiple matched
        // Check for multiple values
        const downwardsAvailabilities = uniqWith(
          matchedAvailabilities.map((matchedAvailability) => matchedAvailability.downwards),
          AvailabilityDetails.isEqual
        );
        const upwardsAvailabilities = uniqWith(
          matchedAvailabilities.map((matchedAvailability) => matchedAvailability.upwards),
          AvailabilityDetails.isEqual
        );

        const hasMultipleDownwards = downwardsAvailabilities.length > 1;
        const hasMultipleUpwards = upwardsAvailabilities.length > 1;

        const upwardsFullyOff = upwardsAvailabilities
          .filter((availabilityDetails) => !!availabilityDetails)
          .every((availabilityDetails) => availabilityDetails?.availabilityType === AvailabilityType.OFF);

        const downwardsFullyOff = downwardsAvailabilities
          .filter((availabilityDetails) => !!availabilityDetails)
          .every((availabilityDetails) => availabilityDetails?.availabilityType === AvailabilityType.OFF);

        capacityDownward = AvailabilityDetails.getMinAvailability(downwardsAvailabilities);
        capacityUpward = AvailabilityDetails.getMinAvailability(upwardsAvailabilities);

        return {
          capacityDownward,
          capacityDownwardVariable: hasMultipleDownwards,
          capacityUpward,
          capacityUpwardVariable: hasMultipleUpwards,
          period: itemPeriod,
          hasOtherServiceAgreementsWithAvailabilities: hasOtherServiceAgreements,
          readOnly,
          noAvailabilities: upwardsFullyOff && downwardsFullyOff,
          noContract: !matchesServiceAgreement && !hasOtherServiceAgreements,
          periodCoveredBySelectedServiceAgreement: matchesServiceAgreement,
          hasHourlyAvailabilities,
          partialReadOnly,
          tsoAuctionClosed,
          hasMatchedUpwardsTsoAgreement,
          hasMatchedDownwardsTsoAgreement,
          isPeriodPadding,
          isCurrent
        } as IncidentReserveAvailabilityViewItem;
      }
    );
  }

  /**
   * Return the total availability fee for the provided period. When no fee is available for the period, return null.
   */
  static getAvailabilityFeeForPeriodAndDirection(
    itemPeriod: CalendarPeriod,
    direction: 'upwards' | 'downwards'
  ): (...args: any) => Money | null {
    return createSelector(
      [IncidentReserveAvailabilityState.availabilityFeesRangeMap(direction)],
      (availabilityFeesRangeMap: RangeMap<Money>) => {
        const days = CalendarPeriod.calendarDays(itemPeriod);

        const daysResult = days.map((day) => {
          const itemPeriodNumberRange = CalendarPeriod.toNumberRange(day);

          const subRangeMapEntries = [...availabilityFeesRangeMap.subRangeMap(itemPeriodNumberRange).asMapOfRanges().entries()];

          const filteredEntries = subRangeMapEntries.filter(([key, value]) => !key.isEmpty() && value);

          if (filteredEntries.length === 0) {
            return null;
          }

          return filteredEntries.reduce((a, [range, value]) => a + (Money.toEUR(value) ?? 0), 0);
        });

        if (daysResult.every((a) => !a)) {
          return null;
        }

        return {
          amount: daysResult.filter((a) => !!a).reduce((a, c) => a + c),
          currency: 'EUR'
        };
      }
    );
  }

  static getActivationCountForPeriod(period: CalendarPeriod): (...args: any) => number {
    return createSelector([IncidentReserveAvailabilityState.activationCountRangeMap], (rangeMap: RangeMap<number>) => {
      const subRangeMap = rangeMap.subRangeMap(CalendarPeriod.toNumberRange(period));
      return uniq(flatMap([...subRangeMap.asMapOfRanges().values()])).length;
    });
  }

  static incidentReserveAvailabilityDialogView(period: CalendarPeriod): (...args: any) => IncidentReserveAvailabilityDialogView {
    return createSelector(
      [
        IncidentReserveAvailabilityState.getAvailabilityForPeriod(period),
        IncidentReserveAvailabilityState.selectedServiceAgreement,
        IncidentReserveAvailabilityState.earliestEditableDay
      ],
      (availability: IncidentReserveAvailabilityViewItem, serviceAgreement: R3ServiceAgreement, earliestEditableDay: string) => {
        if (!serviceAgreement || !availability) {
          return null;
        }

        const earliestEditableDayRange = NumberRange.atLeast(moment(earliestEditableDay).valueOf());

        const maxPeriodNumberRange = CalendarPeriod.toNumberRange(serviceAgreement.period).intersection(earliestEditableDayRange);

        const initialPeriodNumberRange = CalendarPeriod.toNumberRange(period).intersection(earliestEditableDayRange);

        return {
          downward: {
            defaultCapacityMW: Capacity.asMW(availability.capacityDownward?.capacity),
            defaultPeriod: availability.period,
            defaultType: availability.capacityDownward?.availabilityType || AvailabilityType.OFF,
            maxCapacityMW: Capacity.asMW(serviceAgreement.maxCapacityDownward)
          },
          upward: {
            defaultCapacityMW: Capacity.asMW(availability.capacityUpward?.capacity),
            defaultPeriod: availability.period,
            defaultType: availability.capacityUpward?.availabilityType || AvailabilityType.OFF,
            maxCapacityMW: Capacity.asMW(serviceAgreement.maxCapacityUpward)
          },
          maxPeriod: CalendarPeriod.fromNumberRange(maxPeriodNumberRange),
          initialPeriod: CalendarPeriod.fromNumberRange(initialPeriodNumberRange)
        };
      }
    );
  }

  @Selector([IncidentReserveAvailabilityState.itemPeriods, IncidentReserveAvailabilityState.selectedServiceAgreementId])
  static viewAvailabilities(itemPeriods: CalendarPeriod[], selectedServiceAgreementId: string): IncidentReserveAvailabilityViewItem[] {
    if (!selectedServiceAgreementId) {
      return [];
    }

    const store = AppInjector.get(Store);
    return itemPeriods.map((itemPeriod) => store.selectSnapshot(IncidentReserveAvailabilityState.getAvailabilityForPeriod(itemPeriod)));
  }

  @Action(RouterNavigation)
  routerInit({ getState, patchState, dispatch, setState }: StateContext<R3AvailabilityStateModel>, { routerState }: RouterNavigation): any {
    if (!getState().active) {
      if (getState().shouldReset) {
        setState(new R3AvailabilityStateModel());
      }
      return;
    }

    const selectedCustomerId = this.store.selectSnapshot(IncidentReserveAvailabilityState.selectedCustomerId);
    const currentCustomerId = this.store.selectSnapshot(IncidentReserveAvailabilityState.currentCustomerId);
    const selectedServiceAgreementId = this.store.selectSnapshot(IncidentReserveAvailabilityState.selectedServiceAgreementId);
    const currentActivationsServiceAgreementId = this.store.selectSnapshot(
      IncidentReserveAvailabilityState.currentActivationsServiceAgreementId
    );
    const currentAvailabilityFeesServiceAgreementId = this.store.selectSnapshot(
      IncidentReserveAvailabilityState.currentAvailabilityFeesServiceAgreementId
    );
    const currentActivationId = this.store.selectSnapshot(IncidentReserveAvailabilityState.currentActivationId);
    const currentActivationServiceAgreementId = this.store.selectSnapshot(
      IncidentReserveAvailabilityState.currentActivationServiceAgreementId
    );

    const customerIdParameterValue = findNotNullParameterValue(routerState, 'customerId');
    const dateRangeParameterValue = findNotNullParameterValue(routerState, 'dateRange');
    const serviceAgreementIdParameterValue = findNotNullParameterValue(routerState, 'serviceAgreementId');
    const activationIdParameterValue = findNotNullParameterValue(routerState, 'activationId');

    let patchForm = {};

    if (customerIdParameterValue && customerIdParameterValue !== selectedCustomerId) {
      patchForm = {
        customerId: customerIdParameterValue
      };
    }

    if (serviceAgreementIdParameterValue && serviceAgreementIdParameterValue !== selectedServiceAgreementId) {
      patchForm = {
        ...patchForm,
        serviceAgreementId: serviceAgreementIdParameterValue
      };
    }

    // Get the current, or default, in case the route does not contain a dateRange parameter
    const currentOrDefaultDateRange = getState().filters.model.dateRange ?? formatAsISO(new Date(), PeriodUnit.YEAR);
    const dateRange = inferPeriodUnitFromStringFormat(dateRangeParameterValue) ? dateRangeParameterValue : currentOrDefaultDateRange;

    patchState({
      filters: {
        ...getState().filters,
        model: {
          ...getState().filters.model,
          ...patchForm,
          dateRange
        }
      }
    });

    if (customerIdParameterValue && customerIdParameterValue !== currentCustomerId) {
      dispatch(new ImbalanceReserveAvailabilityRefreshCommand(customerIdParameterValue));
    }

    if (serviceAgreementIdParameterValue && serviceAgreementIdParameterValue !== currentActivationsServiceAgreementId) {
      dispatch(new ImbalanceReserveAvailabilityRefreshActivationsCommand(serviceAgreementIdParameterValue));
    }

    if (serviceAgreementIdParameterValue && serviceAgreementIdParameterValue !== currentAvailabilityFeesServiceAgreementId) {
      dispatch(new ImbalanceReserveAvailabilityRefreshFeesCommand(serviceAgreementIdParameterValue));
    }

    if (activationIdParameterValue) {
      if (activationIdParameterValue !== currentActivationId || serviceAgreementIdParameterValue !== currentActivationServiceAgreementId) {
        dispatch(new ImbalanceReserveAvailabilityRefreshActivationCommand(serviceAgreementIdParameterValue, activationIdParameterValue));
      }
    }
  }

  @Action(ImbalanceReserveAvailabilityRefreshCommand, { cancelUncompleted: true })
  refreshData({ dispatch, patchState }: StateContext<R3AvailabilityStateModel>, event: ImbalanceReserveAvailabilityRefreshCommand): any {
    patchState({
      isBusyFetchingAvailabilities: true
    });

    return this.serviceAgreementService.getDailyR3Availabilities(event.customerId).pipe(
      retry(getRetryConfig()),
      switchMap((result) =>
        dispatch(
          new ImbalanceReserveAvailabilityLoadedEvent(
            event.customerId,
            result.availabilities,
            result.serviceAgreements,
            result.earliestEditableDay.day,
            parseISO(result.earliestEditableDayEditableUntil),
            result.earliestAuctionDay.day,
            result.tsoAgreements
          )
        )
      ),
      tap(() => {
        patchState({
          isBusyFetchingAvailabilities: false
        });
      })
    );
  }

  @Action(ImbalanceReserveAvailabilityLoadedEvent)
  handleImbalanceReserveAvailabilityLoaded(
    { getState, patchState, dispatch }: StateContext<R3AvailabilityStateModel>,
    {
      customerId,
      serviceAgreements,
      availabilities,
      earliestEditableDay,
      earliestEditableDayEditableUntil,
      earliestAuctionDay,
      r3TsoAgreements
    }: ImbalanceReserveAvailabilityLoadedEvent
  ): any {
    const sortedServiceAgreements = sortBy(serviceAgreements || [], ['period.startDate', 'label']);
    const selectedCustomerId = this.store.selectSnapshot(IncidentReserveAvailabilityState.selectedCustomerId);

    if (selectedCustomerId !== customerId) {
      // Select the customerId that is currently loaded (fallback, should be in sync)
      dispatch(
        new UpdateFormValue({
          value: {
            ...getState().filters.model,
            customerId
          },
          path: 'imbalanceReserveAvailability.filters'
        })
      );
    }

    if (
      !serviceAgreements.some(
        (current) => current.serviceAgreementId === this.store.selectSnapshot(IncidentReserveAvailabilityState.selectedServiceAgreementId)
      )
    ) {
      const serviceAgreementId = getDefaultServiceAgreement(serviceAgreements)?.serviceAgreementId;

      dispatch(
        new UpdateFormValue({
          value: {
            ...getState().filters.model,
            serviceAgreementId
          },
          path: 'imbalanceReserveAvailability.filters'
        })
      );
    } else if (serviceAgreements.length === 0) {
      dispatch(
        new UpdateFormValue({
          value: {
            ...getState().filters.model,
            serviceAgreementId: null
          },
          path: 'imbalanceReserveAvailability.filters'
        })
      );
    }

    patchState({
      currentCustomerId: customerId,
      serviceAgreements: sortedServiceAgreements,
      availabilities,
      earliestEditableDay: moment(earliestEditableDay),
      earliestEditableDayEditableUntil,
      earliestAuctionDay: normalizeToDate(earliestAuctionDay),
      tsoAgreements: r3TsoAgreements
    });
  }

  @Action(UpdateFormValue)
  handleFormUpdate({ dispatch, getState, setState }: StateContext<R3AvailabilityStateModel>, event: UpdateFormValue): any {
    if (!getState().active) {
      return;
    }

    if (event.payload.path.includes('imbalanceReserveAvailability.filters.customerId')) {
      const currentCustomerId = this.store.selectSnapshot(IncidentReserveAvailabilityState.currentCustomerId);
      const selectedCustomerId = this.store.selectSnapshot(IncidentReserveAvailabilityState.selectedCustomerId);

      if (currentCustomerId !== selectedCustomerId && selectedCustomerId) {
        dispatch(new ImbalanceReserveAvailabilityRefreshCommand(selectedCustomerId));
      }
    }

    if (!this.router.url.startsWith(this.path)) {
      // Do not do anything when not on incident reserve availability
      return;
    }

    // Determine sub path, e.g. /incident-reserve-availability/availability/uuid would be "availability" when path matches /incident-reserve-availability
    // Should work for both this.path ending with and without /
    const pageSubPath =
      this.router.url
        .substring(this.path.length + 1)
        .split('/')
        .filter((a) => !!a)[0] ?? 'availability';

    const statePairs = [];

    const { customerId, serviceAgreementId, dateRange } = getState().filters.model;

    // Always push customerId, if it falsy it means no customer is selected and storage should be cleared
    statePairs.push(['customerId', customerId]);

    if (dateRange) {
      statePairs.push(['dateRange', dateRange]);
    }

    if (serviceAgreementId) {
      statePairs.push(['serviceAgreementId', serviceAgreementId]);
    }

    handleStateToRouteSync(
      this.router,
      [
        `${this.path}/${pageSubPath}`,
        `${this.path}/${pageSubPath}/:customerId`,
        `${this.path}/${pageSubPath}/:customerId/:dateRange`,
        `${this.path}/${pageSubPath}/:customerId/:dateRange/:serviceAgreementId`,
        `${this.path}/activation/:activationId`
      ],
      fromPairs(statePairs)
    );
  }

  @Action(IncidentReserveAvailabilityChangeAvailabilityCommand)
  handleChangeAvailabilityCommand(
    { patchState, getState }: StateContext<R3AvailabilityStateModel>,
    event: IncidentReserveAvailabilityChangeAvailabilityCommand
  ): any {
    if (getState().savingAvailabilityStatus && !getState().savingAvailabilityStatus.done) {
      console.warn('ignored save availability due to currently being busy with saving');
      return;
    }

    return this.serviceAgreementService
      .saveDailyR3Availability(
        this.store.selectSnapshot(IncidentReserveAvailabilityState.currentCustomerId),
        this.store.selectSnapshot(IncidentReserveAvailabilityState.selectedServiceAgreementId),
        event.period,
        event.upwards,
        event.downwards
      )
      .pipe(
        tap(
          AsyncActionStatus.partialObserverFactory((newStatus) =>
            patchState({
              savingAvailabilityStatus: newStatus
            })
          )
        ),
        tap((result: R3Availabilities) =>
          this.store.dispatch(
            new ImbalanceReserveAvailabilityLoadedEvent(
              result.customerId,
              result.availabilities,
              result.serviceAgreements,
              result.earliestEditableDay.day,
              parseISO(result.earliestEditableDayEditableUntil),
              result.earliestAuctionDay.day,
              result.tsoAgreements
            )
          )
        )
      );
  }

  @Action(AsyncMessageReceivedEvent)
  handleSseMessageEvent({ dispatch, getState }: StateContext<R3AvailabilityStateModel>, event: AsyncMessageReceivedEvent): void {
    if (!getState().active) {
      return;
    }

    const currentCustomerId = this.store.selectSnapshot(IncidentReserveAvailabilityState.currentCustomerId);
    const selectedServiceAgreementId = this.store.selectSnapshot(IncidentReserveAvailabilityState.selectedServiceAgreementId);
    if (!currentCustomerId) {
      return;
    }

    if (
      event.message.type === MessageType.AvailabilityAdjustmentTimeForTomorrowExpiredSseEvent ||
      event.message.type === MessageType.TsoAgreementPublicationTimeExpiredSseEvent
    ) {
      dispatch(new ImbalanceReserveAvailabilityRefreshCommand(currentCustomerId));
    }

    if (
      this.store.selectSnapshot(IncidentReserveAvailabilityState.active) &&
      event.message.type === MessageType.IncidentReserveInvoiceDataCalculatedSseEvent
    ) {
      dispatch(new ImbalanceReserveAvailabilityRefreshFeesCommand(selectedServiceAgreementId));
    }
  }

  @Action(IncidentReserveAvailabilitySelectMonthCommand)
  handleIncidentReserveAvailabilitySelectMonthCommand(
    { dispatch, getState }: StateContext<R3AvailabilityStateModel>,
    { period }: IncidentReserveAvailabilitySelectMonthCommand
  ): void {
    dispatch(
      new UpdateFormValue({
        value: {
          ...getState().filters.model,
          dateRange: formatAsISO(normalizeToDate(period.startDate), PeriodUnit.MONTH)
        },
        path: 'imbalanceReserveAvailability.filters'
      })
    );
  }

  @Action(ImbalanceReserveAvailabilityRefreshFeesCommand, { cancelUncompleted: true })
  handleImbalanceReserveAvailabilityRefreshFeeCommand(
    { dispatch, patchState }: StateContext<R3AvailabilityStateModel>,
    { serviceAgreementId }: ImbalanceReserveAvailabilityRefreshFeesCommand
  ): any {
    if (!serviceAgreementId) {
      patchState({
        currentAvailabilityFeesServiceAgreementId: null,
        downwardsAvailabilityFees: [],
        upwardsAvailabilityFees: []
      });
      return;
    }

    patchState({
      isBusyFetchingAvailabilityFees: true
    });
    return this.serviceAgreementService.getDailyR3AvailabilityFees(serviceAgreementId).pipe(
      tap({
        next: (result) => {
          dispatch(new ImbalanceReserveAvailabilityFeesUpdatedEvent(serviceAgreementId, result.downwards, result.upwards));
        },
        error: () =>
          patchState({
            isBusyFetchingAvailabilityFees: false
          })
      })
    );
  }

  @Action(ImbalanceReserveAvailabilityFeesUpdatedEvent)
  handleImbalanceReserveAvailabilityFeesUpdatedEvent(
    { patchState }: StateContext<R3AvailabilityStateModel>,
    { serviceAgreementId, upwardsAvailabilityFees, downwardsAvailabilityFees }: ImbalanceReserveAvailabilityFeesUpdatedEvent
  ): any {
    patchState({
      currentAvailabilityFeesServiceAgreementId: serviceAgreementId,
      upwardsAvailabilityFees,
      downwardsAvailabilityFees,
      isBusyFetchingAvailabilityFees: false
    });
  }

  @Action(ImbalanceReserveAvailabilityRefreshActivationsCommand, { cancelUncompleted: true })
  handleImbalanceReserveAvailabilityRefreshActivationsCommand(
    { dispatch, patchState }: StateContext<R3AvailabilityStateModel>,
    { serviceAgreementId }: ImbalanceReserveAvailabilityRefreshActivationsCommand
  ): any {
    if (!serviceAgreementId) {
      patchState({
        currentActivationsServiceAgreementId: null,
        activations: []
      });
      return;
    }

    patchState({
      isBusyFetchingActivations: true
    });

    return this.operationService.getActivationsForServiceAgreement(serviceAgreementId).pipe(
      tap({
        next: (result) => {
          dispatch(new ImbalanceReserveAvailabilityActivationsUpdatedEvent(serviceAgreementId, result));
        },
        error: () =>
          patchState({
            isBusyFetchingActivations: false
          })
      })
    );
  }

  @Action(ImbalanceReserveAvailabilityActivationsUpdatedEvent)
  handleImbalanceReserveAvailabilityActivationsUpdatedEvent(
    { patchState }: StateContext<R3AvailabilityStateModel>,
    { serviceAgreementId, activations }: ImbalanceReserveAvailabilityActivationsUpdatedEvent
  ): void {
    patchState({
      currentActivationsServiceAgreementId: serviceAgreementId,
      activations,
      isBusyFetchingActivations: false
    });
  }

  @Action(ImbalanceReserveAvailabilityActivate)
  activateState({ patchState }: StateContext<R3AvailabilityStateModel>): any {
    patchState({
      active: true
    });
  }

  @Action(ImbalanceReserveAvailabilityDeactivate)
  deactivateState({ patchState }: StateContext<R3AvailabilityStateModel>): any {
    patchState({
      active: false, // We cannot clear the state here, since this can trigger a form update, which can cause a route change
      shouldReset: true
    });
  }

  @Action(ImbalanceReserveAvailabilityRefreshActivationCommand)
  handleImbalanceReserveAvailabilityRefreshActivationCommand(
    { dispatch, patchState }: StateContext<R3AvailabilityStateModel>,
    { activationId, serviceAgreementId }: ImbalanceReserveAvailabilityRefreshActivationCommand
  ): any {
    patchState({
      activationMeasurementBusy: true
    });

    return this.operationService
      .getPoolMeasurementsForServiceAgreement(activationId, serviceAgreementId)
      .pipe(
        tap((result) =>
          dispatch(new ImbalanceReserveAvailabilityActivationMeasurementsUpdatedEvent(activationId, serviceAgreementId, result))
        )
      );
  }

  @Action(ImbalanceReserveAvailabilityActivationMeasurementsUpdatedEvent)
  handleImbalanceReserveAvailabilityActivationMeasurementsUpdatedEvent(
    { patchState }: StateContext<R3AvailabilityStateModel>,
    { activationId, serviceAgreementId, data }: ImbalanceReserveAvailabilityActivationMeasurementsUpdatedEvent
  ): any {
    patchState({
      currentActivationServiceAgreementId: serviceAgreementId,
      currentActivationId: activationId,
      activationMeasurementData: data,
      activationMeasurementBusy: false
    });
  }
}

// For testing purposes: allow year to be overridden in unit tests
export const getCurrent = {
  year: () => moment().year(),
  month: () => moment().month()
};

/**
 * Find the year that is closest to the current year
 */
export function getClosestYear(years: number[]): number {
  const currentYear = getCurrent.year();

  return getClosest(years, currentYear);
}

export function getClosestMonth(months: number[]): number {
  const currentMonth = getCurrent.month();

  return getClosest(months, currentMonth);
}

export function getClosest(numbers: number[], target: number): number {
  return numbers.includes(target)
    ? target
    : numbers.reduce((c, currentNumber) => {
        const oldDiff = Math.abs(c - target);
        const newDiff = Math.abs(currentNumber - target);
        if (oldDiff > newDiff) {
          return currentNumber;
        } else {
          return c;
        }
      }, Number.POSITIVE_INFINITY);
}
