<ng-container>
  <button
    mat-icon-button
    disableRipple
    i18n-aria-label="@@announcementsTrigger-openUnreadNotifications"
    aria-label="Open unread notifications"
  >
    <mat-icon
      svgIcon="ic-new:close"
      class="announcements-icon"
      [matBadge]="newAnnouncements"
      matBadgeSize="small"
      [matBadgeHidden]="hideNewAnnouncementsBadge"
    ></mat-icon>
  </button>
  <span class="cdk-visually-hidden">
    <ng-container *ngIf="!newAnnouncements">
      No new {newAnnouncements, plural, =0 { announcements } =1 {announcement} other {announcements}}</ng-container
    >
    <ng-container *ngIf="newAnnouncements">
      {{ newAnnouncements }}
      new {newAnnouncements, plural, =0 { announcements } =1 {announcement} other {announcements}}
    </ng-container>
  </span>
</ng-container>
