import { ChangeDetectorRef, Component, HostBinding, Injectable, OnInit } from '@angular/core';
import moment from 'moment';
import { Moment } from 'moment';
import { IntradaySlotStatus, IntradayState } from '../../state/intraday-state.service';
import { Store } from '@ngxs/store';
import { IntraDaySlotStatusChanged } from '../../state/intraday.actions';
import { ReplaySubject } from 'rxjs';
import { MixinBase, OnDestroyMixin } from 'flex-app-shared';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CountdownService {
  static minSecondsRemaining = -60 * 5; // 5 minutes after expiry
  public currentStatus: IntradaySlotStatus;
  private secondsRemainingSubject = new ReplaySubject(1);
  public secondsRemaining$ = this.secondsRemainingSubject.asObservable();
  private priceWindowTo$ = this.store.select(IntradayState.priceWindowTo);
  private intervalHandle: any;

  private currentTargetTime: Moment;
  private nextTargetTime: Moment;

  constructor(private store: Store) {
    this.priceWindowTo$.subscribe((priceWindowTo) => {
      this.updateTimer(priceWindowTo);
    });
  }

  private _secondsRemaining: number;

  get secondsRemaining(): number {
    return this._secondsRemaining;
  }

  set secondsRemaining(value: number) {
    this._secondsRemaining = value;
    this.secondsRemainingSubject.next(value);
  }

  private checkForStatusChange(): void {
    const newStatus = this.getCurrentStatus();
    if (newStatus !== this.currentStatus) {
      this.currentStatus = newStatus;
      this.store.dispatch(new IntraDaySlotStatusChanged(newStatus));
    }
  }

  private getCurrentStatus(): IntradaySlotStatus {
    if (this.secondsRemaining <= CountdownService.minSecondsRemaining) {
      return IntradaySlotStatus.STALE;
    }
    if (this.secondsRemaining <= 0) {
      return IntradaySlotStatus.EXPIRED;
    }
    if (this.secondsRemaining <= 10) {
      return IntradaySlotStatus.CLOSE_TO_EXPIRED;
    }
    return IntradaySlotStatus.VALID;
  }

  private updateTimer(targetTime: string): void {
    if (!targetTime) {
      return;
    }

    if (this.secondsRemaining > 0) {
      // Last timer has not expired yet, set to 5 second max
      console.warn(`Got updated timeslot early, ${this.currentTargetTime.diff(moment(), 'seconds')} seconds remaining.`);

      this.currentTargetTime = moment.min([this.currentTargetTime, moment().add(5, 'seconds')]);
      this.nextTargetTime = moment(targetTime);
      return;
    }

    this.stopInterval();

    this.currentTargetTime = moment(targetTime);

    // Set initial secondsRemaining to currently remaining
    this.secondsRemaining = this.getSecondsRemaining(this.currentTargetTime);
    this.checkForStatusChange();

    // Synchronize with whole second
    setTimeout(() => {
      // After initial offset, update secondsRemaining
      this.secondsRemaining = this.getSecondsRemaining(this.currentTargetTime);

      this.intervalHandle = setInterval(() => {
        this.secondsRemaining = this.getSecondsRemaining(this.currentTargetTime);
        this.checkForStatusChange();

        if (this.nextTargetTime && this.secondsRemaining <= 0) {
          this.currentTargetTime = this.nextTargetTime;
          this.nextTargetTime = null;
          this.secondsRemaining = this.getSecondsRemaining(this.currentTargetTime);
          this.checkForStatusChange();
        }

        if (this.secondsRemaining <= CountdownService.minSecondsRemaining) {
          this.stopInterval();
        }
      }, 1000);
    }, moment().endOf('second').diff(moment(), 'ms'));
  }

  private getSecondsRemaining(targetTime: string | Moment): number {
    const nowMoment = moment();
    return Math.max(Math.round(moment(targetTime).diff(nowMoment, 'seconds', true)), CountdownService.minSecondsRemaining);
  }

  private stopInterval(): void {
    if (this.intervalHandle) {
      clearInterval(this.intervalHandle);
      this.intervalHandle = null;
    }
  }
}

@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss']
})
export class CountdownComponent extends OnDestroyMixin(MixinBase) implements OnInit {
  static displayMinSecondsRemaining = 0;
  displaySecondsRemaining: number = 0;

  constructor(private countdownService: CountdownService, private cdr: ChangeDetectorRef) {
    super();
  }

  get minutes(): number {
    return Math.floor(this.displaySecondsRemaining / 60);
  }

  get seconds(): number {
    return this.displaySecondsRemaining % 60;
  }

  @HostBinding('class.stale')
  get isStale(): boolean {
    return this.countdownService.currentStatus === IntradaySlotStatus.STALE;
  }

  get isExpired(): boolean {
    return this.countdownService.currentStatus === IntradaySlotStatus.EXPIRED;
  }

  get isCloseToExpired(): boolean {
    return this.countdownService.currentStatus === IntradaySlotStatus.CLOSE_TO_EXPIRED;
  }

  ngOnInit(): void {
    this.countdownService.secondsRemaining$.pipe(takeUntil(this.onDestroy$)).subscribe((secondsRemaining) => {
      this.displaySecondsRemaining = Math.max(this.countdownService.secondsRemaining, CountdownComponent.displayMinSecondsRemaining);
      this.cdr.detectChanges();
    });
  }
}
