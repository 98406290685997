import { Injectable, NgZone } from '@angular/core';
import { FocusDispatcher } from '@angular/cdk-experimental/popover-edit';
import { FlattenedIntradayLevel, FlattenedIntradayNode, IntradayDealControlsDataSource } from './intraday-deal-controls-data-source';
import { ReplaySubject } from 'rxjs';
import { Directionality } from '@angular/cdk/bidi';
import { closest, EDITABLE_CELL_SELECTOR, TABLE_SELECTOR } from './angular-material-exports';

@Injectable()
export class IntradaySlotFocusDispatcher extends FocusDispatcher {
  dataSource: IntradayDealControlsDataSource;
  focusedNode$ = new ReplaySubject<FlattenedIntradayNode>(1);

  constructor(directionality: Directionality, private zone: NgZone) {
    super(directionality);
  }

  moveFocusHorizontally(currentCell: HTMLElement, offset: number): void {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const cells = Array.from(closest(currentCell, TABLE_SELECTOR)!.querySelectorAll(EDITABLE_CELL_SELECTOR)) as HTMLElement[];
    const currentIndex = cells.indexOf(currentCell);

    if (!this.dataSource) {
      // Apparently disconnected, return
      return;
    }

    const nodeHelper = new NextNodeHelper(this.dataSource.data, this.dataSource.dataAfterFilter);

    nodeHelper.setSelectedNodeFilteredIndex(currentIndex);

    if (offset === -1) {
      nodeHelper.moveUp();
    } else {
      nodeHelper.moveDown();
    }

    const selectedAncestor = nodeHelper.getSelectedAncestor();
    const selectedNode = nodeHelper.selectedNode;

    if (selectedAncestor) {
      this.dataSource.treeControl.expandDescendants(selectedAncestor);
    }

    if (selectedNode) {
      this.focusedNode$.next(selectedNode);
    }
  }

  registerDataSource(dataSource: IntradayDealControlsDataSource): void {
    this.dataSource = dataSource;
  }
}

export class NextNodeHelper {
  selectedNode: FlattenedIntradayNode;
  selectedFilteredListIndex: number;
  selectedListIndex: number;

  constructor(
    private filteredList: FlattenedIntradayNode[], // Displayed list, used for index lookup
    private list: FlattenedIntradayNode[] // Full list for navigation
  ) {}

  setSelectedNodeFilteredIndex(filteredListIndex: number): void {
    this.selectedNode = this.filteredList[filteredListIndex];
    this.updateSelectedIndexes();
  }

  setSelectedNodeIndex(listIndex: number): void {
    this.selectedNode = this.list[listIndex];
    this.updateSelectedIndexes();
  }

  private updateSelectedIndexes(): void {
    this.selectedFilteredListIndex = this.getFilteredListIndex(this.selectedNode);
    this.selectedListIndex = this.getListIndex(this.selectedNode);
  }

  getSelectedAncestor(): FlattenedIntradayNode {
    for (let i = this.selectedListIndex - 1; i >= 0; i--) {
      if (this.list[i].level === FlattenedIntradayLevel.CUSTOMER) {
        return this.list[i];
      }
    }
  }

  /**
   * Move up to the nearest control
   */
  moveUp(): FlattenedIntradayNode {
    if (this.selectedListIndex === 0) {
      return;
    }
    this.setSelectedNodeIndex(this.selectedListIndex - 1);

    if (!this.isValidLevel()) {
      this.moveUp();
    }
  }

  /**
   * Move down to the nearest control
   */
  moveDown(): FlattenedIntradayNode {
    if (this.selectedListIndex === this.list.length - 1) {
      return;
    }

    this.setSelectedNodeIndex(this.selectedListIndex + 1);

    if (!this.isValidLevel()) {
      this.moveDown();
    }
  }

  private isValidLevel(): boolean {
    return this.selectedNode.level === FlattenedIntradayLevel.CONTROL;
  }

  private getFilteredListIndex(node: FlattenedIntradayNode): number {
    return this.filteredList.indexOf(node);
  }

  private getListIndex(node: FlattenedIntradayNode): number {
    return this.list.indexOf(node);
  }
}
