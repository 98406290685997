<h3 mat-dialog-title i18n="@@intraDay-createNewOrder">Create new order</h3>
<mat-dialog-content class="overflow-auto">
  <mat-stepper [orientation]="stepperOrientation$ | async" linear [@.disabled]="true">
    <mat-step>
      <app-step-direction></app-step-direction>
    </mat-step>
    <mat-step>
      <app-step-price></app-step-price>
    </mat-step>
    <mat-step>
      <app-step-time></app-step-time>
    </mat-step>
    <mat-step>
      <app-step-volume [pricePerMWh]="pricePerMWh$ | async" #stepVolume></app-step-volume>
    </mat-step>
    <mat-step>
      <app-step-summary
        [pricePerMWh]="pricePerMWh$ | async"
        [dealsPerControlId]="stepVolume.formGroup.value"
        [splitCount]="intradayFacade.idconsSplitCount$ | async"
      ></app-step-summary>
    </mat-step>
    <ng-template matStepperIcon="edit" let-index="index">
      <mat-icon svgIcon="ic-old:check"></mat-icon>
    </ng-template>
  </mat-stepper>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-stroked-button [disabled]="!hasPreviousStep()" (click)="stepper.previous()">
    <ph-flex-vertical-center
      ><mat-icon svgIcon="ic-old:chevron-left"></mat-icon
      ><span i18n="@@intraday-dialogButtonPrevious">Previous</span></ph-flex-vertical-center
    >
  </button>
  <ng-container *ngIf="!isSubmit()">
    <button mat-stroked-button [disabled]="!(hasNextStep() && isCurrentStepValid())" (click)="stepper.next()" class="icon-after-text">
      <ph-flex-vertical-center>
        <span i18n="@@intraday-dialogButtonNext">Next</span>
        <mat-icon svgIcon="ic-old:chevron-right"></mat-icon>
      </ph-flex-vertical-center>
    </button>
  </ng-container>
  <ng-container *ngIf="isSubmit()">
    <ph-flex-save-button (clickChanged)="submit()" [pending]="isPending$ | async" i18n="@@intraday-dialogButtonConfirmOrders">
      Confirm orders
    </ph-flex-save-button>
  </ng-container>
</mat-dialog-actions>
