import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { MixinBase } from '../../../core/common/constructor-type.mixin';
import { OnDestroyMixin } from '../../../core/common/on-destroy.mixin';
import { PeriodUnit } from '../default-period';
@Component({
  selector: 'ph-flex-date-range-fixed-period-combined-input',
  templateUrl: './date-range-fixed-period-combined-input.component.html',
  styleUrls: ['./date-range-fixed-period-combined-input.component.scss']
})
export class DateRangeFixedPeriodCombinedInputComponent extends OnDestroyMixin(MixinBase) {
  @Input()
  label: string;

  @Input()
  min: Date;

  @Input()
  max: Date;

  dateControl: UntypedFormControl;

  PeriodUnit = PeriodUnit;

  dateRangeSelectedPeriod$: Observable<PeriodUnit>;

  constructor() {
    super();
  }
}
