import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Direction, IntradayService, PriceHistoryJson, TimeSlot } from 'flex-app-shared';
import { map, Observable } from 'rxjs';
import { IntradayFacade } from '../../state/intraday-facade.service';

export interface IntradayPriceHistoryDialogData {
  timeslot: TimeSlot;
  direction: Direction;
}

@Component({
  selector: 'app-intraday-price-history-dialog',
  templateUrl: './intraday-price-history-dialog.component.html',
  styleUrls: ['./intraday-price-history-dialog.component.scss']
})
export class IntradayPriceHistoryDialogComponent implements OnInit, OnDestroy {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public dialogData: IntradayPriceHistoryDialogData,
    public service: IntradayService,
    public facade: IntradayFacade
  ) {}

  data$: Observable<PriceHistoryJson> = this.facade.priceHistory$;
  slotPeriod$: Observable<TimeSlot> = this.data$.pipe(map((data) => data?.slotPeriod));

  ngOnInit(): void {
    this.facade.activateIntradayPriceHistory(this.dialogData.timeslot);
  }

  ngOnDestroy(): void {
    this.facade.deactivateIntradayPriceHistory();
  }
}
