<ng-container [formGroup]="formGroup">
  <mat-button-toggle-group formControlName="selectedDayOffset">
    <mat-button-toggle [value]="0" i18n="@@intraday-labelToday">Today</mat-button-toggle>
    <mat-button-toggle [value]="1" i18n="@@intraday-labelTomorrow" *ngIf="isTomorrowAvailable$ | async">Tomorrow</mat-button-toggle>
  </mat-button-toggle-group>
  <app-hour-selector
    formControlName="selectedTimeSlot"
    [targetDay]="targetDay"
    [editableNumberRange]="editableNumberRange$ | async"
  ></app-hour-selector>
  <mat-slide-toggle formControlName="allowMatchingIndividualHours" i18n="@@intraday-labelAllowMatchingIndividualHours">
    Allow matching individual hours
  </mat-slide-toggle>
</ng-container>

<ng-template matStepLabel>
  <ng-container *ngIf="shouldShowLabelWithoutValue()" i18n="@@intraday-labelStepTime">Time</ng-container>
  <ng-container *ngIf="!shouldShowLabelWithoutValue() && formGroup.get('selectedTimeSlot').value as timeSlot">
    {{ timeSlot.startDateTime | date: 'shortDate' }}
    <ng-container *ngTemplateOutlet="timeSlotTemplate; context: { $implicit: timeSlot }"></ng-container>
  </ng-container>
</ng-template>

<ng-template #timeSlotTemplate let-timeSlot
  >{{ timeSlot.startDateTime | date: 'shortTime' }} - {{ timeSlot.toDateTime | date: 'shortTime' }}</ng-template
>
