import { Component, Input } from '@angular/core';
import { IdconsAnnouncement } from '../../shared/idcons/announcement/idcons-announcement';

@Component({
  selector: 'app-idcons-announcements',
  templateUrl: './idcons-announcements.component.html',
  styleUrls: ['./idcons-announcements.component.scss']
})
export class IdconsAnnouncementsComponent {
  @Input() announcements: IdconsAnnouncement[] = [];
  @Input() unreadAnnouncementsIds: string[];

  constructor() {}

  isUnreadAnnouncement(announcementId: string): boolean {
    return this.unreadAnnouncementsIds?.includes(announcementId);
  }
}
