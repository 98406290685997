<form [formGroup]="filterForm">
  <div [class.ph-flex-mat-form-field-group-inline]="!inDialog" [class.ph-flex-mat-form-field-group-block]="inDialog">
    <mat-form-field *ngIf="isSelected$('customerId') | async" phFlexInputTheme>
      <mat-label i18n="@@intraday-labelCustomer">Company name</mat-label>
      <ph-flex-autocomplete
        [phFlexDataSourceFilterById]="(selectedControlCustomerId$ | async) || (selectedGridPointCustomerId$ | async)"
        [shouldAutoSelectIfOnlyOneResult]="true"
        [shouldEmitNull]="true"
        [shouldOnlyEmitIds]="true"
        formControlName="customerId"
        i18n-placeholder="@@intraday-labelCustomer"
        phFlexCustomerDataSource
        placeholder="Customer"
      ></ph-flex-autocomplete>
    </mat-form-field>
    <mat-form-field *ngIf="isSelected$('gridPointId') | async" phFlexInputTheme>
      <mat-label i18n="@@intraday-labelGridPoint">Grid Point</mat-label>
      <ph-flex-autocomplete
        [phFlexDataSourceFilterByCustomerId]="filterForm.get('customerId').valid && filterForm.get('customerId').value"
        [phFlexDataSourceFilterById]="selectedControlGridPointIds$ | async"
        [shouldAutoSelectIfOnlyOneResult]="true"
        [shouldEmitNull]="true"
        [shouldOnlyEmitIds]="true"
        formControlName="gridPointId"
        i18n-placeholder="@@intraday-labelGridpoint"
        phFlexGridPointDataSource
        placeholder="GridPoint"
      ></ph-flex-autocomplete>
    </mat-form-field>
    <mat-form-field *ngIf="isSelected$('controlId') | async" phFlexInputTheme>
      <mat-label i18n="@@intraday-labelControl">Component</mat-label>
      <ph-flex-autocomplete
        [customerIdFilter]="filterForm.get('customerId').valid && filterForm.get('customerId').value"
        [gridPointIdFilter]="filterForm.get('gridPointId').valid && filterForm.get('gridPointId').value"
        [phFlexDataSourceFilterByCustomerId]="filterForm.get('customerId').valid && filterForm.get('customerId').value"
        [phFlexDataSourceFilterByGridPointId]="filterForm.get('gridPointId').valid && filterForm.get('gridPointId').value"
        [shouldEmitNull]="true"
        [shouldOnlyEmitIds]="true"
        formControlName="controlId"
        i18n-placeholder="@@intraday-labelControl"
        phFlexControlDataSource
        placeholder="Control"
      ></ph-flex-autocomplete>
    </mat-form-field>
    <mat-form-field *ngIf="isSelected$('dealReference') | async" phFlexInputTheme>
      <mat-label i18n="@@intraday-labelDealReference">Deal Reference</mat-label>
      <input formControlName="dealReference" i18n-placeholder="@@intraday-labelDealReference" matInput placeholder="Deal Reference" />
    </mat-form-field>
  </div>
  <ng-container *ngIf="isSelected$('dealPeriod') | async">
    <ph-flex-date-range-fixed-period
      [class.ph-flex-mat-form-field-group-inline]="!inDialog"
      formControlName="dealPeriod"
      phFlexDateRangeFixedPeriodDefaultYear
      phFlexDateRangeFixedPeriodStringValueAccessor
      [phFlexDateRangeFixedPeriodToolbar]="!inDialog"
      [phFlexDateRangeFixedPeriodDialog]="inDialog"
    >
      <ph-flex-date-range-fixed-period-button-group
        [availablePeriodUnits]="availablePeriodUnits"
      ></ph-flex-date-range-fixed-period-button-group>
      <ph-flex-date-range-fixed-period-combined-input
        i18n-label="@@intraday-labelDealPeriod"
        label="Delivery date"
      ></ph-flex-date-range-fixed-period-combined-input>
    </ph-flex-date-range-fixed-period>
  </ng-container>
  <ng-container *ngIf="isSelected$('createdPeriod') | async">
    <ph-flex-date-range-fixed-period
      [class.ph-flex-mat-form-field-group-inline]="!inDialog"
      formControlName="createdPeriod"
      phFlexDateRangeFixedPeriodDefaultYear
      phFlexDateRangeFixedPeriodStringValueAccessor
      [phFlexDateRangeFixedPeriodToolbar]="!inDialog"
      [phFlexDateRangeFixedPeriodDialog]="inDialog"
    >
      <ph-flex-date-range-fixed-period-button-group
        [availablePeriodUnits]="availablePeriodUnits"
      ></ph-flex-date-range-fixed-period-button-group>
      <ph-flex-date-range-fixed-period-combined-input
        i18n-label="@@intraday-labelCreatedPeriod"
        label="Created date"
      ></ph-flex-date-range-fixed-period-combined-input>
    </ph-flex-date-range-fixed-period>
  </ng-container>
</form>
