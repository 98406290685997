import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SubmoduleAttachHelper } from './submodule-attach.helper';
import { SubjectProvider } from '../common';
import { D3GraphAxisSubModule } from '../submodules/d3-graph-axis-submodule';
import { OnDestroyProvider } from '../../../core/common/on-destroy.mixin';

/**
 * Manage attach/detach for axes based on axisPriority and the currently used scales.
 * The current behavior is, draw left for index 0 match, draw right for index 1 match (based on axisPriority) and don't draw otherwise.
 */
export class AxisAttachHelper extends SubmoduleAttachHelper {
  usedYScalesProvider = new SubjectProvider<any[]>(this);
  subModule: D3GraphAxisSubModule<any, any, any>;
  private shownProvider = new SubjectProvider<boolean>(this, new BehaviorSubject(false));

  constructor(onDestroyProvider: OnDestroyProvider, axisSubModule: D3GraphAxisSubModule<any, any, any>) {
    super(onDestroyProvider, axisSubModule);

    this.usedYScalesProvider.follow(this.subModule.yScaleProvider.value$.pipe(map((value) => [value])));
  }

  get shown(): boolean {
    return this.shownProvider.value;
  }

  show(): void {
    this.shownProvider.next(true);
    if (this.mainSvg) {
      this.attach(this.mainSvg);
    }
  }

  hide(): void {
    this.shownProvider.next(false);
    this.detach();
  }
}
