import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { BaseType, select, Selection } from 'd3';

/**
 * Create an svg as a child of the element this directive is present on.
 * Provides an output callback that is called on afterViewInit with the selection of the new svg element
 */
@Component({
  template: '',
  selector: 'ph-flex-svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhFlexSvgComponent implements AfterViewInit {
  // eslint-disable-next-line
  @Output('afterViewInit') output = new EventEmitter<Selection<BaseType, any, any, any>>();

  svgNode: Selection<BaseType, any, any, any>;

  constructor(private elRef: ElementRef) {}

  ngAfterViewInit(): void {
    this.svgNode = select(this.elRef.nativeElement).append('svg').attr('width', '100%');
    this.output.emit(this.svgNode);
  }
}
