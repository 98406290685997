import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Inject, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { ApplicationUpdateService, AuthorityService, AuthService } from 'flex-app-shared';
import { Router } from '@angular/router';
import { TopBarService } from '../../../flex-app-shared/src/lib/material/top-bar/top-bar.service';
import { SideMenuService } from '../../../flex-app-shared/src/lib/material/side-menu/side-menu/side-menu.service';
import { registerGlobalAppInsights } from '../../../flex-app-shared/src/lib/app-insights/register-global-app-insights';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  showContents = false;
  error = null;
  sidenavExpanded = true;

  constructor(
    public router: Router,
    public cdr: ChangeDetectorRef,
    public authService: AuthService,
    public authorityService: AuthorityService,
    private applicationUpdateService: ApplicationUpdateService,
    private topBarService: TopBarService,
    private sideMenuService: SideMenuService
  ) {
    if (environment.enableAppInsights) {
      registerGlobalAppInsights('flex-app-customer-portal', this.router);
    }
  }

  @HostListener('window:resize')
  onResize(): void {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    if (!environment.requireAuthentication) {
      this.showContents = true;
    }
    this.onResize();

    this.authorityService.authorities$.subscribe((authorities) => {
      // TODO Always show when the user is logged in
      if (!authorities) {
        this.error = 'unavailable';
      } else if (authorities.length === 0 && environment.requireAuthentication) {
        this.error = 'unauthorized';
      } else if (this.error && !this.showContents) {
        // We came from an error state (while the application was still uninitialized), so we shouldn't just show the application.
        // Instead we should check for updates, and then refresh
        this.applicationUpdateService.updateOrRefreshAfterReconnect();
      } else {
        this.error = null;
        this.showContents = true;
      }

      this.cdr.detectChanges();
    });

    this.sideMenuService.expandedSidebar$.subscribe(() => {
      this.sidenavExpanded = !this.sidenavExpanded;
    });
  }

  logout(): void {
    this.authService.logout();
  }
}
