import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';

type Option = { key: string; isChecked: boolean; disabled?: boolean };

@Component({
  selector: 'ph-flex-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss']
})
export class FilterDialogComponent implements OnInit {
  options: Option[];
  selectedOptionsAmount: number;

  @Input() filters: string[];
  @Input() selectedFilters: string[];
  @Input() dialogCloseActionDataCb: (cb: () => any) => void;
  @Input() maxSelectedFilters: number;
  @Input() optionsTemplate?: TemplateRef<any>;

  @Output() selectedFiltersChanged = new EventEmitter<string[]>();

  constructor() {}

  ngOnInit(): void {
    this.selectedOptionsAmount = this.selectedFilters?.length;

    this.setOptions();
    this.updateSelectedFilters();
  }

  changeSelection(key: string, $event: MatCheckboxChange): void {
    if ($event.checked) {
      this.addFilter(key);
    } else {
      this.removeFilter(key);
    }

    this.setOptions();
    this.updateSelectedFilters();
  }

  private addFilter(key: string): void {
    this.selectedFilters = [...this.selectedFilters, key];
    this.selectedOptionsAmount = this.selectedOptionsAmount + 1;
  }

  private removeFilter(key: string): void {
    this.selectedFilters = this.selectedFilters.filter((selectedFilterKey) => selectedFilterKey !== key);
    this.selectedOptionsAmount = this.selectedOptionsAmount - 1;
  }

  private setOptions(): void {
    this.options = this.filters?.map((key) => {
      const isChecked = this.selectedFilters.includes(key);
      const disabled = this.selectedOptionsAmount >= this.maxSelectedFilters && !isChecked;
      return { key, isChecked, disabled };
    });
  }

  private updateSelectedFilters(): void {
    const checkedFilters = this.options?.filter((option) => option.isChecked);
    this.selectedFiltersChanged.emit(checkedFilters?.map((option) => option.key));
  }
}
