<div class="hours" (click)="helper.handleOutsideClick()" (mouseout)="helper.handleMouseOut()">
  <div class="hour-row" *ngFor="let row of rows">
    <div
      class="hour-row-child"
      [class.disabled]="isHourDisabled(hour)"
      *ngFor="let hour of row; trackBy: trackByHour; last as isLast"
      [class.hover]="helper.isHover(hour) && !isLast"
      [class.selected]="helper.isSelected(hour) && !isLast"
    >
      <div class="hour-label">
        <span>{{ hour | date: 'HH' }}</span>
      </div>
      <div
        class="hour"
        [class.disabled]="isHourDisabled(hour)"
        *ngIf="!isLast"
        (click)="handleHourClick(hour, $event)"
        (mouseover)="helper.handleMouseOver(hour)"
      ></div>
    </div>
  </div>
</div>
