import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { keys } from 'lodash-es';
import { IdconsAnnouncementsPerDay } from '../../../store/idcons-announcements/idcons-announcements.facade';

@Component({
  selector: 'app-idcons-announcements-per-day',
  templateUrl: './idcons-announcements-per-day.component.html',
  styleUrls: ['./idcons-announcements-per-day.component.scss']
})
export class IdconsAnnouncementsPerDayComponent implements OnChanges {
  @Input() announcementsPerDay: IdconsAnnouncementsPerDay | null;
  @Input() unreadAnnouncementsIds: string[];

  days: string[] = [];

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.announcementsPerDay) {
      this.days = keys(this.announcementsPerDay);
    }
  }

  isUnreadAnnouncement(announcementId: string): boolean {
    return this.unreadAnnouncementsIds?.includes(announcementId);
  }
}
