<div class="ph-g-container-page">
  <mat-card class="ph-g-card-body-main">
    <mat-card-header>
      <mat-card-title i18n="@@intraday-headerDealHistory"> Deal history</mat-card-title>
      <button
        i18n-matTooltip="@@intraday-buttonDownloadDealData"
        matTooltip="Download deal data"
        mat-icon-button
        (click)="downloadData()"
        [disabled]="downloadDataButtonDisabled()"
      >
        <mat-icon svgIcon="ic-new:download"></mat-icon>
      </button>
    </mat-card-header>
    <mat-card-content>
      <ph-flex-loading-context [isLoading]="dealsBusy$ | async">
        <table [dataSource]="dataSource" mat-table matSort matSortActive="created" matSortDirection="desc" [trackBy]="trackBy">
          <ng-container matColumnDef="created">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>
              <ng-container i18n="@@intraday-tableHeaderCreated"> Created</ng-container>
            </th>
            <td *matCellDef="let deal" mat-cell>{{ deal.created | date: 'short' }}</td>
          </ng-container>
          <ng-container matColumnDef="dealCapacity">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>
              <ng-container i18n="@@intraday-tableHeaderPower"> Power</ng-container>
              [kW]
            </th>
            <td *matCellDef="let deal" mat-cell>{{ deal.dealCapacity | capacityNumber: 'kW' }}</td>
          </ng-container>
          <ng-container matColumnDef="dealPrice">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>
              <ng-container i18n="@@intraday-tableHeaderPrice"> Price</ng-container>
              [€/MWh]
            </th>
            <td *matCellDef="let deal" mat-cell>{{ deal.dealPrice | currency: 'EUR' }}</td>
          </ng-container>
          <ng-container matColumnDef="dealAmount">
            <th *matHeaderCellDef mat-header-cell>
              <ng-container i18n="@@intraday-tableHeaderAmount"> Amount</ng-container>
              [€]
            </th>
            <td *matCellDef="let deal" mat-cell>{{ deal.dealAmount.amount | currency: 'EUR' }}</td>
          </ng-container>
          <ng-container matColumnDef="priceWindowPeriodDay">
            <th *matHeaderCellDef mat-header-cell>
              <ng-container i18n="@@intraday-tableHeaderDay"> Day</ng-container>
            </th>
            <td *matCellDef="let deal" mat-cell>{{ deal.dealPeriod.startDateTime | date: 'shortDate' }}</td>
          </ng-container>
          <ng-container matColumnDef="dealPeriod">
            <th *matHeaderCellDef mat-header-cell>
              <ng-container i18n="@@intraday-tableHeaderPeriod"> Period</ng-container>
            </th>
            <td *matCellDef="let deal" mat-cell>
              {{ deal.dealPeriod.startDateTime | date: 'shortTime' }}
              - {{ deal.dealPeriod.toDateTime | date: 'shortTime' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="gridPointDescription">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>
              <ng-container i18n="@@intraday-tableHeaderGridPointDescription"> Grid point</ng-container>
            </th>
            <td *matCellDef="let deal" mat-cell>{{ deal.gridPointDescription }}</td>
          </ng-container>
          <ng-container matColumnDef="controlDescription">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>
              <ng-container i18n="@@intraday-tableHeaderControl"> Component</ng-container>
            </th>
            <td *matCellDef="let deal" mat-cell>{{ deal.controlDescription }}</td>
          </ng-container>
          <ng-container matColumnDef="dealDirection">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>
              <ng-container i18n="@@intraday-tableHeaderType"> Type</ng-container>
            </th>
            <td *matCellDef="let deal" mat-cell>
              <app-intraday-deal-direction [deal]="deal"></app-intraday-deal-direction>
            </td>
            <ng-container matColumnDef="dealStatus">
              <th *matHeaderCellDef mat-header-cell mat-sort-header>
                <ng-container i18n="@@intraday-tableHeaderStatus"> Status</ng-container>
              </th>
              <td *matCellDef="let deal" mat-cell>
                <app-intraday-deal-display-status [deal]="deal"></app-intraday-deal-display-status>
              </td>
            </ng-container>
          </ng-container>
          <ng-container matColumnDef="customerLegalName">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>
              <ng-container i18n="@@intraday-tableHeaderCustomer"> Company name</ng-container>
            </th>
            <td *matCellDef="let deal" mat-cell>{{ deal.customerLegalName }}</td>
          </ng-container>
          <ng-container matColumnDef="dealReference">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>
              <ng-container i18n="@@intraday-tableHeaderDealReference"> Reference</ng-container>
            </th>
            <td *matCellDef="let deal" mat-cell>{{ deal.dealReference }}</td>
          </ng-container>
          <ng-container matColumnDef="orderType">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>
              <ng-container i18n="@@intraday-tableHeaderMandatoryDelivery"> Mandatory delivery</ng-container>
            </th>
            <td *matCellDef="let deal" mat-cell>
              <ph-flex-vertical-center [ngSwitch]="deal.mandatoryDelivery" [alignCenter]="true">
                <ng-container *ngSwitchCase="true">
                  <mat-icon class="status-on" svgIcon="ic-new:check"></mat-icon>
                </ng-container>
                <ng-container *ngSwitchCase="false">
                  <mat-icon class="status-passive" svgIcon="ic-new:x"></mat-icon>
                </ng-container>
              </ph-flex-vertical-center>
            </td>
          </ng-container>
          <tr *matHeaderRowDef="displayedColumns$ | async" mat-header-row></tr>
          <tr *matRowDef="let row; columns: displayedColumns$ | async" mat-row></tr>
        </table>
        <mat-paginator [showFirstLastButtons]="true"></mat-paginator>
      </ph-flex-loading-context>
    </mat-card-content>
    <mat-card-actions>
      <button (click)="goBack()" mat-stroked-button>
        <ph-flex-vertical-center>
          <mat-icon svgIcon="ic-new:arrow-left"></mat-icon>
          <span i18n="@@intraday-buttonGoBack">Go back</span>
        </ph-flex-vertical-center>
      </button>
    </mat-card-actions>
  </mat-card>
</div>
