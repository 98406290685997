import { Directive, Inject, Injectable, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { get, isArray } from 'lodash-es';
import { AUTOCOMPLETE_DATA_SOURCE_FILTER, DataSourceFilter } from '../../material/autocomplete/autocomplete.component';

@Injectable()
export class DataSourceFilterByGridPointId implements DataSourceFilter<any> {
  gridPointIdSubject = new BehaviorSubject<string | string[]>(null);

  protected gridPointId$ = this.gridPointIdSubject.asObservable();

  getFilter(values: any[]): Observable<boolean[]> {
    return this.gridPointId$.pipe(
      map((gridPointId) => {
        if (gridPointId) {
          const gridPointIds = isArray(gridPointId) ? gridPointId : [gridPointId];

          return values.map((value) =>
            gridPointIds.some((currentGridPointIdFilter) => {
              if (!(get(value, 'gridPointId') || get(value, 'gridPointIds'))) {
                return true;
              }
              return (
                get(value, 'gridPointId') === currentGridPointIdFilter || get(value, 'gridPointIds')?.includes(currentGridPointIdFilter)
              );
            })
          );
        } else {
          return values.map(() => true);
        }
      })
    );
  }
}

@Directive({
  selector: '[phFlexDataSourceFilterByGridPointId]',
  providers: [
    {
      provide: AUTOCOMPLETE_DATA_SOURCE_FILTER,
      useFactory: (a) => a,
      multi: true,
      deps: [DataSourceFilterByGridPointId]
    },
    {
      provide: DataSourceFilterByGridPointId,
      useClass: DataSourceFilterByGridPointId
    }
  ]
})
export class DataSourceFilterByGridPointIdDirective implements OnChanges, OnDestroy {
  @Input('phFlexDataSourceFilterByGridPointId') gridPointId: string | string[];

  constructor(@Inject(DataSourceFilterByGridPointId) private dataSourceFilter: DataSourceFilterByGridPointId) {}

  ngOnDestroy(): void {
    this.dataSourceFilter.gridPointIdSubject.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.gridPointId) {
      this.dataSourceFilter.gridPointIdSubject.next(this.gridPointId);
    }
  }
}
