import { Directive, Host, OnInit } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { default as theme } from 'projects/flex-app-shared/design-tokens/theme.json';
import { FlexDialogComponent } from './dialog.component';
import { OnDestroyMixin } from '../../core/common/on-destroy.mixin';
import { MixinBase } from '../../core/common/constructor-type.mixin';

enum BREAKPOINT_SIZES {
  SMALL = 's'
}

/**
 * Closes target 'ph-flex-dialog' when Window.innerWidth is greater than specified width
 */
@Directive({
  selector: '[phFlexDialogResponsiveClose]'
})
export class FlexModalDialogResponsiveCloseDirective extends OnDestroyMixin(MixinBase) implements OnInit {
  resizeWindowInnerWidthChange$: Observable<number>;

  constructor(@Host() private dialogComponent: FlexDialogComponent) {
    super();
  }

  ngOnInit(): void {
    this.resizeWindowInnerWidthChange$ = fromEvent(window, 'resize').pipe(
      distinctUntilChanged(),
      // eslint-disable-next-line rxjs/no-unsafe-takeuntil
      takeUntil(this.onDestroy$),
      //TODO fix lint
      map(() => window.innerWidth)
    );

    this.resizeWindowInnerWidthChange$.subscribe((windowInnerWidth) => {
      if (windowInnerWidth > theme.layout.width[BREAKPOINT_SIZES.SMALL]) {
        this.dialogComponent?.dialogRef?.close();
      }
    });
  }
}
