import { Directive, Host, TemplateRef } from '@angular/core';
import { TooltipRowDirective } from './tooltip-row.directive';

@Directive({
  selector: '[phFlexTooltipColumnRightDef]'
})
export class TooltipColumnRightDirective {
  constructor(@Host() row: TooltipRowDirective, template: TemplateRef<any>) {
    row.tooltipColumnsRight = template;
  }
}
