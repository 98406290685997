import { ElementRef, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DocumentClickService {
  private documentClickTargetSubject = new Subject<HTMLElement>();

  documentClickTarget$ = this.documentClickTargetSubject.asObservable();

  elementClicked$(elementRef: ElementRef): Observable<HTMLElement> {
    return this.documentClickTarget$.pipe(filter((targetElement) => elementRef.nativeElement.contains(targetElement)));
  }

  outsideElementClicked$(elementRef: ElementRef): Observable<HTMLElement> {
    return this.documentClickTarget$.pipe(filter((targetElement) => !elementRef.nativeElement.contains(targetElement)));
  }

  /**
   * Should be called by an AppComponent
   */
  handleDocumentClick(target: HTMLElement): void {
    this.documentClickTargetSubject.next(target);
  }
}
