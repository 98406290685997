import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { CanActivateSlotsPage, CanDeactivateSlotsPage } from './state/can-activate-slots-page';
import { IntradaySlotComponent } from './intraday/intraday-slot/intraday-slot.component';
import { IntradaySlotOverviewComponent } from './intraday/intraday-slot-overview/intraday-slot-overview.component';
import { IntradayHistoryComponent } from './intraday-history/intraday-history.component';
import { IntraDayComponent } from './intraday/intraday.component';
import { IntradayToolbarContainerComponent } from './intraday-toolbar-container.component';
import { ActivateHistoryState, DeactivateHistoryState } from './state/history/route-active';
import { ActivateIntradayState, DeactivateIntradayState } from './state/route-active';

const routes: Routes = [
  {
    path: '',
    component: IntradayToolbarContainerComponent,
    canActivate: [ActivateIntradayState],
    canDeactivate: [DeactivateIntradayState],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'trading'
      },
      {
        path: 'history',
        component: IntradayHistoryComponent,
        canActivate: [ActivateHistoryState],
        canDeactivate: [DeactivateHistoryState]
      },
      {
        path: 'trading',
        component: IntraDayComponent,
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: IntradaySlotOverviewComponent
          },
          {
            path: 'slot',
            component: IntradaySlotComponent,
            canActivate: [CanActivateSlotsPage],
            canDeactivate: [CanDeactivateSlotsPage]
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class IntradayRoutingModule {}
