import { Directive, Host, Input, OnInit, TemplateRef } from '@angular/core';
import { TooltipComponent } from './tooltip.component';

@Directive({
  selector: '[phFlexTooltipRowDef]'
})
export class TooltipRowDirective implements OnInit {
  tooltipColumnsLeft: TemplateRef<any>;
  tooltipColumnsRight: TemplateRef<any>;

  /**
   * Row name to register
   */
  @Input('phFlexTooltipRowDef') rowName: string;

  constructor(@Host() private tooltipComponent: TooltipComponent) {}

  ngOnInit(): void {
    if (!this.rowName) {
      throw new Error('Row name is required. For a default template use tooltip-default-row.directive');
    }
    this.tooltipComponent.registerRow(this.rowName, this.tooltipColumnsLeft, this.tooltipColumnsRight);
  }
}
