import { Component, Input, OnInit } from '@angular/core';
import { IntraDayDealDisplayStatus, PostPendingIntradayDeal } from 'flex-app-shared';

@Component({
  selector: 'app-intraday-deal-display-status',
  templateUrl: './intraday-deal-display-status.component.html',
  styleUrls: ['./intraday-deal-display-status.component.scss']
})
export class IntradayDealDisplayStatusComponent {
  IntraDayDealDisplayStatus = IntraDayDealDisplayStatus;

  @Input()
  deal: PostPendingIntradayDeal | null;

  constructor() {}

  dealDisplayStatus(): IntraDayDealDisplayStatus | null {
    if (this.deal) {
      return PostPendingIntradayDeal.getDealDisplayStatus(this.deal);
    }
    return null;
  }
}
