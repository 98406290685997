<ng-container *ngIf="availability$ | async; let availability">
  <div class="availability-item-header">
    <div class="actions">
      <button (click)="handleEditClick($event)" *ngIf="canEdit$ | async" class="close-button" mat-icon-button>
        <mat-icon svgIcon="ic-old:inline-edit-o"></mat-icon>
      </button>
    </div>
    <div *ngIf="activationCount$ | async; let activationCount" class="activation-count">
      <span>{{ activationCount }}</span>
    </div>
    <span
      [ngClass]="{ disabled: availability.readOnly, 'period-today': this.isToday$ | async, clickable: isYearView$ | async }"
      (click)="handlePeriodClick($event)"
      class="period"
      >{{ this.periodIndicator$ | async }}</span
    >
  </div>

  <div class="chip-label flex-row align-center">
    <div class="col-1 chips">
      <!-- Calender items statuses -->
      <ng-container *ngIf="availability.noContract">
        <em i18n="@@incidentReserveAvailability-noServiceAgreement">No contract.</em>
      </ng-container>
      <ng-container
        *ngIf="
          availability.hasOtherServiceAgreementsWithAvailabilities &&
          availability.noAvailabilities &&
          !availability.periodCoveredBySelectedServiceAgreement
        "
      >
        <em i18n="@@incidentReserveAvailability-periodCoveredByDifferentContract">Period covered by a different contract.</em>
      </ng-container>
      <ng-container
        *ngIf="availability.periodCoveredBySelectedServiceAgreement && availability.noAvailabilities"
        i18n="@@incidentReserveAvailability-noAvailabilities"
      >
        <em>No availabilities.</em>
      </ng-container>
      <ng-container *ngIf="!availability.noAvailabilities">
        <div
          class="block"
          matTooltip="The deadline to change this period has expired"
          i18n-matTooltip="@@incidentReserveAvailability-deadlineExpired"
          [matTooltipDisabled]="!availability.readOnly"
          *ngIf="availability.capacityUpward?.availabilityType !== AvailabilityType.OFF || availability.capacityUpwardVariable"
        >
          <button
            mat-stroked-button
            class="upwards availability-button button-text-only"
            [disabled]="availability.readOnly"
            (click)="handleUpwardsEditClick($event)"
          >
            <ph-flex-vertical-center class="availability-details">
              <mat-icon class="status-up" svgIcon="ic-old:arrow_upward"></mat-icon>
              <ng-container [ngSwitch]="availability.capacityUpwardVariable">
                <span *ngSwitchCase="true" i18n="@@incidentReserveAvailability-variableCapacity">Variable</span>
                <app-adjust-availability *ngSwitchCase="false" [value]="availability.capacityUpward"></app-adjust-availability>
              </ng-container>
            </ph-flex-vertical-center>
            <ph-flex-vertical-center class="availability-status">
              <ng-container *ngIf="availability.tsoAuctionClosed">
                <mat-icon
                  svgIcon="ic-new:check"
                  *ngIf="availability.hasMatchedUpwardsTsoAgreement"
                  [ngClass]="{ current: availability.isCurrent }"
                  class="has-matched-tso-agreement check"
                ></mat-icon>

                <mat-icon
                  svgIcon="ic-new:x"
                  *ngIf="!availability.hasMatchedUpwardsTsoAgreement"
                  [ngClass]="{ current: availability.isCurrent }"
                  class="has-matched-tso-agreement cross"
                ></mat-icon>
                <span class="availability-fee">{{ upwardsAvailabilityFee$ | async | money }}</span>
              </ng-container>
            </ph-flex-vertical-center>
          </button>
        </div>
        <div
          class="block"
          matTooltip="The deadline to change this period has expired"
          i18n-matTooltip="@@incidentReserveAvailability-deadlineExpired"
          [matTooltipDisabled]="!availability.readOnly"
          *ngIf="availability.capacityDownward?.availabilityType !== AvailabilityType.OFF || availability.capacityDownwardVariable"
        >
          <button
            mat-stroked-button
            class="downwards availability-button button-text-only"
            [disabled]="availability.readOnly"
            (click)="handleDownwardsEditClick($event)"
          >
            <ph-flex-vertical-center class="availability-details">
              <mat-icon class="status-down" svgIcon="ic-old:arrow_downward"></mat-icon>
              <ng-container [ngSwitch]="availability.capacityDownwardVariable">
                <span *ngSwitchCase="true" i18n="@@incidentReserveAvailability-variableCapacity"> Variable</span>
                <app-adjust-availability *ngSwitchCase="false" [value]="availability.capacityDownward"></app-adjust-availability>
              </ng-container>
            </ph-flex-vertical-center>
            <ph-flex-vertical-center class="availability-status">
              <ng-container *ngIf="availability.tsoAuctionClosed">
                <mat-icon
                  *ngIf="availability.hasMatchedDownwardsTsoAgreement"
                  [ngClass]="{ current: availability.isCurrent }"
                  class="has-matched-tso-agreement check"
                  svgIcon="ic-new:check"
                >
                </mat-icon>
                <mat-icon
                  *ngIf="!availability.hasMatchedDownwardsTsoAgreement"
                  [ngClass]="{ current: availability.isCurrent }"
                  class="has-matched-tso-agreement cross"
                  svgIcon="ic-new:x"
                >
                </mat-icon>
                <span class="availability-fee">{{ downwardsAvailabilityFee$ | async | money }}</span>
              </ng-container>
            </ph-flex-vertical-center>
          </button>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="footer">
    <div *ngIf="availability.hasHourlyAvailabilities">
      <mat-icon svgIcon="ic-old:alert-warning" class="status-alert"></mat-icon>
      <ng-container i18n="@@incidentReserveAvailability-hourlyAvailabilityAlert">Has hourly availability.</ng-container>
    </div>
  </div>
</ng-container>
