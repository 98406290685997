import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Host,
  Input,
  OnInit,
  SimpleChanges,
  TrackByFunction,
  ViewChild
} from '@angular/core';
import { EditSlotContext, IntradayState } from '../../../../state/intraday-state.service';
import {
  CustomValidators,
  FlexNgXsFormSync,
  IntradayControl,
  IntraDayControlDeals,
  IntradayControlDirection,
  MixinBase,
  OnDestroyMixin,
  TableFilterComponent
} from 'flex-app-shared';
import {
  BaseFlattenedIntraDayNode,
  FlattenedIntradayControlNode,
  FlattenedIntradayLevel,
  FlattenedIntradayNode
} from '../../../../intraday/intraday-slot/intraday-deal-controls-data-source';
import { FlatTreeControl } from '@angular/cdk/tree';
import { filter, map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Parser } from 'expr-eval';
import { fromPairs, get, has, uniq } from 'lodash-es';
import { Subscription } from 'rxjs';
import { Store } from '@ngxs/store';
import { IntradayFacade } from '../../../../state/intraday-facade.service';
import { StepVolumeDataSource } from './step-volume-data-source';
import { FocusDispatcher, PopoverEditPositionStrategyFactory } from '@angular/cdk-experimental/popover-edit';
import { NonPushDefaultPopoverEditPositionStrategyFactory } from '../../../../intraday/intraday-slot/intraday-slot.component';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { FormGroupProvider } from '../form-group-provider';
import { MatStep, MatStepLabel, MatStepper } from '@angular/material/stepper';
import { IntradayOrderEditSlotContext, OrderDirection } from '../../../../state/order/intraday-order.state';
import { differenceInHours, parseISO } from 'date-fns';
import { IntradaySlotFocusDispatcher } from '../../../../intraday/intraday-slot/intraday-slot-focus-dispatcher.service';

/**
 * WARNING. This class is pretty much a copy paste of intra-day-slot.component.ts (at time of writing)
 * TODO the table can possibly be extracted, but first we should get an idea of the functional overlap between the two.
 */
@Component({
  selector: 'app-step-volume',
  templateUrl: './step-volume.component.html',
  styleUrls: ['./step-volume.component.scss'],
  providers: [
    {
      provide: PopoverEditPositionStrategyFactory,
      useClass: NonPushDefaultPopoverEditPositionStrategyFactory
    },
    {
      provide: FocusDispatcher,
      useClass: IntradaySlotFocusDispatcher
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepVolumeComponent extends OnDestroyMixin(MixinBase) implements OnInit, AfterViewInit, FormGroupProvider {
  OrderDirection = OrderDirection;
  FlattenedIntraDayLevel = FlattenedIntradayLevel;
  IntraDayControlDirection = IntradayControlDirection;

  @FlexNgXsFormSync('intraday.order.selectedVolume', { initialSyncOnlyTruthyFormValues: false })
  formGroup = new UntypedFormGroup({}); // Will be replaced by updateFormGroup

  dealPriceCalculator = Parser.parse('MWhPrice / 1000 * dealVolume * hours');

  lastFormValueChangedSubscription: Subscription = null;

  hasMultipleCustomers$ = this.intraDayFacade.idconsCustomers$.pipe(map((customers) => customers.length > 1));

  displayedColumns = ['description', 'availableCapacityPercentage', 'deal', 'availableCapacity', 'dealPrice'];

  filterFormGroup = this.fb.group({
    gridPointId: [''],
    customerId: ['']
  });

  customerIdsWithCapacity = [];
  gridPointIdsWithCapacity = [];

  dealTotalPerCustomer: {
    [key: string]: number;
  } = {};
  dealTotalPerGridPoint: {
    [key: string]: number;
  } = {};
  @Input() pricePerMWh: number;
  dataSource = new StepVolumeDataSource();

  treeControl = new FlatTreeControl<string>(
    (id) => this.dataSource.getNodeLevel(id),
    (id) => this.dataSource.getNodeLevel(id) < FlattenedIntradayLevel.CONTROL
  );
  tableHasNoPadding$ = this.dataSource.data$.pipe(
    map((data) => !data.some((currentNode) => currentNode.level === FlattenedIntradayLevel.CUSTOMER))
  );
  isExpandable = ((i: number, node: FlattenedIntradayNode) => {
    return this.treeControl.isExpandable(node.id);
  }).bind(this);
  editSlotContext: IntradayOrderEditSlotContext = {
    direction: OrderDirection.BUY,
    priceId: '',
    pricePerMW: 0,
    startDateTime: null,
    toDateTime: null,
    volume: 12
  };
  @ViewChild(MatStepLabel) stepLabel: MatStepLabel;
  @ViewChild('hideEmptyControlsToggle', {
    static: false,
    read: MatSlideToggle
  })
  private readonly hideEmptyControlsToggle: MatSlideToggle;
  @ViewChild(TableFilterComponent, { static: false }) private readonly tableFilter: TableFilterComponent;

  constructor(
    private store: Store,
    private fb: UntypedFormBuilder,
    public intraDayFacade: IntradayFacade,
    @Host() private matStep: MatStep,
    private stepper: MatStepper
  ) {
    super();
  }

  trackBy: TrackByFunction<FlattenedIntradayNode> = (index, item) => {
    switch (item.level) {
      case FlattenedIntradayLevel.CUSTOMER:
        return item.customerId;
      case FlattenedIntradayLevel.GRID_POINT:
        return item.gridPointId;
      case FlattenedIntradayLevel.CONTROL:
        return item.controlId;
      default:
        return index;
    }
  };

  ngAfterViewInit(): void {
    this.dataSource.hideEmptyControlsFilterProvider.register(this.hideEmptyControlsToggle);
    this.dataSource.filter = this.tableFilter;

    this.matStep.stepLabel = this.stepLabel;

    this.dataSource.customerIdFilterProvider.register(this.filterFormGroup.get('customerId'));
    this.dataSource.gridPointIdFilterProvider.register(this.filterFormGroup.get('gridPointId'));

    this.hasMultipleCustomers$
      .pipe(
        // If the customer only has 1 customer, we shouldn't disable the grid point selection
        filter((hasMultipleCustomers) => hasMultipleCustomers),
        switchMap(() => this.filterFormGroup.get('customerId').valueChanges.pipe(startWith(this.filterFormGroup.get('customerId').value))),
        takeUntil(this.onDestroy$)
      )
      .subscribe((result) => {
        const gridPointControl = this.filterFormGroup.get('gridPointId');
        if (!result && gridPointControl.enabled) {
          gridPointControl.disable();
          gridPointControl.reset();
        } else if (result && gridPointControl.disabled) {
          gridPointControl.enable();
        }
      });

    this.dataSource.data$
      .pipe(
        switchMap(() => this.dataSource.builtTree$), // builtTree$ is replaced when data is updated.
        takeUntil(this.onDestroy$)
      )
      .subscribe((result) => {
        const customerIds = uniq(result.filter((item) => has(item, 'customerId')).map((item) => item.customerId));

        const gridPointIds = uniq(
          result
            .filter((item) => has(item, 'gridPointId'))
            // @ts-ignore
            .map((item) => item.gridPointId)
        );

        this.customerIdsWithCapacity = customerIds;
        this.gridPointIdsWithCapacity = gridPointIds;
      });
  }

  getTotalOfAllDeals(): number {
    return Object.values(this.dealTotalPerCustomer).reduce((c, a) => c + a, 0);
  }

  shouldShowLabelWithoutValue(): boolean {
    return this.formGroup.invalid || this.isCurrentStepSelected();
  }

  ngOnInit(): void {
    this.dataSource.treeControlFilterProvider.register(this.treeControl);

    this.intraDayFacade.controls$.pipe(takeUntil(this.onDestroy$)).subscribe((controls) => {
      this.updateFormGroup(controls, {});
    });

    this.dataSource.editSlotContext$.pipe(takeUntil(this.onDestroy$)).subscribe((editSlotcontext) => {
      this.editSlotContext = editSlotcontext;
    });

    this.updateDealTotals();
  }

  isCurrentStepSelected(): boolean {
    return this.stepper.selected === this.matStep;
  }

  rowClick(node: FlattenedIntradayNode, event: Event): void {
    if (this.treeControl.isExpandable(node.id)) {
      this.treeControl.toggleDescendants(node.id);
    }
  }

  getDealPrice(editSlotContext: EditSlotContext, dealVolume: number): number | void {
    if (!editSlotContext) {
      return 0;
    }

    const hours = differenceInHours(parseISO(editSlotContext.toDateTime), parseISO(editSlotContext.startDateTime));

    return this.dealPriceCalculator.evaluate({ MWhPrice: editSlotContext.pricePerMW, dealVolume: dealVolume || 0, hours });
  }

  getAvailableOffset(node: FlattenedIntradayNode): number {
    const totalDealVolume = this.getDealTotal(node);

    const totalRemaining = node.totalAvailableCapacity - totalDealVolume;

    if (totalRemaining > 0) {
      return totalRemaining;
    }

    return -totalDealVolume;
  }

  getDealTotal(node: FlattenedIntradayNode): number {
    switch (node.level) {
      case FlattenedIntradayLevel.CONTROL:
        return this.formGroup.get(node.controlId).value || 0;
      case FlattenedIntradayLevel.CUSTOMER:
        return this.dealTotalPerCustomer[node.customerId];
      case FlattenedIntradayLevel.GRID_POINT:
        return this.dealTotalPerGridPoint[node.gridPointId];
    }
  }

  isNodeInvalid(node: FlattenedIntradayNode): boolean {
    switch (node.level) {
      case FlattenedIntradayLevel.CUSTOMER:
      case FlattenedIntradayLevel.GRID_POINT:
        return node.controlIds.some((controlId) => this.formGroup.get(controlId)?.invalid);
      case FlattenedIntradayLevel.CONTROL:
        return this.formGroup.get(node.controlId)?.invalid;
      default:
        return false;
    }
  }

  isNodeWarning(node: FlattenedIntradayNode): boolean {
    let control;

    switch (node.level) {
      case FlattenedIntradayLevel.CUSTOMER:
      case FlattenedIntradayLevel.GRID_POINT:
        return node.controlIds.some((controlId) => {
          const controlNode: FlattenedIntradayControlNode = this.dataSource.dataAfterFilter.find(
            (currentNode) => currentNode.id === controlId
          ) as FlattenedIntradayControlNode;
          control = this.formGroup.get(controlId);
          return control?.valid && controlNode?.availableCapacity < control?.value;
        });
      case FlattenedIntradayLevel.CONTROL:
        control = this.formGroup.get(node.controlId);
        return control?.valid && node.availableCapacity < control?.value;
      default:
        return false;
    }
  }

  updateFormGroup(controls: IntradayControl[], deals: IntraDayControlDeals): void {
    this.formGroup = new UntypedFormGroup(
      fromPairs(
        controls.map((control) => {
          const initValue = has(deals, control.controlId) ? get(deals, control.controlId) : '';
          const existingControl = this.formGroup.get(control.controlId);

          if (existingControl && existingControl.value !== initValue) {
            existingControl.setValue(initValue);
          }

          // Create a formGroup with the control id as key and a form control as Value
          // This makes it easier to identify the control and to look up the corresponding control object
          return [
            control.controlId,
            existingControl ||
              this.fb.control(initValue, [
                CustomValidators.numberOfDecimals(0, 0),
                CustomValidators.multipleOf(100),
                CustomValidators.minOrZero(100),
                CustomValidators.maxFn(
                  () =>
                    this.dataSource.dataAfterFilter
                      ?.filter((data) => data.level === FlattenedIntradayLevel.CONTROL)
                      // @ts-ignore
                      .find((data) => data.id === control.controlId)?.maxAvailableCapacity
                )
              ])
          ];
        })
      ),
      {
        validators: [
          (formGroup) => {
            const values = Object.values(formGroup.value);
            return values.length > 0 && values.some((value) => !!value) ? null : { volumesRequired: true };
          }
        ]
      }
    );

    if (this.lastFormValueChangedSubscription) {
      this.lastFormValueChangedSubscription.unsubscribe();
      this.lastFormValueChangedSubscription = null;
    }
    this.formGroup.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.updateDealTotals();
    });
    this.matStep.stepControl = this.formGroup;
  }

  applyAvailableOffset(node: FlattenedIntradayNode, event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    const controlIds = BaseFlattenedIntraDayNode.getControlIds(node);
    const controlNodeMap = fromPairs(
      controlIds
        .map((controlId) => [controlId, this.dataSource.dataAfterFilter.find((currentNode) => currentNode.id === controlId)])
        .filter((a) => !!a[1])
    );
    const shouldIncrease = Object.values(controlNodeMap).some((currentNode) => this.getAvailableOffset(currentNode) > 0);

    controlIds.forEach((controlId) => {
      const currentNode: FlattenedIntradayControlNode = controlNodeMap[controlId] as FlattenedIntradayControlNode;

      if (!currentNode) {
        // Skip nodes that are not present in the filtered results
        return;
      }

      if (shouldIncrease) {
        this.formGroup.get(controlId).setValue(currentNode.availableCapacity);
      } else {
        this.formGroup.get(controlId).setValue(0);
      }
    });
  }

  private updateDealTotals(): void {
    this.dealTotalPerCustomer = {};
    this.dealTotalPerGridPoint = {};

    const controls = this.store.selectSnapshot(IntradayState.availableControls);

    controls.forEach((control) => {
      const currentDealValue = this.formGroup.get(control.controlId)?.value || 0;

      const oldTotalPerCustomer = this.dealTotalPerCustomer[control.customerId] || 0;
      this.dealTotalPerCustomer[control.customerId] = oldTotalPerCustomer + currentDealValue;

      const oldTotalPerGridPoint = this.dealTotalPerGridPoint[control.gridPointId] || 0;
      this.dealTotalPerGridPoint[control.gridPointId] = oldTotalPerGridPoint + currentDealValue;
    });
  }
}
