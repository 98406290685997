<mat-card>
  <mat-card-content>
    <table class="deals" [class.expired]="isExpired$ | async" [class.stale]="isStale$ | async">
      <tr>
        <th i18n="@@intraday-tableHeaderHours">Hours</th>
        <th colspan="3" i18n="@@intraday-labelBid">Sell</th>
        <th>Day-ahead</th>
        <th colspan="3" i18n="@@intraday-labelAsk">Buy</th>
      </tr>
      <tr class="with-unit">
        <th></th>
        <th>
          <em i18n="@@intraday-tableHeaderMax">Max</em>
          <span>[kW]</span>
        </th>
        <th>
          <em i18n="@@intraday-tableHeaderVolume">Volume</em>
          <span>[kW]</span>
        </th>
        <th>
          <em i18n="@@intraday-tableHeaderPrice">Price</em>
          <span>[€/MWh]</span>
        </th>
        <th>
          <em i18n="@@intraday-tableHeaderPrice">Price</em>
          <span>[€/MWh]</span>
        </th>
        <th>
          <em i18n="@@intraday-tableHeaderPrice">Price</em>
          <span>[€/MWh]</span>
        </th>
        <th>
          <em i18n="@@intraday-tableHeaderVolume">Volume</em>
          <span>[kW]</span>
        </th>
        <th>
          <em i18n="@@intraday-tableHeaderMax">Max</em>
          <span>[kW]</span>
        </th>
      </tr>
      <tr *ngIf="isStale$ | async">
        <td colspan="8" class="unavailable" i18n="@@intraday-countdownNoPricesAvailable">No prices available</td>
      </tr>
      <ng-container *ngIf="!(isStale$ | async)">
        <tr
          *ngFor="let slot of slots$ | async; trackBy: trackByPriceId"
          [attr.disabled]="isEditEnabledForSlot(slot) ? null : ''"
          [class.ask-disabled]="!isEditEnabledForSlot(slot, Direction.CONSUMPTION)"
          [class.bid-disabled]="!isEditEnabledForSlot(slot, Direction.PRODUCTION)"
        >
          <td>{{ slot.period.startDateTime | date : 'shortTime' }} - {{ slot.period.toDateTime | date : 'shortTime' }}</td>
          <td class="number">
            {{ slot.availableBidCapacity | capacityNumber : 'kW' }}
          </td>
          <td
            class="number edit"
            (click)="
              isEditEnabledForSlot(slot, Direction.PRODUCTION) &&
                editVolume(Direction.PRODUCTION, slot.period.startDateTime, slot.period.toDateTime)
            "
            [phFlexTooltip]="tooltip"
            [context]="{ slot: slot }"
            [phFlexTooltipDisabled]="!(slot.bidDealVolumeTooLow || slot.bidDealVolumeTooHigh || slot.bidDealsInvalid)"
            [attr.disabled]="isEditEnabledForSlot(slot, Direction.PRODUCTION) ? null : ''"
            [class.invalid]="slot.bidDealVolumeTooHigh || slot.bidDealVolumeTooLow || slot.bidDealsInvalid"
          >
            {{ bidDealVolumeCachedDynamicSelector(slot.period.startDateTime, slot.period.toDateTime) | async | number }}
          </td>
          <td class="number" (click)="showPriceHistoryDialog(slot, Direction.PRODUCTION)">
            <ph-flex-vertical-center [alignRight]="true">
              <app-intraday-slot-delta-icon [slot]="slot" [direction]="Direction.PRODUCTION"></app-intraday-slot-delta-icon>
              <span class="price">{{ slot.bidPrice | number : '1.2-2' }}</span>
            </ph-flex-vertical-center>
          </td>
          <td class="number">
            <span class="price">{{ slot.dayAheadPrice | number : '1.2-2' }}</span>
          </td>
          <td class="number" (click)="showPriceHistoryDialog(slot, Direction.CONSUMPTION)">
            <ph-flex-vertical-center [alignRight]="true">
              <app-intraday-slot-delta-icon [slot]="slot" [direction]="Direction.CONSUMPTION"></app-intraday-slot-delta-icon>
              <span class="price">{{ slot.askPrice | number : '1.2-2' }}</span>
            </ph-flex-vertical-center>
          </td>
          <td
            class="number edit"
            (click)="
              isEditEnabledForSlot(slot, Direction.CONSUMPTION) &&
                editVolume(Direction.CONSUMPTION, slot.period.startDateTime, slot.period.toDateTime)
            "
            [phFlexTooltip]="tooltip"
            [context]="{ slot: slot }"
            [phFlexTooltipDisabled]="!(slot.askDealVolumeTooLow || slot.askDealVolumeTooHigh || slot.askDealsInvalid)"
            [class.invalid]="slot.askDealVolumeTooHigh || slot.askDealVolumeTooLow || slot.askDealsInvalid"
          >
            {{ askDealVolumeCachedDynamicSelector(slot.period.startDateTime, slot.period.toDateTime) | async | number }}
          </td>
          <td class="number">
            {{ slot.availableAskCapacity | capacityNumber : 'kw' }}
          </td>
          <ng-template #tooltip="cdkPortal" cdkPortal let-slot="slot">
            <div class="list-item" *ngIf="slot.bidDealVolumeTooLow || slot.askDealVolumeTooLow" i18n="@@intraday-errorDealVolumeTooLow">
              Deal volume is too low, should be at least 100 kW
            </div>
            <div class="list-item" *ngIf="slot.bidDealVolumeTooHigh || slot.askDealVolumeTooHigh" i18n="@@intraday-errorDealVolumeTooHigh">
              Deal volume is too high, should be less than available ({{ slot.availableBidCapacity | capacity : 'kW' }})
            </div>
            <div class="list-item" *ngIf="slot.bidDealsInvalid || slot.askDealsInvalid" i18n="@@intraday-errorDealsInvalid">
              Some deals are invalid based on the component position.
            </div>
          </ng-template>
        </tr>
      </ng-container>
    </table>
  </mat-card-content>
</mat-card>
<mat-card [phFlexAuthShow]="[VIEW_INTRA_DAY_IDCONS_ORDER, VIEW_INTRA_DAY_REM_ORDER]">
  <mat-card-header>
    <h3 matCardTitle i18n="@@intraday-openIntraday+Orders">Open Intraday+ orders</h3>
  </mat-card-header>
  <mat-card-content>
    <app-open-orders></app-open-orders>
  </mat-card-content>
  <mat-card-actions [phFlexAuthShow]="[MANAGE_INTRA_DAY_IDCONS_ORDER, MANAGE_INTRA_DAY_REM_ORDER]">
    <button mat-flat-button color="primary" *ngIf="isOrderCreationEnabled$ | async" (click)="showGopacsDialog()">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:create"></mat-icon>
        <span i18n="@@intraDay-createNewOrder">Create new order</span>
      </ph-flex-vertical-center>
    </button>
  </mat-card-actions>
</mat-card>
