import { forwardRef, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Action, createSelector, Selector, SelectorOptions, State, StateContext, Store } from '@ngxs/store';
import { isSameDay, parseISO } from 'date-fns';
import { Parser } from 'expr-eval';
import {
  AppInjector,
  AsyncMessageReceivedEvent,
  AsyncMessagingService,
  Capacity,
  DealableControlIdsPerDutchDay,
  Direction,
  EnergyPrice,
  getFromInjector,
  getIntraDayPriceDeltaFromPrices,
  IntradayControl,
  IntraDayControlDeals,
  IntradayControlDirection,
  IntradayDeal,
  IntradayDealStatus,
  IntraDayDealStatusUpdate,
  IntraDayDealWithStatus,
  IntraDayGridPointDealDirectionClaimApprovedSseEventPayload,
  IntraDayJson,
  IntraDayPosition,
  IntraDayPositionHelper,
  IntraDayPricesReceivedData,
  IntraDayRecentSummary,
  IntradayService,
  IntraDaySlot,
  IntraDaySlotWithFlags,
  IntraDaySummary,
  MandatoryTradeDirection,
  MessageType,
  PaginatedResponse,
  PostPendingIntradayDeal,
  PriceHistoryJson,
  RecentDeal,
  TimeSlot,
  TokenService
} from 'flex-app-shared';
import { flatMap, fromPairs, groupBy, memoize, uniq } from 'lodash-es';
import moment from 'moment';
import { RangeMap } from 'range-ts';
import { Observable, of, Subscription } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { v4 } from 'uuid';
import { IntradayOrder, IntradayOrderStatusHelper } from '../../shared/intraday-order/intraday-order.service';
import { DayChangeService } from './day-change.service';
import { IntradayHistoryState } from './history/intraday-history-state.service';
import {
  ClearIntraDayDraftDealsCommand,
  ClearIntradayErrorStateCommand,
  ConfirmIntradayDealsCommand,
  EditIntradayTimeSlot,
  IdconsDealingEnabledUpdatedEvent,
  IntradayActivate,
  IntraDayAddOrUpdateRecentDealCommand,
  IntradayControlsUpdatedEvent,
  IntradayDeactivate,
  IntraDayDealableControlsUpdatedEvent,
  IntraDayDealsReceivedEvent,
  IntraDayDealsReceivedForCurrentDayEvent,
  IntraDayDealStatusesReceivedEvent,
  IntraDayJsonReceivedEvent,
  IntraDayPositionsUpdatedEvent,
  IntradayPricesReceivedEvent,
  IntraDaySlotStatusChanged,
  IntradayUpdatePriceHistoryCommand,
  IntradayUpdatePriceHistoryDeactivateCommand,
  IntraDayUpdatePriceHistoryUpdatedEvent,
  MandatoryTradingDirectionsUpdatedEvent,
  MapIntraDayDealsToNewPriceWindowCommand,
  RecentDealsClearedEvent,
  RemLimitOrderDealingEnabledUpdatedEvent,
  SlotsPageActivatedEvent,
  SlotsPageDeactivatedEvent,
  UpdateControlDealsCommand,
  UpdateLatestIntraDayDealsCommand,
  UpdateLatestIntraDayInformationCommand,
  UpdateTodaysIntraDayDealsCommand
} from './intraday.actions';
import { IntradayOrderState, OrderDirection } from './order/intraday-order.state';

export interface EditSlotContext {
  direction: Direction;
  startDateTime: string;
  toDateTime: string;
  pricePerMW: number;
  volume: number;
  priceId: string;
}

class IntradayStateModel {
  /**
   * Deals that are not sent to the backend yet
   */
  draftDeals: IntradayDeal[] = [];

  recentDeals: RecentDeal[] = [];
  receivedDeals: PaginatedResponse<PostPendingIntradayDeal>;
  showOrderTypeColumn: boolean;

  currentPricesTimestamp: string;
  newPricesTimestamp: string;

  currentSlotStatus: IntradaySlotStatus;

  availableControls: IntradayControl[] = [];

  // Slots that have been received but are not active yet
  newSlots: IntraDaySlot[] = [];

  // Slots that are currently active
  slots: IntraDaySlot[] = [];

  // Previous slots that are used to calculate deltas
  oldSlots: IntraDaySlot[] = [];

  mandatoryTradingDirections: MandatoryTradeDirection[] = [];

  dealableControlIdsPerDutchCalendarDay: DealableControlIdsPerDutchDay[] = [];

  saveError: string;
  savePending: boolean;

  // Initialized using IntraDayPricesReceivedSseEvent and/or /latest
  initializedAdHocPrices = false;

  // Initialized using IntraDayDealsReceivedForCurrentDayEvent
  initializedRecentDeals = false;

  // Initialized using IntraDayControlsUpdatedEvent
  initializedControls = false;

  // Initialized using /latest
  initializedPositions = false;

  // Initialized using IntraDayDealableControlsUpdatedEvent
  initializedDealableControlIds = false;

  // Initialed using latest call, which includes idconsDealingEnabled
  initializedIdconsDealingEnabled = false;

  // Initialed using latest call, which includes remLimitOrderDealingEnabled
  initializedRemLimitOrderDealingEnabled = false;

  // Initialized using /latest
  initializedMandatoryTradingDirections = false;

  positions: IntraDayPosition[] = [];

  currentPriceWindowId = null;
  newPriceWindowId = null;

  priceWindowTo: string;
  newPriceWindowTo: string;

  editSlotContext: EditSlotContext;

  idconsDealingEnabled = false;
  remLimitOrderDealingEnabled = false;

  priceHistory: PriceHistoryJson;
  priceHistoryBusy: boolean;
  priceHistoryActive: boolean;

  slotsPageActive: boolean;

  isActive: boolean;
}

@State({
  name: 'intraday',
  defaults: new IntradayStateModel(),
  children: [IntradayOrderState, IntradayHistoryState]
})
@Injectable({
  providedIn: 'root'
})
@SelectorOptions({
  injectContainerState: false,
  suppressErrors: false
})
export class IntradayState {
  static averagePriceFromTotalsCalculator = Parser.parse('a / b');
  static weightedPriceCalculator = Parser.parse('a * b');
  static subtractCalculator = Parser.parse('a - b');

  // Optimized so it can be used in the component template directly
  static dealVolumeForDirection = memoize(
    (direction: Direction, startDateTime: string, toDateTime: string): ((...ags: any) => number) => {
      return createSelector([IntradayState.dealsForDealDirection(direction)], (deals: IntradayDeal[]) => {
        return deals?.reduce((c, deal) => {
          if (TimeSlot.isEqual(deal.dealPeriod, { startDateTime, toDateTime })) {
            return c + Capacity.asKW(deal.dealCapacity);
          }
          return c;
        }, 0);
      });
    },
    (...args) => args.map((arg) => `${arg}`).join('-')
  );
  committedDealsPollingHelper: CommittedIntradayDealPollingHelper;
  intradaySlotsPollingHelper: IntradaySlotsPollingHelper;
  recentDealsDayChangeHandler = this.dayChangeService.registerOnDayChange(() => {
    this.store.dispatch(new RecentDealsClearedEvent());
  });

  constructor(
    private store: Store,
    private intradayService: IntradayService,
    private asyncMessagingService: AsyncMessagingService,
    private router: Router,
    private dayChangeService: DayChangeService
  ) {
    this.committedDealsPollingHelper = new CommittedIntradayDealPollingHelper();
    this.intradaySlotsPollingHelper = new IntradaySlotsPollingHelper();

    this.asyncMessagingService.isConnected$.subscribe((isConnected) => {
      if (isConnected) {
        // Stop polling when connected
        this.committedDealsPollingHelper.stopPolling();
        this.intradaySlotsPollingHelper.stopPolling();
      } else {
        // WARNING. This is called before initialization in some cases, the slotStatus selector should handle state being undefined.
        const slotStatus = store.selectSnapshot(IntradayState.slotStatus);
        if (slotStatus === IntradaySlotStatus.STALE || slotStatus === IntradaySlotStatus.EXPIRED) {
          this.intradaySlotsPollingHelper.startPolling();
        }
      }
    });
  }

  @Selector([IntradayState])
  static slotsPageActive(state: IntradayStateModel): boolean {
    return state.slotsPageActive;
  }

  @Selector([IntradayState])
  static currentPriceHistoryData(state: IntradayStateModel): PriceHistoryJson {
    return state.priceHistory;
  }

  @Selector([IntradayState])
  static priceHistoryBusy(state: IntradayStateModel): boolean {
    return state.priceHistoryBusy;
  }

  @Selector([IntradayState])
  static draftDeals(state: IntradayStateModel): IntradayDeal[] {
    return state.draftDeals;
  }

  @Selector([IntradayState.draftDeals])
  static hasDraftDeals(deals: IntradayDeal[]): boolean {
    return deals?.length > 0;
  }

  @Selector([IntradayState])
  static dealsInvalidBasedOnAvailableCapacityOrMinVolume(state: IntradayStateModel): boolean {
    const dealsByPriceId = groupBy(state.draftDeals, (deal) => deal.priceId);

    return Object.keys(dealsByPriceId).some((priceId) => {
      const deals = dealsByPriceId[priceId];
      const foundSlot = state.slots.find((slot) => slot.askPriceId === priceId || slot.bidPriceId === priceId);

      if (!foundSlot) {
        return false;
      }

      const availableCapacity = foundSlot.askPriceId === priceId ? foundSlot.availableAskCapacity : foundSlot.availableBidCapacity;
      const totalDealVolume = deals.reduce((c, a) => c + Capacity.asKW(a.dealCapacity), 0);

      return totalDealVolume > Capacity.asKW(availableCapacity) || totalDealVolume < 100;
    });
  }

  @Selector([IntradayState, IntradayState.slotsWithFlags])
  static dealsInvalidBasedOnControlValidations(state: IntradayStateModel, slots: IntraDaySlotWithFlags[]): boolean {
    return state.draftDeals.some((deal) => {
      const foundSlot = slots.find((slot) => slot.bidPriceId === deal.priceId || slot.askPriceId === deal.priceId);
      if (!foundSlot) {
        // No slot is found for price due to priceId changing
        return true;
      }
      return deal.dealDirection === Direction.PRODUCTION ? foundSlot.bidDealsInvalid : foundSlot.askDealsInvalid;
    });
  }

  @Selector([IntradayState])
  static receivedDeals(state: IntradayStateModel): PaginatedResponse<PostPendingIntradayDeal> {
    return state.receivedDeals;
  }

  @Selector([IntradayState])
  static showOrderTypeColumn(state: IntradayStateModel): boolean {
    return state.showOrderTypeColumn;
  }

  @Selector([IntradayState])
  static editSlotContext(state: IntradayStateModel): EditSlotContext {
    return state.editSlotContext;
  }

  @Selector([IntradayState.editSlotContext])
  static editSlotContextPeriod(editSlotContext: EditSlotContext): TimeSlot {
    return TimeSlot.toTimeSlot(editSlotContext.startDateTime, editSlotContext.toDateTime);
  }

  @Selector([IntradayState])
  static slotStatus(state: IntradayStateModel): IntradaySlotStatus {
    // Selector can be called before init, so state can be undefined.
    return state?.currentSlotStatus;
  }

  @Selector([IntradayState])
  static draftDealTotals(state: IntradayStateModel): { volume: number; direction: Direction; period: TimeSlot }[] {
    return flatMap(state.slots, (slot) => {
      const deals = state.draftDeals.filter((deal) => TimeSlot.isEqual(deal.dealPeriod, slot.period));

      return [
        {
          direction: Direction.CONSUMPTION,
          period: slot.period,
          volume: deals
            .filter((deal) => deal.dealDirection === Direction.CONSUMPTION)
            .reduce((c, a) => Capacity.asKW(a.dealCapacity) + c, 0)
        },
        {
          direction: Direction.PRODUCTION,
          period: slot.period,
          volume: deals.filter((deal) => deal.dealDirection === Direction.PRODUCTION).reduce((c, a) => Capacity.asKW(a.dealCapacity) + c, 0)
        }
      ];
    });
  }

  /**
   * Check if current draft deals overlap with known open orders.
   * Only match on 'open' orders and orders that match with the gridpoints associated with the current draft deals
   */
  @Selector([IntradayState.draftDeals, IntradayOrderState.openOrders, IntradayState.availableControls])
  static hasOpenOrdersForDraftDeals(
    draftDeals: IntradayDeal[],
    openOrders: PaginatedResponse<IntradayOrder>,
    controls: IntradayControl[]
  ): boolean {
    if (!openOrders?.content || openOrders.content.length === 0) {
      return false;
    }
    const gridPointEans = uniq(draftDeals.map((deal) => controls.find((control) => control.controlId === deal.controlId)?.gridPointEan));

    // Filter out statuses for orders that aren't open anymore

    const filteredOpenOrders = openOrders.content
      .filter((order) => !IntradayOrderStatusHelper.isEndState(order.status))
      .filter((order) => gridPointEans.includes(order.gridPointEan));

    const dealPeriods = uniq(draftDeals.map((deal) => deal.dealPeriod));
    return dealPeriods.some((period) => filteredOpenOrders.some((order) => TimeSlot.overlaps(order.period, period)));
  }

  /**
   * Return moment representing the startOfDay + the remaining time in ms.
   */
  @Selector([IntradayState])
  static priceWindowTo(state: IntradayStateModel): string | null {
    return state.priceWindowTo ? state.priceWindowTo : null;
  }

  @Selector([IntradayState])
  static availableControls(state: IntradayStateModel): IntradayControl[] {
    return state.availableControls;
  }

  @Selector([IntradayState])
  static slots(state: IntradayStateModel): IntraDaySlot[] {
    return state.slots;
  }

  @Selector([IntradayState])
  static oldSlots(state: IntradayStateModel): IntraDaySlot[] {
    return state.oldSlots;
  }

  @Selector([
    IntradayState.draftDeals,
    IntradayState.slots,
    IntradayState.oldSlots,
    IntradayState.positions,
    IntradayState.availableControls,
    IntradayState.mandatoryTradingDirections
  ])
  static slotsWithFlags(
    draftDeals: IntradayDeal[],
    slots: IntraDaySlot[],
    oldSlots: IntraDaySlot[],
    positions: IntraDayPosition[],
    availableControls: IntradayControl[],
    mandatoryTradingDirections: MandatoryTradeDirection[]
  ): IntraDaySlotWithFlags[] {
    const dealsByPriceId = groupBy(draftDeals, (deal) => deal.priceId);

    return slots.map((slot) => {
      const askDealVolume = (dealsByPriceId[slot.askPriceId] || []).reduce((c, a) => c + Capacity.asKW(a.dealCapacity), 0);
      const bidDealVolume = (dealsByPriceId[slot.bidPriceId] || []).reduce((c, a) => c + Capacity.asKW(a.dealCapacity), 0);
      const oldSlot = oldSlots.find((currentOldSlot) => TimeSlot.isEqual(currentOldSlot.period, slot.period));

      let askDealsInvalid = false;
      let bidDealsInvalid = false;

      const positionHelper = new IntraDayPositionHelper(slot.period);

      positions
        .filter((position) => TimeSlot.overlaps(position.period, slot.period))
        .forEach((position) => {
          positionHelper.add(position);
        });

      const combinedPositions = positionHelper.getPositions();

      draftDeals
        .filter((deal) => TimeSlot.isEqual(deal.dealPeriod, slot.period))
        .forEach((deal) => {
          if (deal.dealDirection === Direction.PRODUCTION ? bidDealsInvalid : askDealsInvalid) {
            // Skip if we already found an invalid deal
            return;
          }

          const foundControl = availableControls.find((control) => deal.controlId === control.controlId);
          const foundPosition = combinedPositions.find(
            (position) => position.controlId === deal.controlId && TimeSlot.overlaps(position.period, deal.dealPeriod)
          );

          const positionMin = Capacity.asKW(foundPosition?.positionMin) || 0;
          const positionMax = Capacity.asKW(foundPosition?.positionMax) || 0;

          const mandatoryTradeDirection = mandatoryTradingDirections.find(
            (currentMandatoryTradeDirection) =>
              currentMandatoryTradeDirection.gridPointId === foundControl.gridPointId &&
              TimeSlot.overlaps(slot.period, currentMandatoryTradeDirection.period)
          )?.direction;

          const availablePower = calculateAvailableControlPower(
            Capacity.asKW(foundControl.namePlatePower),
            positionMin,
            positionMax,
            foundControl.controlDirection,
            deal.dealDirection,
            true,
            mandatoryTradeDirection
          );

          const isInvalid = Capacity.asKW(deal.dealCapacity) > availablePower;
          if (isInvalid) {
            if (deal.dealDirection === Direction.PRODUCTION) {
              bidDealsInvalid = true;
            } else {
              askDealsInvalid = true;
            }
          }
        });

      let askPriceDelta = null;
      let bidPriceDelta = null;
      let askPriceDeltaAmount = null;
      let bidPriceDeltaAmount = null;

      if (oldSlot) {
        // both the old price (oldSlot) and new one (slot) must be dealable to allow showing a delta.
        if (IntraDaySlot.canBeDealt(oldSlot, Direction.CONSUMPTION) && IntraDaySlot.canBeDealt(slot, Direction.CONSUMPTION)) {
          askPriceDelta = getIntraDayPriceDeltaFromPrices(oldSlot.askPrice, slot.askPrice);
          askPriceDeltaAmount = slot.askPrice - oldSlot.askPrice;
        }
        if (IntraDaySlot.canBeDealt(oldSlot, Direction.PRODUCTION) && IntraDaySlot.canBeDealt(slot, Direction.PRODUCTION)) {
          bidPriceDelta = getIntraDayPriceDeltaFromPrices(oldSlot.bidPrice, slot.bidPrice);
          bidPriceDeltaAmount = slot.bidPrice - oldSlot.bidPrice;
        }
      }

      return {
        ...slot,
        askDealVolumeTooLow: askDealVolume !== 0 && askDealVolume < 100,
        bidDealVolumeTooLow: bidDealVolume !== 0 && bidDealVolume < 100,
        askDealVolumeTooHigh: askDealVolume > Capacity.asKW(slot.availableAskCapacity),
        bidDealVolumeTooHigh: bidDealVolume > Capacity.asKW(slot.availableBidCapacity),
        askPriceDelta,
        bidPriceDelta,
        askPriceDeltaAmount,
        bidPriceDeltaAmount,
        askDealsInvalid,
        bidDealsInvalid
      };
    });
  }

  @Selector([IntradayState])
  static positions(state: IntradayStateModel): IntraDayPosition[] {
    return state.positions;
  }

  @Selector([IntradayState])
  static committedDeals(state: IntradayStateModel): IntraDayDealWithStatus[] {
    return state.recentDeals;
  }

  @Selector([IntradayState])
  static currentPriceWindowId(state: IntradayStateModel): string {
    return state.currentPriceWindowId;
  }

  @Selector([IntradayState])
  static mandatoryTradingDirections(state: IntradayStateModel): MandatoryTradeDirection[] {
    return state.mandatoryTradingDirections;
  }

  @Selector([IntradayState])
  static dealableControlIdsPerDutchCalendarDay(state: IntradayStateModel): DealableControlIdsPerDutchDay[] {
    return state.dealableControlIdsPerDutchCalendarDay;
  }

  static dealableControlIds(slotContextDate: Date | null | undefined): (...args: any) => string[] {
    return createSelector(
      [IntradayState.dealableControlIdsPerDutchCalendarDay],
      (dealableControlIdsPerDutchCalendarDay: DealableControlIdsPerDutchDay[]) => {
        if (!slotContextDate) {
          return [];
        }
        return dealableControlIdsPerDutchCalendarDay.find((it) => isSameDay(parseISO(it.dutchDay), slotContextDate))?.controlIds || [];
      }
    );
  }

  static dealsForDealDirection(direction: Direction): (...args: any) => IntradayDeal[] {
    return createSelector([IntradayState], (state: IntradayStateModel) => {
      return state.draftDeals.filter((deal) => deal.dealDirection === direction);
    });
  }

  static intraDayControlDealsForDirection(
    direction: Direction,
    startDateTime: string,
    toDateTime: string
  ): (...args: any) => IntraDayControlDeals {
    return createSelector(
      [IntradayState, IntradayState.dealsForDealDirection(direction)],
      (model: IntradayStateModel, deals: IntradayDeal[]) => {
        return fromPairs(
          deals
            .filter((deal) => TimeSlot.isEqual(deal.dealPeriod, { startDateTime, toDateTime }))
            .map((deal) => [deal.controlId, Capacity.asKW(deal.dealCapacity)])
        );
      }
    );
  }

  static slot(startDateTime: string, toDateTime: string): (...args: any) => IntraDaySlot {
    return createSelector([IntradayState.slots], (slots: IntraDaySlot[]) =>
      slots.find((slot) => TimeSlot.isEqual(slot.period, { startDateTime, toDateTime }))
    );
  }

  static slotWithFlags(startDateTime: string, toDateTime: string): (...args: any) => IntraDaySlotWithFlags {
    return createSelector([IntradayState.slotsWithFlags], (slots: IntraDaySlotWithFlags[]) =>
      slots.find((slot) => TimeSlot.isEqual(slot.period, { startDateTime, toDateTime }))
    );
  }

  static summaryForDirection(direction: Direction): (...args: any) => IntraDaySummary {
    return createSelector([IntradayState.dealsForDealDirection(direction)], (deals: IntradayDeal[]) => {
      let totalVolume = 0;
      let totalWeightedPrice = 0;

      deals.forEach((deal) => {
        totalVolume += Capacity.asKW(deal.dealCapacity);
        totalWeightedPrice += IntradayState.weightedPriceCalculator.evaluate({
          a: Capacity.asKW(deal.dealCapacity),
          b: deal.dealPrice
        });
      });

      return {
        numberOfDeals: deals.length,
        volume: deals.reduce((c, a) => c + Capacity.asKW(a.dealCapacity), 0) || 0,
        averagePrice:
          IntradayState.averagePriceFromTotalsCalculator.evaluate({
            a: totalWeightedPrice,
            b: totalVolume
          }) || 0
      };
    });
  }

  static intraDayDealStatusSummaryForDirection(direction: Direction): (...args: any) => IntraDayRecentSummary {
    return createSelector([IntradayState], (state: IntradayStateModel) => {
      const dealsForDirection = state.recentDeals.filter((deal) => deal.dealDirection === direction);

      const pending = dealsForDirection.filter((deal) =>
        [IntradayDealStatus.AWAITING_APPROVAL, IntradayDealStatus.APPROVED, IntradayDealStatus.AWAITING_CONFIRMATION].includes(
          deal.dealStatus
        )
      );
      const confirmed = dealsForDirection.filter((deal) => deal.dealStatus === IntradayDealStatus.CONFIRMED);
      const failed = dealsForDirection.filter(
        (deal) =>
          deal.dealStatus === IntradayDealStatus.FAILED ||
          deal.dealStatus === IntradayDealStatus.INVALID ||
          deal.dealStatus === IntradayDealStatus.REJECTED
      );

      let totalVolumeKW = 0;
      let totalWeightedPrice = 0;

      confirmed.forEach((deal) => {
        totalVolumeKW += Capacity.asKW(deal.dealCapacity);
        totalWeightedPrice += IntradayState.weightedPriceCalculator.evaluate({
          a: Capacity.asKW(deal.dealCapacity),
          b: deal.dealPrice
        });
      });

      return {
        numberOfDeals: confirmed.length,
        volume: confirmed.reduce((c, a) => c + Capacity.asKW(a.dealCapacity), 0) || 0,
        averagePrice:
          IntradayState.averagePriceFromTotalsCalculator.evaluate({
            a: totalWeightedPrice,
            b: totalVolumeKW
          }) || 0,
        pending: pending.length,
        confirmed: confirmed.length,
        failed: failed.length
      };
    });
  }

  @Selector([IntradayState])
  static saveError(state: IntradayStateModel): string {
    return state.saveError;
  }

  @Selector([IntradayState])
  static savePending(state: IntradayStateModel): boolean {
    return state.savePending;
  }

  @Selector([IntradayState])
  static idconsDealingEnabled(state: IntradayStateModel): boolean {
    return state.idconsDealingEnabled;
  }

  @Selector([IntradayState])
  static idconsDealingDisabled(state: IntradayStateModel): boolean {
    return !state.idconsDealingEnabled;
  }

  @Selector([IntradayState])
  static remLimitOrderDealingEnabled(state: IntradayStateModel): boolean {
    return state.remLimitOrderDealingEnabled;
  }

  @Selector([IntradayState])
  static isOrderCreationEnabled(state: IntradayStateModel): boolean {
    return state.remLimitOrderDealingEnabled || state.idconsDealingEnabled;
  }

  @Action(IntraDayDealStatusesReceivedEvent)
  handleIntraDayDealStatusesReceived(
    { patchState, getState }: StateContext<IntradayStateModel>,
    { dealStatuses }: IntraDayDealStatusesReceivedEvent
  ): void {
    patchState({
      recentDeals: getState().recentDeals.map((deal) => {
        const foundDealStatus = dealStatuses.find((dealStatus) => dealStatus.id === deal.id);
        if (foundDealStatus) {
          return {
            ...deal,
            dealStatus: foundDealStatus.dealStatus
          };
        }
        return deal;
      })
    });
  }

  @Action(IntraDaySlotStatusChanged)
  updateSlotStatus({ patchState, getState, dispatch }: StateContext<IntradayStateModel>, action: IntraDaySlotStatusChanged): void {
    const oldState = getState().currentSlotStatus;
    patchState({
      currentSlotStatus: action.status
    });

    if (
      (!oldState || oldState === IntradaySlotStatus.CLOSE_TO_EXPIRED || oldState === IntradaySlotStatus.VALID) &&
      (action.status === IntradaySlotStatus.EXPIRED || action.status === IntradaySlotStatus.STALE)
    ) {
      // Went from valid to invalid

      if (!this.asyncMessagingService.isConnected) {
        this.intradaySlotsPollingHelper.startPolling();
      }

      if (getState().newPriceWindowId) {
        // New prices available
        dispatch(
          new IntradayPricesReceivedEvent({
            receivedDateTime: getState().newPricesTimestamp,
            priceWindowId: getState().newPriceWindowId,
            priceWindowTo: getState().newPriceWindowTo,
            priceWindowStart: getState().priceWindowTo, // Could also be new Date().toISOString(), is currently unused
            slots: getState().newSlots
          })
        );
      }
    }

    if (action.status === IntradaySlotStatus.STALE) {
      // Clear draft deals
      patchState({
        draftDeals: []
      });
    }
  }

  @Action(IntraDayDealableControlsUpdatedEvent)
  handleIntraDayDealableControlsUpdatedEvent(
    { patchState }: StateContext<IntradayStateModel>,
    { dealableControlIdsPerDutchCalendarDay }: IntraDayDealableControlsUpdatedEvent
  ): any {
    patchState({
      dealableControlIdsPerDutchCalendarDay,
      initializedDealableControlIds: true
    });
  }

  @Action(UpdateLatestIntraDayDealsCommand)
  handleDealsUpdate({ dispatch }: StateContext<IntradayStateModel>, action: UpdateLatestIntraDayDealsCommand): void {
    this.intradayService.getFilteredDeals(action.pageIndex, action.pageSize, action.sort, action.filter).subscribe((result) => {
      dispatch(new IntraDayDealsReceivedEvent(result.deals, result.unpagedContainsMandatoryDeals));
    });
  }

  @Action(UpdateTodaysIntraDayDealsCommand)
  handleTodaysDealsUpdate({ dispatch }: StateContext<IntradayStateModel>, action: UpdateTodaysIntraDayDealsCommand): void {
    this.intradayService
      .getDeals(
        action.pageIndex,
        action.pageSize,
        action.searchTerm,
        action.sort,
        action.searchCategory,
        action.customerId,
        action.dealStatus,
        action.direction,
        true
      )
      .subscribe((result) => {
        dispatch(new IntraDayDealsReceivedEvent(result.deals, result.unpagedContainsMandatoryDeals));
      });
  }

  @Action(UpdateLatestIntraDayInformationCommand)
  handleUpdate(
    { getState, dispatch, patchState }: StateContext<IntradayStateModel>,
    { force }: UpdateLatestIntraDayInformationCommand
  ): void {
    const { initializedRecentDeals, initializedControls, initializedDealableControlIds } = getState();

    // TODO Maybe remove after FA-4299 is implemented
    // Used to only be called when it was not yet initialized, but we had some issues with day ahead deals which were not fetched, and the update events were not sent to the user.
    this.intradayService.getLatest().subscribe((result) => {
      dispatch(new IntraDayJsonReceivedEvent(result));
    });

    if (!initializedRecentDeals) {
      this.registerRecentDealsOnDayChange();

      patchState({
        initializedRecentDeals: true
      });

      const latestDeals$ = this.intradayService
        .getDealsForCurrentDay()
        .pipe(switchMap((result) => dispatch(new IntraDayDealsReceivedForCurrentDayEvent(result))));

      this.asyncMessagingService
        .onMessageOfTypeWithInit(latestDeals$, MessageType.NewIntraDayDealSseEvent)
        .pipe(filter((message) => message.type !== MessageType.Response))
        .subscribe((sseMessage) => {
          const recentDeal: RecentDeal = JSON.parse(sseMessage.payload);
          dispatch(new IntraDayAddOrUpdateRecentDealCommand(recentDeal));
        });

      this.asyncMessagingService.onMessageOfType(MessageType.TradingIntraDayDealCreatedSseEvent).subscribe((sseMessage) => {
        const recentDeal: RecentDeal = JSON.parse(sseMessage.payload);
        const newDeal = {
          ...recentDeal,
          dealPrice: EnergyPrice.asEURPerMWh(recentDeal.dealPrice as unknown as EnergyPrice)
        };

        dispatch(new IntraDayAddOrUpdateRecentDealCommand(newDeal));
      });
    }

    if (force || !initializedControls) {
      this.intradayService.getControls().subscribe((result) => {
        dispatch(new IntradayControlsUpdatedEvent(result));
      });
    }

    if (force || !initializedDealableControlIds) {
      this.intradayService.getDealableControlIds().subscribe((result) => {
        dispatch(new IntraDayDealableControlsUpdatedEvent(result));
      });
    }
  }

  @Action(IntradayControlsUpdatedEvent)
  handleIntraDayControlsUpdatedEvent({ patchState }: StateContext<IntradayStateModel>, { controls }: IntradayControlsUpdatedEvent): void {
    patchState({
      availableControls: controls,
      initializedControls: true
    });
  }

  @Action(IntraDayDealsReceivedForCurrentDayEvent)
  handleDealsReceivedForCurrentDay(
    { patchState, getState }: StateContext<IntradayStateModel>,
    action: IntraDayDealsReceivedForCurrentDayEvent
  ): void {
    const pendingRecentDeals = getState()
      .recentDeals.filter((deal) => deal.dealStatus === IntradayDealStatus.PENDING)
      .filter((deal) => !action.deals.content.some((updatedDeal) => updatedDeal.id === deal.id));

    patchState({
      // Preserve pending deals that have not been updated
      recentDeals: [...action.deals.content, ...pendingRecentDeals],
      initializedRecentDeals: true
    });
  }

  @Action(IntradayPricesReceivedEvent)
  handleIntraDayPricesReceivedEvent(
    { patchState, getState, dispatch }: StateContext<IntradayStateModel>,
    { result }: IntradayPricesReceivedEvent
  ): void {
    const currentSlotStatus = getState().currentSlotStatus;

    if (!currentSlotStatus || currentSlotStatus === IntradaySlotStatus.EXPIRED || currentSlotStatus === IntradaySlotStatus.STALE) {
      patchState({
        initializedAdHocPrices: true,
        currentPriceWindowId: result.priceWindowId,
        newSlots: [],
        newPriceWindowId: null,
        newPriceWindowTo: null,
        newPricesTimestamp: null,
        oldSlots: getState().slots,
        slots: result.slots,
        currentPricesTimestamp: result.receivedDateTime,
        priceWindowTo: result.priceWindowTo
      });
      dispatch(new MapIntraDayDealsToNewPriceWindowCommand());
    } else {
      // Ready new slot information for next expiry
      patchState({
        newSlots: result.slots,
        newPriceWindowTo: result.priceWindowTo,
        newPriceWindowId: result.priceWindowId,
        newPricesTimestamp: result.receivedDateTime
      });
    }
  }

  @Action(IdconsDealingEnabledUpdatedEvent)
  handleIdconsDealingEnabledUpdatedEvent(
    { patchState, getState, dispatch }: StateContext<IntradayStateModel>,
    { result }: IdconsDealingEnabledUpdatedEvent
  ): void {
    patchState({
      initializedIdconsDealingEnabled: true,
      idconsDealingEnabled: result
    });
  }

  @Action(RemLimitOrderDealingEnabledUpdatedEvent)
  handleRemLimitOrderDealingEnabledUpdatedEvent(
    { patchState, getState, dispatch }: StateContext<IntradayStateModel>,
    { result }: RemLimitOrderDealingEnabledUpdatedEvent
  ): void {
    patchState({
      initializedRemLimitOrderDealingEnabled: true,
      remLimitOrderDealingEnabled: result
    });
  }

  @Action(MandatoryTradingDirectionsUpdatedEvent)
  handleMandatoryTradingDirectionsUpdatedEvent(
    { patchState, getState, dispatch }: StateContext<IntradayStateModel>,
    { result }: MandatoryTradingDirectionsUpdatedEvent
  ): void {
    patchState({
      initializedMandatoryTradingDirections: true,
      mandatoryTradingDirections: result
    });
  }

  @Action(IntraDayPositionsUpdatedEvent)
  handleIntraDayPositionsUpdatedEvent(
    { patchState, getState, dispatch }: StateContext<IntradayStateModel>,
    { result }: IntraDayPositionsUpdatedEvent
  ): void {
    patchState({
      positions: result,
      initializedPositions: true
    });
  }

  @Action(IntraDayJsonReceivedEvent)
  handleJsonReceivedEvent(
    { patchState, getState, dispatch }: StateContext<IntradayStateModel>,
    { result }: IntraDayJsonReceivedEvent
  ): void {
    const idconsDealingEnabled = result.idconsDealingEnabled === undefined ? getState().idconsDealingEnabled : result.idconsDealingEnabled;
    const remLimitOrderDealingEnabled =
      result.remLimitOrderDealingEnabled === undefined ? getState().remLimitOrderDealingEnabled : result.remLimitOrderDealingEnabled;

    dispatch([
      new IntradayPricesReceivedEvent(result),
      new IdconsDealingEnabledUpdatedEvent(idconsDealingEnabled),
      new RemLimitOrderDealingEnabledUpdatedEvent(remLimitOrderDealingEnabled),
      new MandatoryTradingDirectionsUpdatedEvent(result.mandatoryTradingDirections),
      new IntraDayPositionsUpdatedEvent(result.positions)
    ]);
  }

  @Action(MapIntraDayDealsToNewPriceWindowCommand)
  updateDraftIntraDayDeals({ patchState, getState }: StateContext<IntradayStateModel>): void {
    patchState({
      draftDeals: getState()
        .draftDeals.map((deal) => {
          const newSlot: IntraDaySlot = this.store.selectSnapshot(
            IntradayState.slot(deal.dealPeriod.startDateTime, deal.dealPeriod.toDateTime)
          );
          if (!newSlot) {
            return null;
          }

          return {
            ...deal,
            priceId: deal.dealDirection === Direction.PRODUCTION ? newSlot.bidPriceId : newSlot.askPriceId,
            dealPrice: deal.dealDirection === Direction.PRODUCTION ? newSlot.bidPrice : newSlot.askPrice
          };
        })
        .filter((a) => !!a) // Filter out deals that do not map to a new slot (they are old)
    });
  }

  @Action(UpdateControlDealsCommand)
  updateDealDealHandler({ patchState, getState }: StateContext<IntradayStateModel>, action: UpdateControlDealsCommand): void {
    const slot = this.store.selectSnapshot(IntradayState.slot(action.startDateTime, action.toDateTime));

    if (!slot || !action.deals?.length) {
      // No slot present, or no deals to update
      return;
    }

    const price = action.direction === Direction.CONSUMPTION ? slot.askPrice : slot.bidPrice;
    const priceId = action.direction === Direction.CONSUMPTION ? slot.askPriceId : slot.bidPriceId;

    const convertedDeals: IntradayDeal[] = action.deals.map((controlDeal) => ({
      id: v4(),
      dealPeriod: slot.period,
      controlId: controlDeal.controlId,
      dealCapacity: Capacity.kW(controlDeal.volume),
      dealPrice: price,
      dealDirection: action.direction === Direction.CONSUMPTION ? Direction.CONSUMPTION : Direction.PRODUCTION,
      priceId
    }));

    patchState({
      draftDeals: this.updateDeals(getState().draftDeals, convertedDeals)
    });
  }

  @Action(ClearIntradayErrorStateCommand)
  clearIntraDayErrorState({ patchState }: StateContext<IntradayStateModel>): void {
    patchState({
      saveError: null,
      savePending: false
    });
  }

  @Action(ClearIntraDayDraftDealsCommand)
  clearIntraDayDraftDeals({ patchState }: StateContext<IntradayStateModel>, { dialogRef }: ClearIntraDayDraftDealsCommand): void {
    patchState({
      draftDeals: [],
      savePending: false
    });
    dialogRef?.close();
  }

  @Action(ConfirmIntradayDealsCommand)
  handleConfirmDealsCommand(
    { getState, patchState, dispatch }: StateContext<IntradayStateModel>,
    { dialogRef }: ConfirmIntradayDealsCommand
  ): void {
    if (getState().savePending) {
      // Extra check to hopefully prevent FA-1632
      console.warn('Ignoring ConfirmIntraDayDealsCommand due to savePending being true');
      return;
    }

    const newlyCommittedDeals = getState().draftDeals.map((deal) => ({
      ...deal,
      dealStatus: IntradayDealStatus.PENDING,
      lastUpdated: moment().toISOString()
    }));
    if (this.router.url.includes('/intraday/trading/slot')) {
      this.router.navigate(['/intraday/trading']);
    }

    patchState({
      savePending: true,
      saveError: null,
      recentDeals: [...getState().recentDeals, ...newlyCommittedDeals]
    });

    this.intradayService.sendDeals(newlyCommittedDeals).subscribe({
      next: () => {
        if (!this.asyncMessagingService.isConnected) {
          this.committedDealsPollingHelper.dealIds = getState().recentDeals.map((deal) => deal.id);
          this.committedDealsPollingHelper.startPolling();
        }
        dispatch(new ClearIntraDayDraftDealsCommand(dialogRef));
      },
      error: (err) => {
        // Mark all deals as FAILED since they were not accepted by the backend, if they are still pending
        patchState({
          saveError: err?.error?.message || err?.message,
          savePending: false,
          recentDeals: getState().recentDeals.map((deal) => {
            if (!newlyCommittedDeals.some((newDeal) => deal.id !== newDeal.id) || deal.dealStatus !== IntradayDealStatus.PENDING) {
              return deal;
            }
            return {
              ...deal,
              status: IntradayDealStatus.FAILED
            };
          })
        });
      }
    });
  }

  @Action(IntraDayDealsReceivedEvent)
  handleDealsReceivedEvent({ getState, patchState }: StateContext<IntradayStateModel>, action: IntraDayDealsReceivedEvent): void {
    patchState({
      receivedDeals: action.deals,
      showOrderTypeColumn: action.showOrderTypeColumn,
      recentDeals: getState().recentDeals.map((deal) => {
        const foundMatchingDeal = action.deals.content.find((receivedDeal) => receivedDeal.id === deal.id);
        if (foundMatchingDeal && moment(foundMatchingDeal.lastUpdated).isAfter(moment(deal.lastUpdated))) {
          return {
            ...deal,
            status: foundMatchingDeal.dealStatus
          };
        }
        return deal;
      })
    });
  }

  @Action(IntraDayAddOrUpdateRecentDealCommand)
  handleIntraDayAddOrUpdateRecentDealCommand(
    { getState, patchState }: StateContext<IntradayStateModel>,
    { newOrUpdatedRecentDeal }: IntraDayAddOrUpdateRecentDealCommand
  ): void {
    patchState({
      recentDeals: addToOrUpdate(
        getState().recentDeals,
        {
          ...newOrUpdatedRecentDeal
        },
        (a) => a.id
      )
    });
  }

  @Action(AsyncMessageReceivedEvent)
  handleSseMessageReceived({ getState, dispatch, patchState }: StateContext<IntradayStateModel>, action: AsyncMessageReceivedEvent): void {
    switch (action.message?.type) {
      case MessageType.IntraDayPricesReceivedSseEvent:
        const intraDayJson: IntraDayPricesReceivedData = JSON.parse(action.message.payload);
        dispatch(new IntradayPricesReceivedEvent(intraDayJson));
        break;
      case MessageType.IntraDayDealStatusSseEvent:
        const parsedIntraDayDealStatusPayload: IntraDayDealStatusSsePayload = JSON.parse(action.message.payload);
        const convertedDeals: PostPendingIntradayDeal[] = getState().receivedDeals?.content.map((deal) =>
          IntraDayDealStatusSsePayload.apply(deal, parsedIntraDayDealStatusPayload)
        ) as PostPendingIntradayDeal[];

        patchState({
          recentDeals: getState().recentDeals.map((deal) => IntraDayDealStatusSsePayload.apply(deal, parsedIntraDayDealStatusPayload)),
          receivedDeals: convertedDeals ? { ...getState().receivedDeals, content: convertedDeals } : null
        });

        break;
      case MessageType.IntraDayPriceCapacityAvailabilitySseEvent:
        const parsedAvailableCapacityPayload: IntraDayPriceCapacityAvailabilitySsePayload = JSON.parse(action.message.payload);

        patchState({
          slots: getState().slots.map((slot) => {
            if (slot.askPriceId === parsedAvailableCapacityPayload.priceId) {
              return {
                ...slot,
                availableAskCapacity: parsedAvailableCapacityPayload.availableCapacity
              };
            }
            if (slot.bidPriceId === parsedAvailableCapacityPayload.priceId) {
              return {
                ...slot,
                availableBidCapacity: parsedAvailableCapacityPayload.availableCapacity
              };
            }
            return slot;
          })
        });
        break;
      case MessageType.IntraDayControlPositionChangedSseEvent:
        const intraDayPosition: IntraDayPosition = JSON.parse(action.message.payload);

        const rangeMap = new RangeMap<IntraDayPosition>();

        const positionsForCurrentControlId = getState().positions.filter((position) => position.controlId === intraDayPosition.controlId);
        const positionsForOtherControlIds = getState().positions.filter((position) => position.controlId !== intraDayPosition.controlId);

        positionsForCurrentControlId.forEach((position) => {
          rangeMap.put(TimeSlot.toNumberRange(position.period), position);
        });

        rangeMap.put(TimeSlot.toNumberRange(intraDayPosition.period), intraDayPosition);

        const rangeMapResult = [...rangeMap.asMapOfRanges().entries()].map(([key, value]) => {
          return {
            ...value,
            period: TimeSlot.fromNumberRangeWithTime(key)
          };
        });

        patchState({
          positions: [...positionsForOtherControlIds, ...rangeMapResult]
        });
        break;

      case MessageType.IntraDayDealableDaysClearedEvent:
        const parsedDealableDaysClearedEvent = JSON.parse(action.message.payload);
        const clearedDays: string[] = parsedDealableDaysClearedEvent?.clearedDutchDays || [];
        const clearedGridPointIds: string[] = parsedDealableDaysClearedEvent?.gridPointIds || [];

        const clearedControlIds: string[] = getState()
          .availableControls.filter((availableControl) => clearedGridPointIds.includes(availableControl.gridPointId))
          .map((availableControl) => availableControl.controlId);

        patchState({
          dealableControlIdsPerDutchCalendarDay: getState().dealableControlIdsPerDutchCalendarDay.map((dealableDutchDay) => {
            if (clearedDays.includes(dealableDutchDay.dutchDay)) {
              // If no gridPoints are provided, clear all, otherwise clear only the provided gridpoints
              const controlIds =
                clearedGridPointIds.length === 0
                  ? []
                  : dealableDutchDay.controlIds.filter((controlId) => !clearedControlIds.includes(controlId));

              return {
                ...dealableDutchDay,
                controlIds
              };
            }
            return dealableDutchDay;
          })
        });
        break;

      case MessageType.IntraDayDealableDaysRefreshedEvent:
        this.intradayService.getDealableControlIds().subscribe((result) => {
          patchState({
            dealableControlIdsPerDutchCalendarDay: result
          });
        });
        break;

      case MessageType.IntraDayIdconsDealingSseEvent:
        patchState({
          idconsDealingEnabled: JSON.parse(action.message.payload).enabled
        });
        break;

      case MessageType.IntraDayRemLimitOrdersSseEvent:
        patchState({
          remLimitOrderDealingEnabled: JSON.parse(action.message.payload).enabled
        });
        break;

      case MessageType.IntraDayGridPointDealDirectionClaimApprovedSseEvent:
        const dealDirectionClaimApprovedPayload: IntraDayGridPointDealDirectionClaimApprovedSseEventPayload = JSON.parse(
          action.message.payload
        );
        patchState({
          mandatoryTradingDirections: addToOrUpdate(
            getState().mandatoryTradingDirections,
            {
              period: dealDirectionClaimApprovedPayload.dealPeriod,
              gridPointId: dealDirectionClaimApprovedPayload.gridPointId,
              customerId: dealDirectionClaimApprovedPayload.customerId,
              direction: dealDirectionClaimApprovedPayload.dealDirection,
              id: dealDirectionClaimApprovedPayload.id
            },
            (a) => a.id
          )
        });
        break;
    }
  }

  @Action(EditIntradayTimeSlot)
  editIntradayTimeSlot({ patchState }: StateContext<IntradayStateModel>, action: EditIntradayTimeSlot): void {
    const slot = this.store.selectSnapshot(IntradayState.slot(action.startDateTime, action.toDateTime));
    const timeSlot = this.store.selectSnapshot(IntradayState.slot(action.startDateTime, action.toDateTime));

    patchState({
      editSlotContext: {
        direction: action.direction,
        startDateTime: action.startDateTime,
        toDateTime: action.toDateTime,
        pricePerMW: action.direction === Direction.CONSUMPTION ? slot.askPrice : slot.bidPrice,
        volume: this.store.selectSnapshot(IntradayState.dealVolumeForDirection(action.direction, action.startDateTime, action.toDateTime)),
        priceId: action.direction === Direction.CONSUMPTION ? timeSlot.askPriceId : timeSlot.bidPriceId
      }
    });

    this.router.navigate(['intraday/trading/slot']);
  }

  @Action(IntradayPricesReceivedEvent)
  updateSlotContext({ getState, patchState }: StateContext<IntradayStateModel>): void {
    const direction = getState().editSlotContext?.direction;
    const startDateTime = getState().editSlotContext?.startDateTime;
    const toDateTime = getState().editSlotContext?.toDateTime;

    let newSlotContext = getState().editSlotContext;

    if (!newSlotContext) {
      // If we don't have an edit slot context, we don't care about the price update so we ignore the update
      return;
    }

    if (startDateTime && toDateTime) {
      const slot = this.store.selectSnapshot(IntradayState.slot(startDateTime, toDateTime));

      newSlotContext = {
        ...newSlotContext,
        pricePerMW: direction === Direction.CONSUMPTION ? slot?.askPrice : slot?.bidPrice,
        priceId: direction === Direction.CONSUMPTION ? slot?.askPriceId : slot?.bidPriceId
      };
    }

    if (startDateTime && toDateTime && direction) {
      newSlotContext = {
        ...newSlotContext,
        volume: this.store.selectSnapshot(IntradayState.dealVolumeForDirection(direction, startDateTime, toDateTime))
      };
    }

    patchState({
      editSlotContext: newSlotContext
    });
  }

  @Action(RecentDealsClearedEvent)
  handleRecentDealsClearedEvent({ patchState }: StateContext<IntradayStateModel>): void {
    patchState({
      recentDeals: []
    });
    this.registerRecentDealsOnDayChange();
  }

  @Action(IntradayPricesReceivedEvent)
  updateHistoryIfNewPricesReceivedAndHistoryIsActive({ getState, dispatch }: StateContext<IntradayStateModel>): void {
    const timeSlot = getState().priceHistory?.slotPeriod;

    if (getState().priceHistoryActive && timeSlot) {
      dispatch(new IntradayUpdatePriceHistoryCommand(timeSlot));
    }
  }

  @Action(IntradayUpdatePriceHistoryCommand, { cancelUncompleted: true })
  handleIntraDayUpdatePriceHistoryCommand(
    { patchState, dispatch }: StateContext<IntradayStateModel>,
    { timeSlot }: IntradayUpdatePriceHistoryCommand
  ): any {
    patchState({
      priceHistoryBusy: true,
      priceHistoryActive: true
    });

    return this.intradayService.getPriceHistory(timeSlot).pipe(tap((data) => dispatch(new IntraDayUpdatePriceHistoryUpdatedEvent(data))));
  }

  @Action(IntraDayUpdatePriceHistoryUpdatedEvent)
  handleIntraDayUpdatePriceHistoryUpdatedEvent(
    { patchState, getState }: StateContext<IntradayStateModel>,
    { data }: IntraDayUpdatePriceHistoryUpdatedEvent
  ): any {
    if (!getState().priceHistoryActive) {
      // Do not update data if price history is not active
      return;
    }

    patchState({
      priceHistoryBusy: false,
      priceHistory: data
    });
  }

  @Action(IntradayUpdatePriceHistoryDeactivateCommand)
  handleIntraDayUpdatePriceHistoryDeactivateCommand({ patchState }: StateContext<IntradayStateModel>): any {
    patchState({
      priceHistoryActive: false,
      priceHistoryBusy: false,
      priceHistory: undefined
    });
  }

  @Action(SlotsPageActivatedEvent)
  handleSlotsPageActivatedEvent({ patchState }: StateContext<IntradayStateModel>): any {
    patchState({
      slotsPageActive: true
    });
  }

  @Action(SlotsPageDeactivatedEvent)
  handleSlotsPageDeactivatedEvent({ patchState }: StateContext<IntradayStateModel>): any {
    patchState({
      slotsPageActive: false
    });
  }

  @Action(IntradayActivate)
  activateIntradayState({ patchState, getState, dispatch }: StateContext<IntradayStateModel>): any {
    if (!getState().isActive) {
      dispatch(new UpdateLatestIntraDayInformationCommand(false));

      patchState({
        isActive: true
      });
    }
  }

  @Action(IntradayDeactivate)
  deactivateIntradayState({ patchState }: StateContext<IntradayStateModel>): any {
    patchState({
      isActive: false
    });
  }

  private registerRecentDealsOnDayChange(): void {
    this.recentDealsDayChangeHandler.reset();
  }

  private updateDeals(currentDeals: IntradayDeal[], newDeals: IntradayDeal[]): IntradayDeal[] {
    return currentDeals
      .filter((deal) => !newDeals.some((newDeal) => IntradayDeal.overlaps(deal, newDeal)))
      .concat(newDeals)
      .filter((deal) => Capacity.isAboveZero(deal.dealCapacity)); // Filter out empty deals
  }
}

abstract class IntraDayDealStatusSsePayload {
  id: string;
  message: string;
  dealStatus: IntradayDealStatus;

  static apply(
    deal: PostPendingIntradayDeal | IntraDayDealWithStatus,
    payload: IntraDayDealStatusSsePayload
  ): PostPendingIntradayDeal | IntraDayDealWithStatus {
    if (deal.id !== payload.id) {
      return deal;
    }
    return {
      ...deal,
      dealStatus: payload.dealStatus
    };
  }
}

interface IntraDayPriceCapacityAvailabilitySsePayload {
  priceId: string;
  availableCapacity: Capacity;
}

export abstract class PollingHelper<T> {
  intervalHandle: any;
  requestPending = false;
  currentPolls = 0;
  lastSourceSubscription: Subscription;

  private isInitializing = true;

  constructor(protected source$: Observable<T>, interval: number = 5000, public maxPolls: number | null = 10) {
    this.interval = interval;
    this.isInitializing = false;
  }

  private _interval: number;

  get interval(): number {
    return this._interval;
  }

  set interval(value: number) {
    this._interval = value;
    if (!this.isInitializing) {
      this.startPolling();
    }
  }

  get isPolling(): boolean {
    return !!this.intervalHandle;
  }

  abstract isDone(value: T): boolean;

  stopPolling(): void {
    this.currentPolls = 0;
    if (this.intervalHandle) {
      clearInterval(this.intervalHandle);
      this.intervalHandle = null;
    }
  }

  startPolling(): void {
    this.stopPolling();

    this.intervalHandle = setInterval(() => {
      this.intervalFired();
      this.currentPolls += 1;
      if (this.maxPolls !== null && this.currentPolls >= this.maxPolls) {
        this.stopPolling();
      }
    }, this.interval);
  }

  private intervalFired(): void {
    this.requestPending = true;

    if (this.lastSourceSubscription) {
      this.lastSourceSubscription.unsubscribe();
      this.lastSourceSubscription = undefined;
    }

    // Only subscribe if a token is available
    this.lastSourceSubscription = getFromInjector(TokenService)
      .pipe(
        switchMap((tokenService) => tokenService.validIdToken$),
        filter((validIdToken) => !!validIdToken),
        switchMap(() => this.source$)
      )
      .subscribe((result) => {
        if (this.isDone(result)) {
          this.stopPolling();
        }
      });
  }
}

export enum IntradaySlotStatus {
  EXPIRED = 'EXPIRED', // Between 0 seconds remaining and 5 minutes
  STALE = 'STALE', // After 5 minutes
  VALID = 'VALID', // Still valid
  CLOSE_TO_EXPIRED = 'CLOSE_TO_EXPIRED' // Show warning, but still valid
}

class CommittedIntradayDealPollingHelper extends PollingHelper<IntraDayDealStatusUpdate[]> {
  public dealIds: string[] = [];

  get store(): Store {
    return AppInjector.get(Store);
  }

  get intraDayService(): IntradayService {
    return AppInjector.get(IntradayService);
  }

  constructor() {
    super(
      of(null).pipe(
        switchMap(() => this.intraDayService.getDealStatuses(this.dealIds)),
        tap((result) => this.store.dispatch(new IntraDayDealStatusesReceivedEvent(result)))
      )
    );
  }

  isDone(): boolean {
    return this.store
      .selectSnapshot(IntradayState.committedDeals)
      .every((deal) => deal.dealStatus === IntradayDealStatus.FAILED || deal.dealStatus === IntradayDealStatus.CONFIRMED);
  }
}

class IntradaySlotsPollingHelper extends PollingHelper<IntraDayJson> {
  get store(): Store {
    return AppInjector.get(Store);
  }

  get intraDayService(): IntradayService {
    return AppInjector.get(IntradayService);
  }

  constructor() {
    super(
      of(null).pipe(
        switchMap(() => this.intraDayService.getLatest()),
        tap((result) => this.store.dispatch(new IntraDayJsonReceivedEvent(result)))
      ),
      5000,
      null
    );
  }

  isDone(value: IntraDayJson): boolean {
    return moment(value.priceWindowTo).isAfter(moment());
  }
}

const marginCalculator = Parser.parse('round(power * 1.15)');

export function directionToOrderDirection(direction: Direction | OrderDirection): OrderDirection {
  if (direction === OrderDirection.SELL || direction === OrderDirection.BUY) {
    return direction;
  }

  if (direction === Direction.CONSUMPTION || direction === Direction.PRODUCTION) {
    return direction === Direction.CONSUMPTION ? OrderDirection.BUY : OrderDirection.SELL;
  }

  console.warn('unknown direction in directionToOrderDirection: ', direction);
  return null;
}

export function calculateAvailableControlPower(
  namePlatePower: number,
  positionMin: number | null,
  positionMax: number | null,
  controlDirection: IntradayControlDirection,
  direction: Direction | OrderDirection,
  useMargin: boolean,
  mandatoryDirection?: Direction
): number {
  const namePlatePowerWithMargin = useMargin ? marginCalculator.evaluate({ power: namePlatePower }) : namePlatePower;
  const directionMatchesControlDirection =
    (controlDirection === IntradayControlDirection.PRODUCTION &&
      (direction === Direction.PRODUCTION || direction === OrderDirection.SELL)) ||
    (controlDirection === IntradayControlDirection.CONSUMPTION &&
      (direction === Direction.CONSUMPTION || direction === OrderDirection.BUY));

  const position = (directionMatchesControlDirection ? positionMax : positionMin) || 0;

  if (mandatoryDirection) {
    // Direction is mandatory, return 0 if direction is different
    const mandatoryOrderDirection = directionToOrderDirection(mandatoryDirection);
    const orderDirection = directionToOrderDirection(direction);

    if (orderDirection !== mandatoryOrderDirection) {
      return 0;
    }
  }

  return directionMatchesControlDirection
    ? Math.max(
        IntradayState.subtractCalculator.evaluate({
          a: namePlatePowerWithMargin,
          b: position
        }),
        0
      )
    : Math.max(position, 0);
}

/**
 * Get max or min position based on direction
 */
export function getPosition(
  positionMin: number | null,
  positionMax: number | null,
  controlDirection: IntradayControlDirection,
  direction: Direction | OrderDirection
): number {
  const directionMatchesControlDirection =
    (controlDirection === IntradayControlDirection.PRODUCTION &&
      (direction === Direction.PRODUCTION || direction === OrderDirection.SELL)) ||
    (controlDirection === IntradayControlDirection.CONSUMPTION &&
      (direction === Direction.CONSUMPTION || direction === OrderDirection.BUY));

  return directionMatchesControlDirection ? positionMax : positionMin;
}

export function addToOrUpdate<T>(target: T[], value: T, identifyWith: (a: T) => any): T[] {
  let updated = false;
  const valueIdentifier = identifyWith(value);
  const newTarget = target.map((item) => {
    if (identifyWith(item) === valueIdentifier) {
      updated = true;
      return {
        ...item,
        ...value
      };
    }
    return item;
  });
  return updated ? newTarget : [...newTarget, value];
}
