import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UserPreferences, UserPreferenceService } from '../user-preference.service';

@Component({
  selector: 'app-user-preferences-dialog',
  templateUrl: './user-preferences-dialog.component.html',
  styleUrls: ['./user-preferences-dialog.component.scss']
})
export class UserPreferencesDialogComponent implements OnInit {
  userPreferences: UserPreferences;
  initialized = false;
  busy = false;
  error = '';

  dialogForm = this.fb.group({
    intraDayOrdersRequestedEmailEnabled: [false],
    intraDayOrdersRequestedSmsEnabled: [false],
    intraDayOrderMatchedEmailEnabled: [false]
  });

  constructor(public userPreferenceService: UserPreferenceService, public fb: UntypedFormBuilder, public dialogRef: MatDialogRef<any>) {}

  ngOnInit(): void {
    this.userPreferenceService.getByUserName().subscribe(
      (prefs) => {
        this.userPreferences = prefs;
        this.initForm(prefs);
        this.busy = false;
        this.initialized = true;
        this.error = '';
      },
      (error) => {
        this.busy = false;
        this.error = error.error.message;
      }
    );
  }

  private initForm(userPreferences: UserPreferences): void {
    this.dialogForm.get('intraDayOrdersRequestedEmailEnabled').setValue(userPreferences.intraDayOrdersRequestedEmailEnabled);
    this.dialogForm.get('intraDayOrdersRequestedSmsEnabled').setValue(userPreferences.intraDayOrdersRequestedSmsEnabled);
    this.dialogForm.get('intraDayOrderMatchedEmailEnabled').setValue(userPreferences.intraDayOrderMatchedEmailEnabled);
  }

  handleSave(): void {
    this.busy = true;
    const userPreferences = {
      intraDayOrdersRequestedEmailEnabled: this.dialogForm.get('intraDayOrdersRequestedEmailEnabled').value,
      intraDayOrdersRequestedSmsEnabled: this.dialogForm.get('intraDayOrdersRequestedSmsEnabled').value,
      intraDayOrderMatchedEmailEnabled: this.dialogForm.get('intraDayOrderMatchedEmailEnabled').value,
      userName: this.userPreferences.userName,
      name: this.userPreferences.name
    } as UserPreferences;

    this.userPreferenceService.update(userPreferences).subscribe(
      () => {
        this.dialogRef.close();
        this.busy = false;
        this.error = '';
      },
      (error) => {
        this.busy = false;
        this.error = error.error.message;
      }
    );
  }
}
