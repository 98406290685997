import { Component, OnInit } from '@angular/core';

/**
 * Use with <mat-select> to display tabs as a dropdown
 */
@Component({
  selector: 'ph-flex-toolbar-tab-select',
  templateUrl: './toolbar-tab-select.component.html',
  styleUrls: ['./toolbar-tab-select.component.scss']
})
export class ToolbarTabSelectComponent {
  constructor() {}
}
