<th mat-header-cell *matHeaderCellDef colspan="100">
  <ng-container *ngFor="let action of actions">
    <button mat-icon-button (click)="actionButtonClicked(action)" [ngSwitch]="action">
      <mat-icon class="mat-icon-text" *ngSwitchCase="TableSelectionAction.DELETE" svgIcon="ic-old:trash"></mat-icon>
    </button>
    <span i18n="@@tableSelection-nOfMSelectedText"
      >({{ getSelectedRowCount$() | async }} of {{ getTotalRowCount$() | async }} selected)</span
    >
  </ng-container>
</th>

<ng-template let-dialogRef="dialogRef" let-dialogData #confirmDeleteDialog>
  <mat-dialog-content>
    <strong i18n="@@tableSelection-deleteDialogConfirmation"
      >Are you sure you want to delete {{ getSelectedRowCount$() | async }} items?</strong
    >
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-stroked-button (click)="dialogRef.close()">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:close"></mat-icon>
        <span i18n="@@tableSelection-buttonCancel">Cancel</span>
      </ph-flex-vertical-center>
    </button>
    <ph-flex-save-button (clickChanged)="performDialogAction(dialogRef, dialogData.action)" [error]="actionError" [pending]="actionBusy">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:trash"></mat-icon>
        <span i18n="@@tableSelection-buttonDelete">Delete</span>
      </ph-flex-vertical-center>
    </ph-flex-save-button>
  </mat-dialog-actions>
</ng-template>
