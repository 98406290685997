import { Component, HostBinding, Input } from '@angular/core';

/**
 * Use as a parent of a mat table to allow scrolling to work properly.
 * Supports sticky top headers.
 */
@Component({
  selector: 'ph-flex-table-container',
  templateUrl: './table-container.component.html',
  styleUrls: ['./table-container.component.scss']
})
export class TableContainerComponent {
  @Input()
  @HostBinding('class.sticky-header')
  stickyHeader: boolean = true;
}
