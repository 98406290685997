<h2 mat-dialog-title>
  <ng-container i18n="@@intraday-priceHistoryDialogTitle">Price history intraday:</ng-container>
  <ng-container *ngIf="slotPeriod$ | async; let slotPeriod">
    {{ slotPeriod.startDateTime | date: 'shortTime' }} - {{ slotPeriod.toDateTime | date: 'shortTime' }}
  </ng-container>
</h2>
<mat-dialog-content>
  <ph-flex-loading-context [isLoading]="facade.priceHistoryBusy$ | async">
    <app-intraday-price-history-graph [data]="data$ | async" [direction]="dialogData.direction"></app-intraday-price-history-graph>
  </ph-flex-loading-context>
</mat-dialog-content>
