import {
  D3GraphSubmodule,
  DestroyableMixin,
  DynamicDataHelper,
  MixinBase,
  OnDestroyMixin,
  SubjectProvider,
  TimeSlot,
  theme,
  OperationMeasurements
} from 'flex-app-shared';
import { BaseType, Selection } from 'd3-selection';
import { combineLatest, Observable } from 'rxjs';
import { ScaleTime } from 'd3';
import { parseISO } from 'date-fns';
import { ScaleContinuousNumeric } from 'd3-scale';
import { takeUntil } from 'rxjs/operators';

type SupportedXScale = ScaleTime<number, number>;
type SupportedYScale = ScaleContinuousNumeric<number, number>;

const labelBaseline = $localize`:@@incidentReserveActivationsDetail-baselineLabel:Baseline`;
const labelRampUp = $localize`:@@incidentReserveActivationsDetail-rampUpLabel:Ramp up`;
const labelDelivery = $localize`:@@incidentReserveActivationsDetail-deliveryLabel:Delivery`;
const labelRampDown = $localize`:@@incidentReserveActivationsDetail-rampDownLabel:Ramp down`;

export class ChartStageBackgroundSubmodule extends DestroyableMixin(OnDestroyMixin(MixinBase)) implements D3GraphSubmodule {
  private dataProvider = new SubjectProvider<OperationMeasurements>(this);
  private xScaleProvider = new SubjectProvider<SupportedXScale>(this);
  private yScaleProvider = new SubjectProvider<ScaleContinuousNumeric<any, any>>(this);

  private dataHelper: BackgroundXAreaDataHelper;

  setData(data: OperationMeasurements): void {
    this.dataProvider.next(data);
  }

  setData$(data$: Observable<OperationMeasurements>): void {
    this.dataProvider.follow(data$);
  }

  setXScale$(scale$: Observable<SupportedXScale>): void {
    this.xScaleProvider.follow(scale$);
  }

  setYScale$(scale$: Observable<SupportedYScale>): void {
    this.yScaleProvider.follow(scale$);
  }

  attach(mainSvg: Selection<BaseType, any, any, any>): () => any {
    const container = mainSvg.append('g').attr('class', 'r3-activation-stages');

    this.dataHelper = new BackgroundXAreaDataHelper(container);

    const subscription = combineLatest([this.dataProvider.value$, this.xScaleProvider.value$, this.yScaleProvider.value$])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([data]) => {
        this.dataHelper.setData(this.convertData(data));
      });

    return () => subscription.unsubscribe();
  }

  private convertData(measurements: OperationMeasurements): OperationMeasurementsDatum[] {
    const xScale = this.xScaleProvider.value;
    const yScaleRange = this.yScaleProvider.value.range();

    const y1 = Math.min(...yScaleRange);
    const y2 = Math.max(...yScaleRange);

    const yData = {
      y: y1,
      height: y2 - y1
    };

    return [
      {
        fill: theme.color.ui.support['grey-light'],
        ...this.periodToParameters(measurements.baselinePeriod),
        ...yData,
        label: labelBaseline
      },
      {
        fill: theme.color.markets.reserve.translucent10,
        ...this.periodToParameters(measurements.rampUpPeriod),
        ...yData,
        label: labelRampUp
      },
      {
        fill: theme.color.markets.reserve.translucent,
        ...this.periodToParameters(measurements.targetPeriod),
        ...yData,
        label: labelDelivery
      },
      {
        fill: theme.color.markets.reserve.translucent10,
        ...this.periodToParameters(measurements.rampDownPeriod),
        ...yData,
        label: labelRampDown
      }
    ];
  }

  private periodToParameters(period: TimeSlot): { x: number; width: number } {
    const xScale = this.xScaleProvider.value;

    const x1 = xScale(parseISO(period.startDateTime));
    const x2 = xScale(parseISO(period.toDateTime));

    return {
      x: x1,
      width: x2 - x1
    };
  }
}

class OperationMeasurementsDatum {
  fill: string;
  x: number;
  width: number;
  y: number;
  height: number;
  label: string;
}

/**
 * Define
 */
export class BackgroundXAreaDataHelper extends DynamicDataHelper<OperationMeasurementsDatum> {
  protected class = 'stage';
  protected nodeName = 'g';

  protected setDynamic(selectAllWithData: Selection<BaseType, OperationMeasurementsDatum, BaseType, any>): void {
    const labelOffset = 8;

    selectAllWithData
      .select('.baseline')
      .attr('x', (datum) => datum.x)
      .attr('width', (datum) => datum.width)
      .attr('y', (datum) => datum.y)
      .attr('height', (datum) => datum.height)
      .attr('fill', (datum) => datum.fill);

    selectAllWithData
      .select('text')
      .text((datum) => datum.label)
      .attr('x', (datum) => datum.x + datum.width / 2)
      .attr('y', (datum) => datum.y - labelOffset);
  }

  protected setStatic(selectAllWithData: Selection<BaseType, OperationMeasurementsDatum, BaseType, any>): void {
    selectAllWithData.append('rect').attr('class', 'baseline');

    selectAllWithData.append('text').attr('class', 'stage-label').style('text-anchor', 'middle');
  }
}
